<template>
    <div class="py-1 container-fluid">
        <div class="">
            <vue-splitter v-model:percent="splitter_list" :initial-percent="splitter_list">
                <template #left-pane>
                    <div class="card m-1" v-if="splitter_list !=0">

                        <div class="bg-gradient-dark-green p-1 border-radius-xl d-flex">

                            <button class="btn btn-xs btn-round btn-danger mb-0" v-if="conversationShow"
                                @click="conversationShow = false; conversationId = null;">
                                <i class="fa fa-arrow-left me-1"></i> Retour</button>
                            <div style="flex:1" v-if="conversationShow"></div>
                            <h6 class="mb-0 text-white px-2" v-if="!conversationShow">Liste des mails</h6>
                            <h6 class="mb-0 text-white px-2" v-else>Conversations</h6>
                            <div style="flex:1" v-if="!conversationShow"></div>
                            <button class="btn btn-xs btn-round btn-white mb-0" v-if="!conversationShow"
                                @click="newVisible = true">
                                <i class="fa fa-plus me-1"></i> Nouveau mail</button>

                        </div>

                        <div class="card text-center">
                            <ContextMenu ref="menu" :model="options">
                                <template #item="slotProps">
                                    <div class="p-1">
                                        <i :class="slotProps.item.icon"></i>
                                        &nbsp;
                                        <span>{{ slotProps.item.label }}</span>

                                    </div>
                                </template>
                            </ContextMenu>
                            <Modal v-model="newVisible" modal closeable header="Nouvel e-mail">
                                <NewMail v-model="newMailContent" />
                                <div class="d-flex align-items-center justify-content-center gap-2">
                                    <button class="btn btn-danger" @click="newVisible = false">Annuler</button>
                                    <div style="flex:10"></div>
                                    <button class="btn btn-danger bg-gradient-success"
                                        @click="sendNewMail">Envoyer</button>
                                </div>
                            </Modal>
                        </div>
                        <div class="container text-center mb-0  ">
                            <div class="mt-2">
                                <ArgonInput icon="fa fa-search" iconDir="left" placeholder="Rechercher"
                                    v-model="search" />


                            </div>
                            <!--
            <div class="dataTable-search ">
                <input   class="form-control rounded-lg dataTable-input search-input-table" placeholder="Recherche..." type="text">
            </div>-->
                        </div>
                        <!--<div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Add Role' }"
                    class="mb-0 btn bg-gradient-success btn-sm">
                      <i class="fa fa-plus me-1"/> Add Role
                  </router-link>
                </div>
              </div>-->


                        <div class="card">

                            <!-- Make sure you add the `ref` attribute, as that is what gives you the ability
to open the menu. -->
                            <!-- Card header -->
                            <div class="px-0 pb-0 pt-0 card-body">
                                <el-select class="select-primary mailbox-select col-sm-12" v-model="box"
                                    placeholder="Mailbox" size="small" filterable>
                                    <el-option class="select-primary" v-for="item in boxitems" :key="item[0]"
                                        :label="item[1]" :value="item[0]">
                                        {{ item[1] }}
                                    </el-option>
                                </el-select>
                                <div class="table-responsive" id="infinite-list" style="overflow-x: hidden;"
                                    :style="'height:calc(100vh - ' + minus_list + 'px);'">
                                    <table class="table table-responsive">
                                        <tbody class="text-sm">
                                            <tr v-for="row in roleList" v-bind:key="row.id">
                                                <div @contextmenu="onRightClick($event, row.id)" @click="editItem(row)"
                                                    class=" d-flex align-items-start flex-column justify-content-center"
                                                    :style="mailid == row.id ? 'background-color: rgb(223, 242, 225);' : ''"
                                                    style=" padding: 5px; cursor: pointer;margin: 0px;">

                                                    <h6 class="mb-0 text-sm"
                                                        style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 20px; ">
                                                        {{
                row.from }}</h6>
                                                    <h6 class="mb-0 text-sm"
                                                        style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 20px; ">
                                                        {{
                row.message }}</h6>
                                                    <p class="mb-0  text-xs"
                                                        style="color: grey; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 16px; ">

                                                        <button v-if="row.trusted_user" class="btn btn-info btn-sm"
                                                            style="margin: 0;padding: 0;">&nbsp;INT&nbsp;</button>

                                                        <button v-else-if="row.building" class="btn btn-danger btn-sm"
                                                            style="margin: 0;padding: 0;">&nbsp;<i
                                                                class="fa fa-building"></i>&nbsp;{{
                row.building.EXTAPI_IMM_ID }}&nbsp;</button>

                                                        <b style="color:rgb(2, 130, 2);font-weight: 600;">&nbsp;{{ new
                Date(row.received).toLocaleDateString("fr", {
                    minute: 'numeric', hour: 'numeric', year:
                        'numeric', month: 'numeric', day: 'numeric'
                                                            }) }}</b> {{ row.bodyPreview }}
                                                    </p>



                                                </div>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </div>
                </template>
                <template #right-pane>
                    <div v-if="phone_view"><button class="btn btn-white" @click="splitter_list =100"><i
                                class="fa fa-arrow-left"></i>Retour</button></div>
                    <MailView v-bind:id="mailid" v-model:split_list="splitter_list"
                        v-model:conversationId="conversationId" v-model:conversationShow="conversationShow" />

                </template>

            </vue-splitter>


        </div>
    </div>
</template>

<script>
//import { DataTable } from "simple-datatables";
import store from "../../../store";
//import BasePagination from "../../../components/BasePagination.vue";
import eventTable from "../../../mixins/eventTable.js";
import _ from "lodash";
import ArgonInput from "../../../components/ArgonInput.vue";
import MailView from "./MailView.vue";
//import BaseButton from "../../../components/BaseButton.vue";


import ContextMenu from 'primevue/contextmenu';

//import PvDialog from 'primevue/dialog';
import NewMail from "./NewMail.vue";
import Swal from 'sweetalert2'
import Modal from "../../../components/Modal.vue";

//import Splitter from 'primevue/splitter';
//import SplitterPanel from 'primevue/splitterpanel';
import VueSplitter from '@rmp135/vue-splitter'



var sortDirection = "EXTAPI_IMM_ID";
var getCurrentPage = 1;
var searchQuery = '';

const getRoleList = _.debounce(async function (params) {
    await store.dispatch("mail/mailsList", {
        ...(params.sort ? { sort: params.sort } : {}),
        ...(params.query ? { search: params.query } : {}),
        ...(params.box ? { box: params.box } : {}),
        ...(params.conversationId ? { conversationId: params.conversationId } : {}),

        page: params.nr,
        size: params.perpage,
        /*page: {
          number: params.to,
        },*/
    });

}, 300);

export default {
    name: "ListRolePage",
    components: {
        //BasePagination,
        ArgonInput,
        MailView,
        ContextMenu,
        Modal,
        NewMail,
        VueSplitter,
        //SplitterPanel,

        //BaseButton
    },
    mixins: [eventTable],
    data() {
        return {
            newVisible: false,
            newActionMode: 0,
            splitter_list: 30,
            splitter_main: 100,
            splitter_actions: 0,
            conversationShow: false,
            conversationId: null,
            newMailContent: {
                to: [],
                cc: [],
                cci: [],
                subject: '',
                body: '',
                files: [],
                options: {
                    tocc: {
                        active: false,
                        toggle: true
                    }
                }
            },

            options: [
                {
                    label: 'Afficher',
                    icon: 'fa fa-fw fa-eye',
                },
                {
                    label: 'Translate',
                    icon: 'fa fa-fw fa-language',
                },
                {
                    label: 'Speech',
                    icon: 'fa fa-volume-up',
                    items: [
                        {
                            label: 'Start',
                            icon: 'pi pi-caret-right'
                        },
                        {
                            label: 'Stop',
                            icon: 'pi pi-pause'
                        }
                    ]
                },
                {
                    separator: true
                },
                {
                    label: 'Print',
                    icon: 'pi pi-print'
                }
            ],
            mailid: null,
            minus_list: 205,
            updatedList: [],
            dataRoles: [],
            pagination: {},
            tableRoles: null,
            keys: null,
            page_t: 1,
            search: '',
            boxitems: [
                ['inbox', 'Boite de réception'],
                ['SentItems', 'Messages envoyés'],
            ],
            box: 'inbox',
        }
    },
    created() {

        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('id')) {
            this.mailid = urlParams.get('id')

        } else {
            this.newActionMode = 2;

        }

    },
    computed: {
        roleList() {
            return this.getList();
        },
        metaPage() {
            return this.$store.getters["mail/mailsList"]?.meta;
        }
    },
    watch: {
        $route() {
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('id')) {
                this.mailid = urlParams.get('id')

            }
        },
        metaPage: {
            handler: "reactivePagination",
            immediate: false,
            deep: true,
        },
        search: {
            handler: "reactiveSearch"
        },
        box: {
            handler: "reactiveBox"
        },
        conversationShow: {
            handler: function () {
                this.getDataFromPage(1)
            }
        },
        splitter_list: {
            handler: function () {

                this.newActionMode = 0;
            }
        },
        newActionMode: {
            handler: function (val) {
                if (val == 2) {
                    this.splitter_list = 100;
                    this.minus_list = 205;
                    this.splitter_main = 0;
                    this.splitter_actions = 0;
                } else if (val) {
                    this.splitter_list = 0;
                    this.minus_list = 500;
                    this.splitter_main = this.default_splitter_main;
                    this.splitter_actions = 30;
                } else {
                    this.splitter_list = this.default_splitter_list;
                    this.splitter_main = 100;
                    this.splitter_actions = 0;
                    this.minus_list = 205;
                }
            }
        }

    },
    async mounted() {

        const masonry = document.querySelector('#infinite-list');

        this.setView();
        addEventListener('resize', () => {
            this.setView();
        })




        masonry.addEventListener('scroll', () => {
            let v_close = Math.abs(masonry.scrollHeight - masonry.clientHeight - masonry.scrollTop);


            if (v_close <= 1) {
                console.log('scroll')
                if (this.metaPage.current_page == 1) {
                    this.page_t = 1
                }
                if (this.page_t <= this.metaPage.current_page && this.page_t <= this.metaPage.last_page) {
                    this.page_t += 1;
                    console.log('page', this.page_t)
                    this.getDataFromPage(this.page_t);
                }
            }
        })
        this.getMailFolders();

        await getRoleList({
            sort: sortDirection,
            query: '',
            conversationId: this.conversationId,
            nr: getCurrentPage,
            perpage: 15//this.tableRoles.options.perPage
        });
    },
    beforeUnmount() {
        sortDirection = "EXTAPI_IMM_ID";
        searchQuery = '';
        getCurrentPage = 1;
    },
    methods: {
        setView() {

            if (window.innerWidth < 768) {
                this.phone_view = true;
                this.splitter_list = 0;
                this.splitter_main = 100;
                this.splitter_actions = 0;


                let urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('id')) {
                    this.splitter_list = 0;
                    this.splitter_main = 100;

                } else {
                    this.splitter_list = 100;
                    this.splitter_main = 0;


                }


                this.default_splitter_list = 0;

                this.default_splitter_main = 0;

            }
            else {
                if (this.newActionMode == 0) {
                    this.splitter_list = 30;
                    this.splitter_main = this.default_splitter_main;
                    this.splitter_actions = 30;
                } else {

                    this.phone_view = false;
                    this.splitter_list = 30;
                    this.splitter_main = 100;
                    this.splitter_actions = 0;
                    this.default_splitter_list = 30;
                    this.default_splitter_main = 50;
                }

            }
        },
        onRightClick(event, id) {
            console.log(id)
            //this.selectedId.value = id;
            this.$refs.menu.show(event);
        },
        handleClick(event, item) {
            console.log(event, item)
            event.preventDefault();
            this.$refs.vueSimpleContextMenu.showMenu(event, item)
        },
        shakeCreate() {
            this.shaker = true;
            setTimeout(() => { this.shaker = false }, 1000)
        },

        sendNewMail() {
            //
            Swal.fire({
                title: 'Etes-vous sûr?',
                text: "Vous êtes sur le point d'envoyer un e-mail",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',

                confirmButtonText: 'Oui, envoyer!',
                cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    try {
                        this.$store.dispatch("mail/addMail", this.newMailContent);
                        Swal.fire({
                            icon: 'success',
                            title: 'Envoyé!',
                            text: 'Votre e-mail a été envoyé.',
                            timer: 1000
                        })


                    } catch (e) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Une erreur s\'est produite',
                            timer: 1000
                        }
                        )
                    }


                    this.newVisible = false;
                }
            })
        },

        optionClicked(event) {
            window.alert(JSON.stringify(event))
        },
        async getMailFolders() {
            await this.$store.dispatch("mail/mailfolders");
            let f = await this.$store.getters["mail/mailfolders"]
            f.forEach(element => {
                this.boxitems.push([element.id, element.displayName + ' (' + element.total + ')'])
            });
        },

        editItem(row) {
            this.mailid = row.id;
            this.newActionMode = 0;
            this.$router.replace({
                query: { id: row.id }
            })
            if (window.innerWidth < 768) {
                this.splitter_list = 0;
                this.splitter_main = 100;
                this.splitter_actions = 0;
            }
            /*
                        this.$router.push({

                            path: "/s/mails",
                            params: { id: row.id }
                        });
                   */

        },
        getList() {
            if (this.$store.getters["mail/mailsList"]) {
                if (this.$store.getters["mail/mailsList"].meta.current_page == 1) {
                    console.log(this.$store.getters["mail/mailsList"].data)
                    this.updatedList = this.$store.getters["mail/mailsList"].data

                }
                else {
                    let datas = this.$store.getters["mail/mailsList"].data
                    // if datas not in updatedList
                    datas.forEach(element => {
                        if (!this.updatedList.find(x => x.id === element.id)) {
                            this.updatedList.push(element)
                        }
                    });
                    //this.updatedList = [...this.updatedList, ...]

                }
                /*this.$store.getters["mail/mailsList"]?.data.forEach(element => {

                   this.$store.dispatch("mail/getMail", element.id);
                });*/
            }

            else {
                this.updatedList = [...this.updatedList]
            }
            return this.updatedList
        },
        openImmeuble(row) {
            this.$router.push({
                name: "Mail",
                params: { id: row.id }
            });
        },
        async getDataFromPage(page) {
            await getRoleList({
                sort: sortDirection,
                query: this.search,
                box: this.box,
                conversationId: this.conversationId,
                nr: page,
                perpage: 15//this.tableRoles.options.perPage
            });
        },

        async reactiveSearch() {
            searchQuery = this.search;
            console.log(searchQuery)
            await getRoleList({
                sort: sortDirection,
                query: this.search,
                box: this.box,
                conversationId: this.conversationId,
                nr: 1,
                perpage: 25//this.tableRoles.options.perPage
            });
        },
        async reactiveBox() {


            await getRoleList({
                sort: sortDirection,
                box: this.box,
                query: this.search,
                conversationId: this.conversationId,
                nr: 1,
                perpage: 15//this.tableRoles.options.perPage
            });

        },

        async reactivePagination() {

            this.pagination = await this.metaPage;


            this.pagination = {
                currentPage: this.pagination.current_page,
                perPage: this.pagination.per_page,
                total: this.pagination.total

            }
            getCurrentPage = this.pagination.current_page;
            return this.pagination;
        },

    },
};
</script>
<style>
.dataTable-top {
    display: none;
}

.vue-splitter .splitter {
    width: 5px;
    background: #f5f5f5;

}

.hover {
    background-color: #f5f5f5;
}

.p-contextmenu-root-list {
    padding-left: 0rem;
    margin-bottom: 0rem;
}

.p-submenu-list {
    padding-left: 0rem;
}

.bg-gradient-dark-green {
    background: linear-gradient(-87deg, #108b39 0, #1aac4f 100%) !important;
}

.p-dialog-mask {
    z-index: 100 !important;
}


.vue-splitter.vertical {
    height: 100%;
}

body{
    background-color: #f4fff2 !important;
}
</style>
