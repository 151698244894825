/* eslint-disable no-unused-vars */
import persoService from "../services/perso.service.js";

export const perso = {
    namespaced: true,
    state: {
        persosList: null,
        persosFromBuilding: null,
        oneItem: null,
        image: null,
        exportPersos: null,
    },
    getters: {
        persosList: state => state.persosList,
        persosFromBuilding: state => state.persosFromBuilding,
        exportPersos: state => state.exportPersos,
        onePerso: state => state.onePerso,
        image: state => state.image,
    },
    actions: {
        async persosList({ commit }, params) {
            console.log("action")
            const data = await persoService.getListPersos(params);
            console.log(data);
            commit('PERSOS_LIST', data);
        },
        async persosFromBuilding({ commit }, params) {
            console.log("action")
            const data = await persoService.getListPersosFromBuilding(params);
            console.log(data);
            commit('PERSOS_LIST_FROM_BUILDING', data);
        },

        async exportPersos({ commit }, params) {
            console.log("action")
            const data = await persoService.exportPersos(params);
            console.log(data);
            commit('EXPORT_PERSOS', data);
        },

        async getPerso({ commit }, itemId) {
            const data = await persoService.getPerso(itemId);
            commit('GET_PERSO', data);
        },
        async addPerso({ commit }, item) {
            const data = await persoService.createPerso(item);
            commit('GET_ITEM', data);
        },
        async editPerso({ commit }, item) {
            await persoService.editPerso(item);
        },
        async deletePerso({ commit }, itemId) {
            await persoService.deletePerso(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await persoService.uploadImagePerso(item);
            commit('UPLOAD_IMAGE_PERSO', data);
        },
    },
    mutations: {
        PERSOS_LIST(state, data) {
            state.persosList = data;
        },
        GET_PERSO(state, data) {
            state.onePerso = data;
        },
        PERSOS_LIST_FROM_BUILDING(state, data) {
            state.persosFromBuilding = data;
        },
        UPLOAD_IMAGE_PERSO(state, data) {
            state.image = data;
        },
        EXPORT_PERSOS(state, data) {
            state.exportPersos = data;
        }
  }
}
