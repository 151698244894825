<template>
  <div
    v-if="layout === 'custom'"
    class="position-absolute w-100 min-height-300 top-0"
    :style="{
      'background-image': 'url(' + image + ')',
      'background-position-y': '50%'
    }"
  >
    <span class="mask bg-success opacity-6"></span>
  </div>
  <div
    v-show="layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${darkMode ? 'bg-transparent' : 'bg-transparent'}`"
  />

  <div>
      <button class="btn btn-danger btn-xs d-block d-xl-none" @click="collapse=!collapse" style="position: fixed;top:10px;left:10px;z-index: 1000;">
        <i class="fa fa-times" v-if="!collapse"></i>
        <i class="fa fa-bars" v-else></i>
      </button>
    </div>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4"
    style="z-index: 5;"
    :class="` ${sidebarType} ${
      isRTL ? 'fixed-end me-3 rotate-caret' : 'fixed-start ms-3'
    } ${layout === 'landing' ? 'bg-transparent shadow-none' : ''}
    ${collapse?'collapsed-in':''}`
    "
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-1 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>

      <div class="m-0 navbar-brand" @click="navbarMinimize">
        <img
          :src="
          isPinned ?
          (
            (layout === 'landing' || darkMode || sidebarType === 'bg-default')
              ? logoWhite
              : logo
              ) :(
                logoMini
              )
          "
          class="navbar-brand-img h-100"
          alt="main_logo"

        />
      </div>

    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list v-model="collapse" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/mycopro1.png";
import logoWhite from "@/assets/img/mycopro2.png";
import logoMini from "@/assets/img/favicon.png";
import image from "@/assets/img/mycopro1.png";

import { mapState,mapMutations } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList
  },
  data() {
    return {
      logo,
      logoWhite,logoMini,
      image,
      collapse:false
    };
  },
  computed: {
    ...mapState(["layout", "isRTL","isPinned", "sidebarType", "darkMode","showSidenav"])
  },
  beforeUnmount() {
    this.$store.state.image;
  },

  methods: {
  ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    toggleNavigationOnMobile() {
    /*  if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }*/
    },
  }
};
</script>
<style>
@media(max-width: 1200px){
  .collapsed-in{
   transform: translateX(-200%);


  }
}
@media(min-width: 1200px){
  .collapsed-in{
    transform: translateX(0);
    display: block;

  }
}
</style>
