/* eslint-disable no-unused-vars */
import feedbackservice from "../services/feedback.service.js";

export const feedback = {
    namespaced: true,
    state: {
        feedbacksList: null,
        feedbacksListFromBuilding: null,
        onefeedback: null,
        image: null,
        phone: null,
    },
    getters: {
        feedbacksList: state => state.feedbacksList,
        feedbacksListFromBuilding: state => state.feedbacksListFromBuilding,
        onefeedback: state => state.onefeedback,
        image: state => state.image,
        phone: state => state.phone,

    },
    actions: {
        async feedbacksList({ commit }, params) {
            console.log("action")
            const data = await feedbackservice.getListfeedbacks(params);
            console.log(data);
            commit('feedbacks_LIST', data);
        },
        async feedbacksFromBuilding({ commit }, params) {
            console.log("action")
            const data = await feedbackservice.getListfeedbacksFromBuilding(params);
            console.log(data);
            commit('feedbacks_LIST_FROM_BUILDING', data);
        },
        async phones({commit, dispatch}, params) {
            return await feedbackservice.phones(params)
            .then(({list, meta}) => {
            commit('SET_PHONES', list);
            });
        },
        async getfeedback({ commit }, itemId) {
            const data = await feedbackservice.getfeedback(itemId);
            commit('GET_feedback', data);
        },
        async createfeedback({ commit }, data) {
            const response = await feedbackservice.createfeedback(data);
            commit('GET_feedback', response.data);
        },
        async createfeedbackanswer({ commit }, data) {
            const response = await feedbackservice.createfeedbackanswer(data);
            commit('GET_feedback', response.data);
        },
    },
    mutations: {
        feedbacks_LIST(state, data) {
            state.feedbacksList = data;
        },
        GET_feedback(state, data) {
            state.onefeedback = data;
        },
        lots_LIST_FROM_BUILDING(state, data) {
            state.lotsFromBuilding = data;
        },
        UPLOAD_IMAGE_lot(state, data) {
            state.image = data;
        },
        SET_PHONES(state, data) {
            state.phone = data;
        },

  }
}