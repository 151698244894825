/* eslint-disable no-unused-vars */
import ModeleService from "../services/modele.service.js";

export const modele = {
    namespaced: true,
    state: {
        modelesList: null,
        oneModele: null,
        image: null,
    },
    getters: {
        modelesList: state => state.modelesList,
        oneModele: state => state.oneModele,
        image: state => state.image,
    },
    actions: {
        async modelesList({ commit }, params) {
            console.log("action")
            const data = await ModeleService.getListModeles(params);
            console.log(data);
            commit('ModeleS_LIST', data);
        },
        async modelesListGest({ commit }, params) {
            console.log("action")
            const data = await ModeleService.getListModelesGest(params);
            console.log(data);
            commit('ModeleS_LIST', data);
        },
        async getModele({ commit }, itemId) {
            const data = await ModeleService.getModele(itemId);
            commit('GET_Modele', data);
        },
        async getDetailedModele({ commit }, itemId) {
            const data = await ModeleService.getDetailedModele(itemId);
            commit('GET_Modele', data);
        },
        async addModele({ commit }, item) {
            const data = await ModeleService.createModele(item);
            commit('GET_Modele', data);
        },
        async dupplicateModele({ commit }, item) {
            await ModeleService.dupplicateModele(item);
        },
        async editModele({ commit }, item) {
            await ModeleService.editModele(item);
        },
        async deleteModele({ commit }, itemId) {
            await ModeleService.deleteModele(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await ModeleService.uploadImageModele(item);
            commit('UPLOAD_IMAGE_Modele', data);
        },
    },
    mutations: {
        ModeleS_LIST(state, data) {
            state.modelesList = data;
        },
        GET_Modele(state, data) {
            state.oneModele = data;
        },
        UPLOAD_IMAGE_Modele(state, data) {
            state.image = data;
        },
  }
}