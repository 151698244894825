<template>
    <div>
    <label class="form-label">{{title}}</label>
      <div class="form-group">
        <div class="input-group">
          <input type="text" class="form-control  " @keypress="isNumber($event)" :class="small ?'form-control-sm':''" :placeholder="placeholder" v-model="modele" />
          <span class="input-group-text text-body" style="border-right: #d2d6da !important;border: 1px solid #d2d6da !important;">
            {{ end }}
          </span>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    name: "EndInput",
    props: {

        modelValue: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",

        },
        end: {
            type: String,
            default: "€",

        },
        small: {
            type: Boolean,
            default: false,

        },
        placeholder: {
            type: String,
            default: "Non renseigné",

        },
    },
    data() {
        return {
            modele: this.modelValue
        }
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
    methods: {
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        }
    }
}

</script>