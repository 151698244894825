<template>
    <div>

        <base-header class="pb-3 text-center border-radius-xl p-3 m-3"
            style="background-image: url('https://mycopro.fr/assemblee-video/Fond.png'); background-size: cover; background-position: center top;">
            <div class="navbar-top container-fluid navbar-expand " style="justify-content: end;">
                <ul class="navbar-nav align-items-center ml-auto ml-md-0 pt-2" style="justify-content: end;">
                    <div style="position:fixed;right:10px;top:10px;">
                        <a @click="logout" to="" class="btn btn-xs btn-danger">
                            <i class="fa fa-user-run"></i>
                            <span>Déconnexion</span>
                        </a>
                    </div>

                    <!--
            <base-dropdown
              menu-on-right
              class="nav-item"
              tag="li"
              title-tag="a"
              title-classes="nav-link pr-0"
            >
  
              <template>
  
              <a href="#" class="nav-link pr-0" @click.prevent >
                <div class="media align-items-center">
                  <span
                    class="avatar avatar-sm rounded-circle avatar-image"
                    :style="{
                      'background-image': `url('${profileImage}')`,
                    }"
                  >
                  </span>
                  <div class="media-body ml-2 d-none d-lg-block">
                    <span class="mb-0 text-sm font-weight-bold text-white">{{ user.given_name }} {{ user.family_name }}</span>
                  </div>
                </div>
              </a>
                <a href="/s/user-profile" class="dropdown-item" >
                  <i class="ni ni-single-02"></i>
                  <span>Mon Compte</span>
                </a>
                <a  class="dropdown-item" v-for="ql in user.quick_links" v-bind:key="ql.id"  :href="ql.url">
                  <i class="ni ni-world-2"></i>
                  <span>{{ ql.name }}</span>
                </a>
                <a  class="dropdown-item" v-for="ql in user.info.syndic.quick_links"  v-bind:key="ql.id" :href="ql.url">
                  <i class="ni ni-world-2"></i>
                  <span>{{ ql.name }}</span>
                </a>
                <div class="dropdown-divider"></div>
                <a @click.prevent="logout()" to="" class="dropdown-item">
                  <i class="ni ni-user-run"></i>
                  <span>Me déconnecter</span>
                </a>
              </template>
            </base-dropdown>-->
                </ul>

                <h1 class="text-white h2">Mon compte fournisseur</h1>
            </div>
            
        </base-header>
    

        <div class="container-fluid mt--6" >


            <!--Widgets-->
            <div class="row">
                <div class="col-xl-6 pt-1">
                    <EventTinyView />
                </div>
   
            </div>
        </div>

    </div>
</template>
<script>
import Vuex from "vuex";
import Swal from "sweetalert2";
import BaseHeader from "../../components/BaseHeader.vue";
//import BaseDropdown from "../../components/BaseDropdown.vue";
// Tables
import EventTinyView from './evenements/ListeEvenementsMini.vue'
import placeholder from "@/assets/img/placeholder.jpg"
export default {
    components: {
        BaseHeader,
        // BaseDropdown,
        EventTinyView, 
    },
    data() {
        return {
            user: {

            },
            placeholder,
            show_perso_options: false,
            today: '',
        };
    },
    props: {

        roles: {
            type: Function

        }
    },
    computed: {
        ...Vuex.mapState({
            me: (state) => state.profile.me
        }),

        profileImage() {
            return this.avatar ? this.avatar : this.placeholder;
        },
    },
    watch: {
        me: {
            handler: function (val) {
                console.log(val)
                this.user = val;
            },
            deep: true,
        },
    },
    /*async created() {
      this.$store.watch(
        () => this.$store.getters["profile/me"],
        (me) => {
          this.title = me.first_name;
          this.avatar = me.profile_image;
          this.user = me;
        }
      );
      await this.$store.dispatch("profile/me");
    },*/

    created() {
        let str1 = new Date().toLocaleDateString("fr", { weekday: 'long', day: 'numeric' })
        let str2 = new Date().toLocaleDateString("fr", { year: 'numeric', month: 'long', })
        this.today = str1.charAt(0).toUpperCase() + str1.slice(1) + ' ' + str2.charAt(0).toUpperCase() + str2.slice(1);


        Vuex.mapState({
            me: (state) => state.profile.me
        }),
            this.user = this.me;

    },
    methods: {

        logout() {
            let confirmLogoutSwal = new Swal({
                title: "Êtes-vous sûr ?",
                text: "Vous allez être déconnecté de MyCopro. Vous pourrez vous reconnecter à tout moment.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui, me déconnecter",
                cancelButtonText: "Annuler",
                customClass: {
                    confirmButton: "btn bg-gradient-danger m-2",
                    cancelButton: "btn bg-gradient-success m-2",
                },
                buttonsStyling: false,
            })
            confirmLogoutSwal.then((result) => {
                if (result.isConfirmed) {
                    this.logoutConfirmed();
                }
            });
        },
        async logoutConfirmed() {
            try {
                await this.$store.dispatch('auth/logout');
                this.$router.push({ name: 'Login' })
            } catch (error) {
                this.$notify({
                    type: "danger",
                    message: "Nous n'avons pas réussi à vous déconnecter !",
                });
            }
        },
        async microsoftConnect() {
            await this.$store.dispatch("mails/connect");
            //console.log('before', this.items)
            this.items = this.$store.getters["mails/connect"];
            window.location = this.items.url;
            console.log('I', this.items.url);
            //window.location = 'api.mycopro.fr/api/v1/microsoft/signin'
        },

    },

};
</script>
<style></style>
  