<template>
  <div class="form-check">
    <input
      :id="id"
      class="form-check-input"
      type="radio"
      :name="name"
      v-model="checked"
      :disabled="disabled"
    />
    <label class="custom-control-label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "ArgonRadio",
  props: {
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      default: ()=>{},
    },
  },
  watch: {
    checked: function (val) {
      this.$emit("update:modelValue", val);
    },
    modelValue: function (val) {
      this.checked = val;
    },
  },
  data(){
    return {
      checked: this.modelValue
    }
  }
};
</script>
