<template>
  <Transition>
  <Loading v-if="mainLoading"/>
  <div v-else>
    <div class="py-4 container-fluid">
    <Modal v-model="modals.equipe.show" closeable header="Closeable Modal">
      <p>You can close this modal using the close button on the top right corner</p>
    </Modal>




    <base-header :class="'bg-gradient-warning'" class="pb-1 border-radius-xl">
      <div class="row align-items-center pt-4 pb-2">
        <div class="col-lg-3 text-left">
          <button class="btn btn-white mt-2 btn-xs" @click="$router.go(-1)">
            <span class="btn-inner--icon"><i class="fas fa-arrow-left"></i></span> Retour
          </button>
        </div>
        <div class="col-lg-6 text-center text-white">
          <h6 class="h3  d-inline-block mb-0  text-white">{{item.EXTAPI_LOT_ID}} —  {{ item.typedescr.texte }}

          </h6>
        </div>
        <div class="col-lg-3 text-end">
          <!--<button class="btn btn-white mt-2 btn-xs" @click="modals.equipe.show = true">
            <span class="btn-inner--icon"><i class="fas fa-users"></i></span> Équipe de gestion
          </button>-->

        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-4">

              <mini-statistics-card class="" @click="goImm(item.immeuble.id)"
                :title="{ text: 'Immeuble '+item.immeuble.EXTAPI_IMM_ID, color: 'opacity-7 text-dark' }"
                :value="{ text: item.immeuble.nom, color: 'text-dark' }" :icon="{
                  component: 'fa fa-building text-white',
                  background: 'bg-danger',
                  shape: 'rounded-circle',
                }" />
            </div>
            <div class="col-lg-4">

              <mini-statistics-card class="" @click="goPerso(item.perso.id)"
                :title="{ text: 'Propriétaire', color: 'opacity-7 text-dark' }"
                :value="{ text: item.perso.prenom + ' '+ item.perso.nom , color: 'text-dark' }" :icon="{
                  component: 'fa fa-user text-white',
                  background: 'bg-dark_yellow',
                  shape: 'rounded-circle',
                }" />
            </div>
            <div class="col-lg-4">

              <mini-statistics-card  :title="{ text: 'Évènements', color: 'opacity-7 text-dark' }"
                :value="{ text: item.events, color: 'text-dark' }" :icon="{
                  component: 'fas fa-calendar text-white',
                  background: 'bg-primary',
                  shape: 'rounded-circle',
                }" />
                <base-button size="xs" type="white" @click="newLocation"><i class="fa fa-plus"></i>Louer ce bien</base-button>

            </div>

          </div>


        </div>
        <div class="col-lg-4">

          <mini-statistics-card @click="openAdresse()" class="bg-white"
            :title="{ text: 'Adresse', color: 'opacity-7 text-dark' }"
            :value="{ text: item.immeuble.adresse1 + ' '+ item.immeuble.adresse2 + ' '+ item.immeuble.cp+' '+ item.immeuble.ville, color: 'text-dark' }"
            :icon="{
              component: 'fas fa-location-arrow text-white',
              background: 'bg-success',
              shape: 'rounded-circle',
            }" />

        </div>





      </div>
    </base-header>

  </div>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-lg-3  ">
        <div class="card p-3" style="height: calc(100vh - 350px);">

          <div class="btn-group" role="group" aria-label="Basic example">
            <base-button size="xs" outline class="bg-dark_yellow text-white active"
              >Locataires</base-button>
          </div>
          <table class="table-responsive align-items-center table-flush">
            <tr class="" v-for="l in item.locataires" :key="l.id" @click="goPerso(l.perso.id)">
                <div class="d-flex align-items-start flex-column justify-content-center"
                  style=" padding: 5px; cursor: pointer;margin: 0px;" >
              <a style="width: 100%;"> <b>{{l.perso.prenom }} {{l.perso.nom}}</b><br>depuis le {{ date_convert(l.bail_actif.date_entree)}}
                <a class="d-flex w-100" v-if="l.perso.phones[0]">
<a style="width:100%;color:darkblue" >{{ l.perso.phones[0].phone }}</a> &nbsp;<a class="btn btn-xs btn-icon btn-round btn-cyan mb-0" :href="'tel:'+l.perso.phones[0].phone"><i class="fa fa-phone"></i></a>

                </a>
                <a class="d-flex w-100" v-if="l.perso.mails[0]">
<a style="width:100%">{{ l.perso.mails[0].mail }}</a> &nbsp;<a class="btn btn-xs btn-icon btn-round btn-success mb-0" :href="'mailto:'+l.perso.mails[0].mail"><i class="fa fa-paper-plane"></i></a>

                </a>

               </a>

                </div>
            </tr>
          </table>



        </div>

      </div>
      <div class="col-lg-6">
        <div class="card p-3" style="height: calc(100vh - 350px);">
          <div style="display: flex;">
            <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
              <base-button size="xs" type="primary" outline :class="evenements_type == 'actuels' ? 'active' : ''"
                @click="evenements_type = 'actuels'">Évènements actuels</base-button>
              <base-button size="xs" type="primary" outline :class="evenements_type == 'futurs' ? 'active' : ''"
                @click="evenements_type = 'futurs'">Évènements futurs</base-button>
            </div>
          </div>
          <div v-if="evenements_type == 'actuels'" style="overflow-y: scroll;">
            <div class="text-center" v-if="rights.includes('évènements')">
              <base-button size="xs" type="neutral" @click="newEventImmeuble"><i class="fa fa-plus"></i>Créer un évènement</base-button>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card p-3" style="height: calc(100vh - 350px);">
          <div style="display: flex;">
            <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
              <base-button size="xs" type="purple" outline
                :class="attributs_or_contrats == 'attributs' ? 'active' : ''"
                @click="attributs_or_contrats = 'attributs'">Attributs</base-button>
            </div>
          </div>
          <div v-if="attributs_or_contrats == 'attributs'">
            <table class="table">
              <tr v-if="item.escalier">
                <td>Escalier</td>
                <td>{{ item.escalier }}</td>
              </tr>
              <tr v-if="item.etage">
                <td>Etage</td>
                <td>{{ item.etage }}</td>
              </tr>
              <tr v-if="item.porte">
                <td>N° porte</td>
                <td>{{ item.porte }}</td>
              </tr>
              <tr v-if="item.nbpiece">
                <td>Nb de pièces</td>
                <td>{{ item.nbpiece }}</td>
              </tr>
              <tr v-if="item.surface">
                <td>Surface</td>
                <td>{{ item.surface }} m2</td>
              </tr>
              <tr v-if="item.champ_libre">
                <td>Info</td>
                <td>{{ item.champ_libre }}</td>
              </tr>
            </table>
            <!--<base-button size="sm" type="neutral" @click="modals.attributs.show = true">
              <i class="fas fa-plus"></i>Ajouter</base-button>-->

        {{item.libelle1}} {{item.libelle2}} {{item.libelle3}}


                <a v-for="i in item.perso.imm_links" :key="i.id"><a v-if="i.mandataire" @click="goMand(i.mandataire.id)">{{ i.mandataire.civilite }} {{ i.mandataire.nom }}<br>{{ i.mandataire.ville }}<br>
                  <a v-if="i.mandataire.perso && i.mandataire.perso.mails[0]">{{ i.mandataire.perso.mails[0].mail }}</a>
                  <a v-if="i.mandataire.perso && i.mandataire.perso.phones[0]">{{ i.mandataire.perso.phones[0].phone }}</a>
                </a></a>
          </div>
          <div v-if="attributs_or_contrats == 'contrats'" style="overflow-y: scroll;">
            <ArgonInput v-model="searchcontrat" size="small" placeholder="Rechercher un contrat..." />
          </div>

        </div>
      </div>
    </div>
  </div>
  </div>
</Transition>
</template>

<script>
import ArgonInput from '../../../components/ArgonInput.vue';
//import ArgonButton from '../../../components/ArgonButton.vue';
import formMixin from "../../../mixins/form-mixin.js"
//import ValidationError from "../../../components/ValidationError.vue";
import showSwal from '../../../mixins/showSwal.js';
import MiniStatisticsCard from '../../../components/Cards/MiniStatisticsCard.vue';
import BaseHeader from "../../../components/BaseHeader.vue"
import BaseButton from "@/components/BaseButton.vue"
import setNavPills from "@/assets/js/nav-pills.js";
//import {ElTree} from "element-plus"
import Modal from '@/components/Modal.vue'

import Loading from '@/components/Loading.vue'
export default {
  name: "EditRolePage",
  components: {
    ArgonInput,
    //ArgonButton,
    //ValidationError,
    MiniStatisticsCard, BaseHeader, BaseButton, Modal,Loading
  },
  mixins: [formMixin, showSwal],
  data() {
    return {
      mainLoading:true,
      rights: [],
      modals: {
        equipe: {
          show: false,
          item: {}
        },
        contrats: {
          show: false,
          item: {}
        },
        residents: {
          show: false,
          f: []
        },
        locataires: {
          show: false,
          f: []
        },
        edit_attributs: {
          show: false,
          id: this.$route.params.id,
          f: [],
          name: '',
          value: '',
          fichiers: '',
          attr_type: '',
          codes: []
        },
        attributs: {
          show: false,
          id: this.$route.params.id,
          f: [],
          codes: []
        }
      },
      searchcop: '',
      residents: 'all',
      resident_search_placeholder: 'Rechercher un résident',
      evenements_type: "actuels",
      cs_or_mandats: "cs",
      attributs_or_contrats: 'attributs',
      searchcontrat: '',
      item: {
        civilite:'',
        attributes: []
      },
      options: {
        sort: "created_at",
        query: "",
        nr: "1",
        perpage: "5"
      }
    }
  },
  computed: {

    filterCops() {
      return this.cops.filter(
        (data) =>
          (!this.searchcop && this.residents == 'all') ||
          (!this.searchcop && this.residents == 'cop' && data.qualite === undefined) ||
          (!this.searchcop && this.residents == 'loc' && data.qualite) ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && this.residents === 'all')
          ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && data.qualite === undefined && this.residents === 'cop')
          ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && data.qualite !== undefined && this.residents === 'loc')
      ).sort(function (a, b) {
        if (a.perso_mini.nom < b.perso_mini.nom) {
          return -1;
        }
        if (a.perso_mini.nom > b.perso_mini.nom) {
          return 1;
        }
        return 0;
      });
      //el => el.perso_mini.nom)

    },
  },
  async mounted() {
    setNavPills();

    let val = await this.$store.getters["profile/me"];
    this.roles = val.roles.map((r) => r);
    if(val.info.access !== null){
        let tmp_rights = val.info.access.map((r) => r);
        var vtmp;
        if(tmp_rights.length >0 ){
          tmp_rights.forEach((r) => {
            vtmp = JSON.parse(r.acces_text)
            vtmp.forEach((r) => {
              if(!this.rights.includes(r))
                this.rights.push(r)
            })
          })
        } else {
          //this.rights = ["immeubles","résidents","fournisseurs","calendrier","mails","discussions","courriers","appels","évènements","assemblées","dropzones","intranet"]
          this.rights = ["immeubles","résidents","fournisseurs","mails","évènements"]

        }
      }

    await this.$store.dispatch("lot/getLot", this.$route.params.id);
    this.item = await this.$store.getters["lot/oneLot"];

          this.lots = this.item.lots;/*
          this.imm_links = Array();
          var i =0;
          this.item.imm_links.forEach(imm => {
            this.lots.forEach(lot => {
              let pres1 = imm.num_imm === lot.immeuble.EXTAPI_IMM_ID;

              let pres = this.imm_links.findIndex( elt => elt.num_imm === lot.immeuble.EXTAPI_IMM_ID );
              if(pres1 && pres === -1 ){
                this.imm_links.push(imm)
                this.imm_links[i].hasChildren = true;
                this.imm_links[i].children = [lot];
                i+=1;


              } else if(pres1) {
                this.imm_links[pres].children.push(lot);

              }

            });
          })

          console.log('itis', this.item, this.imm_links);

          this.imm_baux = Array();
          i =0;
          this.item.baux_actifs.forEach(b => {
            b.lots.forEach(lot => {

              let pres = this.imm_baux.findIndex( elt => elt.EXTAPI_IMM_ID === lot.immeuble.EXTAPI_IMM_ID );
              if( pres === -1 ){
                this.imm_baux.push(lot.immeuble)
                this.imm_baux[i].hasChildren = true;
                this.imm_baux[i].children = [lot];
                i+=1;


              } else {
                this.imm_baux[pres].children.push(lot);

              }

            });
          })
*/
          this.mainLoading = false

  },
  methods: {
    openAdresse() {
      window.open('https://www.google.com/maps/search/?api=1&query=' + this.item.immeuble.adresse1 + ' ' + this.item.immeuble.adresse2 + ' ' + this.item.immeuble.cp + ' ' + this.item.immeuble.ville, '_blank');

    },

    date_convert(date){
        return new Date(date).toLocaleDateString('fr-FR')
      },
    newEventImmeuble(){
      console.log(this.item)
      this.$router.push({
        name: "Liste des évènements",
        params:{newEventProp: JSON.stringify({'lot_id':this.item.id,'lot':this.item})}
      })
    },
    newLocation(){
      console.log(this.item)
      this.$router.push({
        name: "Nouveau bien",
        params:{newLocationProp: JSON.stringify({'lot_id':this.item.id,'lot':this.item})}
      })
    },
    showChildren(row) {
      console.log(row);
      this.item.attributes.forEach(element => {
        if (element.id == row.id) {
          console.log(element);
          element.show_children = !element.show_children;
        }
      });
      // simplifier
      this.item.attributes.push({})
      this.item.attributes.pop()
    },
    showAllResidents() {
      this.residents = 'all';
      this.resident_search_placeholder = "Rechercher un résident";

    },
    showOnlyCops() {
      this.residents = 'cop';
      this.resident_search_placeholder = "Rechercher un copropriétaire";

    },
    showOnlyLocs() {
      this.residents = 'loc';
      this.resident_search_placeholder = "Rechercher un locataire";

    },
        goImm(id){
        this.$router.push({
          name: "Immeuble",
          params: { id: id },
        });

        },
        goPerso(id){
        this.$router.push({
          name: "Perso",
          params: { id: id },
        });

        },
    changeAttrImmeuble(row) {
      this.modals.edit_attributs.show = true;
      this.modals.edit_attributs.immeuble_id = this.$route.params.id;
      this.modals.edit_attributs.id = row.id;
      this.modals.edit_attributs.name = row.name;
      this.modals.edit_attributs.attr_type = row.attr_type;
      console.log(row);
      switch (row.attr_type) {
        case "texte":
          this.modals.edit_attributs.value = JSON.parse(row.value);
          break;
        case "codes":
          this.modals.edit_attributs.codes = JSON.parse(row.value);
          break;
        case "fichiers":
          this.modals.edit_attributs.fichiers = row.fichiers;
          break;
        case "number":
          this.modals.edit_attributs.number = row.value;
          break;
        case "bool":
          this.modals.edit_attributs.bool = row.v
      }
      this.modals.edit_attributs.name = row.name;
    },
    async handleEditRole() {
      try {
        await this.$store.dispatch("role/editRole", this.item);
        await this.$store.dispatch("role/rolesList", {
          ...(this.options.sort ? { sort: this.options.sort } : {}),
          filter: {
            ...(this.options.query ? { name: this.options.query } : {}),
          },
          page: {
            number: this.options.nr,
            size: this.options.perpage,
          },
        });
        this.resetApiValidation();
        this.showSwal({
          type: "success",
          message: "Role edited successfully!"
        });
        await this.$router.push({ name: 'Role Management' });
      }
      catch (error) {
        this.setApiValidation(error.response.data.errors);
        this.showSwal({
          type: "error",
          message: "Oops, something went wrong!"
        });
      }
    },
  }
};
</script>
