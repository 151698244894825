import service from "../services/profile.service.js";

export const profile = {
    namespaced: true,
    state: {
        me: null,    },
    getters: {
        me: state => state.me,
    },
    actions: {
        async me({ commit }) {
            const data = await service.getMe();
            if(!data){
                commit('IS_AUTHENTICATED', false);
                return
            }

            console.log('try me')
            commit('GET_ME_PROFILE', data);
        },
        async getCollabImages({ commit }) {
            const data = await service.getMe(0,1);
            commit('GET_ME_PROFILE', data);
        },
        async refreshData(){
            await service.refreshData();

        },

        async update({ commit }, updateUser) {
            const data = await service.updateProfile(updateUser);
            commit('GET_ME_PROFILE', data);
        },
    },
    mutations: {
        GET_ME_PROFILE(state, me) {
            state.me = me
        }
    }
}
