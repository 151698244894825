import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getMe(no_reload=1,with_picture=0) {
  let ac = sessionStorage.getItem('fullme')
  let acces_token = localStorage.getItem('acces_token')
  console.log(acces_token)
  if(ac && no_reload){
    return JSON.parse(ac)
  }

  const response = await axios.get(`${API_URL}/me?`+(with_picture?'with_picture=1':""), { headers: authHeader() });
  // add case of error if get no authenticated user
  if (!response.data) {
    // change route by
    return null;

  }
  sessionStorage.setItem('fullme',JSON.stringify(response.data))
  sessionStorage.setItem('rights',response.data.info.access[0].acces_text)
  if(response.data.roles && response.data.roles.length > 0){
    let role = response.data.roles[0];
    if (role == 'g'){
      let agence = response.data.info.agence;
      agence.attributes.forEach((attr) => {
        attr.fichiers.forEach((file) => {
          localStorage.getItem("agence_file_" + file.id) || getAgenceFile({agence_id: agence.id, attr_id: attr.id, file_id: file.id})
        }
        );
      });
      localStorage.setItem("collabs", JSON.stringify(response.data.collabs));
      var agences = [];
      response.data.info.syndic.supersyndic.syndics.forEach((syndic) => {
        syndic.agences.forEach((agence) => {
        agences.push(agence);})
      });
      localStorage.setItem("agences", JSON.stringify(agences));
      localStorage.setItem("agence", JSON.stringify(response.data.info.agence));
    }

  }
  // load getMe with picture
  getMePicture()

  return response.data;
}

async function getMePicture() {

    const response =  await axios.get(`${API_URL}/me?with_picture=1`, { headers: authHeader() });
    // add images to local storage
    response.data.collabs.forEach((collab) => {
        if(collab.avatar){
        sessionStorage.setItem("collab_image_" + collab.dolibarr_id, collab.avatar);
        }
        }
    );
}

function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

async function getAgenceFile({agence_id, attr_id,file_id}) {
   await axios.get(`${API_URL}/agence/${agence_id}/attr/${attr_id}/files/${file_id}`, {headers: authHeader()})
    .then(async (response) => {

      let att = response.data;

      const response2 = await axios.get(att, { responseType: 'arraybuffer' });
      const base64 = arrayBufferToBase64(response2.data);

      localStorage.setItem("agence_file_" + file_id, base64);

    });
}


async function refreshData() {

    const response = await axios.post(`${API_URL}/me`, {}, { headers: authHeader() });
    return response.data;
  }



async function updateProfile(updateUser) {

  const payload = jsona.serialize({
    stuff: updateUser,
    includeNames: []
  });

  const response = await axios.patch(`${API_URL}/me?include=roles.permissions`, payload, { headers: authHeader() });
  return jsona.deserialize(response.data);
}

export default {
  getMe,
  refreshData,
  updateProfile
};
