<template>
    <div class="p-2" style="height:calc(100vh - 100px); overflow-y: scroll;">
     

<h1 class="h4">{{ item.title }}</h1>
      <button  class="btn btn-info btn-xs" >
        <i class="fa fa-hashtag"></i>&nbsp;{{ id }}  
      </button>
<button v-if="item.immeuble_mini" class="btn btn-danger btn-xs" @click="goImm(item.immeuble_mini.id)"><i
                            class="fa fa-building"></i>&nbsp;{{ item.immeuble_mini.EXTAPI_IMM_ID }} - {{ item.immeuble_mini.nom }}  </button>



<p>{{ item.text }}</p>
<img v-for="f in fichiers" :src="f.url ? f.url : basic_src" :alt="f.name" :key="f.name"  style="max-height: 300px; border-radius: 1rem; margin: 5px;"/>
    
<textarea v-model="reponse" class="form-control" rows="3" placeholder="Réponse"></textarea>
<button class="btn btn-primary" @click="repondre">Répondre</button>

</div>


</template>
<script>
import basic_src from '@/assets/img/loading.gif';
export default {
    components: {
    },
  props: ['id'],
  watch: {
    id: {
      async handler() {
        this.get();
      }
    }
  },
    data() {
        return {
          basic_src: basic_src,
          item:{
            title: '',
            text: ''
          },
          fichiers: [],
            modals: {
                corrigerContexte: {
                    show: false
                }
            }
        }
    },
    methods: {
        goImm(id) {
            this.$router.push({
                name: 'Immeuble',
                params: {
                    id: id
                }
            })
        },
        goEvent(id) {
            this.$router.push({
                name: 'Immeuble',
                params: {
                    id: id
                }
            })
        },
        repondre() {
          this.$store.dispatch("feedback/createfeedbackanswer", { id: this.mainid, answer: this.reponse });
        },
    async get() {
      try {
        console.log('QUERY', this.id)

        const id = this.id;
        console.log('QUERY', id, this.$route.query)
        this.mainid = id;
        this.fichiers = [];
        if(!id){
          return;
        }
        await this.$store.dispatch("feedback/getfeedback", id);
        var b = this.$store.getters["feedback/onefeedback"]
        this.item = b;

        this.fichiers = [];
        this.item.fichiers.forEach(async (att) => {
              console.log('rezr', att)
              let id = att.id;
                  this.fichiers.push({name: att.name, id: att.id});

                  if(att.url){
                    this.fichiers.find((f) => f.id == id).url = att.url;
                    return;
                  }
                await this.$store.dispatch("event/getFile", { id: att.id, event_id: b.id });
                let url_aws_file = this.$store.getters["event/oneFile"];
                // downlaod aws file
                console.log('url_aws_file', url_aws_file)
                fetch(url_aws_file).then((response) => {
                  response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    this.fichiers.find((f) => f.id == id).url = url;

                    //this.fichiers.push({ url: url, name: att.name });
                  });
                });



            })

      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Problème de récupération du feedback !`,
        });
      }
    },
    },
    mounted() {
        this.items = this.$route.params.items;
        this.contexte = this.$route.params.contexte;
        this.get()
    }
}

</script>