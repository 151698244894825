<template>
  <div v-if="user.collaborateur">

    <base-header class="pb-3 text-center border-radius-xl p-3 m-3" style="background-image: url('https://mycopro.fr/assemblee-video/Fond.png'); background-size: cover; background-position: center top;">
      <div
        class="navbar-top container-fluid navbar-expand "
        style="justify-content: end;"
      >
        <ul class="navbar-nav align-items-center ml-auto ml-md-0 pt-2" style="justify-content: end;">
          <div style="position:fixed;right:10px;top:10px;">
                            <a @click="logout" to="" class="btn btn-xs btn-danger">
                                <i class="fa fa-user-run"></i>
                                <span>Déconnexion</span>
                            </a></div>
<!--
          <base-dropdown
            menu-on-right
            class="nav-item"
            tag="li"
            title-tag="a"
            title-classes="nav-link pr-0"
          >

            <template>

            <a href="#" class="nav-link pr-0" @click.prevent >
              <div class="media align-items-center">
                <span
                  class="avatar avatar-sm rounded-circle avatar-image"
                  :style="{
                    'background-image': `url('${profileImage}')`,
                  }"
                >
                </span>
                <div class="media-body ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm font-weight-bold text-white">{{ user.given_name }} {{ user.family_name }}</span>
                </div>
              </div>
            </a>
              <a href="/s/user-profile" class="dropdown-item" >
                <i class="ni ni-single-02"></i>
                <span>Mon Compte</span>
              </a>
              <a  class="dropdown-item" v-for="ql in user.quick_links" v-bind:key="ql.id"  :href="ql.url">
                <i class="ni ni-world-2"></i>
                <span>{{ ql.name }}</span>
              </a>
              <a  class="dropdown-item" v-for="ql in user.info.syndic.quick_links"  v-bind:key="ql.id" :href="ql.url">
                <i class="ni ni-world-2"></i>
                <span>{{ ql.name }}</span>
              </a>
              <div class="dropdown-divider"></div>
              <a @click.prevent="logout()" to="" class="dropdown-item">
                <i class="ni ni-user-run"></i>
                <span>Me déconnecter</span>
              </a>
            </template>
          </base-dropdown>-->
        </ul>
        </div>
    <div class=" py-4 px-3 text-center" v-if="rights.includes('mails')">
      <h1 class="text-white" style="font-family: Skia;color: rgb(153, 241, 201) !important;font-size: 25pt;">{{ today}}</h1>
      <div v-if="!user.collaborateur.outlook_tokenExpires" class="card row align-items-center py-4 mt-5">
        <h3 class="h3">Connexion à un service e-mail</h3>
        <h6 class=" h4 d-inline-block mb-0">Pour profiter de toutes les fonctionnalités de MyCopro, veuillez vous connecter à votre compte professionnel Microsoft</h6>
        <button  class="btn btn-dark btn-lg my-3" @click="microsoftConnect">
          Me connecter à mon compte Microsoft <img class="ml-4" :src="ms_logo" alt="MS logo">
        </button>

      </div>
      <div v-if="user.collaborateur.outlook_tokenExpires">
        <h3 class="text-white">{{ user.given_name }} {{ user.family_name }} </h3>
      </div>
      <!--<div style="position: fixed; right:10px; top:10px">

                        <a class="nav-link pr-0 dropdown-toggle"
        :aria-expanded="show_perso_options"
        data-toggle="dropdown"
        @click="show_perso_options = !show_perso_options">
                            <div class="media align-items-center">
                                <span class="avatar avatar-sm rounded-circle avatar-image" :style="{
                                    'background-image': `url('${profileImage}')`,
                                }">
                                </span>
                                <div class="media-body ml-2 d-none d-lg-block">
                                    <span class="mb-0 text-sm font-weight-bold">{{ user.title }}</span>
                                </div>
                            </div>
                        </a>
                        <div >

                        <li class="dropdown">
                          <ul class="dropdown-menu dropdown-menu-right"
                              :class="[{ show: show_perso_options },]">
                            <div class="dropdown-header noti-title">
                                <h6 class="text-overflow m-0">Bonjour !</h6>
                            </div>
                            <a href="/s/user-profile" class="dropdown-item" >
                                <i class="ni ni-single-02"></i>
                                <span>Mon compte</span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a @click.prevent="this.$store.dispatch('logout')" to="" class="dropdown-item">
                                <i class="ni ni-user-run"></i>
                                <span>Logout</span>
                            </a>

                          </ul>
                        </li>
                        </div>



                </div>-->
      <div>
        <a v-for="link in user.info.syndic.quick_links" :key="link.id" :href="link.url" class="btn btn-white btn-xs">{{ link.name }}</a>
      </div>


      </div>
      <div v-else class=" py-4 px-3 text-center">
        <h1 class="text-white" style="font-family: Skia;color: rgb(153, 241, 201) !important;font-size: 25pt;">{{ today}}</h1>
        <div  class="card row align-items-center py-4 mt-5">

        <h3 class="h3">Bienvenue sur MyCopro</h3>
        <h3 class="h6">Mes accès :</h3>

        <ul class="navbar navbar-collapse navbar-nav">
        <sidenav-item v-if="rights.includes('immeubles')"
                :to="{ name: 'Liste des immeubles' }"
                text="Mes Immeubles"
              >
          <template #icon>
            <i class="fa fa-building text-danger text-center text-sm opacity-10 m-2 mt-0 mb-0"></i>
          </template>
            </sidenav-item>

            <sidenav-item v-if="rights.includes('résidents')"
                :to="{ name: 'Liste des résidents' }"
                text="Mes Résidents"
              >
          <template #icon>
            <i class="fa fa-group text-yellow text-center text-sm opacity-10 m-2 mt-0 mb-0"></i>
          </template>
            </sidenav-item>
          </ul>
        </div>
      </div>
    </base-header>
    <base-header class="pb-6"  v-if="1==user.collaborateur.outlook_tokenExpires">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Default</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
         <!-- <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
      <!-- Card stats
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card title="Total traffic"
                      type="gradient-red"
                      sub-title="350,897"
                      icon="ni ni-active-40">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Total traffic"
                      type="gradient-orange"
                      sub-title="2,356"
                      icon="ni ni-chart-pie-35">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Sales"
                      type="gradient-green"
                      sub-title="924"
                      icon="ni ni-money-coins">

            <template slot="footer">
              <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 5.72%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>

        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Performance"
                      type="gradient-info"
                      sub-title="49,65%"
                      icon="ni ni-chart-bar-32">

            <template slot="footer">
              <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 54.8%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
      </div> -->
    </base-header>

    <div class="container-fluid mt--6" v-if="user.collaborateur.outlook_tokenExpires">


      <!--Lists-
      <div class="row">
        <div class="col-xl-4">
          <card>
            <h5 class="h3 mb-0" slot="header">Team members</h5>

            <user-list></user-list>
          </card>
        </div>

        <div class="col-xl-4">
          <card>
            <h5 class="h3 mb-0" slot="header">To do list</h5>

            <task-list></task-list>
          </card>
        </div>

        <div class="col-xl-4">
          <card>
            <h5 class="h3 mb-0" slot="header">Progress track</h5>

            <progress-track-list></progress-track-list>
          </card>
        </div>
      </div>
      -End lists-->

      <!--Widgets-->
      <div class="row">
        <div class="col-xl-6 pt-1">
          <MailTinyView/>
        </div><!--
        <div class="col-xl-6 pt-1">
          <CalendarWidget/>
        </div>-->

        <div class="col-xl-6 pt-1">
          <EventTinyView/>
        </div><!--<div class="col-xl-6 pt-1">
          <CallTinyView/>
        </div>-->
        <!--
        <div class="col-xl-7">
          <activity-feed></activity-feed>
          <light-table></light-table>
          <div class="card-deck">
            <card gradient="default" no-body="">
              <div class="card-body">
                <div class="mb-2">
                  <sup class="text-white">$</sup> <span class="h2 text-white">3,300</span>
                  <div class="text-light mt-2 text-sm">Your current balance</div>
                  <div>
                    <span class="text-success font-weight-600">+ 15%</span> <span class="text-light">($250)</span>
                  </div>
                </div>
                <button class="btn btn-sm btn-block btn-neutral">Add credit</button>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <small class="text-light">Orders: 60%</small>
                    <base-progress :value="60"
                                   size="xs"
                                   progress-classes="my-2"
                                   type="success"/>
                  </div>
                  <div class="col">
                    <small class="text-light">Sales: 40%</small>
                    <base-progress :value="40"
                                   size="xs"
                                   progress-classes="my-2"
                                   type="warning"/>
                  </div>
                </div>
              </div>

            </card>

            <card gradient="danger">

              <div class="row justify-content-between align-items-center">
                <div class="col">
                  <img src="/img/icons/cards/bitcoin.png" alt="Image placeholder">
                </div>
                <div class="col-auto">
                  <span class="badge badge-lg badge-success">Active</span>
                </div>
              </div>
              <div class="my-4">
                  <span class="h6 surtitle text-light">
                    Username
                  </span>
                <div class="h1 text-white">@johnsnow</div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="h6 surtitle text-light">Name</span>
                  <span class="d-block h3 text-white">John Snow</span>
                </div>
              </div>

            </card>
          </div>
        </div>-->
      </div>
      <!--End Widgets-->
      <!--Tables
      <div class="row">
        <div class="col-xl-8">
          <page-visits-table></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table></social-traffic-table>
        </div>
      </div>
      -End tables-->
    </div>

  </div>
</template>
<script>import Vuex from "vuex";
import Swal from "sweetalert2";
import BaseHeader from "../../components/BaseHeader.vue";

import EventTinyView from './evenements/ListeEvenementsMini.vue'

//import CallTinyView from '@/views/widgets/calls'
import MailTinyView from './mails/ListeMailsMini'
//import CalendarWidget from '@/views/widgets/calendar'
import placeholder from "@/assets/img/placeholder.jpg"
import ms_logo from '@/assets/img/logos/microsoft.svg'
import SidenavItem from "../../examples/Sidenav/SidenavItem.vue";

export default {
  components: {
    BaseHeader,
   // CalendarWidget,
   EventTinyView,
   MailTinyView, //CallTinyView,
    SidenavItem
  },
  data() {
    return {
      ms_logo,
      placeholder,
      show_perso_options: false,
      today: '',
    };
  },
  computed: {
    ...Vuex.mapState({
      me: state => state.profile.me
    }),
    user() {
      return this.me || {};
    },
    profileImage() {
      return this.user.avatar || this.placeholder;
    },
    rights() {
      if (!this.user.info) return ["immeubles", "résidents", "fournisseurs", "mails", "évènements"];

      const tmp_rights = this.user.info.access;
      if (!tmp_rights || tmp_rights.length === 0) {
        return ["immeubles", "résidents", "fournisseurs", "mails", "évènements"];
      }

      return [...new Set(tmp_rights.flatMap(r => JSON.parse(r.acces_text)))];
    }
  },
  created() {
    this.initializeToday();
  },
  methods: {
    initializeToday() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date().toLocaleDateString("fr", options);
      this.today = date.charAt(0).toUpperCase() + date.slice(1);
    },
    logout() {
      Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Vous allez être déconnecté de MyCopro. Vous pourrez vous reconnecter à tout moment.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, me déconnecter",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn bg-gradient-danger m-2",
          cancelButton: "btn bg-gradient-success m-2",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.logoutConfirmed();
        }
      });
    },
    async logoutConfirmed() {
      try {
        await this.$store.dispatch('auth/logout');
        this.$router.push({ name: 'Login' });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Nous n'avons pas réussi à vous déconnecter !",
        });
      }
    },
    async microsoftConnect() {
      await this.$store.dispatch("mail/connectAuth");
     // const  url = this.$store.getters["mail/connect"];

     // console.log(url);
     window.location = localStorage.getItem('microsoft_auth_url');
    },
  },
};
</script>
<style></style>
