import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListPersos(params){
  const response =  await axios.get(`${API_URL}/persos`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListPersosFromBuilding(params){
  let immeuble_id = params.immeuble_id;
  const response =  await axios.get(`${API_URL}/immeubles/${immeuble_id}/coproprietaires`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data, meta: response.data.meta};
}

async function getPerso(PersoId){
  const response =  await axios.get(`${API_URL}/persos/${PersoId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editPerso(Perso){

  const payload = jsona.serialize({
    stuff: Perso,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/persos/${Perso.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}



async function createPerso(Perso){



  const response =  await axios.post(`${API_URL}/tiers?include=category,tags`, Perso, {headers: authHeader()});
  return response.data;
}

async function deletePerso(PersoId){
  await axios.delete(`${API_URL}/persos/${PersoId}`, {headers: authHeader()});
}


async function exportPersos(param){

    const response =  await axios.get(`${API_URL}/export/persos`, {params:param, headers: authHeader()});
    // get as attachment
    // add
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv', encoding: 'UTF-8' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export_persos.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response.data;
  }



async function uploadImagePerso(Perso) {
    const payload = new FormData();
    payload.append("attachment", Perso.image);

    const response = await axios.post(`${API_URL}/uploads/Persos/${Perso.id}/image`, payload, { headers: authHeader() });
    return response.data.url;

  }

export default {
  getListPersos,getListPersosFromBuilding,
    getPerso,exportPersos,
    editPerso,
    createPerso,
    deletePerso,
    uploadImagePerso
};
