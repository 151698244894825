/* eslint-disable no-unused-vars */
import SyndicService from "../services/syndic.service.js";

export const syndic = {
    namespaced: true,
    state: {
        syndicsList: null,
        oneSyndic: null,
        image: null,
    },
    getters: {
        syndicsList: state => state.syndicsList,
        oneSyndic: state => state.oneSyndic,
        image: state => state.image,
    },
    actions: {
        async syndicsList({ commit }, params) {
            console.log("action")
            const data = await SyndicService.getListSyndics(params);
            console.log(data);
            commit('SyndicS_LIST', data);
        },
        async getSyndic({ commit }, itemId) {
            const data = await SyndicService.getSyndic(itemId);
            commit('GET_Syndic', data);
        },
        async addSyndic({ commit }, item) {
            const data = await SyndicService.createSyndic(item);
            commit('GET_Syndic', data);
        },
        async editSyndic({ commit }, item) {
            await SyndicService.editSyndic(item);
        },
        async deleteSyndic({ commit }, itemId) {
            await SyndicService.deleteSyndic(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await SyndicService.uploadImageSyndic(item);
            commit('UPLOAD_IMAGE_Syndic', data);
        },
    },
    mutations: {
        SyndicS_LIST(state, data) {
            state.syndicsList = data;
        },
        GET_Syndic(state, data) {
            state.oneSyndic = data;
        },
        UPLOAD_IMAGE_Syndic(state, data) {
            state.image = data;
        },
  }
}