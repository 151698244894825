/* eslint-disable no-unused-vars */
import SuperSyndicService from "../services/supersyndic.service.js";

export const supersyndic = {
    namespaced: true,
    state: {
        superSyndicsList: null,
        oneSuperSyndic: null,
        image: null,
    },
    getters: {
        supersyndicsList: state => state.superSyndicsList,
        oneSuperSyndic: state => state.oneSuperSyndic,
        image: state => state.image,
    },
    actions: {
        async supersyndicsList({ commit }, params) {
            console.log("action")
            const data = await SuperSyndicService.getListSuperSyndics(params);
            console.log(data);
            commit('SuperSyndicS_LIST', data);
        },
        async getSuperSyndic({ commit }, itemId) {
            const data = await SuperSyndicService.getSuperSyndic(itemId);
            commit('GET_SuperSyndic', data);
        },
        async addSuperSyndic({ commit }, item) {
            const data = await SuperSyndicService.createSuperSyndic(item);
            commit('GET_SuperSyndic', data);
        },
        async editSuperSyndic({ commit }, item) {
            await SuperSyndicService.editSuperSyndic(item);
        },
        async deleteSuperSyndic({ commit }, itemId) {
            await SuperSyndicService.deleteSuperSyndic(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await SuperSyndicService.uploadImageSuperSyndic(item);
            commit('UPLOAD_IMAGE_SuperSyndic', data);
        },
    },
    mutations: {
        SuperSyndicS_LIST(state, data) {
            state.superSyndicsList = data;
        },
        GET_SuperSyndic(state, data) {
            state.oneSuperSyndic = data;
        },
        UPLOAD_IMAGE_SuperSyndic(state, data) {
            state.image = data;
        },
  }
}