import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListEvents(params){
  const response =  await axios.get(`${API_URL}/calendar`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return response.data;
}

async function getEvent(EventId){
  const response =  await axios.get(`${API_URL}/calendar/${EventId}?include=category,tags`, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function editEvent(Event){

  const payload = jsona.serialize({
    stuff: Event,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/calendar/${Event.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createEvent(Event){

  const payload = jsona.serialize({
    stuff: Event,
    includeNames: []
  });

  const response =  await axios.post(`${API_URL}/events?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function deleteEvent(EventId){
  await axios.delete(`${API_URL}/events/${EventId}`, {headers: authHeader()});
}

async function uploadImageEvent(Event) {
    const payload = new FormData();
    payload.append("attachment", Event.image);

    const response = await axios.post(`${API_URL}/uploads/events/${Event.id}/image`, payload, { headers: authHeader() });
    return response.data.url;

  }

export default {
  getListEvents,
    getEvent,
    editEvent,
    createEvent,
    deleteEvent,
    uploadImageEvent
};
