/* eslint-disable no-unused-vars */
import ViewService from "../services/view.service.js";

export const view = {
    namespaced: true,
    state: {
        viewsList: null,
        oneView: null,
        image: null,
    },
    getters: {
        viewsList: state => state.viewsList,
        oneView: state => state.oneView,
        image: state => state.image,
    },
    actions: {
        async viewsList({ commit }, params) {
            console.log("action")
            const data = await ViewService.getListViews(params);
            console.log(data);
            commit('ViewS_LIST', data);
        },
        async getView({ commit }, itemId) {
            const data = await ViewService.getView(itemId);
            commit('GET_View', data);
        },
        async getDetailedView({ commit }, itemId) {
            const data = await ViewService.getDetailedView(itemId);
            commit('GET_View', data);
        },
        async addView({ commit }, item) {
            const data = await ViewService.createView(item);
            commit('GET_View', data);
        },
        async editView({ commit }, item) {
            await ViewService.editView(item);
        },
        async dupplicateView({ commit }, item) {
            await ViewService.dupplicateView(item);
        },
        async deleteView({ commit }, itemId) {
            await ViewService.deleteView(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await ViewService.uploadImageView(item);
            commit('UPLOAD_IMAGE_View', data);
        },
    },
    mutations: {
        ViewS_LIST(state, data) {
            state.viewsList = data;
        },
        GET_View(state, data) {
            state.oneView = data;
        },
        UPLOAD_IMAGE_View(state, data) {
            state.image = data;
        },
  }
}