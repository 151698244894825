<template>
  <div class="py-1 container-fluid">
    <div class="header bg-success bg-gradient-danger pb-1 border-radius-xl">
      <div class="py-1 container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-3 text-left">
            <button class="btn btn-white mt-2 btn-xs" @click="router.go(-1)">
              <span class="btn-inner--icon"><i class="fas fa-arrow-left"></i></span> &nbsp; Retour
            </button>
          </div>
          <div class="col-lg-6 text-center">
            <h6 class="h6 text-white d-inline-block mb-0"> {{ immInfos.EXTAPI_IMM_ID }} &nbsp;</h6>
            <h6 class="h4 text-white d-inline-block mb-0"> {{ immInfos.nom }}</h6>
          </div>
          <div class="col-lg-3 text-end">
            <button class="btn btn-white mt-2 btn-xs" @click="openImmeuble()">
              <i class="fas fa-info"></i> &nbsp;Immeuble
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-1 container-fluid">
    <table>
      <tr class="text-lg">
        <td style="padding: 10px"> 
          <h6 style="cursor: pointer;" @click="openIntranet"> 
            <i class="fas fa-arrow-right"></i> Immeubles  
          </h6>
        </td>
        <td style="padding: 10px"> 
          <h6 style="cursor: pointer;" @click="openImm"> 
            <i class="fas fa-arrow-right"></i> {{ immInfos.EXTAPI_IMM_ID }}  
          </h6>
        </td>
      </tr>
    </table>
  </div>
  <div class="py-1 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-9 text-left">
        <div v-if="vueListe">
          <h6 v-if="folderDataTable.length > 0" class="h2 text-black d-inline-block mb-0"> Mes dossiers</h6>
          <div class="card">
            <div class="px-0 pb-0 pt-1 card-body">
              <div id="infinite-list" class="table-responsive" style="height:calc(100vh - 200px)" @scroll="handleScroll">
                <table class="table table-responsive">
                  <tbody class="text-lg">
                    <tr>
                      <td > Nom </td>
                      <td > Dernière modification </td>
                    </tr>
                    <tr v-for="folder in folderDataTable" :key="folder.id">
                      <td style="cursor: pointer;" @click="openFolder(folder.id)">
                        <i class="fas fa-solid fa-folder"></i> {{ folder.name }}
                      </td>
                      <td> {{ dateModif(folder.updated_at) }} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h6 v-if="folderDataTable.length > 0" class="h2 text-black d-inline-block mb-0"> Mes dossiers</h6>
          <div class="row" >
            <div v-for="folder in folderDataTable" :key="folder.id" class="col-lg-3">
              <div class="card p-3 mb-2 bg-light">
                <div class="row" >                
                  <div class="col-lg-10">
                    <div class="card p-3" @click="openFolder(folder.id)">
                      <h6 style="flex:2;width: 100%;cursor: pointer;">
                        <i class="fas fa-solid fa-folder"></i> {{ folder.name }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 text-left">
        <h6 class="h2 text-black d-inline-block mb-0"> Mes options</h6>
        <div v-if="vueListe" class="card p-3">
          <div class="row">
            <button class="btn btn-white bg-light mb-2" @click="vueListe = false">
              <i class="fas fa-solid fa-grip-horizontal"></i>&nbsp;Vue grille
            </button>
          </div>
        </div>
        <div v-else class="card p-3 bg-light">
          <div class="row">
            <button class="btn btn-white mb-2" @click="vueListe = true">
              <i class="fas fa-solid fa-list"></i>&nbsp;Vue liste
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import setNavPills from "@/assets/js/nav-pills.js";

const store = useStore();
const router = useRouter();
const route = useRoute();
const folderData = ref([]);
const imm = ref([]);
const vueListe = ref(true);


function dateModif(timestamp) {
    const date = new Date(timestamp);

    // Extraire les composants de la date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Formater la date au format "année-jour-mois heure-minute"
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const fetchData = async () => {
  const immId = route.params.id
  await store.dispatch("intranet/getRoot", immId)
  if (store.getters['intranet/oneRoot']) {
    folderData.value = store.getters['intranet/oneRoot']
  }
};

const fetchDataImmeuble = async () => {
  const id = route.params.id;
  await store.dispatch("intranet/getImmInfos", id);
  if (store.getters['intranet/immInfos']) {
    imm.value = store.getters['intranet/immInfos'].imm
  }
};

const openFolder = (folder) => {
  router.push({
    name: 'Dossier',
    params: { 
      id: route.params.id,
      folderId: folder
      }
  })  
}

const openImm = () => {
  router.push({
    name: 'Racine',
    params: { 
      id: route.params.id
      }
  })  
}

const openIntranet = () => {
  router.push({
    name: 'Liste Intranet'
  })  
}

const openImmeuble = () => {
  router.push({
    name: 'Immeuble',
    params: { 
      id: route.params.id
      }
  })  
}

onMounted(() => {
  setNavPills();
  fetchDataImmeuble();
  fetchData();
});

watch(route, () => {
  fetchData()
})

const folderDataTable = computed(() => {
  return folderData.value
})

const immInfos = computed (() => { 
  return imm.value
})

</script>
