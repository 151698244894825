<template>
    <div class="btn-group text-center" role="group">
  <el-tooltip
        placement="top"
    content="Oui"
  >
                    <button type="button" class="btn btn-xs"
                        :class="activeTab == 'oui' ? 'btn-success' : 'btn-outline-secondary'"
                        @click="changeTab('oui')"><i class="fa fa-check"></i></button>
 
  </el-tooltip>
                        
  <el-tooltip
        placement="top" 
    content="Non communiqué"
  >
                    <button type="button" class="btn btn-xs "
                        :class="activeTab == 'nc' ? 'btn-secondary' : 'btn-outline-secondary'"
                        @click="changeTab('nc')">NC</button>
   
  </el-tooltip>
  <el-tooltip
        placement="top"
    content="Non"
  >
                    <button type="button" class="btn btn-xs "
                        :class="activeTab == 'non' ? 'btn-danger' : 'btn-outline-secondary'"
                        @click="changeTab('non')"><i class="fa fa-close"></i></button>

  </el-tooltip>

                </div>
</template>
<script>
// import elpopover
import { ElTooltip } from 'element-plus';
export default {
    name: "MidToggle",
    components: {
        ElTooltip
    },
    data() {
        return {
            activeTab: 'nc'
        }
    },
    methods: {
        changeTab(tab) {
            this.activeTab = tab;
        }
    }
}
</script>