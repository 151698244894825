<template>
    <div class="mb-1" >
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
        <h3>{{ modele[options.prop] }}</h3>
    </div>
    <div v-else class="bg-light border-radius-lg p-2" style="display: flex">
        <div class="bg-light border-radius-lg p-2 text-black text-sm">{{ options.name }}</div>
        <VueDatePicker 
        v-model="modele[options.prop]" 
        locale="fr" 
        :enable-time-picker="false"
        selectText="Choisir"
    cancelText="Annuler"
    :format="p_format"
    ></VueDatePicker>
    
    </div>
        
        </div>
</template>
<script>


//import {ArgonInput} from "@/components/ArgonInput.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
    name: "BasicInput",
    components: {
       // ArgonInput,
       VueDatePicker
    },
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: () =>[]
        },
    },
    data() {
        return {
            modele: this.modelValue
        }
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
    methods:{
        p_format(date) {
  const day = date.getDate();
  const month = date.getMonth() +1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
    }
}
</script>