<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Réglages MyCopro</h5>
          <p>Modifier vos options d'affichage ici</p>
        </div>
        <div class="mt-4 float-end" @click="toggleConfigurator">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <!-- Sidebar Backgrounds
        <div>
          <h6 class="mb-0">Sidebar Colors</h6>
        </div>
        <div class="switch-trigger background-color">
          <div
            class="my-2 badge-colors"
            :class="isRTL ? 'text-end' : ' text-start'"
          >
            <span
              class="badge filter bg-gradient-primary"
              data-color="primary"
              @click="sidebarColor('primary')"
            ></span>
            <span
              class="badge filter bg-gradient-dark"
              data-color="dark"
              @click="sidebarColor('dark')"
            ></span>
            <span
              class="badge filter bg-gradient-info"
              data-color="info"
              @click="sidebarColor('info')"
            ></span>
            <span
              class="badge filter bg-gradient-success"
              data-color="success"
              @click="sidebarColor('success')"
            ></span>
            <span
              class="badge filter bg-gradient-warning"
              data-color="warning"
              @click="sidebarColor('warning')"
            ></span>
            <span
              class="badge filter bg-gradient-danger"
              data-color="danger"
              @click="sidebarColor('danger')"
            ></span>
          </div>
        </div> -->
        <!-- Sidenav Type -->
        <div class="mt-3">
          <h6 class="mb-0">Barre de navigation</h6>
          <p class="text-sm">Choisissez parmi les deux styles possibles.</p>
        </div>
        <div class="d-flex">
          <button
            id="btn-white"
            class="btn bg-gradient-white w-100 px-3 mb-2 me-2"
            :class="sidebarType === 'bg-white' ? ' active' : ''"
            @click="sidebar('bg-white')"
          >
            Claire
          </button>
          <button
            id="btn-dark"
            class="btn bg-gradient-dark w-100 px-3 mb-2"
            :class="sidebarType === 'bg-default' ? 'active' : ''"
            @click="sidebar('bg-default')"
          >
            Sombre
          </button>
        </div>
        <p class="mt-2 text-sm d-xl-none d-block">
          You can change the sidenav type just on desktop view.
        </p>
        <!-- Navbar Fixed
        <div class="mt-3 d-flex">
          <h6 class="mb-0">Navbar Fixed</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              id="navbarFixed"
              class="mt-1 form-check-input"
              :class="isRTL ? 'float-end  me-auto' : ' ms-auto'"
              type="checkbox"
              :checked="isNavFixed"
              @click="navbarFixed"
            />
          </div>
        </div>-->

        <hr class="my-3 horizontal dark" />
        <div class="mt-2 d-flex">
          <h6 class="mb-0">Mini Barre de navigation</h6>

          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              id="navbarMinimize"
              class="mt-1 form-check-input"
              :class="isRTL ? 'float-end  me-auto' : ' ms-auto'"
              type="checkbox"
              @click="navbarMinimize"
            />
          </div>
        </div>
        <hr class="horizontal dark my-3" />
        <div class="mt-2 d-flex">
          <h6 class="mb-0">Mode sombre</h6>
          <div class="form-check form-switch ps-0 ms-auto my-auto">
            <input
              class="form-check-input mt-1 ms-auto"
              type="checkbox"
              :checked="$store.state.darkMode"
              @click="darkMode"
            />
          </div>
        </div>
        <hr class="horizontal dark my-sm-4" />
        <a
          class="btn  bg-gradient-danger w-100"
          @click="refreshStatic"
          >Rafraichir les données statiques</a
        >
        <!--
        <a
          class="btn  bg-gradient-danger w-100"
          href="https://web.mycopro.fr/mon-compte"
          >Paramètres de mon compte</a
        >-->
        <a
          class="btn btn-outline-dark w-100"
          href="https://web.mycopro.fr/mentions-legales"
          >Mentions légales</a>
        <a
          class="btn btn-outline-dark w-100"
          href="https://web.mycopro.fr/mentions-legales"
          >CGV</a>
          <!--
        <a
          class="btn btn-outline-danger  w-100"
          href="https://api.mycopro.fr/"
          >Documentation API</a
        >-->
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations, mapActions } from "vuex";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";
export default {
  name: "Configurator",
  computed: {
    ...mapState(["isRTL", "isNavFixed", "sidebarType"])
  },
  methods: {
    ...mapMutations([
      "navbarMinimize",
      "navbarFixed",
      "setSidebarType",
      "toggleConfigurator"
    ]),
    ...mapActions(["toggleSidebarColor"]),
    sidebarColor(color = "success") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
    },
    async refreshStatic(){
        sessionStorage.removeItem("fullme")
        localStorage.removeItem("collabs")
        sessionStorage.removeItem("mailsLoaded")
        await this.$store.dispatch("profile/refreshData");
        window.location.reload(true);

    },
    sidebar(type) {
      this.setSidebarType(type);
    },
    darkMode() {
      if (this.$store.state.darkMode) {
        this.$store.state.darkMode = false;
        localStorage.removeItem("darkMode");
        this.sidebar("bg-white");
        deactivateDarkMode();
        document.firstElementChild.classList.remove("dark");
        return;
      } else {
        this.$store.state.darkMode = true;
        localStorage.setItem("darkMode", true);
        this.sidebar("bg-default");

        activateDarkMode();
        document.firstElementChild.classList.add("dark");



      }
    }
  }
};
</script>
