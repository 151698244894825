/* eslint-disable no-unused-vars */
import CollaborateurService from "../services/collaborateur.service.js";

export const collaborateur = {
  namespaced: true,
  state: {
    collaborateursList: null,
    oneCollaborateur: null,
    image: null,
    zones:null
  },
  getters: {
    collaborateursList: (state) => state.collaborateursList,
    oneCollaborateur: (state) => state.oneCollaborateur,
    image: (state) => state.image,
    zones: (state) => state.zones
  },
  actions: {
    async collaborateursList({ commit }, params) {
      console.log("action");
      const data = await CollaborateurService.getListCollaborateurs(params);
      console.log(data);
      commit("CollaborateurS_LIST", data);
    },
    async getZones({ commit }, itemId) {
        const data = await CollaborateurService.getZones(itemId);
        commit("GET_Zones", data);
      },
    async getCollaborateur({ commit }, itemId) {
      const data = await CollaborateurService.getCollaborateur(itemId);
      commit("GET_Collaborateur", data);
    },
    async addCollaborateur({ commit }, item) {
      const data = await CollaborateurService.createCollaborateur(item);
      commit("GET_Collaborateur", data);
    },
    async editCollaborateur({ commit }, item) {
      await CollaborateurService.editCollaborateur(item);
    },
    async updateCollaborateurZones({ commit }, item) {
      await CollaborateurService.updateCollaborateurZones(item);
    },
    async deleteCollaborateur({ commit }, itemId) {
      await CollaborateurService.deleteCollaborateur(itemId);
    },
    async uploadImageItem({ commit }, item) {
      const data = await CollaborateurService.uploadImageCollaborateur(item);
      commit("UPLOAD_IMAGE_Collaborateur", data);
    },
    async updateWebexToken({ commit }, item) {
      try {
        // On récupère les données du stockage local
        const storeData = JSON.parse(localStorage.getItem("collabs"));
        const data = await CollaborateurService.editCollaborateurWebexToken(item, storeData[0].id);
        console.log("retour du stockage en db : ", data);
      } catch (error) {
        console.error("Erreur dans la fonction de set des access token de webex", error);
      }
    },
  },
  mutations: {
    CollaborateurS_LIST(state, data) {
      state.collaborateursList = data;
    },
    GET_Collaborateur(state, data) {
      state.oneCollaborateur = data;
    },
    GET_Zones(state, data) {
      state.zones = data;
    },
    UPLOAD_IMAGE_Collaborateur(state, data) {
      state.image = data;
    },
  },
};
