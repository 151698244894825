<template>
    <div class="mb-1">
        <!--op:{{ options }}-->
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
    <div  class="bg-purple border-radius-lg p-2" style="display: flex;">
        <div class="bg-purple border-radius-lg p-2 text-white text-sm pr-3" >{{ options.name }}</div>
        <div class="bg-purple border-radius-lg p-1 text-bold text-white w-100" style="margin-top: 2px ;font-weight: 700;">{{ perso_name }}</div>
        <button @click="goFournisseur()" class="btn btn-xs btn-white mb-0"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>
    </div>
    </div>
    <div v-else class="bg-purple border-radius-lg p-2">
        <Modal v-model="new_interlocuteur.show" :header="new_interlocuteur.title" closeable>
                    <AjoutEmploye v-model="new_interlocuteur" :item="four" />
                </Modal>
        <div style="display: flex;">
            <div v-if="fournisseurs_len > 1"><button class="btn btn-danger btn-xs mt-1" @click="removeFour(key)"><i class="fa fa-trash"></i></button></div>
        <div class="bg-purple border-radius-lg p-2 text-white text-sm pr-3" >{{ options.name }}</div>
        

    <el-select class="mt-1" style="width:100%" round v-model="fournisseur" filterable remote reserve-keyword placeholder="Merci d'entrer un fournisseur"
                    remote-show-suffix :remote-method="getFournisseurs" :loading="loading.immeuble" :disabled="disabled.immeuble">
                    <el-option v-for="item in all_immeubles" :key="item.id" :label="item.thirdparty_name " :value="item.id" />
                </el-select>
        </div>

                <div  style="display: flex;" v-if="fournisseur">
        <div class="bg-purple border-radius-lg p-2 text-white text-sm pr-3" >Interlocuteur</div>
                  <el-select class="mt-1" style="width:100%" v-model="interlocuteur" round filterable  reserve-keyword placeholder="Merci d'entrer un interlocuteur"
                   >
                    <el-option v-for="item in interlocuteurs" :key="item.id" :label="item.firstname +' '+ item.lastname " :value="item.id" />
                </el-select>
                <button class="btn btn-white btn-xs mt-1" @click="new_interlocuteur.show = true;"><i class="fa fa-plus"></i></button>
                </div>
        </div>
        </div>
</template>
<script>
//import {ArgonInput} from "@/components/ArgonInput.vue";
import {ElSelect, ElOption} from "element-plus";
import AjoutEmploye from "../Actions/AjoutEmploye.vue";
import Modal from "@/components/Modal.vue";
export default {
    name: "Immeuble",
    components: {
       // ArgonInput,
        ElSelect,ElOption,
        AjoutEmploye,
        Modal
    },
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        blocking: {
            type: Array,
            default: ()=>{}
        },
        fournisseurs_len: {
            type: Number,
            default: 0
        },
        fournisseurs: {
            type: Array,
            default: ()=>{}
        },
    },
    data() {
        return {

        new_interlocuteur: {
          show: false,
          title: 'Nouvelle Fiche Interlocuteur',
          item: {
          }
        },

            interlocuteurs:[],
            interlocuteur:{},
            fournisseur:{},
            modele: this.modelValue,
            all_immeubles:[],
            loading:{
                immeuble:true,
            },
            disabled: {
                perso:false,
                immeuble:false,
                lot:false
            }
        }
    },
    watch: {
        fournisseur: function (val) {
            if (typeof val === "number" || typeof val === "string"){
            this.modele["fournisseur_id"] = val
            this.$emit('update:modelValue', this.modele)
            this.interlocuteur = 0
            this.getFournisseur(val)
            }
        },
        interlocuteur: function (val) {
            this.modele["interlocuteur_id"] = val
            this.$emit('update:modelValue', this.modele)
        },
        modelValue: function (val) {
            this.modele = val
        }
        
    },
    mounted(){
        if (this.modele[this.options.prop.fournisseur]){
            
            this.fournisseur = this.modele[this.options.prop.fournisseur]
            console.log("FF")
            console.log(this.fournisseur)
           this.getFournisseur(this.fournisseur)
            this.interlocuteur = this.modele[this.options.prop.fournisseur].interlocuteur_id
        }
        /*

        if (this.modele.perso){
            this.all_persos = [this.modele.perso];
            this.all_persosd = [this.modele.perso.id];
            this.bounce_perso = true;
            if (this.modele.perso.id){
                this.getPersoById(this.modele.perso.id)
            }
        } else if(this.modele.perso_id){

            this.getPersoById(this.modele.perso_id)
        } else if (this.modele.immeuble_id){
            this.getFromImmeuble()
        }
        */
       if(this.modele[this.options.prop.fournisseur]){
            this.getFournisseurById(this.modele[this.options.prop.fournisseur])
        }

    },
    methods:{

        async getFournisseurs(p){
                this.loading.immeuble =true;
                let params = {
                    ...(p ? { search: p } : {}),
                    page: 1,
                    size: 20,
                    sort: "events.last",
                };
                console.log(params)
                await this.$store.dispatch("fournisseur/fournisseursList", params);
                this.all_immeubles = this.$store.getters["fournisseur/fournisseursList"].data;
                this.all_immeublesd = [];
                    this.all_immeubles.forEach(imm => {
                        this.all_immeublesd.push(imm.id);
                    });
                this.loading.immeuble =false;
                console.log(this.all_immeubles)
        },
        async getFournisseur(){
            await this.$store.dispatch("fournisseur/getFournisseur", this.fournisseur);
            this.more = this.$store.getters["fournisseur/oneFournisseur"];
            this.interlocuteurs = []
            this.interlocuteurs = this.more.employees
            console.log(this.modele.fournisseur)
        },
        removeFour(key){
            console.log(key)
            let fournisseurs = this.fournisseurs
            fournisseurs.splice(key,1);
            this.$emit('update:fournisseurs', fournisseurs) 
            //
            console.log(this.fournisseurs)
        },  
        

        async getFournisseurById(pid){
            await this.$store.dispatch("fournisseur/getFournisseur", pid);
            this.perso_tmp = this.$store.getters["fournisseur/oneFournisseur"];
            this.perso_id = this.perso_tmp.id;
            this.perso_name = this.perso_tmp.nom + ' ' + this.perso_tmp.prenom;

        }
    }
}
</script>