<script setup>import { ref, computed, onMounted, watch,toRaw } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map';
import ArgonInput from "../../../components/ArgonInput.vue";
import { //ElSelect, ElOption,
    ElButton } from 'element-plus';
import _ from "lodash";
import AddressSearch from './AddressSearch.vue';
import BaseButton from "@/components/BaseButton.vue"

const store = useStore();
const router = useRouter();
const EDITING_MODE = ref(false)
const center = ref({ lat: 47.21384480, lng: -1.56349300 });
const zoom = ref(13)
const sort = ref("EXTAPI_IMM_ID");
const city = ref(null);
const onlyme = ref("Tous les immeubles actifs du parc");
const updatedList = ref([]);
const search = ref('');
const collabs = ref([]);
const locations = ref([]);
const ready = ref(null);
const page_t = ref(1);
const searchedAddressMarker = ref(null);
const collaborateur_type = ref('transaction');
const collaborateurZones = ref([]);

const polygons = ref([]);
//const showDialog = ref(false);
//const newPolygonName = ref('');
//const newPolygonColor = ref('#FF0000');
const currentPolygon = ref(null);
//const editingPolygonIndex = ref(null);
const polygonMap = ref(new Map());
const drawingManager = ref(null);
const editingZone = ref(null);

// Nouvelle fonction pour générer une couleur aléatoire
const getRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

/*
// Fonction pour sauvegarder les zones dans le localStorage
const saveZonesToLocalStorage = () => {
    localStorage.setItem(`zones_${collaborateur_type.value}`, JSON.stringify(collabs.value));
};

// Fonction pour charger les zones depuis le localStorage
const loadZonesFromLocalStorage = () => {
    const savedZones = localStorage.getItem(`zones_${collaborateur_type.value}`);
    if (savedZones) {
        collabs.value = JSON.parse(savedZones);
    } else {
        collabs.value = []; // Réinitialiser si aucune zone n'est sauvegardée pour ce métier
    }
};*/


const loadZones = async () => {
      try {

        clearZonesFromMap();
        await store.dispatch('collaborateur/getZones',collaborateur_type.value)
        collaborateurZones.value = store.getters['collaborateur/zones']
        updateCollabsList()
        displayZonesOnMap();
      } catch (error) {
        console.error('Erreur lors du chargement des zones:', error);
      }
    }
    const displayZonesOnMap = () => {
  if (!mapRef.value?.api) return;

  // Clear existing zones
  clearZonesFromMap();

  collaborateurZones.value.forEach(zone => {
    const negociateur = collabs.value.find(c => c.id === zone.collaborateur_id);
    let polygon = new mapRef.value.api.Polygon({
      paths: zone.points,
      strokeColor: negociateur.color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: negociateur.color,
      fillOpacity: 0.35,
      editable: true
    });

    polygonMap.value.set(zone.id, polygon);
    polygon.setMap(mapRef.value.map);

    if (EDITING_MODE.value) {
      mapRef.value.api.event.addListener(polygon, 'set_at', () => onVertexChange(zone));
      mapRef.value.api.event.addListener(polygon, 'insert_at', () => onVertexChange(zone));
      mapRef.value.api.event.addListener(polygon, 'remove_at', () => onVertexChange(zone));

      mapRef.value.api.event.addListener(polygon, 'click', () => {
        if (EDITING_MODE.value) {
          editZone(zone.collaborateur_id, zone.id);
        }
      });
    }
  });

  console.log('Zones displayed on map:', collaborateurZones.value.length);
};

const clearZonesFromMap = () => {
/*
     collabs.value.forEach(z=>{
        //z.zones = []
     })*/
      polygonMap.value.forEach((polygon) => {
        toRaw(polygon).setMap(null);
      });
      //collaborateurZones.value.forEach(zone => {})
      polygonMap.value.clear();
      //collaborateurZones.value = [];
    }

    const updateCollabsList = () => {
      // Grouper les zones par collaborateur
     /*const zonesByCollab = collaborateurZones.value.reduce((acc, zone) => {
        if (!acc[zone.collaborateur_id]) {
          acc[zone.collaborateur_id] = [];
        }
        acc[zone.collaborateur_id].push(zone);
        return acc;
      }, {});*/
      collabs.value.forEach(c =>{
        c.zones = []
        collaborateurZones.value.forEach(z => {
            if(z.collaborateur_id === c.id){

            c.zones.push(
            {
          id: z.id,
          name: `Zone ${z.id}`,
          paths: z.points
        })
            }
        })
       // let tmp = zonesByCollab[c.id]
      //  console.log(tmp)
        //c.zones =
      })
/*
      // Mettre à jour la liste des collaborateurs
      collabs.value = Object.entries(zonesByCollab).map(([collabId, zones]) => ({
        id: parseInt(collabId),
        name: "A",//zones[0].collaborateur_name,
        color: zones[0].color,
        zones: zones.map(z => ({
          id: z.id,
          name: `Zone ${z.id}`,
          paths: z.points
        }))
      }));*/


    }
// Fonction pour ajouter une zone
const addZone = (collabId) => {
    if (drawingManager.value) {
        drawingManager.value.setDrawingMode(mapRef.value.api.drawing.OverlayType.POLYGON);
        editingZone.value = { collabId, isNew: true };
    }
};

// Fonction pour supprimer une zone
const deleteZone = async (collabId, zoneId) => {
    const collab = collabs.value.find(c => c.id === collabId);
    if (collab) {
        const zoneIndex = collab.zones.findIndex(z => z.id === zoneId);
        if (zoneIndex !== -1) {
            collab.zones.splice(zoneIndex, 1);
            // Supprimer le polygone de la carte
            const polygon = polygonMap.value.get(zoneId);
            if (polygon) {
                toRaw(polygon).setMap(null);
                polygonMap.value.delete(zoneId);
            }
            const zonesToSend = collabs.value.find(c => c.id === collabId).zones.map(zone => ({
            color: zone.color || getRandomColor(), // Utilisez la couleur existante ou générez-en une nouvelle
            points: zone.paths.map(point => ({ lat: point.lat, lng: point.lng }))
            }));
            await store.dispatch("collaborateur/updateCollaborateurZones", {
                collaborateurId:collabId,
          type_carte: collaborateur_type.value, // 'gerance' ou 'transaction'
          zones: zonesToSend

            });
           // saveZonesToLocalStorage();
        }
    }
};

// Fonction pour modifier une zone
const editZone = (collabId, zoneId) => {
    const collab = collabs.value.find(c => c.id === collabId);
    if (collab) {
        const zone = collab.zones.find(z => z.id === zoneId);
        if (zone) {
            editingZone.value = { collabId, zoneId, isNew: false };
            const polygon = polygonMap.value.get(zoneId);
            if (polygon) {
                toRaw(polygon).setEditable(true);
            }
        }
        saveZoneEdit()
    }
};

const updateCollaborateurZones = async (collabId) => {
  const collab = collabs.value.find(c => c.id === collabId);
  if (collab) {
    const zonesToSend = collab.zones.map(zone => ({
      color: zone.color || getRandomColor(),
      points: zone.paths.map(point => ({ lat: point.lat, lng: point.lng }))
    }));
    await store.dispatch("collaborateur/updateCollaborateurZones", {
      collaborateurId: collabId,
      type_carte: collaborateur_type.value,
      zones: zonesToSend
    });
    console.log('Zones updated for collaborateur:', collabId);
  }
};

const saveZoneEdit = async () => {
  if (editingZone.value) {
    const { collabId, zoneId, isNew } = editingZone.value;
    const collab = collabs.value.find(c => c.id === collabId);
    if (collab) {
      if (isNew) {
        const newZone = {
          id: Date.now(),
          name: `Zone ${collab.zones.length + 1}`,
          paths: toRaw(currentPolygon.value).getPath().getArray().map(point => ({ lat: point.lat(), lng: point.lng() }))
        };
        toRaw(currentPolygon.value).setOptions({
          fillColor: collab.color,
        });

        collab.zones.push(newZone);
        polygonMap.value.set(newZone.id, currentPolygon.value);

        // Add to collaborateurZones as well
        collaborateurZones.value.push({
          id: newZone.id,
          collaborateur_id: collabId,
          points: newZone.paths
        });
      } else {
        const zone = collab.zones.find(z => z.id === zoneId);
        if (zone) {
          const polygon = polygonMap.value.get(zoneId);
          if (polygon) {
            zone.paths = toRaw(polygon).getPath().getArray().map(point => ({ lat: point.lat(), lng: point.lng() }));
            //toRaw(polygon).setEditable(false);

            // Update collaborateurZones as well
            const collaborateurZone = collaborateurZones.value.find(z => z.id === zoneId);
            if (collaborateurZone) {
              collaborateurZone.points = zone.paths;
            }
          }
        }
      }

      await updateCollaborateurZones(collabId);
    }
  }
  editingZone.value = null;
  if (drawingManager.value) {
    drawingManager.value.setDrawingMode(null);
  }
};

function calculateDistance(lat1, lon1, lat2, lon2, unit) {
  var radlat1 = Math.PI * lat1/180
  var radlat2 = Math.PI * lat2/180
////  var radlon1 = Math.PI * lon1/180
 // var radlon2 = Math.PI * lon2/180
  var theta = lon1-lon2
  var radtheta = Math.PI * theta/180
  var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist)
  dist = dist * 180/Math.PI
  dist = dist * 60 * 1.1515
  if (unit=="K") { dist = dist * 1.609344 }
  if (unit=="N") { dist = dist * 0.8684 }
  return dist
}

// Modifiez la fonction updateCollabVal pour assigner des couleurs aléatoires
const updateCollabVal = (enter_col) => {
    collaborateur_type.value = enter_col
    let liste = localStorage.getItem("collabs");
    let agences = localStorage.getItem("agences");
    let agence = localStorage.getItem("agence");

    liste = JSON.parse(liste);
    agences = JSON.parse(agences);
    agence = JSON.parse(agence);
    var lat1
    var lat2
    var lng1
    var lng2
    collabs.value = liste.filter(c => c.metier === enter_col).sort(function (a, b) {
        return Number("0x"+a.color) - Number("0x"+b.color);
    }).sort(function (a, b) {
      //  console.log(a.agence_id)
      //  console.log(agences)
        lat1 = agences.find(c=>c.id ==  a.agence_id).lat
        lat2 = agences.find(c=>c.id == b.agence_id).lat
        lng1 = agences.find(c=>c.id == a.agence_id).lng
        lng2 = agences.find(c=>c.id == b.agence_id).lng
      //  console.log(lat1,lng1,lat2,lng2,agence.lat,agence.lng)
        //console.log(lat2,lng2,agence.lat,agence.lng)
        return calculateDistance(lat1,lng1,agence.lat,agence.lng,'N')-calculateDistance(lat2,lng2,agence.lat,agence.lng,'N') // + lng1-lng2
    })
    .map(element => ({
        name: `${element.user.given_name} ${element.user.family_name}`,
        agence: agences.find(c => c.id == element.agence_id).nom,
        id: element.id,
        color: element.color ? '#' + element.color : getRandomColor(),
        zones: [] // Initialiser un tableau vide pour les zones
    }))

/*
console.log(liste.filter(c => c.metier === enter_col).sort(function (a, b) {
        return Number("0x"+a.color) - Number("0x"+b.color);
    }))*/

    /*;*/
    loadImmeublesFromSessionStorage();
    //console.log(collaborateurZones.value)
    collaborateurZones.value = [];
    loadZones()
    updateLocations(updatedList.value);
}

// Modifiez la fonction updateLocations pour utiliser la couleur du collaborateur
const updateLocations = (list) => {
    locations.value = list.map(elt => {
        try {
            let collval = collaborateur_type.value;
            if (elt.collaborateurs.find(c => c.metier === collval)) {
                elt.negociateur = elt.collaborateurs.find(c => c.metier === collval).id

                const negociateur = collabs.value.find(c => c.id === elt.negociateur);
                //console.log(negociateur)
                const color = negociateur.color ? negociateur.color : '#FFFFFF';
                //console.log(color)
                return {
                    lat: Number.parseFloat(elt.lat),
                    lng: Number.parseFloat(elt.lng),
                    color: color,
                    negociateur: negociateur.id,
                    adresse1: elt.adresse1,
                    id: elt.id,
                    nom: elt.nom,
                    showInfo: false
                };
            } else {
                return {
                    lat: Number.parseFloat(elt.lat),
                    lng: Number.parseFloat(elt.lng),
                    color: '#FFFFFF',
                    negociateur: 0,
                    adresse1: elt.adresse1,
                    id: elt.id,
                    nom: elt.nom,
                    showInfo: false
                };

            }

        } catch (e) {
            //console.error(e);
            return null;
        }
    }).filter(loc => loc !== null);
};

// Computed property for filtered and sorted immeubles
const filteredImmeubles = computed(() => {
    const searchTerm = search.value.toLowerCase();
    return updatedList.value.filter(immeuble =>
        immeuble.nom.toLowerCase().includes(searchTerm) ||
        immeuble.adresse1.toLowerCase().includes(searchTerm) ||
        immeuble.ville.toLowerCase().includes(searchTerm)
    ).sort((a, b) => a[sort.value] > b[sort.value] ? 1 : -1);
});

// Nouvelle fonction pour sauvegarder les immeubles dans le sessionStorage

const saveImmeublesToSessionStorage = () => {
    sessionStorage.setItem('immeubles', JSON.stringify(updatedList.value));
};

// Nouvelle fonction pour charger les immeubles depuis le sessionStorage
const loadImmeublesFromSessionStorage = () => {
    const savedImmeubles = sessionStorage.getItem('immeubles');
    if (savedImmeubles) {
        updatedList.value = JSON.parse(savedImmeubles);
        updateLocations(updatedList.value);
    }
};
/*
const getPolygonColor = (negociateurId) => {
  const polygon = polygons.value.find(p => p.name === negociateurId);
  return polygon ? polygon.color : '#FFFFFF';
};*/

const getComplementaryColor = (hex) => {
    // Convertir hex en RGB
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    // Calculer le complément
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;

    // Convertir RGB en hex
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};


const handleAddressSelected = (addressData) => {
    if (searchedAddressMarker.value) {
        searchedAddressMarker.value.setMap(null);
    }

    const { lat, lng, address } = addressData;

    if (mapRef.value?.api) {
        searchedAddressMarker.value = new mapRef.value.api.Marker({
            position: { lat, lng },
            map: mapRef.value.map,
            title: address.label,
            icon: {
                path: mapRef.value.api.SymbolPath.BACKWARD_CLOSED_ARROW,
                scale: 8,
                fillColor: getComplementaryColor(getMarkerColor({ lat: lat, lng: lng })),
                fillOpacity: 1,
                strokeWeight: 2,
            }
        });

        mapRef.value.map.setCenter({ lat, lng });
        mapRef.value.map.setZoom(15);
    }
};
// Modified function to change negotiator

const changeNegociateur = async (immeuble, newNegociateurId) => {
    const col = immeuble.negociateur
    const imm_id =  immeuble.id
   console.log(immeuble, newNegociateurId, col,imm_id)
    immeuble.negociateur = newNegociateurId;
    const location = locations.value.find(loc => loc.id === immeuble.id);
    if (location) {
        location.negociateur = col;
        //location.color = "#000000"
        const colo = collabs.value.find(n => n.id === col)
        //console.log(col)
        location.color = colo.color;
    await store.dispatch('immeuble/editImmeubleCollab',{id:imm_id,collaborateur_id:col})
    saveImmeublesToSessionStorage();
    }/*
  const location = locations.value.find(loc => loc.id === immeuble.id);

  immeuble.negociateur = location.negociateur*/

    /*loadImmeublesFromSessionStorage();*/
    //updateLocations(updatedList.value);


    //  location.color = getPolygonColor(location.negociateur);
};


const getRoleList = _.debounce(async (params) => {
    //console.log(params);
    await store.dispatch("immeuble/immeublesList", {
        ...(params.sort ? { sort: params.sort } : {}),
        ...(params.query ? { search: params.query } : {}),
        syndic_only: 1,
        ...(params.onlyme === "Seulement mes immeubles" ? { only: "me" } : {}),
        ...(params.onlyme === "Tous les immeubles du parc" ? { withlost: "1" } : {}),
        ...(params.onlyme !== "Seulement mes immeubles" && params.onlyme !== "Tous les immeubles du parc" && params.onlyme !== "Tous les immeubles actifs du parc" ? { other_collab: params.onlyme } : {}),
        page: params.nr,
        size: 500,
    });
}, 300);

const me = computed(() => store.state.profile.me);


const updateList = () => {
    const immeublesList = store.getters["immeuble/immeublesList"];
    if (immeublesList) {
        if (immeublesList.meta.current_page === 1) {
            updatedList.value = immeublesList.data;
        } else {
            updatedList.value = [...updatedList.value, ...immeublesList.data];
        }
        updateLocations(updatedList.value);
    }
};

//const roleList = computed(() => updatedList.value);

const metaPage = computed(() => store.getters["immeuble/immeublesList"]?.meta);

onMounted(async () => {
    //loadSavedPolygons();
    loadImmeublesFromSessionStorage();
    const user = me.value;
//    console.log(user)
    if (user.info) {
        let tmp_rights = user.info.access.map((r) => r);
        var rights = []
        var vtmp;
        if (tmp_rights.length > 0) {
            tmp_rights.forEach((r) => {
                vtmp = JSON.parse(r.acces_text)
                vtmp.forEach((r) => {
                    if (!rights.includes(r))
                        rights.push(r)
                })
            })
        }
       // console.log(rights)



        if (rights.includes("carte_immeubles")) {
            EDITING_MODE.value = true

        }
        try {

            let obj = { lat: parseFloat(user.info.agence.lat), lng: parseFloat(user.info.agence.lng) }
           // console.log(obj)
            center.value = obj
            zoom.value = user.info.agence.zoom
        } catch (e) {
            console.error(e)
        }
        // city.value = user.info.agence.cp;
    }

   // updateCollabVal('transaction')

    await getRoleList({
        sort: sort.value,
        city: city.value,
        onlyme: onlyme.value,
        query: '',
        nr: 1,
        perpage: 15
    });

    updateList();

    const masonry = document.querySelector('#infinite-list');
    masonry.addEventListener('scroll', () => {
       // console.log(e);
        if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
            if (metaPage.value.current_page === 1) {
                page_t.value = 1;
            }
            if (page_t.value <= metaPage.value.current_page && page_t.value <= metaPage.value.last_page) {
                page_t.value += 1;
                getDataFromPage(page_t.value);
            }
        }
    });
});

const openImmeuble = (immeuble) => {
    //console.log(immeuble)
    // Assuming you're using Vue Router
    router.push({
        name: "Immeuble",
        params: { id: immeuble.id }
    });
};

const getDataFromPage = async (page) => {
    await getRoleList({
        sort: sort.value,
        city: city.value,
        onlyme: onlyme.value,
        query: search.value,
        nr: page,
        perpage: 15
    });
    updateList();
};

const reactiveSearch = () => {
    const searchTerm = search.value.toLowerCase();
    const filteredList = updatedList.value.filter(immeuble =>
        immeuble.nom.toLowerCase().includes(searchTerm) ||
        immeuble.adresse1.toLowerCase().includes(searchTerm) ||
        immeuble.ville.toLowerCase().includes(searchTerm)
    );
    updateLocations(filteredList);
};

watch([sort, city, onlyme, search], reactiveSearch);

watch(ready, () => {
    // updateMap logic here
}, { immediate: false, deep: true });

watch(metaPage, () => {
    // reactivePagination logic here
}, { immediate: false, deep: true });

watch(() => store.getters["immeuble/immeublesList"], updateList, { immediate: true, deep: true });

const mapRef = ref(null)

watch(() => mapRef.value?.api?.drawing.OverlayCompleteEvent, (ove) => {
    console.log("OVERLAY")
    console.log(ove)

})

const onOverlayComplete = (event) => {
   // console.log("ICI")
    if (event.type === mapRef.value.api.drawing.OverlayType.POLYGON) {
        currentPolygon.value = event.overlay;
        if (editingZone.value && editingZone.value.isNew) {
            saveZoneEdit();
        }
    }
};

const onVertexChange = (event) => {
    console.log('vertex',editingZone.value)
    if (event.type === mapRef.value.api.drawing.OverlayType.POLYGON) {
    if (editingZone.value && !editingZone.value.isNew){
        saveZoneEdit();

    }}

}

// Fonction pour déterminer si un point est dans un polygone
const isPointInPolygon = (point, polygon) => {
    const x = point.lat, y = point.lng;
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].lat, yi = polygon[i].lng;
        const xj = polygon[j].lat, yj = polygon[j].lng;
        const intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
};

// Fonction pour déterminer la couleur du marqueur
const getMarkerColor = (location) => {
    for (const polygon of polygons.value) {
        if (isPointInPolygon(location, polygon.paths)) {
            return polygon.color;
        }
    }
    return '#FFFFFF'; // Couleur par défaut pour les points hors zone
};


// Fonction pour déterminer la couleur du marqueur
/*
const getMarkerNego = (location) => {
  for (const polygon of polygons.value) {
    if (isPointInPolygon(location, polygon.paths)) {
      return polygon.name;
    }
  }
  return ''; // Couleur par défaut pour les points hors zone
};*/

/*
const editPolygon = (index) => {
    if (EDITING_MODE.value == true) {

        const polygon = polygons.value[index];
        newPolygonName.value = polygon.name;
        newPolygonColor.value = polygon.color;
        editingPolygonIndex.value = index;

        // Get the corresponding polygon object from the map
        currentPolygon.value = polygonMap.value.get(index);

        showDialog.value = true;
    }
};
// Mettez à jour la fonction deletePolygon

const deletePolygon = (index) => {
  if(EDITING_MODE.value == true){
  // Remove the polygon from the map
  const polygon = polygonMap.value.get(index);
  if (polygon) {
    polygon.setMap(null);
    polygonMap.value.delete(index);
  }

  polygons.value.splice(index, 1);
  localStorage.setItem('savedPolygons', JSON.stringify(polygons.value));

  updateLocations(updatedList.value);
}
};*/

// Mise à jour du watch sur mapRef
watch(() => mapRef.value?.ready, (ready) => {
    if (!ready) return;
    let mapapi = mapRef.value?.api;
    const map = mapRef.value?.map;

    if (EDITING_MODE.value) {
        drawingManager.value = new mapapi.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: mapapi.ControlPosition.TOP_CENTER,
                drawingModes: [
                    mapapi.drawing.OverlayType.POLYGON,
                ],
            },
            polygonOptions: {
                fillColor: "#FF0000",
                fillOpacity: 0.5,
                strokeWeight: 2,
                clickable: true,
               // editable: true,
                zIndex: 1,
            },
        });

        drawingManager.value.setMap(map);

        mapapi.event.addListener(drawingManager.value, 'overlaycomplete', onOverlayComplete);
        mapapi.event.addListener(drawingManager.value, 'set_at', onVertexChange);
       // console.log(mapapi)


    }
    updateCollabVal('transaction')
    /*
    // Charger les zones sauvegardées sur la carte
    collabs.value.forEach(collab => {
        collab.zones.forEach(zone => {
            const polygon = new mapapi.Polygon({
                paths: zone.paths,
                strokeColor: collab.color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: collab.color,
                fillOpacity: 0.35
            });
            polygon.setMap(map);
            polygonMap.value.set(zone.id, polygon);
if (EDITING_MODE.value) {
            mapapi.event.addListener(polygon.value, 'set_at', onVertexChange);
            mapapi.event.addListener(polygon.value, 'insert_at', onVertexChange);
            mapapi.event.addListener(polygon.value, 'remove_at', onVertexChange);
            // Ajouter un écouteur de clic pour permettre l'édition
            mapapi.event.addListener(polygon, 'click', () => {

                    editZone(collab.id, zone.id);

            });}
        });
    });*/

    updateLocations(updatedList.value);
});
</script>

<template>
    <div class="py-1 container-fluid">
        <div class="row">
            <div class="col-9 border-radius-xl">




                <AddressSearch :mapRef="mapRef" @addressSelected="handleAddressSelected" :center="center" />


                <GoogleMap ref="mapRef" api-key="AIzaSyBkiDeWIzazHYmkWT3BxPRIvjXbrFrGVIw"
                    style="width: 100%; height: calc(100vh - 150px)" :center="center" :libraries="['drawing']"
                    :zoom="zoom" language="fr" gestureHandling="greedy"  :panControl="0"
                    :keyboardShortcuts="1">
                    <Marker v-for="(location, i) in locations" :key="i" :options="{
            position: location,
            icon: {
                path: 'M 0,0 C -1,-10 -5,-11 -5,-15 A 5,5 0 1,1 5,-15 C 5,-11 1,-10 0,0 z',//'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
                fillColor: location.color,
                fillOpacity: 1,
                strokeWeight: 1,
                scale: 1
            }
        }">
                        <InfoWindow>
                            <div>
                                <h5>{{ location.nom }}</h5>
                                <p>{{ location.adresse1 }} {{ location.adresse2 }}</p>
                                <p v-if="!(EDITING_MODE && collaborateur_type == 'transaction')">{{ collabs.find(el => el.id == location.negociateur)?.name }}</p>
                                <select v-if="EDITING_MODE && collaborateur_type == 'transaction'" class="select-primary pagination-select "
                                    placeholder="Affichage" size="small" v-model="location.negociateur"
                                    filterable="true" @change="changeNegociateur(location, $event)">
                                    <option key="0">Aucun</option>
                                    <option v-for="coll in collabs" class="select-primary" v-bind:value="coll.id"
                                        :key="coll.id" :label="coll.name"></option>
                                </select><br v-if="EDITING_MODE && collaborateur_type == 'transaction'" />
                                <button class="btn btn-danger btn-xs" @click="openImmeuble(location)"><i
                                        class="fa fa-building"></i> Afficher plus d'informations</button>
                            </div>
                        </InfoWindow>
                    </Marker>
                </GoogleMap>



            </div>
            <div class="col-3">


                <div class=" text-center mb-2  ">

                    <div class="bg-gradient-warning p-1 border-radius-xl">

                        <h6 class="mb-0 text-white px-2">Liste des collaborateurs</h6>

                    </div>
                    <div class="card" style="height:calc(50vh - 120px);overflow-y: scroll;">
                        <div class=" card-body p-3">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <base-button size="xs" outline type="secondary"
                                    :class="collaborateur_type == 'copropriete' ? 'active' : ''"
                                    @click=" updateCollabVal('copropriete');">Copro</base-button>
                                <base-button size="xs" outline type="secondary"
                                    :class="collaborateur_type == 'gerance' ? 'active' : ''"
                                    @click=" updateCollabVal('gerance');">Gérance</base-button>
                                <base-button size="xs" outline type="secondary"
                                    :class="collaborateur_type == 'imm_entreprise' ? 'active' : ''"
                                    @click=" updateCollabVal('imm_entreprise');">I.E.</base-button>
                                <base-button size="xs" outline type="secondary"
                                    :class="collaborateur_type == 'transaction' ? 'active' : ''"
                                    @click="updateCollabVal('transaction');">Transac.</base-button>
                            </div>
                            <div class="polygon-list">
                                <div v-for="collab in collabs" :key="collab.id" class="polygon-item mb-2"
                                    style="text-align: start;">
                                    <div class="d-flex align-items-center">
                                        <span :style="{ color: collab.color }" style="width:20px"><i
                                                class="fa fa-user"></i></span>
                                        <span class="text-xs">{{ collab.name }}</span>
                                        <div style="flex:1"></div>
                                        <span style="font-size: .5rem !important;text-align: end;">&nbsp;{{ collab.agence }}</span>

                                    </div>
                                    <div
                                            v-if="EDITING_MODE && (collaborateur_type === 'gerance' || collaborateur_type === 'transaction')">
                                            <el-button size="small" @click="addZone(collab.id)"><i
                                                    class="fa fa-plus"></i>Ajouter une zone</el-button>
                                    </div>
                                    <div v-if="collab.zones.length > 0 && EDITING_MODE && (collaborateur_type === 'gerance' || collaborateur_type === 'transaction')" class="ml-4" >
                                        <div v-for="zone in collab.zones" :key="zone.id"
                                            class="d-flex align-items-center mt-1">
                                            <el-button size="small" type="primary"
                                                @click="editZone(collab.id, zone.id)">
                                                <i class="fa fa-save"></i>
                                            </el-button>
                                            <el-button size="small" type="danger"
                                                @click="deleteZone(collab.id, zone.id)">
                                                <i class="fa fa-trash"></i>
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div class="bg-gradient-danger p-1 border-radius-xl">

                        <h6 class="mb-0 text-white px-2">Liste des immeubles</h6>
                    </div>

                    <div class="mt-2">
                        <ArgonInput icon="fa fa-search" formgroup_classes="mb-1" iconDir="left" placeholder="Rechercher"
                            v-model="search" />
                        <!--<el-select
                    class="select-primary pagination-select col-sm-3"
                    v-model="onlyme"
                    placeholder="Affichage"
                    size="small"
                    filterable="true"
                  >
                    <el-option
                      class="select-primary"
                      key="Seulement mes immeubles"
                      label="Seulement mes immeubles"
                      value="Seulement mes immeubles"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      key="Tous les immeubles actifs du parc"
                      label="Tous les immeubles actifs du parc"
                      value="Tous les immeubles actifs du parc"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      key="Tous les immeubles du parc"
                      label="Tous les immeubles du parc"
                      value="Tous les immeubles du parc"
                    ></el-option>
                    <el-option
                     v-for="coll in collabs"
                      class="select-primary"
                      :key="coll.id"
                      :label="coll.name"
                      :value="coll.id"
                    ></el-option>
                  </el-select>-->

                  <!--
                        <el-select style="color: red;" class="select-primary pagination-select col-sm-2" v-model="city"
                            placeholder="Code Postal" name="Code Postal" size="small" filterable="true"
                            allowCreate="true" no-data-text="Merci d'entrer un code postal">
                            <el-option class="select-primary" key="Tous les codes postaux"
                                label="Tous les codes postaux" value="Tous les codes postaux"></el-option>


                        </el-select>-->


                    </div>
                    <!--
            <div class="dataTable-search ">
                <input   class="form-control rounded-lg dataTable-input search-input-table" placeholder="Recherche..." type="text">
            </div>-->
                </div>
                <!--<div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Add Role' }"
                    class="mb-0 btn bg-gradient-success btn-sm">
                      <i class="fa fa-plus me-1"/> Add Role
                  </router-link>
                </div>
              </div>-->


                <div class="card">
                    <!-- Card header -->
                    <div class="px-0 pb-0 pt-1 card-body">



                        <div class="table-responsive" id="infinite-list" style="height:calc(50vh - 120px)">




                            <table class="table table-responsive">
                                <!--<thead class="thead-light">
                  <tr>
                    <th title="EXTAPI_IMM_ID">Ref</th>
                    <th title="nom">Nom de l'immeuble</th>
                  </tr>
                </thead>-->
                                <tbody class="text-sm">
                                    <tr v-for="immeuble in filteredImmeubles" v-bind:key="immeuble.id"
                                        @click="openImmeuble(immeuble)">
                                        <td class="text-danger" style="cursor: pointer;">{{ immeuble.EXTAPI_IMM_ID }}
                                        </td>
                                        <td style="flex:2;width: 100%;cursor: pointer;"><a>{{ immeuble.nom }}</a><br />
                                            <a class="text-xs">{{ immeuble.adresse1 }} {{ immeuble.adresse3 }} {{
                                                immeuble.ville
                                                }}</a>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.dataTable-top {
    display: none;
}

.el-radio-button {
    color: red;
    padding: 20em;

    .el-radio-button__inner {
        color: blue;

        &:hover {
            color: red;
        }
    }
}

.card .table td:hover,
.card .table th:hover,
.card .el-table td:hover,
.card .el-table th:hover {
    background-color: rgba(255, 83, 83, 0) !important;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.mapdiv {
    border-radius: 1rem
}
</style>
