import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { profile } from "./profile.module.js";
import { auth } from "./auth.module.js";
import { role } from "./role.module.js";
import { user } from "./user.module.js";
import { category } from "./category.module.js";
import { tag } from "./tag.module.js";
import { item } from "./item.module.js";
import { event } from "./event.module.js";
import { immeuble } from "./immeuble.module.js";
import { intranet } from "./intranet.module.js";
import { perso } from "./perso.module.js";
import { coproprietaire } from "./coproprietaire.module.js";
import { mail } from "./mail.module.js";
import { modele } from "./modele.module.js";

import { controleur } from "./controleur.module.js";
import { view } from "./view.module.js";
import { agence } from "./agence.module.js";
import { syndic } from "./syndic.module.js";
import { supersyndic } from "./supersyndic.module.js";
import { fournisseur } from "./fournisseur.module.js";
import { lot } from "./lot.module.js";
import { collaborateur } from "./collaborateur.module.js";
import { bail } from "./bail.module.js";
import { bien } from "./bien.module.js";
import { call } from "./call.module.js";
import { feedback } from "./feedback.module.js";
import { calendar} from "./calendar.module.js";
export default createStore({
  modules: {
    auth,
    profile,
    role,
    user,
    category,
    tag,
    item,
    event,
    immeuble,
    intranet,
    perso,
    mail,
    modele,
    controleur,
    view,
    agence,
    syndic,
    supersyndic,
    fournisseur,
    lot,
    collaborateur,
    bail,
    bien,
    call,
    feedback,
    coproprietaire,
    calendar
  },
  state: {
    hideConfigButton: false,
    isPinned: false,
    showConfig: false,
    color: "",
    sidebarType: "bg-white",
    darkMode: localStorage.getItem("darkMode")?localStorage.getItem("darkMode"):false,
    isNavFixed: true,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: false,
    showFooter: false,
    showMain: true,
    layout: "default",
    bootstrap,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector("#app");

      if (
        sidenav_show.classList.contains("g-sidenav-show") &&
        sidenav_show.classList.contains("g-sidenav-hidden")
      ) {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      } else if (
        sidenav_show.classList.contains("g-sidenav-show") &&
        sidenav_show.classList.contains("g-sidenav-pinned")
      ) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else if (sidenav_show.classList.contains("g-sidenav-show")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;

      //state.showFooter = !state.showFooter;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("setSidebarType", payload);
    },
  },
  getters: {},
});
