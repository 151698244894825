<template>
  <div class="py-1 container-fluid">
    <div class="">
      <vue-splitter v-model:percent="splitter_list" :initial-percent="splitter_list">
        <template #left-pane>
          <div class="">
            <div class="bg-gradient-success p-1 border-radius-xl d-flex">
              <button
                v-if="conversationShow"
                class="btn btn-xs btn-round btn-danger mb-0"
                @click="
                  conversationShow = false;
                  conversationId = null;
                "
              >
                <i class="fa fa-arrow-left me-1"></i> Retour
              </button>
              <div v-if="conversationShow" style="flex: 1"></div>
              <h6 v-if="!conversationShow" class="mb-0 text-white px-2">Appels passés</h6>
              <h6 v-else class="mb-0 text-white px-2">Conversations</h6>
              <div v-if="!conversationShow" style="flex: 1"></div>
              <button
                v-if="!conversationShow"
                class="btn btn-xs btn-round btn-white mb-0"
                @click="newVisible = true; $router.replace({query: null,});"
              >
                <i class="fa fa-plus me-1"></i> Nouvel appel
              </button>
            </div>

            <div class="card text-center">
              <ContextMenu ref="menu" :model="options">
                <template #item="slotProps">
                  <div class="p-1">
                    <i :class="slotProps.item.icon"></i>
                    &nbsp;
                    <span>{{ slotProps.item.label }}</span>
                  </div>
                </template>
              </ContextMenu>
            </div>
            <div class="container text-center mb-2">
              <div class="mt-2">
                <ArgonInput
                  v-model="search"
                  icon="fa fa-search"
                  icon-dir="left"
                  placeholder="Rechercher"
                />
              </div>
              <!--
            <div class="dataTable-search ">
                <input   class="form-control rounded-lg dataTable-input search-input-table" placeholder="Recherche..." type="text">
            </div>-->
            </div>
            <!--<div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Add Role' }"
                    class="mb-0 btn bg-gradient-success btn-sm">
                      <i class="fa fa-plus me-1"/> Add Role
                  </router-link>
                </div>
              </div>-->


            <div class="card">
              <!-- Card header -->
              <div class="px-0 pb-0 pt-0 card-body">
                <el-select
                  v-model="box"
                  class="select-primary mailbox-select col-sm-12"
                  placeholder="Mailbox"
                  size="small"
                  filterable
                >
                  <el-option
                    v-for="item in boxitems"
                    :key="item[0]"
                    :label="item[1]"
                    :value="item[0]"
                    class="select-primary"
                  >
                    {{ item[1] }}
                  </el-option>
                </el-select>
                <div
                  id="infinite-list"
                  class="table-responsive"
                  style="overflow-x: hidden"
                  :style="'height:calc(100vh - ' + minus_list + 'px);'"
                >
                  <table class="table table-responsive">
                    <tbody class="text-sm">
                      <tr v-for="row in roleList" :key="row.id">
                        <div
                          class="d-flex align-items-start flex-column justify-content-center"
                          :style="callid == row.ticket_event.id ? 'background-color: rgb(223, 242, 225);' : ''"
                          style="padding: 5px; cursor: pointer; margin: 2px;border-radius: 10px;"
                          @click="editItem(row)"
                          @contextmenu="onRightClick($event, row.id)"
                        >
                          <h6
                            class="mb-0 text-sm"
                            style="
                              text-overflow: ellipsis;
                              overflow: hidden;
                              white-space: nowrap;
                              line-height: 20px;
                            "
                          >
                          {{ row.declarant.nom }} {{ row.declarant.prenom }}
                          </h6>
                          <h6
                            class="mb-0 text-sm"
                            style="
                              text-overflow: ellipsis;
                              overflow: hidden;
                              white-space: nowrap;
                              line-height: 20px;
                            "
                          >
                            {{ row.title }}
                          </h6>
                          <p
                            class="mb-0 text-xs"
                            style="
                              color: grey;
                              text-overflow: ellipsis;
                              overflow: hidden;
                              white-space: nowrap;
                              line-height: 16px;
                            "
                          >
                          {{ row.ticket_event.author.given_name }} {{ row.ticket_event.author.family_name }}
                            <button
                              v-if="row.trusted_user"
                              class="btn btn-info btn-sm"
                              style="margin: 0; padding: 0"
                            >
                              &nbsp;INT&nbsp;
                            </button>

                            <button
                              v-else-if="row.immeuble"
                              class="btn btn-danger btn-sm"
                              style="margin: 0; padding: 0"
                            >
                              &nbsp;<i class="fa fa-building"></i>&nbsp;{{
                                row.immeuble.EXTAPI_IMM_ID
                              }}&nbsp;
                            </button>

                            <b style="color: rgb(2, 130, 2); font-weight: 600"
                              >&nbsp;{{
                                new Date(row.created_at).toLocaleDateString("fr", {
                                  minute: "numeric",
                                  hour: "numeric",
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                })
                              }}</b
                            >
                            {{ row.bodyPreview }}
                          </p>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!--- Boutton temporaire (TODO) pour la connection a webex -->
            <div
              v-if="!is_webex_connected"
              class="p1 border-radius-xl d-flex justify-content-center align-items-center"
              style="min-height: 50px"
            >
              <button class="btn btn-xs btn-round btn-white" @click="connection_webex">
                <i class="fa fa-link me-1"></i> Connection Webex
              </button>
            </div>

            <!-- TODO temporaire pour faire des tests -->
            <div v-if="is_webex_connected"
              class="p1 border-radius-xl d-flex justify-content-center align-items-center"
              style="min-height: 50px"
            >
              <button class="btn btn-xs btn-round btn-white" @click="test">
                <i class="fa fa-link me-1"></i> Test
              </button>
            </div>
            {{ callHistory }}
            <div v-if="incomingCalls.length > 0">
              <h3>Appels entrants :</h3>
              <ul>
                <li v-for="call in incomingCalls" :key="call.id">{{ call.from }} -</li>
              </ul>
            </div>
          </div>
        </template>
        <template #right-pane>
          <div v-if="phone_view">
            <button class="btn btn-white" @click="splitter_list = 100">
              <i class="fa fa-arrow-left"></i>Retour
            </button>
          </div>

          <div v-if="newVisible">
            <AddCallPage v-model="newCallContent"
            @refresh="refresh"/>
          </div>
          <div v-else>
            <CallView
              :id="callid"
              v-model:split_list="splitter_list"
              v-model:conversationId="conversationId"
              v-model:conversationShow="conversationShow"
            />
          </div>
        </template>
      </vue-splitter>
    </div>
  </div>
</template>

<script>
//import { DataTable } from "simple-datatables";
import store from "../../../store";
//import BasePagination from "../../../components/BasePagination.vue";
import eventTable from "../../../mixins/eventTable.js";
import _ from "lodash";
import ArgonInput from "../../../components/ArgonInput.vue";
import CallView from "./CallView.vue";
//import BaseButton from "../../../components/BaseButton.vue";

import ContextMenu from "primevue/contextmenu";

//import PvDialog from 'primevue/dialog';
import AddCallPage from "./AddCallPage.vue";
import Swal from "sweetalert2";

//import Splitter from 'primevue/splitter';
//import SplitterPanel from 'primevue/splitterpanel';
import VueSplitter from "@rmp135/vue-splitter";




var sortDirection = "EXTAPI_IMM_ID";
var getCurrentPage = 1;
var searchQuery = "";

const getRoleList = _.debounce(async function (params) {
  await store.dispatch("call/callsList", {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.query ? { search: params.query } : {}),
    ...(params.box ? { box: params.box } : {}),
    ...(params.conversationId ? { conversationId: params.conversationId } : {}),

    page: params.nr,
    size: params.perpage,
    /*page: {
      number: params.to,
    },*/
  });
}, 300);

export default {
  name: "ListRolePage",
  components: {
    //BasePagination,
    ArgonInput,
    CallView,
    ContextMenu,
    AddCallPage,
    VueSplitter,

    //SplitterPanel,

    //BaseButton
  },
  mixins: [eventTable],
  data() {
    return {
        callingClient:null,
        callHistory:null,
        line:null,
      newVisible: false,
      newActionMode: 0,
      splitter_list: 20,
      splitter_main: 100,
      splitter_actions: 0,
      conversationShow: false,
      conversationId: null,

      newCallContent: {
        to: [],
        cc: [],
        cci: [],
        subject: "",
        body: "",
        files: [],
        options: {
          tocc: {
            active: false,
            toggle: true,
          },
        },
      },

      options: [
        {
          label: "Afficher",
          icon: "fa fa-fw fa-eye",
        },
        {
          label: "Translate",
          icon: "fa fa-fw fa-language",
        },
        {
          label: "Speech",
          icon: "fa fa-volume-up",
          items: [
            {
              label: "Start",
              icon: "pi pi-caret-right",
            },
            {
              label: "Stop",
              icon: "pi pi-pause",
            },
          ],
        },
        {
          separator: true,
        },
        {
          label: "Print",
          icon: "pi pi-print",
        },
      ],
      callid: null,
      minus_list: 250,
      updatedList: [],
      dataRoles: [],
      pagination: {},
      tableRoles: null,
      keys: null,
      page_t: 1,
      search: "",
      boxitems: [
        ["inbox", "Boite de réception"],
        ["envoi", "Messages envoyés"],
      ],
      box: "inbox",

      // Variable pour forcer la mise a jour de var computed
      updateTrigger: 0,

      // Variables pour Webex
      webex: null,
      currentCall: null,
      incomingCalls: [],
    };
  },
  computed: {
    roleList() {
      return this.getList();
    },
    metaPage() {
      return this.$store.getters["call/callsList"]?.meta;
    },
    // Variable pour la connection a webex
    is_webex_connected() {
      // On regarde si le cookie de conenction existe bien
      const cookie = this.getCookie("access_token");

      // Cette ligne est la pour forcer la mise a jour de la var quand on change updateTrigger
      this.updateTrigger;

      if (cookie) {
        return true;
      } else {
        return false;
      }
    },
    // Creation de l'url
    url_authorize() {
      // On récupère les paramètre pour la requète
      const baseUrl = process.env.VUE_APP_WEBEX_API_URL_AUTHORIZE;

      const params = {
        client_id: process.env.VUE_APP_WEBEX_PYC_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_WEBEX_REDIRECT_URL,
        scope: process.env.VUE_APP_WEBEX_SCOPE,
        response_type: "code",
      };

      // On construit l'url
      const url = new URL(baseUrl + "?" + new URLSearchParams(params));

      // Retourne le résultat
      console.log("params");
      console.log(params);

      console.log("baseurl");
      console.log(baseUrl);

      console.log(url);
      return url.toString();
    },
  },
  watch: {
    $route() {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("id") && urlParams.get("id") !== null) {
        // if something in id
            this.callid = urlParams.get("id");
      } else {
        this.newActionMode = 2;
      }
    },
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch",
    },
    box: {
      handler: "reactiveBox",
    },
    conversationShow: {
      handler: function () {
        this.getDataFromPage(1);
      },
    },
    splitter_list: {
      handler: function () {
        this.newActionMode = 0;
      },
    },
    newActionMode: {
      handler: function (val) {
        if (val == 2) {
          this.splitter_list = 100;
          this.minus_list = 205;
          this.splitter_main = 0;
          this.splitter_actions = 0;
        } else if (val) {
          this.splitter_list = 0;
          this.minus_list = 500;
          this.splitter_main = this.default_splitter_main;
          this.splitter_actions = 20;
        } else {
          this.splitter_list = this.default_splitter_list;
          this.splitter_main = 100;
          this.splitter_actions = 0;
          this.minus_list = 205;
        }
      },
    },
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("id") && urlParams.get("id") !== null) {
      this.callid = urlParams.get("id");
    } else {
      this.newActionMode = 2;
    }
  },
  async mounted() {


    const masonry = document.querySelector("#infinite-list");

    this.setView();
    addEventListener("resize", () => {
      this.setView();
    });

    masonry.addEventListener("scroll", () => {
      let v_close = Math.abs(masonry.scrollHeight - masonry.clientHeight - masonry.scrollTop);

      if (v_close <= 1) {
        console.log("scroll");
        if (this.metaPage.current_page == 1) {
          this.page_t = 1;
        }
        if (this.page_t <= this.metaPage.current_page && this.page_t <= this.metaPage.last_page) {
          this.page_t += 1;
          console.log("page", this.page_t);
          this.getDataFromPage(this.page_t);
        }
      }
    });

    await getRoleList({
      sort: sortDirection,
      query: "",
      conversationId: this.conversationId,
      nr: getCurrentPage,
      perpage: 15, //this.tableRoles.options.perPage
    });
  },
  beforeUnmount() {
    sortDirection = "EXTAPI_IMM_ID";
    searchQuery = "";
    getCurrentPage = 1;
  },
  methods: {
    refresh() {
        console.log("refresh done")
      this.getDataFromPage(1);
    },
    setView() {
      if (window.innerWidth < 768) {
        this.phone_view = true;
        this.splitter_list = 0;
        this.splitter_main = 100;
        this.splitter_actions = 0;

        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("id")) {
          this.splitter_list = 0;
          this.splitter_main = 100;
        } else {
            this.newVisible = true;
          this.splitter_list = 100;
          this.splitter_main = 0;
        }

        this.default_splitter_list = 0;

        this.default_splitter_main = 0;
      } else {
        let urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has("id")) {
          this.newVisible = true;
        }
        this.phone_view = false;
        this.splitter_list = 20;
        this.splitter_main = 100;
        this.splitter_actions = 0;
        this.default_splitter_list = 20;
        this.default_splitter_main = 50;
      }
    },
    onRightClick(event, id) {
      console.log(id);
      //this.selectedId.value = id;
      this.$refs.menu.show(event);
    },
    handleClick(event, item) {
      console.log(event, item);
      event.preventDefault();
      this.$refs.vueSimpleContextMenu.showMenu(event, item);
    },
    shakeCreate() {
      this.shaker = true;
      setTimeout(() => {
        this.shaker = false;
      }, 1000);
    },

    sendNewMail() {
      //
      Swal.fire({
        title: "Etes-vous sûr?",
        text: "Vous êtes sur le point d'envoyer un e-mail",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",

        confirmButtonText: "Oui, envoyer!",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            this.$store.dispatch("call/callMail", this.newMailContent);
            Swal.fire({
              icon: "success",
              title: "Envoyé!",
              text: "Votre e-mail a été envoyé.",
              timer: 1000,
            });
          } catch (e) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Une erreur s'est produite",
              timer: 1000,
            });
          }

          this.newVisible = false;
        }
      });
    },

    optionClicked(event) {
      window.alert(JSON.stringify(event));
    },

    editItem(row) {
      this.callid = row.ticket_event.id;
      this.newActionMode = 0;
      this.$router.replace({
        query: { id: row.ticket_event.id },
      });
      this.newVisible = false;

      if (window.innerWidth < 768) {
        this.splitter_list = 0;
        this.splitter_main = 100;
        this.splitter_actions = 0;
      }
      /*
                  this.$router.push({

                      path: "/s/mails",
                      params: { id: row.id }
                  });
             */
    },
    getList() {
      if (this.$store.getters["call/callsList"]) {
        if (this.$store.getters["call/callsList"].meta.current_page == 1) {
          console.log(this.$store.getters["call/callsList"].data);
          this.updatedList = this.$store.getters["call/callsList"].data;
        } else {
          this.updatedList = [...this.updatedList, ...this.$store.getters["call/callsList"].data];
        }
        /*this.$store.getters["mail/mailsList"]?.data.forEach(element => {

           this.$store.dispatch("mail/getMail", element.id);
        });*/
      } else {
        this.updatedList = [...this.updatedList];
      }
      return this.updatedList;
    },
    openImmeuble(row) {
      this.$router.push({
        name: "Mail",
        params: { id: row.id },
      });
    },
    async getDataFromPage(page) {
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        box: this.box,
        conversationId: this.conversationId,
        nr: page,
        perpage: 15, //this.tableRoles.options.perPage
      });
    },

    async reactiveSearch() {
      searchQuery = this.search;
      console.log(searchQuery);
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        box: this.box,
        conversationId: this.conversationId,
        nr: 1,
        perpage: 25, //this.tableRoles.options.perPage
      });
    },
    async reactiveBox() {
      await getRoleList({
        sort: sortDirection,
        box: this.box,
        query: this.search,
        conversationId: this.conversationId,
        nr: 1,
        perpage: 15, //this.tableRoles.options.perPage
      });
    },

    async reactivePagination() {
      this.pagination = await this.metaPage;

      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total,
      };
      getCurrentPage = this.pagination.current_page;
      return this.pagination;
    },

    // Methode de la connection a webex
    connection_webex() {
      // On cache le boutton
      //this.is_webex_connected = true;

      // Information de la taille de la popup
      const width = 600;
      const height = 600;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      // On redirige sur la page webex pour la connection
      console.log(this.url_authorize);
      //window.location.href = this.url_authorize; // redirige vers la page url_authorize

      // On affiche les cookies
      console.log("cookies : ", document.cookie);

      // Creation de la popup
      const popup = window.open(
        this.url_authorize,
        "WebexAuth",
        `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,status=yes`,
      ); // Ouvre la parge url_authorize dans une popup

      // On ajoute un écouteur d'évènement
      //      console.log("Creation de popup");
      window.addEventListener("message", this.O2AuthCodeHandler, false);

      // Code pour vérifier que l'authentification dans la popup s'est bien passé TODO
      if (popup) {
        // Optionnel : vérifier périodiquement si la popup est fermée
        const timer = setInterval(() => {
          if (popup.closed) {
            clearInterval(timer);
            console.log("Authentification terminée ou annulée");
            // Vous pouvez ajouter ici une logique pour vérifier l'authentification
          }
        }, 1000);
      } else {
        console.error("La popup a été bloquée par le navigateur.");
        // Informer l'utilisateur que les popups doivent être autorisées
      }
    },

    // Cette méthode récupère le code de retour de la popup
    O2AuthCodeHandler(event) {
      // Vérifier l'origine du message pour la sécurité
      if (event.origin !== process.env.VUE_APP_BASE_URL) return;

      const { message } = event.data; // On récupère le code
      if (message) {
        // Traiter le code ici (par exemple, l'envoyer à votre API)
        //       console.log("Code reçus :", message);

        // On notifie l'utilisateur du résultat de la connection
        if (message === "Success") {
          this.$notify({
            type: "success",
            message: "Connection réussie",
          });
        } else {
          this.$notify({
            type: "error",
            message: "Connection échouée",
          });
        }
      }

      // Fermer la popup si elle est encore ouverte
      if (event.source) {
        event.source.close();
      }

      // On force l'update de variables
      this.updateTrigger++;

      // Supprimer l'écouteur d'événements
      window.removeEventListener("message", this.handleAuthMessage);
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(";").shift();
      }
    },
    async test() {
      //const access_token = this.getCookie("access_token");
      /* TODO
      const data = await this.$store.dispatch("collaborateur/updateWebexToken", {
        access_token: "test",
        refresh_token: "test",
      });
      */
      this.webexTest();
    },
    // Test d'intégration de webex
    async webexTest() {
      // Create the Webex configuration

      const webexConfig = {
        config: {
          logger: {
            level: "debug", // Set the desired log level
          },
          meetings: {
            reconnection: {
              enabled: true,
            },
            enableRtx: true,
          },
          encryption: {
            kmsInitialTimeout: 8000,
            kmsMaxTimeout: 40000,
            batcherMaxCalls: 30,
            caroots: null,
          },
          dss: {},
        },
        credentials: {
          access_token: this.getCookie("access_token"),
        },
      };

      // Create the calling configuration
      const callingConfig = {
        clientConfig: {
          calling: true,
          contact: true,
          callHistory: true,
          callSettings: true,
          voicemail: true,
        },
        callingClientConfig: {
          logger: {
            level: "info",
          },
        },
        logger: {
          level: "info",
        },
      };

      console.log("etape 0");
/*
const webex = Webex.init({
  credentials: {
    access_token: this.getCookie("access_token")
  }
});
console.log(webex)*/

      // Create the Calling object
      const calling = await window.Calling.init({ webexConfig, callingConfig });
      console.log("etape 1");
      //let callingClient;
      calling.on("ready", () => {
        calling.register()
        .then(() => {

          this.callingClient = calling.callingClient;


        if (window.callHistory === undefined) {
            this.callHistory = calling.callHistoryClient.getCallHistoryData();
        }

        let line = Object.values(this.callingClient.getLines())[0];
        console.log(this.callingClient)
        console.log(line)
        try{

        line.register();
    } catch(e){
        console.error(e)
    }
        line.on('registered', (deviceInfo) => {
            console.log("registered success",deviceInfo);
        });
        this.line = line

        line.on('error', (error) => {
            // Try to re-connect and/or show error to end user
            this.$notify('danger','error')
            console.error(error)
        })
        line.on('incoming_call', (call) => {

            call.on('caller_id', (CallerIdEmitter) => {

            //callDetailsElm.innerText = `Name: ${CallerIdEmitter.callerId.name}, Number: ${CallerIdEmitter.callerId.num}, Avatar: ${CallerIdEmitter.callerId.avatarSrc}, UserId: ${CallerIdEmitter.callerId.id}`;
            console.log(
                `callerId : Name: ${CallerIdEmitter.callerId.name}, Number: ${CallerIdEmitter.callerId.name}, Avatar: ${CallerIdEmitter.callerId.avatarSrc}, UserId: ${CallerIdEmitter.callerId.id}`
            );
            });

          console.log("INCOMING CALL INSIDE")
            console.log(call)
            //this.incomingCalls = {id:call.getCallId()}
            //  Call object received. Store this object and listen for events on the call
        });




        });
      });


    },
  },
};
</script>
<style>
.dataTable-top {
  display: none;
}
.vue-splitter .splitter {
  width: 5px;
  background: #f5f5f5;
}
.hover {
  background-color: #f5f5f5;
}

.p-contextmenu-root-list {
  padding-left: 0rem;
  margin-bottom: 0rem;
}

.p-submenu-list {
  padding-left: 0rem;
}

.bg-gradient-dark-green {
  background: linear-gradient(-87deg, #108b39 0, #1aac4f 100%) !important;
}
.p-dialog-mask {
  z-index: 100 !important;
}

.vue-splitter.vertical {
  height: 100%;
}
</style>
