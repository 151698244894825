<template>
    <div class="border-radius-lg bg-light p-2 mb-1">
        <div class="text-sm">{{ options.name }}</div>
        <form
            id="dropzone"
            action="/"
            class="form-control dropzone"
          >
          <div class="dz-default dz-message" style="margin: 0;"><button class="dz-button" type="button">Glissez les fichiers ici</button></div>
            <div class="fallback">
              <input name="file" type="file" multiple />
            </div>
          </form>
        <ul class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush"
        :class="previewClasses"
        ref="previewMultiple">
            <li class="list-group-item px-0 bg-light">
                <div class="row align-items-center">
                <div class="col-auto">
                    <div class="avatar">
                    <img class="avatar-img rounded" data-dz-thumbnail>
                    </div>
                </div>
                <div class="col-auto">
                    <button data-dz-rename class="btn btn-info btn-xs">
                    <i class="fas fa-edit"></i>
                    </button>
                </div>
                <div class="col ml--3">
                    <h6 class="mb-1" data-dz-name >...</h6>
                    <p class="small text-muted mb-0" data-dz-size>...</p>
                </div>
                <div class="col-auto">
                    <button data-dz-remove="true" class="btn btn-danger btn-xs">
                    <i class="fas fa-trash"></i>
                    </button>
                </div>
                </div>
            </li>
        </ul>
            
    </div>
  </template>
  
  <script>
  // If you are using JavaScript/ECMAScript modules:import DropZone from 'dropzone-vue';
    import Dropzone from 'dropzone';
import swal from 'sweetalert2'
  export default {
    name: "UseDropzoneDemo",
    props:{
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },

    },
    data() {
        return {
            modele: this.modelValue
        }
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
    mounted(){
        this.multiple = true
        let preview = this.multiple ? this.$refs.previewMultiple : this.$refs.previewSingle;
        Dropzone.autoDiscover = false;
    var drop = document.getElementById("dropzone");
    let myDropzone = new Dropzone(drop, {
        dictDefaultMessage: "Glissez les fichiers ici",
        dictFallbackMessage: "Your browser does not support drag'n'drop file uploads.",
        dictFallbackText: "Please use the fallback form below to upload your files like in the olden days.",
        dictFileTooBig: "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
        dictInvalidFileType: "You can't upload files of this type.",
        dictResponseError: "Server responded with {{statusCode}} code.",
        dictCancelUpload: "Cancel upload",
        dictCancelUploadConfirmation: "Are you sure you want to cancel this upload?",
        dictRemoveFile: "Supprimer le fichier",
        dictMaxFilesExceeded: "You can not upload any more files.",
        previewsContainer: preview,
        previewTemplate: preview.innerHTML,
      url: "/",
      addRemoveLinks: false,
  autoProcessQueue: false,
    });
        preview.innerHTML = ''
    let modeleF = this.modele
    
    if (this.modele[this.options.prop] == undefined) {
        this.modele[this.options.prop] = []
    }
    let options_prop = this.options.prop

myDropzone.on("addedfile", function(file) {
    swal
    var reader = new FileReader();
    if (myDropzone.files.length < 4) {
        reader.onload = function(event) {
            // event.target.result contains base64 encoded image
            console.log("file being uploaded ",event)
            modeleF[options_prop].push(file)
            /*
            storage_upload("image", event.target.result, file, myDropzone, (r) => {
                console.log("Storage upload response")
                console.log(r)
            })*/
        };
        reader.readAsDataURL(file);
    } else {
        console.log('skipping file as we are excceding the upload limit')
    }
});
myDropzone.on("removedfile", function(file) {
    modeleF[options_prop].splice(modeleF[options_prop].indexOf(file), 1)

})


    }
  };
  </script>
  <style>
  .dropzone {
  width: 100%;
  height: 50px;
  min-height: 0px !important;
  }   
  </style>