import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListcalls(params){
  const response =  await axios.get(`${API_URL}/calls`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListcallsFromBuilding(params){
  let immeuble_id = params.immeuble_id;
  const response =  await axios.get(`${API_URL}/immeubles/${immeuble_id}/baux`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data, meta: response.data.meta};
}

async function getcall(callId){
  const response =  await axios.get(`${API_URL}/calls/${callId}`, {headers: authHeader()});
  return response.data;
}


function phones(params) {
  console.log(params);
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  };

  const response =   axios.get(`${API_URL}/phones`, options).then(response => {
    return {
      list: response.data.data,
      meta: response.data.meta
    };
  });
  return response;
}

async function editcall(call){

  const payload = jsona.serialize({
    stuff: call,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/calls/${call.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createcall(call){


  const response =  await axios.post(`${API_URL}/calls`, call, {headers: authHeader()});
  return response.data;
}

async function deletecall(callId){
  await axios.delete(`${API_URL}/calls/${callId}`, {headers: authHeader()});
}

async function uploadImagecall(call) {
    const payload = new FormData();
    payload.append("attachment", call.image);
  
    const response = await axios.post(`${API_URL}/uploads/calls/${call.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListcalls,getListcallsFromBuilding,
    getcall,
    editcall,
    createcall,
    deletecall,
    uploadImagecall,
    phones
};