import store from "../store";

export default async function admin() {
  let role = await store.getters["profile/me"]?.roles[0];

  if (role != "a") {
    return { name: "Dashboard" };
  }
  
}
