<template>
    <div>
        <ModalNew v-model="modal_new" closeable :header="blocking.find(el=>el==='saveDraft') ? 'Affichage évènement':'Modifier un Évènement'" :saveDraft="actionDraft" :showDraft="blocking.findIndex(el=>el==='saveDraft') === -1">
          <div v-if="modele.html" v-html="modele.html">

          </div>
          <div v-if="loading">Chargement</div>
    <Block v-else :options="options" v-model="modele" :action="action" :blocking="blocking"></Block>
<!--{{ modele.view_id }}-->
        </ModalNew>


    </div>

</template>
<script>
//import BaseButton from "@/components/BaseButton.vue";
import Block from "@/blocks/Block.vue";
import ModalNew from "../../../components/ModalNew.vue";
import showSwal from "../../../mixins/showSwal";
export default {
    name: "NewEvent",
    components: {
        Block,
        ModalNew
    },
    props: {
      editEventModal: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
            default: () => {
                return {
                html:'<p>test</p>'
                };
            },
        },
        blocking: {
            type: Array,
            default: () =>[],
        },
    },
    mixins: [showSwal],
    watch:{
        modal_new: function (val) {
            this.$emit('update:editEventModal', val)
        },
        editEventModal: function (val) {
            console.log('newEventModal',val)
            this.modal_new = val
        },
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
          
            this.modele = val
      setTimeout(() => {
        this.loadView()
      }, 200);
        }
        
    },
    data() {
        return {
          loading:true,
            modal_new:this.editEventModal,
            modals: {
                modalnewevent: {
                    show: false,
                },
            },
            options:{},
            modele:this.modelValue
        };
    },
    methods: {
      actionDraft(){
        this.modele.draft = true
        this.action()
      },
    async action() {
      try{
        switch(this.modele.tab0.name){
          case 'Consigner':
            this.modele.received_type = 'N'
            break;
          case 'Intervenir':
            this.modele.received_type = 'I'
            break;
          case 'Communiquer':
            this.modele.received_type = 'C'
            break;
        }
        this.modele.type = this.modele.tab0.name
           await this.$store.dispatch("event/addEvent", this.modele);
          let v = this.$store.getters['event/oneEvent']
        console.log(v)
        this.modal_new = false
        //this.$router.push({name:'Évènement',params:{id:v.id}})
            this.showSwal({message:"Évènement modifié",type: "success"});
      } catch(e){
            this.showSwal({message:"Un problème est survenu",type: "danger"});
          }



        },
        async loadView(){
          console.log("LOADING VIEW",this.modele.view_id)

          if(this.modele.view_id){
          await this.$store.dispatch("view/getDetailedView", this.modelValue.view_id)

          } else {
          await this.$store.dispatch("view/getDetailedView", 1)

          }
          let v = this.$store.getters['view/oneView']
          this.options = v.view
          //this.options.content = JSON.parse(v.view.content)
          console.log(v)
        this.loading = false

        }

    },
    mounted(){
      /*
    var vuecr = {
      "type": "ButtonTop",
              "showTabName": "tab0",
              "name":"####no",
      "items": [
        {
          "name": "Consigner",
          "type": "ButtonTop",
              "showTabName": "tab1",
          "color": "white",
          "background_color": "#5e72e4",
          "color_off": "black",
          "background_color_off": "rgb(211, 211, 211)",
          "icon": "fa fa-pen",
          "items": [
            {
              "name": "Organiser un RDV",
              "color": "white",
              "showTabName": "tab2",
              "icon": "fa fa-calendar",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "id": "rdv",
              "items": [
                {
                  "type": "row",
                  "items": [
                    {
                      "type": "column",
                      "size": 4,
                      "items": [
                        {
                          "type": "Immeuble",
                          "name": "Immeuble",
                          "prop": "immeuble_id"
                        },
                        {
                          "type": "Perso",
                          "name": "Déclarant",
                          "prop": "perso_id"
                        },
                        {
                          "type": "Lot",
                          "name": "Lot",
                          "prop": "lot_id"
                        },
                        {
                          "type": "SwitchList",
                            "name": "Sur site, quels sont les moyens d'accès à l'immeuble ?",
                            "items":[
                                {
                                    "type":"Switch",
                                    "label":"Une personne va vous recevoir",
                                    "id":"reception",
                                    "default":false,
                                    "prop":"reception",
                                    "active-items":[
                                        {
                                            "label":"Code",
                                             "type": "Perso",
                                             "id":"reception_perso",
                                            "name":"", 
                                            "prop":"digicode_code"
                                        } 
                                    ]
                                },
                                {
                                    "type":"Switch",
                                    "label":"Le code d'accès est le",
                                    "id":"access_code",
                                    "default":true,
                                    "prop":"access_code",
                                    "active-items":[
                                        {
                                            "label":"Code",
                                             "type": "Input",
                                             "id":"access_code_input",
                                            "name":"",
                                            "prop":"digicode_code"
                                        }
                                    ]
                                }
                            ]

                        },
                        {
                          "type": "SwitchList",
                            "name": "Sur site vous agirez",
                            "items":[
                                {
                                    "type":"Radio",
                                    "label":"Dans les parties communes",
                                    "default":false,
                                    "prop":"pc_pp",
                                    "active-items":[
                                        {
                                            "label":"Code",
                                             "type": "Perso",
                                            "name":"",
                                            "prop":"digicode_code"
                                        }
                                    ]
                                },
                                {
                                    "type":"Radio",
                                    "label":"Le code d'accès est le",
                                    "default":true,
                                    "prop":"pc_pp",
                                    "active-items":[
                                        {
                                            "label":"Code",
                                             "type": "Input",
                                            "name":"",
                                            "prop":"digicode_code"
                                        }
                                    ]
                                }
                            ]

                        }
                      ]
                    },
                    {
                      "type": "column",
                      "size": 8,
                      "items": [
                        {
                          "type": "BasicInput",
                          "name": "Titre",
                          "prop": "title",
                          "placeholder": "Merci d'entrer un titre à donner à l'évènement"
                        },
                        {
                          "type": "RichText",
                          "name":"Description",
                          "prop":"description"
                        },
                        { 
                            "type": "row",
                            "items": [
                                {
                                "type": "column",
                                "size": 6,
                                "items": [
                                    {
                                    "type": "DropZone",
                                    "name":"Fichiers",
                                    "prop":"fichiers"

                                    }]
                                },
                                {
                                "type": "column",
                                "size": 6,
                                "items": [
                                    {
                                    "type": "Fournisseur",
                                    "name":"Fournisseur",
                                    "prop":"fournisseur_id"

                                    }]
                                }
                            ]
                        }
                    ]}
                  ]
                }
              ],
              "send_button": {
                "label": "Lancer la demande de devis",
                "color": "white",
              }
            },
            {
              "name": "Ajouter une note",
              "color": "white",
              "default":true,
              "showTabName": "tab2",
              "icon": "fa fa-sticky-note",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "id":"note",
              "items": [
                {
                  "type": "row",
                  "size": 12,
                  "items": [
                    {
                      "type": "column",
                      "size": 6,
                      "items": [{
                        "type": "row",
                        "size": 12,
                        "items": [
                          {
                            "type": "Immeuble",
                            "size": 6,
                          }, {
                            "type": "Perso",
                            "size": 6,

                          }
                        ]
                      }, {
                        "type": "row",
                        "size": 12,
                        "items": [
                          {
                            "type": "Immeuble",
                            "size": 6,
                          }, {
                            "type": "column",
                            "items": [
                              {
                                "type": "column",
                                "size": 6,
                                "items": [
                                  {
                                    "type": "Perso",
                                    "name": "Perso"
                                  },
                                  {
                                    "type": "View2"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },



                      ]
                    }

                  ]
                }
              ],
              "send_button": {
                "label": "Envoyer",
                "color": "white",
              }
            },
            {
              "name": "Compte-rendu de visite",
              "color": "white",
              "showTabName": "tab2",
              "icon": "fa fa-location-dot",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "items": [
                {
                  "type": "row",
                  "items": [
                    {
                      "type": "column",
                      "size": 2,
                      "items": [
                        {
                          "type": "Immeuble",
                          "name": "Immeuble"
                        },
                        {
                          "type": "View2"
                        }
                      ]
                    }
                  ]
                }
              ],
              "send_button": {
                "label": "Envoyer",
                "color": "white",
              }
            },

          ],
          "send_button_label": "Envoyer",
          "send_button_color": "white",
          "send_button_background_color": "#3c8dbc",
          "send_button_size": 2,
          "send_button_icon": "fa fa-envelope-o"
        },
        {
          "name": "Intervenir",
          "color": "white",
              "default": true,
              "showTabName": "tab1",
          "icon": "fa fa-wrench",
          "background_color": "#ff790f",
          "color_off": "black",
          "background_color_off": "rgb(211, 211, 211)",
          "type": "ButtonTop",
          "items": [
            {
              "name": "Sinistre",
              "showTabName": "tab2",
              "color": "white",
              "icon": "fa fa-water",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "items": [
                {
                  "type": "row",
                  "items": [
                    {
                      "type": "column",
                      "size": 2,
                      "items": [
                        {
                          "type": "Immeuble",
                          "name": "Immeuble"
                        },
                        {
                          "type": "View2"
                        }
                      ]
                    }
                  ]
                }
              ],
              "send_button": {
                "label": "Envoyer",
                "color": "white",
              }
            },
            {
              "name": "Devis",
              "showTabName": "tab2",
              "controller":1,
              "default": true,
              "color": "white",
              "icon": "fa-solid fa-sign-hanging",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "id": "devis",
              "items": [
                {
                  "type": "row",
                  "items": [
                    {
                      "type": "column",
                      "size": 4,
                      "items": [
                        {
                          "type": "Immeuble",
                          "name": "Immeuble",
                          "prop": "immeuble_id"
                        },
                        {
                          "type": "Perso",
                          "name": "Déclarant",
                          "prop": "perso_id"
                        },
                        {
                          "type": "Lot",
                          "name": "Lot",
                          "prop": "lot_id"
                        },
                        {
                          "type": "SwitchList",
                            "name": "Sur site, quels sont les moyens d'accès à l'immeuble ?",
                            "items":[
                                {
                                    "type":"Switch",
                                    "label":"Une personne va vous recevoir",
                                    "default":false,
                                    "name":"reception",
                                    "prop":"reception",
                                    "active-items":[
                                        {
                                            "label":"Code",
                                             "type": "Perso",
                                            "name":"digicode_code",
                                            "prop":"digicode_code"
                                        }
                                    ]
                                },
                                {
                                    "type":"Switch",
                                    "label":"Les codes sont à l'agence",
                                    "default":false,
                                    "prop":"agence_code",
                                    "name":"agence_code",
                                    "active-items":[
                                        {
                                            "label":"Code",
                                            "name":"digicode_code",
                                             "type": "Input",
                                            "prop":"digicode_code"
                                        }
                                    ]
                                },
                                {
                                    "type":"Switch",
                                    "label":"Le code d'accès est le",
                                    "default":true,
                                    "name":"access_code",
                                    "prop":"access_code",
                                    
                                    "active-items":[
                                        {
                                            "label":"Code",
                                            "name":"digicode_code",
                                             "type": "Input",
                                            "prop":"digicode_code"
                                        }
                                    ]
                                }
                            ]

                        },
                        {
                          "type": "SwitchList",
                            "name": "Sur site vous agirez",
                            "items":[
                                {
                                    "type":"Radio",
                                    "label":"Dans les parties communes",
                                    "default":false,
                                    "prop":"pc_pp",
                                },
                                {
                                    "type":"Radio",
                                    "label":"Dans les parties primatives",
                                    "default":true,
                                    "prop":"pc_pp"
                                },
                                {
                                    "type":"Radio",
                                    "label":"Dans les parties communes et primatives",
                                    "default":true,
                                    "prop":"pc_pp"
                                },
                                {
                                    "type":"Radio",
                                    "label":"Le code d'accès est le",
                                    "default":true,
                                    "prop":"pc_pp"
                                }
                            ]

                        }
                      ]
                    },
                    {
                      "type": "column",
                      "size": 8,
                      "items": [
                        {
                          "type": "BasicInput",
                          "name": "Titre",
                          "prop": "title",
                          "placeholder": "Merci d'entrer un titre à donner à l'évènement"
                        },
                        {
                          "type": "RichText",
                          "name":"Description",
                          "prop":"description"
                        },
                        { 
                            "type": "row",
                            "items": [
                                {
                                "type": "column",
                                "size": 6,
                                "items": [
                                    { 
                                  "type":"SwitchList",
                                  "items":[
                                    {
                                        "type":"Switch",
                                        "label":"Informer le CS",
                                        "default":true,
                                        "prop":"informer_cs",
                                    },
                                    {
                                        "type":"Switch",
                                        "label":"Informer le demandeur",
                                        "default":true,
                                        "prop":"informer_demandeur",
                                    },
                                    {
                                        "type":"Switch",
                                        "label":"Informer tous les copropriétaires",
                                        "default":false,
                                        "prop":"informer_coproprietaires",
                                    },
                                    {
                                        "type":"Switch",
                                        "label":"Informer tous les résidents",
                                        "default":false,
                                        "prop":"informer_residents",
                                    }

                                  ]
                                }]
                                },
                                {
                                "type": "column",
                                "size": 6,
                                "items": [
                                    {
                                    "type": "Fournisseur",
                                    "name":"Fournisseur",
                                    "prop":"fournisseur_id"

                                    },
                                    {
                                    "type": "DropZone",
                                    "name":"Fichiers",
                                    "prop":"fichiers"

                                    }

                                  
                                  ]
                                },
                                
                                
                            ]
                        }
                    ]}
                  ]
                }
              ],
              "send_button": {
                "label": "Lancer la demande de devis",
                "color": "white",
              }
            },
            {
              "name": "Ordre de service",
              "color": "white",
              "showTabName": "tab2",
              "icon": "fa fa-wrench",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "items": [
                {
                  "type": "row",
                  "items": [
                    {
                      "type": "column",
                      "size": 2,
                      "items": [
                        {
                          "type": "Immeuble",
                          "name": "Immeuble"
                        },
                        {
                          "type": "View2"
                        }
                      ]
                    }
                  ]
                }
              ],
              "send_button": {
                "label": "Envoyer",
                "color": "white",
              }
            },

          ],
        },
        {
          "name": "Communiquer",
          "icon": "fa fa-bullhorn",
          "type": "ButtonTop",
          "color": "white",
              "showTabName": "tab1",
          "background_color": "#10B85F",
          "color_off": "black",
          "background_color_off": "rgb(211, 211, 211)",
          "items": [
            {
              "name": "Écrire un courrier",
              "color": "white",
              "showTabName": "tab2",
              "icon": "fa-solid fa-print",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "items": [
                {
                  "type": "row",
                  "size": 12,
                  "items": [
                    {
                      "type": "column",
                      "size": 6,
                      "items": [
                        {
                          "type": "Immeuble",
                          "name": "Immeuble",
                          "prop": "immeuble_id"
                        },
                          {
                            "type": "RichText",
                            "name": "Corps du courrier",
                            "prop": "html"

                          },



                      ]
                    },
                    {
                      "type": "column",
                      "size": 6,
                      "items": [
                          {
                            "type": "PDFView",
                            "get_prop": "html",
                          },



                      ]
                    }

                  ]
                }
              ],
              "send_button": {
                "label": "Envoyer",
                "color": "white",
              }
            },
            {
              "name": "Écrire un e-mail",
              "color": "white",
              "default":true,
              "icon": "fa fa-at",
              "showTabName": "tab2",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "items": [
                {
                  "type": "row",
                  "items": [
                    {
                      "type": "column",
                      "size": 2,
                      "items": [
                        {
                          "type": "Immeuble",
                          "name": "Immeuble"
                        },
                        {
                          "type": "View2"
                        }
                      ]
                    }
                  ]
                }
              ],
              "send_button": {
                "label": "Envoyer",
                "color": "white",
              }
            },
            {
              "name": "Écrire un SMS",
              "color": "white",
              "showTabName": "tab2",
              "icon": "fa fa-comment-sms",
              "background_color": "black",
              "color_off": "black",
              "background_color_off": "rgb(211, 211, 211)",
              "type": "ButtonTop",
              "items": [
                {
                  "type": "row",
                  "items": [
                    {
                      "type": "column",
                      "size": 2,
                      "items": [
                        {
                          "type": "Immeuble",
                          "name": "Immeuble"
                        },
                        {
                          "type": "View2"
                        }
                      ]
                    }
                  ]
                }
              ],
              "send_button": {
                "label": "Envoyer",
                "color": "white",
              }
            },

          ],
          "send_button_label": "Envoyer",
          "send_button_color": "white",
          "send_button_background_color": "#3c8dbc",
          "send_button_size": 2,
          "send_button_icon": "fa fa-envelope-o"
        }
      ]
    }*/

    //console.log('VUECR',vuecr)
    //this.options = vuecr//JSON.parse(vuecr)
    }
};
</script>