<script setup>
import { ref, watch } from 'vue';
import { ElInput, ElButton } from 'element-plus';
import axios from 'axios';

const props = defineProps(['mapRef','center']);
const emit = defineEmits(['addressSelected']);

const searchQuery = ref('');
const searchResults = ref([]);

const searchAddress = async () => {
  if (searchQuery.value.length < 3) return;
  
  try {
    const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(searchQuery.value)}&limit=5&lat=${props.center.lat}&lon=${props.center.lng}`);
    searchResults.value = response.data.features;
  } catch (error) {
    console.error('Erreur lors de la recherche d\'adresse:', error);
  }
};

const selectAddress = (address) => {
  const { coordinates } = address.geometry;
  const [lng, lat] = coordinates;
  
  emit('addressSelected', { lat, lng, address: address.properties });
  
  // Centrer la carte sur l'adresse sélectionnée
  
  // Effacer les résultats de recherche
  searchResults.value = [];
  searchQuery.value = '';
};

// Debounce la recherche pour éviter trop d'appels API
let timeout = null;
watch(searchQuery, () => {
  clearTimeout(timeout);
  timeout = setTimeout(searchAddress, 300);
});
</script>

<template>
  <div class="address-search">
    <ElInput
      v-model="searchQuery"
      placeholder="Rechercher une adresse sur la carte..."
      class="mb-2"
    >
      <template #append>
        <ElButton @click="searchAddress">
          <i class="fas fa-search"></i>
        </ElButton>
      </template>
    </ElInput>
    
    <ul v-if="searchResults.length > 0" class="search-results">
      <li v-for="result in searchResults" :key="result.properties.id" @click="selectAddress(result)">
        {{ result.properties.label }}
      </li>
    </ul>
  </div>
</template>

<style scoped>
.address-search {
  position: relative;
  z-index: 1000;
  margin-top: 10px;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
}

.search-results li {
  padding: 8px 12px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f0f0f0;
}
</style>