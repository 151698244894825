/* eslint-disable no-unused-vars */
import coproprietaireService from "../services/coproprietaire.service.js";

export const coproprietaire = {
    namespaced: true,
    state: {
        coproprietairesList: null,
        coproprietairesFromBuilding: null,
        oneItem: null,
        image: null,
    },
    getters: {
        coproprietairesList: state => state.coproprietairesList,
        coproprietairesFromBuilding: state => state.coproprietairesFromBuilding,
        onecoproprietaire: state => state.onecoproprietaire,
        image: state => state.image,
    },
    actions: {
        async coproprietairesList({ commit }, params) {
            console.log("action")
            const data = await coproprietaireService.getListcoproprietaires(params);
            console.log(data);
            commit('coproprietaireS_LIST', data);
        },
        async coproprietairesFromBuilding({ commit }, params) {
            console.log("action")
            const data = await coproprietaireService.getListcoproprietairesFromBuilding(params);
            console.log(data);
            commit('coproprietaireS_LIST_FROM_BUILDING', data);
        },
        async getcoproprietaire({ commit }, itemId) {
            const data = await coproprietaireService.getcoproprietaire(itemId);
            commit('GET_coproprietaire', data);
        },
        async addcoproprietaire({ commit }, item) {
            const data = await coproprietaireService.createcoproprietaire(item);
            commit('GET_ITEM', data);
        },
        async editcoproprietaire({ commit }, item) {
            await coproprietaireService.editcoproprietaire(item);
        },
        async deletecoproprietaire({ commit }, itemId) {
            await coproprietaireService.deletecoproprietaire(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await coproprietaireService.uploadImagecoproprietaire(item);
            commit('UPLOAD_IMAGE_coproprietaire', data);
        },
    },
    mutations: {
        coproprietaireS_LIST(state, data) {
            state.coproprietairesList = data;
        },
        GET_coproprietaire(state, data) {
            state.onecoproprietaire = data;
        },
        coproprietaireS_LIST_FROM_BUILDING(state, data) {
            state.coproprietairesFromBuilding = data;
        },
        UPLOAD_IMAGE_coproprietaire(state, data) {
            state.image = data;
        },
  }
}