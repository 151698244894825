<template>
    <div class="border-radius-xl"
      style="background: #E9EAF9;" >
        <div class="bg-gradient-primary border-radius-xl p-2 " >
            <div class="row">
                <div class="col-9" @click="goMails" style="cursor: pointer;">
                    <h4 class="h6 mb-0 text-white">Liste des évènements</h4>
                </div>
                <div class="col-3 text-end">
                    <button class="btn btn-xs btn-light mb-0"  @click="$emit('update:modals', {show:true, text:'Intro'})">
                    <span class="btn-inner--icon"
                        ><i class="fas fa-edit"></i
                    ></span>
                    </button>
                </div>
            </div>

        </div>
    <div
      class="px-0 pb-1 pt-2"

      body-classes=""
      td-classes="pd-5"
      footer-classes=" py-0"
    >
    <div class="table-responsive" id="infinite-list-events" style="height:calc((100vh - 400px) / 2)" @scroll="handleScroll">
      <table class="table table-responsive">
        <tbody class="text-sm">
          <tr v-for="evenement in displayedEvents" :key="evenement.id" @click="openImmeuble(evenement)">
            <td class="text-danger" v-if="evenement.immeuble">
              {{ evenement.immeuble.EXTAPI_IMM_ID }}
            </td>
            <td style="flex:1">{{ evenement.title }}</td>
            <td>{{ evenement.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'

export default {
  name: "ListRolePage",

  setup() {
    const store = useStore()
    const currentPage = ref(1)
    const search = ref('')
    const updatedList = ref([])

    const displayedEvents = computed(() => updatedList.value)

    const metaPage = computed(() => store.getters["event/eventsList"]?.meta)

    const getRoleList = _.debounce(async (params) => {
      await store.dispatch("event/eventsList", {
        sort: params.sort,
        search: params.query,
        page: params.nr,
        size: params.per_page,
      })
    }, 300)

    const loadMoreData = async () => {
      if (currentPage.value < metaPage.value.last_page) {
        currentPage.value++
        await getRoleList({
          sort: "EXTAPI_IMM_ID",
          query: search.value,
          nr: currentPage.value,
          per_page: 15
        })
      }
    }

    const handleScroll = (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        loadMoreData()
      }
    }

    watch(() => store.getters["event/eventsList"], (newVal) => {
      if (newVal) {
        if (currentPage.value === 1) {
          updatedList.value = newVal.data
        } else {
          updatedList.value = [...updatedList.value, ...newVal.data]
        }
      }
    }, { deep: true })

    watch(search, () => {
      currentPage.value = 1
      updatedList.value = []
      getRoleList({
        sort: "EXTAPI_IMM_ID",
        query: search.value,
        nr: 1,
        per_page: 15
      })
    })

    onMounted(async () => {
      await getRoleList({
        sort: "EXTAPI_IMM_ID",
        query: '',
        nr: 1,
        per_page: 15
      })
    })

    return {
      displayedEvents,
      search,
      handleScroll,
      openImmeuble: (evenement) => {
        console.log(evenement)
        // Implémentez la logique de navigation ici
      }
    }
  }
}
</script>
<style>
.dataTable-top {
  display: none;
}
</style>
