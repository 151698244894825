import store from "../store";

export default async function fournisseur({ router,to,next }) {
  console.log(router,to);

    if (!store.getters["auth/isAuthenticated"]) {
   /*   if (role != "f" && role != "e") {*/
        if (to.query.secure_company) {
        return { name: "LoginCompany", query: { redirect: to.path, secure_company:to.query.secure_company, company:to.query.company } };
        }else{
          return { name: "Login", params: { redirect: to.path } };
        }
      
        /*
    }*/
    
    }
  await store.dispatch("profile/me");
  let role = await store.getters["profile/me"]?.roles[0];
  if (role != "f" && role != "e") {
    return router.push({ name: "Login", params: { redirect: to.path } });
  } 
  next();
}
