import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListfeedbacks(params){
  const response =  await axios.get(`${API_URL}/feedbacks`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListfeedbacksFromBuilding(params){
  let immeuble_id = params.immeuble_id;
  const response =  await axios.get(`${API_URL}/immeubles/${immeuble_id}/baux`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data, meta: response.data.meta};
}

async function getfeedback(feedbackId){
  const response =  await axios.get(`${API_URL}/feedbacks/${feedbackId}`, {headers: authHeader()});
  return response.data;
}


function phones(params) {
  console.log(params);
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  };

  const response =   axios.get(`${API_URL}/phones`, options).then(response => {
    return {
      list: response.data.data,
      meta: response.data.meta
    };
  });
  return response;
}

async function editfeedback(feedback){

  const payload = jsona.serialize({
    stuff: feedback,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/feedbacks/${feedback.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createfeedback(feedback){


  const response =  await axios.post(`${API_URL}/feedbacks`, feedback, {headers: authHeader()});
  return response.data;
}

async function createfeedbackanswer(feedback){


  const response =  await axios.post(`${API_URL}/feedbacks/${feedback.id}`, feedback, {headers: authHeader()});
  return response.data;
}

async function deletefeedback(feedbackId){
  await axios.delete(`${API_URL}/feedbacks/${feedbackId}`, {headers: authHeader()});
}

async function uploadImagefeedback(feedback) {
    const payload = new FormData();
    payload.append("attachment", feedback.image);
  
    const response = await axios.post(`${API_URL}/uploads/feedbacks/${feedback.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListfeedbacks,getListfeedbacksFromBuilding,
    getfeedback,
    editfeedback,
    createfeedback,createfeedbackanswer,
    deletefeedback,
    uploadImagefeedback,
    phones
};