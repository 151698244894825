<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">

      <div class="container text-center mb-2  ">
        <div class="bg-gradient-success p-3 border-radius-xl">
          <div class="row">
            <div class="col-lg-3">

            </div>
            <div class="col-lg-6">
                <h5 class="mb-0 text-white">Liste des vues</h5>

            </div>
            <div class="col-lg-3 text-end">
              <button class="btn btn-xs btn-light mb-0" @click="addview">
                <i class="fa fa-plus me-1"></i> Ajouter une vue</button>

            </div>
          </div>
        </div>
        <div class="mt-2">
        <ArgonInput icon="fa fa-search" formgroup_classes="mb-1" iconDir="left" placeholder="Rechercher" v-model="search"/>
                  <el-select style="color: red;"
                    class="select-primary pagination-select col-sm-4"
                    v-model="sort"
                    placeholder="Tri"
                      name="Tri"
                    size="small"
                  >
                    <el-option
                      class="select-primary"
                      key="EXTAPI_IMM_ID"
                      label="Trier par référence croissante"
                      value="EXTAPI_IMM_ID"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      key="nom"
                      label="Trier par nom croissant"
                      value="nom"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      key="events.last"
                      label="Trier par dernier évènement"
                      value="events.last"
                    ></el-option>
                  </el-select>
                  <el-select
                    class="select-primary pagination-select col-sm-3"
                    v-model="onlyme"
                    placeholder="Affichage"
                    size="small"
                  >
                    <el-option
                      class="select-primary"
                      key="Seulement mes immeubles"
                      label="Seulement mes immeubles"
                      value="Seulement mes immeubles"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      key="Tous les immeubles actifs du parc"
                      label="Tous les immeubles actifs du parc"
                      value="Tous les immeubles actifs du parc"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      key="Tous les immeubles du parc"
                      label="Tous les immeubles du parc"
                      value="Tous les immeubles du parc"
                    ></el-option>
                  </el-select>

        </div>
        <!--
            <div class="dataTable-search ">
                <input   class="form-control rounded-lg dataTable-input search-input-table" placeholder="Recherche..." type="text">
            </div>-->
      </div>
              <!--<div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Add Role' }" 
                    class="mb-0 btn bg-gradient-success btn-sm">
                      <i class="fa fa-plus me-1"/> Add Role
                  </router-link>
                </div>
              </div>-->


        <div class="card">
          <!-- Card header -->
          <div class="px-0 pb-0 pt-1 card-body">
            <div class="table-responsive" id="infinite-list" style="height:calc(100vh - 200px)">
              <table   class="table table-responsive">
                <!--<thead class="thead-light">
                  <tr>
                    <th title="EXTAPI_IMM_ID">Ref</th>
                    <th title="nom">Nom de l'immeuble</th>
                  </tr>
                </thead>-->
                <tbody class="text-sm" > 
                  <tr v-for="immeuble in roleList" v-bind:key="immeuble.id" >
                    <td class="text-danger" @click="openImmeuble(immeuble)">{{ immeuble.id }}</td>
                    <td style="flex:1;width: 100%;" @click="openImmeuble(immeuble)">{{ immeuble.title }}</td>
                    <td><button @click="duppl(immeuble.id)" class="btn btn-xs btn-warning mb-0"><i class="fa fa-copy"></i></button></td>

                  </tr>
                </tbody>
              </table>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { DataTable } from "simple-datatables";
import store from "../../../store";
//import BasePagination from "../../../components/BasePagination.vue";
import eventTable from "../../../mixins/eventTable.js";
import _ from "lodash";
import ArgonInput from "../../../components/ArgonInput.vue";
import {ElSelect,ElOption} from 'element-plus'

var getCurrentPage = 1;
var searchQuery = '';

const getRoleList = _.debounce(async function (params) { 
  await store.dispatch("view/viewsList", {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.query ? { search: params.query } : {}),
    ...(params.onlyme === "Seulement mes immeubles" ? {  only:"me", } : {}),
    ...(params.onlyme === "Tous les immeubles du parc" ? {  withlost:"1", } : {}),
  
    page: params.nr,
    size: 25,
    /*page: {
      number: params.to,
    },*/
  });
}, 300);

export default {
  name: "ListRolePage",
  components: {
    //BasePagination,
    ArgonInput,
    ElSelect,
    ElOption
},
  mixins: [eventTable],
  data() {
    return {
      sort: "id",
      onlyme: "Seulement mes immeubles",
      updatedList:[],
      dataRoles: [],
      pagination: {},
      tableRoles: null,
      keys: null,
      page_t: 1,
      search: ''
    }
  },
  computed: {
    roleList() {
      return this.getList();
    },
    metaPage() {
      return this.$store.getters["view/viewsList"]?.meta;
    }
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    roleList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    onlyme: {
      handler: "reactiveSearch"
    },
    sort: {
      handler: "reactiveSearch"
    },
    search: {
      handler: "reactiveSearch"
    }
  },
  async mounted() {

    const masonry = document.querySelector('#infinite-list');

    masonry.addEventListener('scroll', e => {
    console.log(e)
      if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
        if (this.metaPage.current_page == 1){
          this.page_t = 1
        }
        if (this.page_t <= this.metaPage.current_page && this.page_t <= this.metaPage.last_page) {
        this.page_t+=1;
        this.getDataFromPage(this.page_t);
        }
      }
    })

      await getRoleList({
        sort: this.sort,
        onlyme: this.onlyme,
        query: '',
        nr: getCurrentPage,

        perpage: 15//this.tableRoles.options.perPage
      });
  },
  beforeUnmount() {
    searchQuery = '';
    getCurrentPage = 1;
  },
  methods: {

    async duppl(elt){
      this.$store.dispatch("view/dupplicateView", {
        id: elt
      });
      await getRoleList({
        sort: this.sort,
        onlyme: this.onlyme,
        query: '',
        nr: getCurrentPage,

        perpage: 15//this.tableRoles.options.perPage
      });
      //this.getList()
    },
    addview(){
      this.$router.push({
        name: "Nouvelle vue",
      });
    },
    getList(){
      if (this.$store.getters["view/viewsList"]) {
        if (this.$store.getters["view/viewsList"].meta.current_page == 1){
          console.log(this.$store.getters["view/viewsList"].data)
          this.updatedList = this.$store.getters["view/viewsList"].data

        }
        else {
          this.updatedList = [...this.updatedList,...this.$store.getters["view/viewsList"].data]

        }
      }
      
      else{
        this.updatedList = [...this.updatedList]
      }
      return this.updatedList
    },
    openImmeuble(row){
            this.$router.push({
                name: "Vue",
                params: { id: row.id }
            });
          },
    async getDataFromPage(page) {
      await getRoleList({
        sort: this.sort,
        onlyme: this.onlyme,
        query: this.search,
        nr: page,
        perpage: 15//this.tableRoles.options.perPage
      });
    },

    async reactiveSearch() {
      searchQuery = this.search;
      console.log(searchQuery)
      await getRoleList({
        sort: this.sort,
        onlyme: this.onlyme,
        query: this.search,
        nr: 1,
        perpage: 15//this.tableRoles.options.perPage
      });
    },

    async reactivePagination() {
      
      this.pagination = await this.metaPage;

      
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      
      }
      getCurrentPage = this.pagination.current_page;
      return this.pagination;
    },

    async reactiveTable() {/*
      this.dataRoles = [];
      if (this.roleList.length > 0) {
        this.roleList.forEach(row => {
          this.dataRoles.push(
            [

            `${row.EXTAPI_IMM_ID}`,
              `<h6 class="my-auto">${row.nom}</h6>`,
              row.created_at,
              this.actionEditButton(row.id, "Immeuble") + this.actionDeleteButton(row.id, "Delete Role")
            ]
          )
        });
        this.tableRoles.data = [];
        this.tableRoles.refresh();
        this.tableRoles.insert({ data: this.dataRoles });
        this.removeEvent();
        this.eventToCall({
          table: this.tableRoles,
          redirectPath: "Edit Role",
          deletePath: "role/deleteRole",
          getPath: "role/rolesList",
          textDelete: "Role deleted successfully!",
          textDefaultData: 'roles'
        });
      }
      else {
        this.tableRoles.setMessage('Aucun résultat trouvé');
      }*/
    }
  },
};
</script>
<style>
.dataTable-top {
  display: none;
}
.el-radio-button {
  color: red;
  padding: 20em;

  .el-radio-button__inner {
    color: blue;

    &:hover {
      color: red;
    }
  }
}
</style>