<template>
    <div>

    <el-select v-model="local_attributs.attr_type" :disabled="modalAttributes.force" placeholder="Type de valeur">
        <el-option selected value="codes" label="Codes d'accès">Codes d'accès</el-option>
        <el-option value="texte"> Texte </el-option>
        <el-option value="fichiers" label="Dossier de fichiers"> Fichiers</el-option>
    </el-select>
<div v-if="local_attributs.attr_type">
    <div class="container">
        <div v-if="local_attributs.attr_type == 'codes'">
            <div v-for="(code, key) in local_attributs.codes" :key="key" class="row">
                <div class="col-lg-5">
                        <el-input v-model="code.localisation" placeholder="Localisation"/>
                </div>
                <div class="col-lg-5">
                        <el-input v-model="code.code" placeholder="Code d'accès" />
                </div>
                <div class="col-lg-2">
                    <button class="btn btn-sm btn-danger" @click="deleteAttribute(key)">Supprimer</button>
                </div>

            </div>
            <div><button class="btn btn-sm btn-success"
                    @click="local_attributs.codes.push({ localisation: '', code: '' })">Ajouter un code d'accès</button></div>


        </div>

        <el-input placeholder="Nom de l'attribut" v-if="local_attributs.attr_type == 'texte'" v-model="local_attributs.name" />
        

        <el-input v-if="local_attributs.attr_type == 'texte'" v-model="local_attributs.value" placeholder="Valeur de l'attribut"/>
        
        <div v-if="local_attributs.attr_type == 'reglement_copro'">

            <dropzone-file-upload v-model="local_attributs.files" name="Fichiers"
                multiple="control.isMultiple"></dropzone-file-upload>

        </div>
        <div v-if="local_attributs.attr_type == 'fichiers'">
            <el-input v-model="local_attributs.name" placeholder="Nom du dossier"/>
            <DropZone v-model="local_attributs" :options="{prop:'files'}" name="Fichiers" multiple="control.isMultiple"></DropZone>


        </div>



    </div>
        <div style="display:flex; justify-content: end;">
            <button class="btn btn-white" @click="saveAttribut"> Enregistrer cet attribut</button>
        </div>
    

</div>

    </div>
</template>
<script>
import { ElInput, ElSelect } from 'element-plus';
import DropZone from '@/blocks/Controls/DropZone.vue';

export default {
    name:"AjoutAttributImmeuble",
    components: {
    ElSelect,
    ElInput,
    DropZone
},
    data() {
        return {
            attributes: this.modelValue,
            local_attributs:{

                show: false,
                attr_type:this.modalAttributes.attr_type,
                parentId: this.modalAttributes.parentId,
                parent: this.modalAttributes.parent,
                id: this.immeuble_id,
                f: [],
                codes: []
            }
        }
    },
    methods: {
        deleteAttribute(key) {
            this.local_attributs.codes.splice(key, 1);
        },
        async saveAttribut() {
            switch(this.local_attributs.attr_type){
                case 'codes':
                    this.local_attributs.name ="Codes d'accès";
                    this.local_attributs.value =  this.local_attributs.codes;

                    break;
                case 'texte':
                    break;
                case 'fichiers':
                    //this.local_attributs.files = this.local_attributs.files;
                    break;
            }
            this.local_attributs.type = this.local_attributs.attr_type;
          

            console.log(this.local_attributs);
            await this.$store.dispatch("immeuble/addAttributeImmeuble", this.local_attributs);
            let att = this.$store.getters["immeuble/attr"];
            this.closeModal();
            console.log(att);
            
            if(att.parent_id != null){
                this.attributes.find(a => a.id == att.parent_id).children.push(att);
            } else {
                this.attributes.push(att);
            }
            

        }
    },
    watch: {
        attributes: {
            handler: function (val) {
                this.$emit('update:modelValue', val);
            },
            deep: true
        }
    },
    props: {
        modelValue: {
            type: Object,
            default: () => {
                return {
                    attr_type: 'codes',
                    codes: [],
                    name: '',
                    value: '',
                    files: []
                }
            }
        },
        closeModal: {
            type: Function,
            default: () => {
            }
        },
        immeuble_id: {
            type: String,
            default: () => {
                return null;
            }
        },
        modalAttributes: {
            type: Object,
            default: () => {
                return {
                    show: false,
                    force: false
                }
            }
        }
    }
}
</script>