import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

const headers = {
  Accept: "application/vnd.api+json",
  "Content-Type": "application/vnd.api+json",
};

async function login(user) {
  await axios
    .post(
      API_URL + "/login",
      {
        email: user.email,
        password: user.password,
      },
      { headers },
    )
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("access_token", response.data.token);
        localStorage.setItem("me", response.data.token);
      }
      return response.data;
    });
}

async function logout() {
  console.log("logout", authHeader());
  // TO DO: uncomment this line when the API is ready

  await axios.post(API_URL + "/logout", {}, { headers: authHeader() });
  sessionStorage.removeItem("fullme");
  localStorage.removeItem("access_token");
}

async function register(user) {
  const response = await axios.post(
    API_URL + "/signup",
    {
      family_name: user.family_name,
      given_name: user.given_name,
      email: user.email,
      password: user.password,
      password_confirmation: user.confirm_password,
      company: user.company,
    },
    { headers },
  );

  if (response.data.token) {
    localStorage.setItem("access_token", response.data.token);
  }
  return response.data;
}

async function forgotPassword(data) {
  await axios.post(API_URL + "/password-forgot", data, { headers });
}

async function resetPassword(data) {
  await axios.post(API_URL + "/password-reset", data, { headers });
}

export default {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
};
