import store from "../store";

export default async function auth() {

  if (!store.getters["auth/isAuthenticated"]) {
    return { name: "Login" };
  }

   if (!store.getters["profile/me"]  && localStorage.getItem('access_token')) {
    console.log('mid')
    try{
        await store.dispatch("profile/me");
    }
    catch(e){
        console.log(e)
        return { name: "Login" }
    }
    /*await store.dispatch("profile/me");
    console.log(store.getters["profile/me"])*/
  //next();
  //return next({ name: "Error" })
  }
  //return { name: "Error Error500" }
}
