import IntranetService from "../services/intranet.service.js";

export const intranet = {
    namespaced: true,
    state: {
        oneRoot: null,
        oneFolder: null,
        oneFile: null,
        oneRight: null,
        FileURL: null,
        folders: null,
        files: null,
        users: null,
        userRights: null,
        immInfos: null,
        path: null,
        contenu: null,
        /*fichiersList: null,
        oneFichier: null,*/
    },
    getters: {
        oneRoot: state => state.oneRoot,
        folders: state => state.folders,
        files: state => state.files,
        oneFolder: state => state.oneFolder,
        oneFile: state => state.oneFile,
        oneRight: state => state.oneRight,
        fileURL: state => state.fileURL,
        users: state => state.users,
        userRights: state => state.userRights,
        immInfos: state => state.immInfos,
        path: state => state.path,
        contenu: state => state.contenu
        /*
        fichiersList: state => state.fichiersList,
        oneFichier: state => state.oneFichier,
        addFichier: state => state.oneFichier,*/
    },
    actions: {
        async getRoot({ commit }, itemId) {
            const data = await IntranetService.getRoot(itemId);
            commit('GET_ROOT', data);
        },
        async getFolder({ commit }, itemId) {
            const data = await IntranetService.getFolder(itemId);
            commit('GET_FOLDERS', data);
        },
        async getFile({ commit }, itemId) {
            const data = await IntranetService.getFile(itemId);
            commit('GET_FILE_URL', data);
        },
        async getFileRight({ commit }, itemId) {
            const data = await IntranetService.getFileRight(itemId);
            commit('GET_FILE_RIGHT', data);
        },
        async addFolderRoot({ commit }, {itemId, folderName}) {
            const data = await IntranetService.addFolderRoot(itemId, folderName);
            commit('GET_FOLDER', data);
        },
        async addFolder({ commit }, {itemId, folderName}) {
            const data = await IntranetService.addFolder(itemId, folderName);
            commit('GET_FOLDER', data);
        },
        async addFiles({ commit }, {itemId, files}) {
            const data = await IntranetService.addFiles(itemId, files);
            commit('GET_FILES', data);
        },
        async updateFolder({ commit }, {itemId, id, folderName}) {
            const data = await IntranetService.updateFolder(itemId, id, folderName);
            commit('GET_FOLDER', data);
        },
        async updateFile({ commit }, {itemId, id, fileName}) {
            const data = await IntranetService.updateFile(itemId, id, fileName);
            commit('GET_FILE', data);
        },
        async delFolder(_,itemId) {
            console.log('Suppression du dossier ',itemId);
            await IntranetService.delFolder(itemId);
        },
        async delFile(_,itemId) {
            console.log('Suppression du fichier ',itemId);
            await IntranetService.delFile(itemId);
        },
        async moveFile({ commit },{id, folderId}) {
            const data = await IntranetService.moveFile(id, folderId);
            commit('GET_FILE',data);
        },
        async getUsers({ commit }) {
            const data = await IntranetService.getUsers();
            commit('GET_USERS',data);
        },
        async getUserRights({ commit }, {userId, folderId}) {
            const data = await IntranetService.getUserRights(userId, folderId);
            commit('GET_USER_RIGHTS',data);
        },
        async changeRight(_,{userId, type, id, droit}) {
            await IntranetService.changeRight(userId, type, id, droit)
        },
        async getImmInfos({ commit }, itemId) {
            const data = await IntranetService.getImmInfos(itemId);
            commit('GET_IMM_INFOS', data);
        },
        async getPath({ commit }, itemId) {
            const data = await IntranetService.getPath(itemId);
            commit('GET_PATH', data);
        },
        async getFolderContent({ commit }, itemId) {
            const data = await IntranetService.getFolderContent(itemId)
            commit ('GET_CONTENT',data);
        }
        /*
        async fichiersList({ commit }, params) {
            console.log("action")
            const data = await IntranetService.getListFichiers(params);
            console.log(data);
            commit('FICHIERS_LIST', data);
        },
        async oneFichier({ commit }, params) {
            console.log("action")
            const data = await IntranetService.getFichier(params);
            console.log(data);
            commit('GET_FICHIER', data);
        },
        async addFichier({ commit }, item) {
            const data = await IntranetService.createFichier(item);
            commit('GET_FICHIER', data);
        },*/
    },
    mutations: {
        GET_ROOT(state, data) {
            state.oneRoot = data;
        },
        GET_FOLDER(state, data) {
            state.oneFolder = data;
        },
        GET_FOLDERS(state, data) {
            state.folders = data;
        },
        GET_FILES(state, data) {
            state.files = data;
        },
        GET_FILE(state, data) {
            state.oneFile = data;
        },
        GET_FILE_URL(state, data) {
            state.fileURL = data;
        },
        GET_FILE_RIGHT(state, data) {
            state.oneRight = data;
        },
        GET_USERS(state,data) {
            state.users = data;
        },
        GET_USER_RIGHTS(state,data) {
            state.userRights = data;
        },
        GET_IMM_INFOS(state, data) {
            state.immInfos = data;
        },
        GET_PATH(state, data) {
            state.path = data;
        },
        GET_CONTENT(state, data) {
            state.contenu = data;
        }
        /*
        FICHIERS_LIST(state, data) {
            state.fichiersList = data;
        },
        GET_FICHIER(state, data) {
            state.oneFichier = data;
        },*/
    }
}