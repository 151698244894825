<template>
    <div>
        <div class="row">
        <div class="col-lg-4">
          <BasicRadio v-model="modal_inter.item" :options="{prop:'civilite',label:'Monsieur',id:'civilite'}"  />
          <BasicRadio v-model="modal_inter.item" :options="{prop:'civilite',label:'Madame',id:'civilite'}" />
          
        </div>
        <div class="col-lg-4">
      <ArgonInput v-model="modal_inter.item.firstname" size="small" placeholder="Prénom" />
        </div>

        <div class="col-lg-4">
          <ArgonInput v-model="modal_inter.item.lastname" size="small" placeholder="Nom"  />
        </div>
        <div class="col-lg-6">
      <div class="card p-2">
        <h6>Mails</h6>
      <div v-for="mail in modal_inter.item.mails" :key="mail.id" class="d-flex">
        <ArgonInput v-model="mail.mail" size="small" type="email" placeholder="Adresse e-mail" style="width:100%" :error="mailError(mail.mail)"/>
        <button class="btn btn-xs btn-danger"  @click="modal_inter.item.mails.splice(modal_inter.item.mails.indexOf(mail),1)"><i class="fa fa-trash"></i></button>
      </div>
      <button class="btn btn-xs btn-success" @click="addPossibleMail" v-if=" modal_inter.item.mails && modal_inter.item.mails.length < 4"><i class="fa fa-plus"></i>Ajouter un mail</button>

      </div>

        </div>
        <div class="col-lg-6">
      <div class="card p-2">
        <h6>Téléphones</h6>
      <div v-for="phone in modal_inter.item.phones" :key="phone.id" class="mb-2 d-flex">
        <el-select v-if="phone.existing" v-model="phone.phone"  placeholder="Téléphone" filterable style="width:100%" >
          <el-option v-for="sub_phone in item.phones" :key="sub_phone.id" :label="sub_phone.phone" :value="sub_phone.id" />
        </el-select>
        <ArgonInput v-else v-model="phone.phone" size="small" type="text" placeholder="Téléphone" style="width:100%" :error="phoneError(phone.phone)" />
        <button class="btn btn-xs btn-danger"  @click="modal_inter.item.phones.splice(modal_inter.item.phones.indexOf(phone),1)"><i class="fa fa-trash"></i></button>
      </div>
      <div style="width: 100%; display: flex;">
        <button class="btn btn-xs btn-success" v-if="modal_inter.item.phones && modal_inter.item.phones.length < 4" @click="addPossiblePhone"><i class="fa fa-plus"></i>Ajouter un numéro de téléphone</button>
      
      <button class="btn btn-xs btn-success" v-if="modal_inter.item.phones && modal_inter.item.phones.length < 4" @click="addExistingPhone"><i class="fa fa-plus"></i>Attribuer un numéro de téléphone existant</button>

      </div>
      
      </div>
          
        </div>



      </div>

      <div class="text-center mt-2">
        <base-button  type="primary" @click="saveInterlocuteur">Enregistrer</base-button>
      </div>
    </div>
</template>
<script>
import BaseButton from '@/components/BaseButton.vue'
import BasicRadio from '@/blocks/Controls/BasicRadio.vue'
import ArgonInput from '@/components/ArgonInput.vue'
import { ElSelect, ElOption } from 'element-plus'
import showSwal from '../../mixins/showSwal.js';
export default {
    name: 'Interlocuteur',
    components: {
      BaseButton,
      BasicRadio,
      ArgonInput,
        ElSelect,
        ElOption
    },
    mixins: [showSwal],
    props: {
      modelValue: {
        type: Object,
        default: () => {}
      },
      item: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        modal_inter: this.modelValue,
        errors: {
          mail: [],
          phone: []
        }
      }
    },
    mounted() {
        this.modal_inter.item.fournisseur_id = this.item.id
    },
    beforeUnmount() {
      this.modal_inter.item = {}
    },
    
  computed: {
    interluteur_item: {
      get() {
        return this.modal_inter.item
      },
      set(value) {
        this.modal_inter.item = value
      }
    },

  },
  watch:{
    interluteur_item:{
      deep:true,
      handler: function(val){
        this.inter_dyn(val)
      }
    }
  },
    methods:{
        async inter_dyn(val){
      val.lastname = val.lastname.toUpperCase()
      val.phones.forEach(element => {
        if (element.phone && typeof element.phone == "string"){

          element.phone = element.phone.replace(/[^\d]/g, "").replace(/^(0\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/, "$1 $2 $3 $4 $5");
        }
      });
        console.log(val)
        },
    mailError(mail){
      if (mail){
        if (mail.indexOf('@') == -1){
          return "Adresse e-mail invalide"
        }
      }
    },
    phoneError(phone){
      if (phone){
        if (phone.length < 10){
          return "Numéro de téléphone invalide"
        }
      }
    },

    async saveInterlocuteur(){
      //this.modal_inter.item.gender = this.modal_inter.item.civilite.toLowerCase()
      if (this.modal_inter.show){
      this.modal_inter.show = false;
        
      console.log("aV")
      console.log(this.modal_inter.item)
      console.log("aP")
      this.modal_inter.item.gender = this.modal_inter.item.civilite.toLowerCase()

      await this.$store.dispatch("fournisseur/addInterlocuteur", this.modal_inter.item);

        this.showSwal({
          type: "success",
          message: "L'interlocuteur a bien été ajouté"
        });
      }

    },
    addPossibleMail(){
      this.modal_inter.item.mails.push({mail:''})
    },
    addPossiblePhone(){
      this.modal_inter.item.phones.push({phone:''})
    },
    addExistingPhone(){
      this.modal_inter.item.phones.push({phone:'',existing:true})
    },
    }
}


</script>