<template>
    <teleport to="body">
        <div v-if="show" class="modal-backdrop">
            <div class="modal-content">
                <button type="button" class="btn-close btn-close-black" aria-label="Close" @click="close"></button>
                <h6 class="text-white text-center"> Ajouter un dossier </h6>
                <DropZone 
                    :modelValue="modelValue" 
                    :options="{prop:'files'}" 
                    name="Fichiers" 
                    multiple="control.isMultiple"
                    @update:modelValue="updateModelValue">
                </DropZone>
                <button class="btn btn-white mt-2 btn-xs" @click="saveFiles">Ajouter</button>
            </div>
        </div>
    </teleport>
</template>

<script>
import DropZone from '@/blocks/Controls/DropZone.vue';

export default {
    name:"AjoutFichier",
    components: { DropZone },
    props: {
        modelValue: {
            type: Array,
            requires: true
        },
        show: {
            type: Boolean,
            required: true
        }
    },
    emits: ['close', 'submit', 'update:modelValue'],
    methods: {
        saveFiles() {
            this.$emit('submit', this.modelValue);
            this.close();
        },
        close () {
            this.$emit('close');
        },
        updateModelValue(newValue) {
            this.$emit('update:modelValue', newValue);
        },
    }
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.425);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: rgb(26, 36, 54);
  position: relative;
  width: 25%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

</style>