<template>
<div class="py-4 container-fluid">
<base-header class="bg-white pb-1 border-radius-xl">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 text-center">
            <h3 class="h4">Chargement...</h3>
            <img :src="loading"/>
        </div>
      </div>
    </base-header>

</div>
</template>

<script>
import BaseHeader from "./BaseHeader.vue";
import loading from "@/assets/img/loading.gif";
export default {
  components: {
    BaseHeader,
  },
  data() {
    return {
      loading: loading,
    };
  },
};
</script>