<template>
  <div class="container position-sticky z-index-sticky top-0">

  </div>
  <main class="main-content mt-0">
    <div
      class="page-header min-vh-100"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/illustrations/404.svg') + ')',
      }"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1 class="display-1 text-bolder text-primary">Erreur</h1>
            <h2>Cette partie du site est encore en construction</h2>
            <p class="lead">
              Nous vous suggérons de revenir à l'accueil en attendant que nous finissions cette partie.
            </p>
            <argon-button color="dark" variant="gradient" class="mt-4" @click="$router.push({ name: 'Dashboard' })"
              >Revenir à l'accueil</argon-button
            >
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
//import Navbar from "@/examples/PageLayout/Navbar.vue";
//import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
export default {
  name: "SignupBasic",
  components: {
   // Navbar,
    //AppFooter,
    ArgonButton,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100")
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
  },
};
</script>
