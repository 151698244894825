<template>
  <Transition name="fade">
    <Loading v-if="mainLoading" />
    <div v-else>
  <div class="py-4 container-fluid">
    <Modal v-model="modals.residents.show" closeable header="Résidents">
      <div class="btn-group" role="group" aria-label="Basic example">
        <base-button size="sm" outline type="secondary" :class="residents == 'all' ? 'active' : ''"
          @click="showAllResidents">Tous</base-button>
        <base-button size="sm" outline type="secondary" :class="residents == 'cop' ? 'active' : ''"
          @click="showOnlyCops">Copropriétaires</base-button>
        <base-button size="sm" outline type="secondary" :class="residents == 'loc' ? 'active' : ''"
          @click="showOnlyLocs">Locataires</base-button>
      </div>

      <ArgonInput v-model="searchcop" size="small" :placeholder="resident_search_placeholder" />
      <div style="overflow-y: scroll;height: calc(100vh - 370px);">

        <!--<table class="table-responsive align-items-center table-flush" header-row-class-name="thead-light hidden-columns">
          <tr v-for="row in filterCops" :key="row.perso_mini.id">
            <td>
              <div class="d-flex align-items-start flex-column justify-content-center"
                style=" padding: 5px; cursor: pointer;margin: 0px;">
                <h6 class="mb-0 text-sm" @click="goPerso(row.perso_mini.id)">{{ row.perso_mini.nom }} {{
                  row.perso_mini.prenom }}</h6>

                <p class="mb-0  text-xs" v-if="row.bail"><a v-for="lot in row.bail.lots" v-bind:key="lot.id"
                    @click="goPerso(lot.perso_mini.id)">{{ lot.typedescr.texte }} de {{ lot.perso_mini.nom }} {{
                      lot.perso_mini.prenom }}</a></p>

                <p class="mb-0  text-xs" v-else>
                  <a v-for="l in row.lots" v-bind:key="l.id">
                    <a v-for="c in l.baux_actifs" v-bind:key="c.id">
                      <a v-for="b in c.ext_locataire" v-bind:key="b.id" @click="goPerso(b.perso_mini.id)"> {{
                        b.perso_mini.nom }} {{ b.perso_mini.prenom }} </a>

                    </a>

                  </a>

                </p>

              </div>
            </td>
          </tr>

        </table>-->
      </div>

    </Modal>
    <Modal v-model="modals.equipe.show" closeable header="Équipe de gestion">
      <p>You can close this modal using the close button on the top right corner</p>
    </Modal>
    <NewEvent v-model="new_item" v-model:newEventModal="newEventModal" :blocking="['immeuble_id']"/>
    <EditionEvent v-model="edition_item" v-model:editEventModal="editEventModal" :blocking="edit_blocking"/>
    


    <base-header class="bg-gradient-primary pb-1 border-radius-xl">
      <div class="row align-items-center py-2">
        <div class="col-lg-3 text-left">
          <button class="btn btn-white mt-2 btn-xs" @click="$router.go(-1)">
            <span class="btn-inner--icon"><i class="fas fa-arrow-left"></i></span> Retour
          </button>
        </div>
        <div class="col-lg-6 text-center">
          <h6 class="h5 text-white d-inline-block mb-0"> {{ item.id }} &nbsp;</h6>
          <h6 class="h3 text-white d-inline-block mb-0">{{ item.title }}

          </h6>
          <h6 class="h6 text-white d-inline-block mb-0"> 
            {{ item.pertemandat ? ' - Mandat syndic terminé le ' + item.pertemandat : '' }}</h6>
            <br/>
            
          <argon-badge color="danger" variant="gradient" @click="goImmeuble(item.immeuble)">{{ item.immeuble.nom }}</argon-badge>
        </div>
        <div class="col-lg-3 text-end">
          <button class="btn btn-white mt-2 btn-xs" @click="modals.equipe.show = true">
            <span class="btn-inner--icon"><i class="fas fa-users"></i></span> Équipe de gestion
          </button>

        </div>




      </div>
    </base-header>

  </div>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-lg-9">
        <div class="card p-3" style="height: calc(100vh - 160px);">
          <div style="display: flex;">
            <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
              <base-button size="xs" type="primary" outline :class="evenements_type == 'actuels' ? 'active' : ''"
                @click="evenements_type = 'actuels'">Évènements actuels</base-button>
              <base-button size="xs" type="primary" outline :class="evenements_type == 'futurs' ? 'active' : ''"
                @click="evenements_type = 'futurs'">Évènements futurs</base-button>
            </div>
          </div>
          <div v-if="evenements_type == 'actuels'" style="overflow-y: scroll;">

            <div class="text-center">
              <base-button size="sm" type="dark" @click="newEventModal = true"><i class="fa fa-plus"></i>Ajouter un évènement</base-button>
            </div>
              <table v-for="(event,i) in item.events" :key="event.id" class="table-responsive w-100 flex-fill">
                <EventMiniBlock v-model="item.events[i]" :editSubEvent="editSubEvent"/>
              

              </table>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card p-3" style="height: calc(100vh - 350px);">
          <div style="display: flex;">
            <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
              <base-button size="xs" type="purple" outline
                :class="attributs_or_contrats == 'attributs' ? 'active' : ''"
                @click="attributs_or_contrats = 'attributs'">Attributs</base-button>
              <base-button size="xs" type="purple" outline :class="attributs_or_contrats == 'contrats' ? 'active' : ''"
                @click="attributs_or_contrats = 'contrats'">Contrats</base-button>
            </div>
          </div>
          <div v-if="attributs_or_contrats == 'attributs'">
            <base-button size="sm" type="neutral" @click="modals.attributs.show = true">
              <i class="fas fa-plus"></i>Ajouter</base-button>
            <table class="table-responsive align-items-center table-flush">
              <!--<tr v-for="row in item.attributes.filter(item => item.parent_id == null)" v-bind:key="row.id">

                <div v-if="row.attr_type != 'fichiers'" @click="changeAttrImmeuble(row); modals.attributs.parent_id = 0"
                  class="d-flex align-items-start flex-column justify-content-center"
                  style=" padding: 5px; cursor: pointer;margin: 0px;">


                  <a><i v-if="row.attr_type == 'codes'" class="fas fa-key"></i><i v-if="row.attr_type == 'texte'"
                      class="fas fa-sticky-note"></i> {{ row.name }}</a>
                  <a v-if="row.attr_type == 'texte'">{{ JSON.parse(row.value) }}</a>

                </div>

                <div v-else @click="showChildren(row)" class="d-flex align-items-start flex-column justify-content-center"
                  style=" padding: 5px; cursor: pointer;margin: 0px;">

                  <a><i v-if="row.attr_type == 'fichiers'" class="fas fa-folder"></i> {{ row.name }}</a>

                </div>


                <base-button v-if="row.attr_type == 'fichiers' && row.show_children" size="sm" type="neutral"
                  @click="changeAttrImmeuble(r);">
                  <i class="fas fa-pen"></i>Modifier</base-button>

                <base-button v-if="row.attr_type == 'fichiers' && row.show_children" size="sm" type="neutral"
                  @click="modals.attributs.show = true; modals.attributs.parentId = row.id; modals.attributs.parent = row; modals.attributs.attr_type = 'fichiers'; modals.attributs.force = true;">
                  <i class="fas fa-plus"></i>Sous-dossier</base-button>

                <div v-for="r in row.children.filter(r => r.show_children)" :key="r.id" class="ml-3">
                  <div @click="changeAttrImmeuble(r)" class="d-flex align-items-start flex-column justify-content-center"
                    style=" padding: 5px; cursor: pointer;margin: 0px;">


                    <a><i v-if="r.attr_type == 'fichiers'" class="fas fa-folder"></i>
                      <i v-if="r.attr_type == 'codes'" class="fas fa-key"></i>
                      <i v-if="r.attr_type == 'texte'" class="fas fa-sticky-note"></i>
                      {{ r.name }}
                    </a>
                    <a v-if="r.attr_type == 'texte'">{{ JSON.parse(r.value) }}</a>

                  </div>
                </div>

                <div v-for="f in row.fichiers.filter(r => r.show_children)" :key="f.id" class="ml-3">
                  {{ f.name }}

                </div>
              </tr>-->
            </table>
          </div>
          <div v-if="attributs_or_contrats == 'contrats'" style="overflow-y: scroll;">
            <ArgonInput v-model="searchcontrat" size="small" placeholder="Rechercher un contrat..." />
            <!--<table class="table-responsive align-items-center table-flush" >
              <tr v-for="row in filterContrat" :key="row.id">
                <div  class="table-actions" style="display: flex;" @click="modals.contrats.item=row; modals.contrats.show=true">
                    {{ row.contrat_libelle }}{{ row.ext_fournisseur_type }} {{ row.ext_fournisseur_idspe }}
                    <div style="flex:1">
                      <h5 class="text-sm" v-if="row.ext_fournisseur">{{ row.ext_fournisseur.nom }}</h5>
                      <p class="text-xs" >{{ row.contrat_libelle }}</p>

                    </div>
                    <div class="text-end text-xs" style="flex:1;" v-if="row.ext_fournisseur.dl_fournisseur">{{ row.ext_fournisseur.dl_fournisseur.tva }}</div>
                  </div>
              </tr>
            </table>-->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</Transition>
</template>

<script>
import ArgonInput from '../../../components/ArgonInput.vue';
//import ArgonButton from '../../../components/ArgonButton.vue';
import formMixin from "../../../mixins/form-mixin.js"
//import ValidationError from "../../../components/ValidationError.vue";
import showSwal from '../../../mixins/showSwal.js';
//import MiniStatisticsCard from '../../../components/Cards/MiniStatisticsCard.vue';
import BaseHeader from "../../../components/BaseHeader.vue"
import BaseButton from "@/components/BaseButton.vue"
//import setNavPills from "@/assets/js/nav-pills.js";
//import {ElTable,ElTableColumn} from "element-plus"
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import ArgonBadge from "@/components/ArgonBadge.vue";
import NewEvent from "./NewEvent"
import EditionEvent from "./EditionEvent"
import EventMiniBlock from './EventMiniBlock.vue';
export default {
  name: "EditRolePage",
  components: {
    ArgonInput,
    //ArgonButton,
    ArgonBadge,
    //ValidationError,
    BaseHeader, BaseButton, Modal, Loading,
    NewEvent, EditionEvent,
    EventMiniBlock
},
  mixins: [formMixin, showSwal],
  data() {
    return {
      mainLoading: true,newEventModal:false,editEventModal:false,
      modals: {
        equipe: {
          show: false,
          item: {}
        },
        contrats: {
          show: false,
          item: {}
        },
        residents: {
          show: false,
          f: []
        },
        locataires: {
          show: false,
          f: []
        },
        edit_attributs: {
          show: false,
          id: this.$route.params.id,
          f: [],
          name: '',
          value: '',
          fichiers: '',
          attr_type: '',
          codes: []
        },
        attributs: {
          show: false,
          id: this.$route.params.id,
          f: [],
          codes: []
        }
      },
      searchcop: '',
      residents: 'all',
      resident_search_placeholder: 'Rechercher un résident',
      evenements_type: "actuels",
      cs_or_mandats: "cs",
      attributs_or_contrats: 'attributs',
      searchcontrat: '',
      item: {
        attributes: [],
        immeuble_id:0
      },
      edition_item: {
        attributes: [],
        immeuble_id: 0,
        ticket_id: this.$route.params.id,
        parent_id: 0,
        title: '',
        controller_id: 1,
        view_id: 1,
      },
      new_item: {
        attributes: [],
        immeuble_id: 0,
        ticket_id: this.$route.params.id,
        parent_id: 0,
        title: '',
        controller_id: 1,
        view_id: 1,
      },
      options: {
        sort: "created_at",
        query: "",
        nr: "1",
        perpage: "5"
      }
    }
  },
  computed: {

    /*filterContrat() {
            return this.item.contrats.filter(
              (data) =>
                !this.searchcontrat ||
                data.ext_fournisseur.nom.toLowerCase().includes(this.searchcontrat.toLowerCase())
            )

          },
    filterCops() {
      return this.cops.filter(
        (data) =>
          (!this.searchcop && this.residents == 'all') ||
          (!this.searchcop && this.residents == 'cop' && data.qualite === undefined) ||
          (!this.searchcop && this.residents == 'loc' && data.qualite) ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && this.residents === 'all')
          ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && data.qualite === undefined && this.residents === 'cop')
          ||
          (data.perso_mini.nom.toLowerCase().includes(this.searchcop.toLowerCase()) && data.qualite !== undefined && this.residents === 'loc')
      ).sort(function (a, b) {
        if (a.perso_mini.nom < b.perso_mini.nom) {
          return -1;
        }
        if (a.perso_mini.nom > b.perso_mini.nom) {
          return 1;
        }
        return 0;
      });
      //el => el.perso_mini.nom)

    },*/
  },
  async mounted() {
    //setNavPills();
    await this.$store.dispatch("event/getEvent", this.$route.params.id);
    this.item = await this.$store.getters["event/oneEvent"];
    this.item.events = this.item.events.reverse()
    console.log(this.item)
    this.new_item.immeuble_id = this.item.immeuble_id
    this.edition_item.immeuble_id = this.item.immeuble_id
      this.edition_item.view_id = this.item.view_id

/*
    console.log('GET');
    this.item.contrats = this.item.contrats.sort(function (a, b) {
      if (a.ext_fournisseur.nom < b.ext_fournisseur.nom) {
        return -1;
      }
      if (a.ext_fournisseur.nom > b.ext_fournisseur.nom) {
        return 1;
      }
      return 0;
    });

    this.cops = [];
    this.item.coplots_actifs.forEach(element => {
      console.log('a');
      let s = this.cops.findIndex(el => el.EXTAPI_PERSO_ID === element.EXTAPI_PERSO_ID)
      console.log(element);
      if (s === -1) {
        if (element.date_vente === "") {
          this.cops.push(element)
        }


      } else {
        if (element.date_vente === "") {
          this.cops[s].lots.push(element.lots[0])
        }

      }
      element.lots.forEach(lot => {
        console.log('<')
        lot.baux_actifs.forEach(tbail => {
          console.log(tbail)
          tbail.ext_locataire.forEach(loca => {
            let s2 = this.cops.findIndex(el => el.EXTAPI_PERSO_ID === loca.EXTAPI_PERSO_ID)
            if (s2 === -1) {
              let lt = { ...loca, bail: { lots: [{ ...lot, perso_mini: element.perso_mini }] } }
              console.log(lt)
              // loca.bail.lots = [lot]
              this.cops.push(lt);

            } else {
              // this.cops[s2].bail.lots.push(lot);

            }
          })


        })
        console.log('>')
      })



    });
    this.item.mandats.forEach((mandat) => {
      let s = this.cops.find(el => el.EXTAPI_PERSO_ID === mandat.EXTAPI_PERSO_ID)
      if (s !== undefined) {
        mandat.cop = s

      }


    })
    this.item.mandats = this.item.mandats.filter((mandat) => mandat.date_fin_effet === "")
*/
    this.mainLoading = false;
  },
  methods: {
    parseJSONRecursively(jsonString) {
  const parseValue = (value) => {
    if (typeof value === 'string') {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    } else if (typeof value === 'object' && value !== null) {
      for (const key in value) {
        value[key] = parseValue(value[key]);
      }
    }
    return value;
  };

  try {
    const parsedObject = JSON.parse(jsonString);
    return parseValue(parsedObject);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
},
    editSubEvent(eve, watch=false){
      this.editEventModal = true; 
      this.edition_item=this.parseJSONRecursively(eve.content)
      this.edition_item.view_id = eve.view_id
      console.log('editSubEvent',this.edition_item)
      if (watch){
        this.edit_blocking = ['all','saveDraft']
      } else {
        this.edit_blocking =['immeuble_id','']
      }
      
    },
    newEventImmeuble(){
      this.$router.push({
        name: "Liste des évènements",
        params:{newEventProp: JSON.stringify({'immeuble_id':this.item.id,'immeuble':this.item})}
      })
    },
      goPerso(id){
        this.$router.push({
          name: "Perso",
          params: { id: id },
        });

      },
      goImmeuble(row){
        this.$router.push({
          name: "Immeuble",
          params: { id: row.id },
        });

      },
      
    openAdresse() {
      window.open('https://www.google.com/maps/search/?api=1&query=' + this.item.adresse1 + ' ' + this.item.adresse2 + ' ' + this.item.cp + ' ' + this.item.ville, '_blank');

    },

    showChildren(row) {
      console.log(row);
      this.item.attributes.forEach(element => {
        if (element.id == row.id) {
          console.log(element);
          element.show_children = !element.show_children;
        }
      });
      // simplifier
      this.item.attributes.push({})
      this.item.attributes.pop()
    },
    showAllResidents() {
      this.residents = 'all';
      this.resident_search_placeholder = "Rechercher un résident";

    },
    showOnlyCops() {
      this.residents = 'cop';
      this.resident_search_placeholder = "Rechercher un copropriétaire";

    },
    showOnlyLocs() {
      this.residents = 'loc';
      this.resident_search_placeholder = "Rechercher un locataire";

    },
    changeAttrImmeuble(row) {
      this.modals.edit_attributs.show = true;
      this.modals.edit_attributs.immeuble_id = this.$route.params.id;
      this.modals.edit_attributs.id = row.id;
      this.modals.edit_attributs.name = row.name;
      this.modals.edit_attributs.attr_type = row.attr_type;
      console.log(row);
      switch (row.attr_type) {
        case "texte":
          this.modals.edit_attributs.value = JSON.parse(row.value);
          break;
        case "codes":
          this.modals.edit_attributs.codes = JSON.parse(row.value);
          break;
        case "fichiers":
          this.modals.edit_attributs.fichiers = row.fichiers;
          break;
        case "number":
          this.modals.edit_attributs.number = row.value;
          break;
        case "bool":
          this.modals.edit_attributs.bool = row.v
      }
      this.modals.edit_attributs.name = row.name;
    },
    async handleEditRole() {
      try {
        await this.$store.dispatch("role/editRole", this.item);
        await this.$store.dispatch("role/rolesList", {
          ...(this.options.sort ? { sort: this.options.sort } : {}),
          filter: {
            ...(this.options.query ? { name: this.options.query } : {}),
          },
          page: {
            number: this.options.nr,
            size: this.options.perpage,
          },
        });
        this.resetApiValidation();
        this.showSwal({
          type: "success",
          message: "Role edited successfully!"
        });
        await this.$router.push({ name: 'Role Management' });
      }
      catch (error) {
        this.setApiValidation(error.response.data.errors);
        this.showSwal({
          type: "error",
          message: "Oops, something went wrong!"
        });
      }
    },
  }
};
</script>
