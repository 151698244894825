<template>
  <Transition name="fade">
    <Loading v-if="mainLoading" />
    <div v-else>
  <div class="py-1 container-fluid">
    <Modal v-model="modals.residents.show" closeable header="Résidents">
      <div class="btn-group" role="group" aria-label="Basic example">
        <base-button size="sm" outline type="secondary" :class="residents == 'all' ? 'active' : ''"
          @click="showAllResidents">Tous</base-button>
        <base-button size="sm" outline type="secondary" :class="residents == 'cop' ? 'active' : ''"
          @click="showOnlyCops">Copropriétaires</base-button>
        <base-button size="sm" outline type="secondary" :class="residents == 'loc' ? 'active' : ''"
          @click="showOnlyLocs">Locataires</base-button>
      </div>

      <ArgonInput v-model="searchcop" size="small" :placeholder="resident_search_placeholder" />
      <div style="overflow-y: scroll;height: calc(100vh - 370px);">

        <table class="table-responsive align-items-center table-flush" header-row-class-name="thead-light hidden-columns">
          <tr v-for="row in filterCops" :key="row.perso_mini.id">
            <td>
              <div class="d-flex align-items-start flex-column justify-content-center"
                style=" padding: 5px; cursor: pointer;margin: 0px;">
                <h6 class="mb-0 text-sm" @click="goPerso(row.perso_mini.id)">{{ row.perso_mini.nom }} {{
                  row.perso_mini.prenom }}</h6>

                <p class="mb-0  text-xs" v-if="row.bail"><a v-for="lot in row.bail.lots" v-bind:key="lot.id"
                    @click="goPerso(lot.perso_mini.id)">{{ lot.typedescr.texte }} de {{ lot.perso_mini.nom }} {{
                      lot.perso_mini.prenom }}</a></p>

                <p class="mb-0  text-xs" v-else>
                  <a v-for="l in row.lots" v-bind:key="l.id">
                    <a v-for="c in l.baux_actifs" v-bind:key="c.id">
                      <a v-for="b in c.ext_locataire" v-bind:key="b.id" @click="goPerso(b.perso_mini.id)"> {{
                        b.perso_mini.nom }} {{ b.perso_mini.prenom }} </a>

                    </a>

                  </a>

                </p>

              </div>
            </td>
          </tr>

        </table>
      </div>

    </Modal>
    <Modal v-model="modals.equipe.show" closeable header="Closeable Modal">
      <p>You can close this modal using the close button on the top right corner</p>
    </Modal>
    <Modal v-model="modals.new_interlocuteur.show" closeable header="Nouvelle Fiche Interlocuteur">
      <AjoutEmploye v-model="modals.new_interlocuteur" :item="item" />
    </Modal>
    <Modal v-model="modals.interluteur.show" closeable header="Fiche Interlocuteur">
      <div class="row">
        <div class="col-lg-4">
          <BasicRadio v-model="modals.interluteur.item" :options="{prop:'civilite',label:'Monsieur',id:'civilite'}" :read-only="!modals.interluteur.edition" />
          <BasicRadio v-model="modals.interluteur.item" :options="{prop:'civilite',label:'Madame',id:'civilite'}" :read-only="!modals.interluteur.edition" />
          
        </div>
        <div class="col-lg-4">
      <ArgonInput v-model="modals.interluteur.item.firstname" size="small" placeholder="Prénom" :disabled="!modals.interluteur.edition" />
        </div>

        <div class="col-lg-4">
          <ArgonInput v-model="modals.interluteur.item.lastname" size="small" placeholder="Nom" :disabled="!modals.interluteur.edition" />
        </div>
        <div class="col-lg-6">
      <div class="card p-2">
        <h6>Mails</h6>
      <div v-for="mail in modals.interluteur.item.mails" :key="mail.id" class="d-flex">
        <ArgonInput v-model="mail.mail" size="small" type="email" placeholder="Adresse e-mail" style="width:100%" :error="mailError(mail.mail)" :disabled="!modals.interluteur.edition"/>
        <button class="btn btn-xs btn-danger" v-if="modals.interluteur.edition" @click="modals.interluteur.item.mails.splice(modals.interluteur.item.mails.indexOf(mail),1)"><i class="fa fa-trash"></i></button>
      </div>
      <button class="btn btn-xs btn-success" @click="addPossibleMail" v-if="modals.interluteur.edition && modals.interluteur.item.mails && modals.interluteur.item.mails.length < 4"><i class="fa fa-plus"></i>Ajouter un mail</button>

      </div>

        </div>
        <div class="col-lg-6">
      <div class="card p-2">
        <h6>Téléphones</h6>
      <div v-for="phone in modals.interluteur.item.phones" :key="phone.id" class="mb-2 d-flex">
        <el-select v-if="phone.existing" v-model="phone.phone"  placeholder="Téléphone" filterable style="width:100%" :disabled="!modals.interluteur.edition">
          <el-option v-for="sub_phone in item.phones" :key="sub_phone.id" :label="sub_phone.phone" :value="sub_phone.id" />
        </el-select>
        <ArgonInput v-else v-model="phone.phone" size="small" type="text" placeholder="Téléphone" style="width:100%" :error="phoneError(phone.phone)" :disabled="!modals.interluteur.edition"/>
        <button class="btn btn-xs btn-danger" v-if="modals.interluteur.edition" @click="modals.interluteur.item.phones.splice(modals.interluteur.item.phones.indexOf(phone),1)"><i class="fa fa-trash"></i></button>
      </div>
      <div style="width: 100%; display: flex;" v-if="modals.interluteur.edition">
        <button class="btn btn-xs btn-success" v-if="modals.interluteur.item.phones && modals.interluteur.item.phones.length < 4" @click="addPossiblePhone"><i class="fa fa-plus"></i>Ajouter un numéro de téléphone</button>
      
      <button class="btn btn-xs btn-success" v-if="modals.interluteur.item.phones && modals.interluteur.item.phones.length < 4" @click="addExistingPhone"><i class="fa fa-plus"></i>Attribuer un numéro de téléphone existant</button>

      </div>
      
      </div>
          
        </div>



      </div>

      <div class="text-center mt-2">
        <base-button v-if="modals.interluteur.edition" type="primary" @click="saveInterlocuteur()">Enregistrer</base-button>
        <base-button v-else type="primary" @click="modals.interluteur.edition = true">Modifier</base-button>
      </div>
      


    </Modal>




    <base-header :class="item.pertemandat ? 'bg-gray' : 'bg-gradient-purple'" class="pb-1 border-radius-xl">
      <div class="row align-items-center pt-4 pb-2">
        <div class="col-lg-3 text-left">
          <button class="btn btn-white mt-2 btn-xs" @click="$router.go(-1)">
            <span class="btn-inner--icon"><i class="fas fa-arrow-left"></i></span> Retour
          </button>
        </div>
        <div class="col-lg-6 text-center">
          <h6 class="h5 text-white d-inline-block mb-0"> {{ item.EXTAPI_IMM_ID }} &nbsp;</h6>
          <h6 class="h3 text-white d-inline-block mb-0">{{ item.thirdparty_name }}

          </h6>
          <br/>
          <h6 class="h6 text-white d-inline-block mb-0" v-if="item.thirdparty_name !== item.name_alias"> {{ item.name_alias }} </h6>
          <h6 class="h6 text-white d-inline-block mb-0"> &nbsp;
            {{ item.pertemandat ? ' - Mandat syndic terminé le ' + item.pertemandat : '' }}</h6>
        </div>
        <div class="col-lg-3 text-end">
          <button class="btn btn-white mt-2 btn-xs" @click="modals.equipe.show = true">
            <span class="btn-inner--icon"><i class="fas fa-users"></i></span> Équipe de gestion
          </button>

        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-4" >


              <mini-statistics-card  v-if="item.avis && item.avis == 'collective'"
                :title="{ text: 'Statut', background: 'bg-danger', color: 'opacity-7 text-white' }"
                :value="{ text: item.jugement.nature, color: 'text-white' }" :icon="{
                  component: 'fa fa-close text-danger',
                  background: 'bg-white',
                  shape: 'rounded-circle',
                }" />
              <mini-statistics-card  v-else-if="item.radiationSirene || (item.avis && item.avis == 'radiation')"
                :title="{ text: 'Statut', background: 'bg-danger', color: 'opacity-7 text-white' }"
                :value="{ text: 'Radiée', color: 'text-white' }" :icon="{
                  component: 'fa fa-close text-danger',
                  background: 'bg-white',
                  shape: 'rounded-circle',
                }" />
              <mini-statistics-card  v-else-if="item.avis && item.avis == 'vente'"
                :title="{ text: 'Statut', background: 'bg-warning', color: 'opacity-7 text-white' }"
                :value="{ text: 'En vente', color: 'text-white' }" :icon="{
                  component: 'fa fa-question text-danger',
                  background: 'bg-white',
                  shape: 'rounded-circle',
                }" />
              <mini-statistics-card  v-else
                :title="{ text: 'Statut', background: 'bg-success', color: 'opacity-7 text-white' }"
                :value="{ text: 'Valide', color: 'text-white' }" :icon="{
                  component: 'fa fa-check text-success',
                  background: 'bg-white',
                  shape: 'rounded-circle',
                }" />
            </div>
            <div class="col-lg-4">

              <mini-statistics-card  :title="{ text: 'Évènements', color: 'opacity-7 text-dark' }"
                :value="{ text: item.events, color: 'text-dark' }" :icon="{
                  component: 'fas fa-calendar text-white',
                  background: 'bg-primary',
                  shape: 'rounded-circle',
                }" />
            </div>
            <div class="col-lg-4">

              <mini-statistics-card 
                :title="{ text: 'Satisfaction client', color: 'opacity-7 text-dark' }"
                :value="{ text: '?%', color: 'text-dark' }" :icon="{
                  component: 'fas fa-smile text-white',
                  background: 'bg-info',
                  shape: 'rounded-circle',
                }" />
            </div>

          </div>


        </div>
        <div class="col-lg-4">

          <mini-statistics-card @click="openAdresse()" class="bg-white"
            :title="{ text: 'Adresse', color: 'opacity-7 text-dark' }"
            :value="{ text: item.address + ' ' + item.address_cpl + ' ' + item.zip + ' ' + item.city, color: 'text-dark' }"
            :icon="{
              component: 'fas fa-location-arrow text-white',
              background: 'bg-success',
              shape: 'rounded-circle',
            }" />

        </div>





      </div>
    </base-header>

  </div>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-lg-3  ">
        <div class="card p-3" style="height: calc(100vh - 350px);">

          <div class="btn-group" role="group" aria-label="Basic example">
            <base-button size="xs" outline type="dark_yellow" :class="cs_or_mandats == 'cs' ? 'active' : ''"
              >Interlocuteurs</base-button>
          </div>

          <div  style="overflow-y: scroll;">
            <base-button size="xs" type="white" 
              @click="modals.new_interlocuteur.show = true"><i class="fa fa-plus"></i>Ajouter</base-button>

            <table class="table-responsive align-items-center table-flush">
              <tr v-for="row in item.employees" v-bind:key="row.id">
                <div class="d-flex align-items-start flex-column justify-content-center"
                  style=" padding: 5px; cursor: pointer;margin: 0px;"
                  @click="changeInterlocuteur(row)">
                  <h6 class="mb-0 text-sm" 
                  >{{row.lastname }} {{row.firstname }} </h6>
                  <p v-if="row.mail">{{ row.mail.mail }}</p>
                </div>
              </tr>
            </table>
          </div>

        </div>

      </div>
      <div class="col-lg-6">
        <div class="card p-3" style="height: calc(100vh - 350px);">
          <div style="display: flex;">
            <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
              <base-button size="xs" type="purple" outline :class="evenements_type == 'contrats' ? 'active' : ''"
                @click="evenements_type = 'contrats'">Contrats</base-button>
              <base-button size="xs" type="primary" outline :class="evenements_type == 'actuels' ? 'active' : ''"
                @click="evenements_type = 'actuels'">Évènements actuels</base-button>
              <base-button size="xs" type="primary" outline :class="evenements_type == 'futurs' ? 'active' : ''"
                @click="evenements_type = 'futurs'">Évènements futurs</base-button>
            </div>
          </div>
          <div v-if="evenements_type == 'contrats'" style="overflow-y: scroll;">
            <div class="text-center">
              <table class="table text-sm">
                <thead>
                  <th>Réf Contrat</th>
                  <th>Immeuble</th>
                  <th>Date première souscription</th>
                  <th>Date fin contrat</th>
                  <th>Montant dernière facture</th>

                </thead>
                <tbody>
                <tr v-for="c in contrats" :key="c.id">
                  <td>{{ c.contrat_ref }}</td>
                  <td @click="goImm(c.immeuble?.id)">{{ c.immeuble?.EXTAPI_IMM_ID }}</td>
                  <td>{{ c.date_premiere_souscription }}</td>
                  <td>{{ c.date_fin_contrat }}</td>
                  <td>{{ c.contrat_montant_derniere_facture }}</td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div v-if="evenements_type == 'actuels'" style="overflow-y: scroll;">
            <div class="text-center">
              <base-button size="xs" type="neutral" @click="newEventImmeuble"><i class="fa fa-plus"></i>Créer un évènement</base-button>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="card p-3" style="height: calc(100vh - 350px); overflow-y: scroll;">
          <div style="display: flex;">
            <div class="btn-group" style="width:100%" role="group" aria-label="Basic example">
              <base-button size="xs" type="purple" outline
                :class="attributs_or_contrats == 'attributs' ? 'active' : ''"
                @click="attributs_or_contrats = 'attributs'">Informations légales</base-button>
            </div>
          </div>
          <div  style="overflow-y: scroll;">
            <table class="table">
              <tr>
                <td>N° SIRET</td>
                <td>{{ item.siret }}</td>
              </tr>
              <tr>
                <td>N° SIREN</td>
                <td>{{ item.siren }}</td>
              </tr>
              <tr>
                <td>Code NAF</td>
                <td>{{ item.naf_code }}</td>
              </tr>
              <tr>
                <td>NAF</td>
                <td>{{ item.naf_text }}</td>
              </tr>

              <tr>
                <td>Légal</td>
                <td>
                  <a v-if="item.DLvalid"><i class="fa fa-check text-success"></i> Valide</a>
                  <a v-else><i class="fa fa-close text-danger"></i> Invalide</a>
                </td>
              </tr>
              <tr>
                <td>Exclu</td>
                <td>
                  <a v-if="item.exclu"><i class="fa fa-check text-success"></i> Exclu</a>
                  <a v-else><i class="fa fa-close text-success"></i> Non</a>
                </td>
              </tr>
              <tr v-for="f in item.syndic_fournisseurs" :key="f.id">
                <td><a :href="f.quitus_url">Quitus</a></td>
                
              </tr>
            </table>
            <!--<table class="table-responsive align-items-center table-flush" >
              <tr v-for="row in filterContrat" :key="row.id">
                <div  class="table-actions" style="display: flex;" @click="modals.contrats.item=row; modals.contrats.show=true">
                    <div style="flex:1">
                      <h5 class="text-sm" v-if="row.ext_fournisseur">{{ row.ext_fournisseur.nom }}</h5>
                      <p class="text-xs" >{{ row.contrat_libelle }}</p>

                    </div>
                    <div class="text-end text-xs" style="flex:1;" v-if="row.ext_fournisseur.dl_fournisseur">{{ row.ext_fournisseur.dl_fournisseur.tva }}</div>
                  </div>
              </tr>
            </table>-->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</Transition>
</template>

<script>
import ArgonInput from '../../../components/ArgonInput.vue';
//import ArgonButton from '../../../components/ArgonButton.vue';
import formMixin from "../../../mixins/form-mixin.js"
//import ValidationError from "../../../components/ValidationError.vue";
import showSwal from '../../../mixins/showSwal.js';
import MiniStatisticsCard from '../../../components/Cards/MiniStatisticsCard.vue';
import BaseHeader from "../../../components/BaseHeader.vue"
import BaseButton from "@/components/BaseButton.vue"
import setNavPills from "@/assets/js/nav-pills.js";
import {ElSelect,ElOption} from "element-plus"
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import BasicRadio from '@/blocks/Controls/BasicRadio.vue';
import AjoutEmploye from './AjoutEmploye.vue';
export default {
  name: "EditRolePage",
  components: {
    ArgonInput, ElSelect, ElOption,
    //ArgonButton,
    //ValidationError,
    MiniStatisticsCard, BaseHeader, BaseButton, Modal, Loading,
    BasicRadio,
    AjoutEmploye
},
  mixins: [formMixin, showSwal],
  data() {
    return {
      contrats:[],
      mainLoading: true,
      secondLoading: true,
      modals: {
        new_interlocuteur: {
          show: false,
          title: 'Nouvelle Fiche Interlocuteur',
          item: {
            firstname: '',
            lastname: '',
            civilite: '',
            phones: [],
            mails: []
          }
        },
        interluteur: {
          show: false,
          title: 'Interlocuteur',
          edition:false,
          item: {}
        },
        equipe: {
          show: false,
          item: {}
        },
        contrats: {
          show: false,
          item: {}
        },
        residents: {
          show: false,
          f: []
        },
        locataires: {
          show: false,
          f: []
        },
        edit_attributs: {
          show: false,
          id: this.$route.params.id,
          f: [],
          name: '',
          value: '',
          fichiers: '',
          attr_type: '',
          codes: []
        },
        attributs: {
          show: false,
          id: this.$route.params.id,
          f: [],
          codes: []
        }
      },
      searchcop: '',
      residents: 'all',
      resident_search_placeholder: 'Rechercher un résident',
      evenements_type: "actuels",
      cs_or_mandats: "cs",
      attributs_or_contrats: 'attributs',
      searchcontrat: '',
      item: {
        civilite:{
          monsieur:false,
          madame:false
        },
        
        attributes: []
      },
      options: {
        sort: "created_at",
        query: "",
        nr: "1",
        perpage: "5"
      }
    }
  },
  computed: {
    interluteur_item: {
      get() {
        return this.modals.interluteur.item
      },
      set(value) {
        this.modals.interluteur.item = value
      }
    },

  },
  watch:{
    interluteur_item:{
      deep:true,
      handler: function(val){
      val.lastname = val.lastname.toUpperCase()
      val.phones.forEach(element => {
        if (element.phone){
          element.phone = element.phone.replace(/[^\d]/g, "").replace(/^(0\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/, "$1 $2 $3 $4 $5");
        }
      });
        console.log(val)
      }
    }
  },
  async mounted() {
    setNavPills();
    await this.$store.dispatch("fournisseur/getFournisseur", this.$route.params.id);
    this.item = await this.$store.getters["fournisseur/oneFournisseur"];
    if (this.item.avis && this.item.jugement !=null){
      this.item.jugement = JSON.parse(this.item.jugement)
    }
    this.contrats = []
    this.item.syndic_fournisseurs.forEach(s=>{
      if(s.ext_fournisseur){
        this.contrats.push(...s.ext_fournisseur.contrats)
      }
    })

    console.log(this.item)


    this.mainLoading = false;

    await this.$store.dispatch("fournisseur/getEntreprise", {id:this.item.siret});
    this.item.entreprise = await this.$store.getters["fournisseur/oneEntreprise"];
    this.secondLoading = false;
  },
  methods: {
          goImm(id){
          this.$router.push({
            name: "Immeuble",
            params: { id: id },
          });
  
          },
    mailError(mail){
      if (mail){
        if (mail.indexOf('@') == -1){
          return "Adresse e-mail invalide"
        }
      }
    },
    phoneError(phone){
      if (phone){
        if (phone.length < 10){
          return "Numéro de téléphone invalide"
        }
      }
    },
    saveInterlocuteur(){
      this.modals.interluteur.show = false;
      this.modals.interluteur.edition = false;
      this.modals.interluteur.item.gender = this.modals.interluteur.item.civilite.toLowerCase()

      this.$store.dispatch("fournisseur/updateInterlocuteur", this.modals.interluteur.item);

    },
    changeInterlocuteur(row){
      this.modals.interluteur.show = true;
      this.modals.interluteur.title = "Modifier une fiche"
      this.modals.interluteur.item = row 
      this.modals.interluteur.item.lastname = this.modals.interluteur.item.lastname.toUpperCase()
      let publication = this.modals.interluteur.item.gender
      this.modals.interluteur.item.civilite = publication[0].toUpperCase() + publication.substring(1);


    },
    addPossibleMail(){
      this.modals.interluteur.item.mails.push({mail:''})
    },
    addPossiblePhone(){
      this.modals.interluteur.item.phones.push({phone:''})
    },
    addExistingPhone(){
      this.modals.interluteur.item.phones.push({phone:'',existing:true})
    },


    newEventImmeuble(){
      console.log(this.item)
      this.$router.push({
        name: "Liste des évènements",
        params:{newEventProp: JSON.stringify({'immeuble_id':this.item.id,'immeuble':this.item})}
      })
    },
      goPerso(id){
        this.$router.push({
          name: "Perso",
          params: { id: id },
        });

      },
    openAdresse() {
      window.open('https://www.google.com/maps/search/?api=1&query=' + this.item.adresse1 + ' ' + this.item.adresse2 + ' ' + this.item.cp + ' ' + this.item.ville, '_blank');

    },

    showChildren(row) {
      console.log(row);
      this.item.attributes.forEach(element => {
        if (element.id == row.id) {
          console.log(element);
          element.show_children = !element.show_children;
        }
      });
      // simplifier
      this.item.attributes.push({})
      this.item.attributes.pop()
    },
    showAllResidents() {
      this.residents = 'all';
      this.resident_search_placeholder = "Rechercher un résident";

    },
    showOnlyCops() {
      this.residents = 'cop';
      this.resident_search_placeholder = "Rechercher un copropriétaire";

    },
    showOnlyLocs() {
      this.residents = 'loc';
      this.resident_search_placeholder = "Rechercher un locataire";

    },
    changeAttrImmeuble(row) {
      this.modals.edit_attributs.show = true;
      this.modals.edit_attributs.immeuble_id = this.$route.params.id;
      this.modals.edit_attributs.id = row.id;
      this.modals.edit_attributs.name = row.name;
      this.modals.edit_attributs.attr_type = row.attr_type;
      console.log(row);
      switch (row.attr_type) {
        case "texte":
          this.modals.edit_attributs.value = JSON.parse(row.value);
          break;
        case "codes":
          this.modals.edit_attributs.codes = JSON.parse(row.value);
          break;
        case "fichiers":
          this.modals.edit_attributs.fichiers = row.fichiers;
          break;
        case "number":
          this.modals.edit_attributs.number = row.value;
          break;
        case "bool":
          this.modals.edit_attributs.bool = row.v
      }
      this.modals.edit_attributs.name = row.name;
    },
    async handleEditRole() {
      try {
        await this.$store.dispatch("role/editRole", this.item);
        await this.$store.dispatch("role/rolesList", {
          ...(this.options.sort ? { sort: this.options.sort } : {}),
          filter: {
            ...(this.options.query ? { name: this.options.query } : {}),
          },
          page: {
            number: this.options.nr,
            size: this.options.perpage,
          },
        });
        this.resetApiValidation();
        this.showSwal({
          type: "success",
          message: "Role edited successfully!"
        });
        await this.$router.push({ name: 'Role Management' });
      }
      catch (error) {
        this.setApiValidation(error.response.data.errors);
        this.showSwal({
          type: "error",
          message: "Oops, something went wrong!"
        });
      }
    },
  }
};
</script>
