<template>
    <div class="mb-1">
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
        <div v-if="options.label == modele[options.prop]" class="p-2">
            {{modele[options.prop]}}
        </div>
        
    </div>
    <div v-else class=" " >
        <div class=" text-black text-sm">{{ options.name }}</div>


        <div class="form-check ">
    <input
      :id="options.label"
      class="form-check-input"
      :class="inputClass"
      type="radio"
    :value="options.label"
      v-model="modele[options.prop]"
    />
    <label class="form-check-label" :for="modele[options.prop]" @click="modele[options.prop] = options.label">
        {{ options.label }} 
    </label>
  </div>
        </div>
        </div>
</template>
<script>
//import Block from '../Block.vue'
//import {ArgonInput} from "@/components/ArgonInput.vue";
export default {
    name: "BasicInput",
    components: {
       // Block
},
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: () =>[]
        },
    },
    data() {
        return {
            modele: this.modelValue,
            checked:this.options.default
        }
    },
    watch: {
        checked:function(val){
            this.modele[this.options.prop] = val
            this.$emit('update:modelValue', this.modele)
        },
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
    mounted(){
        if (this.options.default == true && this.modele[this.options.prop] === undefined){
            this.modele[this.options.prop] = this.options.label
        }
    }
}
</script>