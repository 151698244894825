import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListEntreprises(params){
  const response =  await axios.get(`${API_URL}/entreprises`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return response.data;
}

async function getListFournisseurs(params){
  const response =  await axios.get(`${API_URL}/fournisseurs`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListMetiers(params){
    const response =  await axios.get(`${API_URL}/metiers`, {
        params: params,
        paramsSerializer: params => {
            return qs.stringify(params, { encode: false });
        },
        headers: authHeader()
        });
    return response.data;
}

async function getFournisseur(ImmeubleId){
  const response =  await axios.get(`${API_URL}/fournisseurs/${ImmeubleId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function getEntreprise(params){
  const response =  await axios.get(`${API_URL}/entreprises/${params.id}`, {headers: authHeader(), params: params});
  return response.data;
}

async function getHashedCompany(param){
  const response =  await axios.get(`${API_URL}/hashed/company/${param}`);
  return response.data;
}

async function editFournisseur(Immeuble){

  const payload = jsona.serialize({
    stuff: Immeuble,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/fournisseurs/${Immeuble.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createFournisseur(Immeuble){


  const response =  await axios.post(`${API_URL}/entreprises?include=category,tags`, Immeuble, {headers: authHeader()});
  return response.data;
}

async function createInterlocuteur(item){


  const response =  await axios.post(`${API_URL}/fournisseurs/${item.fournisseur_id}/interlocuteurs`, item, {headers: authHeader()});
  return response.data;
}

 function updateInterlocuteur(item){



  return axios
    .patch(`${API_URL}/fournisseurs/${item.fournisseur_id}/interlocuteurs/${item.id}`, item, {headers: authHeader()})
    .then(response => {
      return response.data;
    });


}


function getFile(immeuble_id,id, name) {
  const options = {headers: authHeader()}
console.log("Imm:",immeuble_id,id, name)
  return axios
    .get(`${API_URL}/immeubles/${immeuble_id}/attr/${id}/files/${name}`, options)
    .then(response => {
      let att = response.data;
      return att;
    });
}

function removeAttributeImmeuble(item) {
  const formData = new FormData();

  Object.entries(item).forEach(([key, value]) => {
    if(key !== "files"){

      formData.append(key, JSON.stringify(value));
    }

  });
  const options = {headers: authHeader()}

  return axios
    .delete(`${API_URL}/immeubles/${item.immeuble_id}/attr/${item.id}`, formData, options)
    .then(response => {
      return response.data;
    });
}

async function deleteImmeuble(ImmeubleId){
  await axios.delete(`${API_URL}/immeubles/${ImmeubleId}`, {headers: authHeader()});
}

async function uploadImageImmeuble(Immeuble) {
    const payload = new FormData();
    payload.append("attachment", Immeuble.image);

    const response = await axios.post(`${API_URL}/uploads/immeubles/${Immeuble.id}/image`, payload, { headers: authHeader() });
    return response.data.url;

  }

export default {
  getListFournisseurs,getListEntreprises,getListMetiers,
    getFournisseur,getEntreprise,getHashedCompany,
    editFournisseur,
    createFournisseur,
    createInterlocuteur,updateInterlocuteur,
    getFile,removeAttributeImmeuble,
    deleteImmeuble,
    uploadImageImmeuble
};
