/* eslint-disable no-unused-vars */
import AgenceService from "../services/agence.service.js";

export const agence = {
    namespaced: true,
    state: {
        agencesList: null,
        oneAgence: null,
        image: null,
        file: null,
    },
    getters: {
        agencesList: state => state.agencesList,
        oneAgence: state => state.oneAgence,
        image: state => state.image,
        file: state => state.file,
    },
    actions: {
        async agencesList({ commit }, params) {
            console.log("action")
            const data = await AgenceService.getListAgences(params);
            console.log(data);
            commit('AgenceS_LIST', data);
        },
        async getAgence({ commit }, itemId) {
            const data = await AgenceService.getAgence(itemId);
            commit('GET_Agence', data);
        },
        async getFile({ commit }, {agence_id,attr_id,file_id}) {
            console.log("action")
            console.log({agence_id,attr_id,file_id})
            const data = await AgenceService.getFile({agence_id,attr_id,file_id});
            commit('GET_FILE', data);
        },
        async addAgence({ commit }, item) {
            const data = await AgenceService.createAgence(item);
            commit('GET_Agence', data);
        },
        async editAgence({ commit }, item) {
            await AgenceService.editAgence(item);
        },
        async deleteAgence({ commit }, itemId) {
            await AgenceService.deleteAgence(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await AgenceService.uploadImageAgence(item);
            commit('UPLOAD_IMAGE_Agence', data);
        },
    },
    mutations: {
        AgenceS_LIST(state, data) {
            state.agencesList = data;
        },
        GET_Agence(state, data) {
            state.oneAgence = data;
        },
        GET_FILE(state, data) {
            state.file = data;
        },
        UPLOAD_IMAGE_Agence(state, data) {
            state.image = data;
        },
  }
}