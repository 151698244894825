<template>
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <!--<navbar
            is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
            btn-background="bg-gradient-success"
            :dark-mode="true"
          />-->
        </div>
      </div>
    </div>
    <main class="main-content main-content-bg mt-0">
      <div
        class="page-header min-vh-100"
        :style="'background-image: url(' + background + ');'
        "
      >
        <span class="mask bg-gradient-dark opacity-3"></span>
        <div class="container">
          <div class="row justify-content-center">
            <img src="../assets/img/mycopro2.png" alt="logo" class="w-40 h-40 mb-5"/>
            <div class="text-center">
                    <router-link :to="{ name: 'Register' }">
                      <argon-button
                        type="button"
                        color="light"
                        variant="gradient"
                        full-width
                        class="mt-2 mb-4"
                        >
                            S'inscrire
                        </argon-button>
                    </router-link>
                    <router-link :to="{ name: 'Login' }">
                      <argon-button
                        type="button"
                        color="light"
                        variant="gradient"
                        full-width
                        class="mt-2 mb-4"
                        >
                            Se connecter
                        </argon-button>
                    </router-link>

            </div>
          </div>
        </div>
      </div>
    </main>
    <app-footer />
  </template>
  
  <script>
  //import Navbar from "@/views/PageLayout/Navbar.vue";
  import AppFooter from "@/views/PageLayout/Footer.vue";
  import { mapMutations } from "vuex";
  import formMixin from "../mixins/form-mixin.js"
  import showSwal from "../mixins/showSwal.js";
  import ArgonButton from "../components/ArgonButton.vue";
import background from "@/assets/img/Fond.png";
  export default {
    name: "Login",
    components: {
    //Navbar,
    AppFooter,
    ArgonButton,
    
},
    mixins: [formMixin, showSwal],
    data(){
      return{background,
        user: {
          email: 'admin@jsonapi.com',
          password: 'secret'
        },
      }
    },
    created() {
      this.$store.state.hideConfigButton = true;
      this.toggleDefaultLayout();
    },
    beforeUnmount() {
      this.$store.state.hideConfigButton = false;
      this.$store.state.image;
      this.toggleDefaultLayout();
    },
    methods: {
      ...mapMutations(["toggleDefaultLayout"]),
      async goToSignup() {
        this.$router.push({name: 'Signup'});
      },
      async goToLogin() {
        this.$router.push({name: 'Login'});
      },
      async handleLogin() {
        try{
          this.resetApiValidation();
          const user_sent = {
                email: this.user.email,
                password: this.user.password,
          };
        try {
          await this.$store.dispatch("auth/login", user_sent);
          this.$router.push({name: 'Dashboard'});
        } catch (error) {
          this.$swal({
            icon: "error",
            text: "Identifiants invalides !",
          });
        }
  
        }
        catch(error)
        {
          this.setApiValidation(error.response.data.errors);
          this.showSwal({
            type:"error",
            message: "Invalid credentials!"
          });
        }
      },
    },
  };
  </script>
  