
<template>
    <div>
        <div v-if="item.options.tocc.active">
            <button @click="item.options.tocc.toggle = !item.options.tocc.toggle" class="btn btn-xs">{{ item.options.tocc.toggle? 'Masquer les champs facultatifs':'Afficher plus de champs'}}</button>
        </div>
        <div class=" flex justify-content-center">
            
        <div v-if="item.options.tocc.toggle ">
            <PersoMail v-model="item" :options="{prop:'to'}" v-if="!item.options.tocc.active" label="À"/>
            <PersoMail v-model="item" :options="{prop:'cc'}" label="CC"/>
            <PersoMail v-model="item" :options="{prop:'cci'}" label="CCI"/>
            <div class="w-100 d-flex mb-1" v-if="!item.options.tocc.active">
                <div class="el-input-group__prepend">Objet</div>
                <input v-model="item.subject" placeholder="Objet" class="form-control input-with-select"/>
            </div>

        </div>
        <Editor :init="{
            plugins: 'lists link image table code help wordcount',
            promotion:false,
            branding: false,
            language:'fr_FR',
            height: 'calc(100vh - 320px)',
            menubar: false,

        }" v-model="item.body" />
        <DropZone v-model="item" :options="options_props"  />
        </div>
    </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import PersoMail from '../../../blocks/Controls/PersoMail.vue';
import DropZone from '../../../blocks/Controls/DropZone.vue';

export default {
    name: 'AutoCompleteGroupDemo',
    components: {
        Editor,
        PersoMail,
        DropZone
},
    data() {
        return {
            options_props:{prop:"files"},
            item: this.modelValue
        };
    },
    props:{
        modelValue: {
            type: Object,
            default: () => {
                return {
                    to:[],
                    cc:[],
                    cci:[],
                    subject:'',
                    body:'',
                    files:[],
                    options:{
                        tocc:{
                            active:false,
                            toggle:true
                        }
                    }
                };
            },
        },
    },
    watch: {
        item: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.item = val
        }
    },
};
</script>
