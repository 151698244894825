import store from "../store";

export default async function collaborateur() {
  let role = await store.getters["profile/me"]?.roles[0];

  if (role != "g") {
    return { name: "Dashboard" }
  }
  
}
