/* eslint-disable no-unused-vars */
import bailservice from "../services/bail.service.js";

export const bail = {
    namespaced: true,
    state: {
        bailsListList: null,
        bailsListFromBuilding: null,
        oneBail: null,
        image: null,
    },
    getters: {
        bailsList: state => state.bailsListList,
        bailsListFromBuilding: state => state.bailsListFromBuilding,
        oneBail: state => state.oneBail,
        image: state => state.image,
    },
    actions: {
        async bailsList({ commit }, params) {
            console.log("action")
            const data = await bailservice.getListbails(params);
            console.log(data);
            commit('lots_LIST', data);
        },
        async bailsFromBuilding({ commit }, params) {
            console.log("action")
            const data = await bailservice.getListbailsFromBuilding(params);
            console.log(data);
            commit('lots_LIST_FROM_BUILDING', data);
        },
        async getBail({ commit }, itemId) {
            const data = await bailservice.getbail(itemId);
            commit('GET_bail', data);
        }
    },
    mutations: {
        bails_LIST(state, data) {
            state.bailsList = data;
        },
        GET_bail(state, data) {
            state.oneBail = data;
        },
        lots_LIST_FROM_BUILDING(state, data) {
            state.lotsFromBuilding = data;
        },
        UPLOAD_IMAGE_lot(state, data) {
            state.image = data;
        },
  }
}