<template>
    <teleport to="body">
        <transition name="fade">
            <div v-if="modelValue" class="pt-5 position-fixed top-0 start-0 h-100 w-100" style="background-color: rgba(0, 0, 0, 0.25);z-index: 10;">
                <div id="backdrop" @click="backdropClick" class="modal1-dialog h-100 overflow-auto">
                    <div class="card px-0" :class="(container == null) ? 'container' : `container-${container}`" :style="(maxwidth != null) ? `max-width: ${maxwidth}` : null">
                        <div class="card-header h4 pb-1">
                            {{header}}
                            <button v-if="closeable" @click='this.$emit("update:modelValue", false)' class="btn btn-text float-end"><strong>X</strong></button>
                        </div>
                        <div class="card-body pt-1">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script >
export default {
    name: "ModalNew",
    emits:['update:modelValue'],
    props:{
        modelValue: {
            type: Boolean,
            required: true,
            default: false
        },
        closeable: {
            type: Boolean,
            required: false,
            default: false
        },
        header: {
            type: String,
            required: false,
            default: null
        },
        container: {
            type: String,
            required: false,
            default: null
        },
        maxwidth: {
            type: String,
            required: false,
            default: null
        }
    },
    methods: {
        backdropClick(event) {
            if (event.target.id == 'backdrop' && this.closeable == true) {
                this.$emit("update:modelValue", false);
            }
        }
    }
}




</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s
}
.fade-enter-from, .fade-leave-to{
  opacity: 0
}

</style>