<template>
    <div class="row p-4">
        <div >
            <div class="row">
                <div class="col-sm-9 text-start">

            <h1 class="h2">Créer un collaborateur</h1>
                </div>

                <div class="col-sm-3 text-end">
                    <button class="btn btn-success" @click="handleSubmit"><i class="fa fa-save"></i> Enregistrer</button>
                </div>
            </div>
            <h4>Personnalité</h4>
            <div class="row">
                <div class="col-md-6">
                     <input type="text" v-model="content.prenom" class="mb-2 form-control" placeholder="Prénom" >

                </div>
                <div class="col-md-6">
                    <input   type="text" v-model="content.nom" class="mb-2 form-control" placeholder="Nom">
                </div>
                <div class="col-md-6">
                    <input   type="text" v-model="content.email" class="mb-2 form-control" placeholder="Email">
                </div>
                <div class="col-md-6">
            
                    <input   type="text" v-model="content.password" class="mb-2 form-control" placeholder="Mot de passe">
                </div>

                <div class="col-md-4">
                <input placeholder="Téléphone fixe" class="mb-2 form-control" v-model="content.phone" />
              </div>
              <div class="col-md-4">
                <input placeholder="Téléphone mobile pro" class="mb-2 form-control" v-model="content.mobile_phone_pro" />
              </div>
              <div class="col-md-4">
                <input placeholder="Téléphone mobile perso" class="mb-2 form-control" v-model="content.mobile_phone_perso" />
              </div>


              <div class="col-md-12">

                <el-select
                    style="width: 100%;"
                    class="pb-2"
                  v-model="content.mailboxes"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  :reserve-keyword="false"
                  placeholder="Indiquez les adresses emails supplémentaires"
                >
                </el-select>
              </div>
            </div>
            <div>
            
            <h4>Syndic</h4>
            <div class="row">
              <div class="col-md-4" >
                    <el-select filterable
                    style="width: 100%;"
                      name="holding"
                      v-model="supersyndic"
                      required
                      placeholder="Holding"
                      :loading="loading.holding"
                      v-if="loading.holding == false"
                    >
                      <ElOption
                        v-for="single_category in all_supersyndics"
                        :key="single_category.id"
                        :value="single_category.id"
                        :label="single_category.nom"
                      >
                      </ElOption>
                    </el-select>
              </div>
              
              <div class="col-md-4">
                <div label="" v-if="supersyndic !== undefined">
                    <el-select  filterable
                    style="width: 100%;"
                      name="syndic"
                      v-model="syndic"
                      prepend-icon="fas fa-user"
                      placeholder="Établissement principal (Syndic)"
                    >
                      <el-option
                        v-for="single_category in all_supersyndics.filter(sup => {return sup.id == supersyndic})[0].syndics"
                        :key="single_category.id"
                        :value="single_category.id"
                        :label="single_category.nom"
                      >
                      </el-option>
                    </el-select>
                </div>
              </div>
              <div class="col-md-4">
                <div label="" v-if="supersyndic !== undefined && syndic !== undefined">
                    <el-select  filterable
                    style="width: 100%;"
                      name="agence"
                      v-model="content.agence_id"
                      prepend-icon="fas fa-user"
                        placeholder="Établissement secondaire (Agence)"
                    >
                      <el-option
                        v-for="single_category in all_syndic.agences"
                        :key="single_category.id"
                        :value="single_category.id"
                        :label="single_category.nom"
                      >
                      </el-option>
                    </el-select>
                </div>
              </div>

              <div class="col-md-12 mt-4" v-if="supersyndic !== undefined && syndic !== undefined">
                <div style="display:flex">
                    <h5>Rôles</h5>
                  <div style="flex:1"></div>
                  <button type="button" class="btn btn-xs btn-success" @click="addRole" native-type="button">
                  Ajouter un rôle
                  </button>
                </div>
                
                <div class="row" v-for="r in content.roles" :key="r.id">
                  <div class="col-md-3">
                    <div  v-if="supersyndic !== undefined && syndic !== undefined">
                    <a class="text-xs">Service</a>
                        <el-select  filterable
                        style="width: 100%;"
                          name="service"
                          v-model="r.syndic_service_id"
                          prepend-icon="fas fa-user"
                        >
                          <el-option
                            v-for="single_category in all_syndic.services"
                            :key="single_category.id"
                            :value="single_category.id"
                            :label="single_category.nom"
                          >
                          </el-option>
                        </el-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div  v-if="supersyndic !== undefined && syndic !== undefined">
                    <a class="text-xs">Fonction</a>
                        <el-select  filterable
                        style="width: 100%;"
                          name="fonction"
                          v-model="r.syndic_fonction_id"
                          prepend-icon="fas fa-user"
                        >
                          <el-option
                            v-for="single_category in all_syndic.fonctions"
                            :key="single_category.id"
                            :value="single_category.id"
                            :label="single_category.nom"
                          >
                          </el-option>
                        </el-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <a class="text-xs">Date de début</a>
                        <VueDatePicker 
                            v-model="r.debut" 
                            locale="fr" 
                            :enable-time-picker="false"
                            selectText="Choisir"
                        cancelText="Annuler"
                        :format="p_format"
                        ></VueDatePicker>
                        
                  </div>
                  <div class="col-md-3">
                    <a class="text-xs">Date de fin</a>
                        <VueDatePicker 
                            v-model="r.fin" 
                            locale="fr" 
                            :enable-time-picker="false"
                            selectText="Choisir"
                        cancelText="Annuler"
                        :format="p_format"
                        ></VueDatePicker>
                  </div>
                </div>
              </div>
             
              
                  





            </div>

            <h4>Affichages</h4>
            <el-select class="mt-1" style="width:100%" round v-model="content.autorises" filterable multiple placeholder="Affichages autorisés"
                    >
                    <el-option key="immeubles" value="immeubles">Immeubles</el-option>
                    <el-option key="résidents" value="résidents">Résidents</el-option>
                    <el-option key="fournisseurs" value="fournisseurs"  >Fournisseurs</el-option>
                    <el-option key="Calendrier" value="calendrier">Calendrier</el-option>
                    <el-option key="mails"  value="mails">Mail</el-option>
                    <el-option key="discussions" value="discussions" >Discussions</el-option>
                    <el-option key="courrier" value="courrier">Courrier</el-option>
                    <el-option key="appels" value="appels" >Appels</el-option>
                    <el-option key="evenements" value="évènements" >Évènements</el-option>
                    <el-option key="assemblées" value="assemblées" >Assemblées</el-option>
                    <el-option key="dropzone" value="dropzones" >Dropzone</el-option>
                    <el-option key="intranet" value="intranet" >Intranet</el-option>
                    
                </el-select>


            </div>
            <h4>Ref Fiche métier</h4>
            <input type="text" v-model="content.extcollaborateur" class="mb-2 form-control" placeholder="(Num ICS)" >

 

        </div>
    </div>
</template>
<script>
import axios from 'axios';
import showSwal from '../../../mixins/showSwal';
//import bg_capitole from '../../../assets/img/bg_capitole.png';
import {ElSelect, ElOption} from "element-plus";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
    components: {
    ElSelect, ElOption,
    VueDatePicker
},
    data() {
        return {
            content: {value:'<p>Some <strong>rich</strong> <em>text</em></p>',
            syndic_id:1,
            agence_id:1,
            roles:[
                {
                    id:'',
                    syndic_service_id:'',
                    syndic_fonction_id:'',
                }
            
            ]
        },
        loading:{
            modele:false,
            holding:false,
        },
        supersyndic:undefined,
        all_supersyndics:[],
        all_syndic:{},
        syndic:undefined,
        mode: 'courrier',
            options: {
                prop: 'value'
            },
            options_pdf:{
                bg: null,
                prop: 'render',
                get_prop: 'value',
            },
            show_pdf: false,
        }
    },
    mixins: [showSwal],
    watch: {
          supersyndic: {
               async handler(val){
                console.log(val)
                  this.syndic = undefined;
                  //this.get();
              }
          },
          syndic: {
               async handler(val){
                if(val !== undefined){
                  console.log(val)
                  this.getSyndic(val)
                }
                
              }
          }
      }, 
    methods: {

        p_format(date) {
  const day = date.getDate();
  const month = date.getMonth() +1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
},
    async handleSubmit() {
      console.log('SUBMIT', this.content)
      //this.item.date_at = moment(this.item.date_at).format("YYYY-MM-DD");
     
      try {
        delete this.content.id;
        await this.$store.dispatch("collaborateur/addCollaborateur", this.content);
        this.content = await this.$store.getters["collaborateur/oneCollaborateur"];


        this.$notify({
          type: "success",
          message: "Le collaborateur a été ajouté.",
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Tous les champs ne sont pas remplis !",
        });

      }
    },
    addRole(){
      this.content.roles.push({
        id:'',
        syndic_service_id:'',
        syndic_fonction_id:'',
      });
    },
    async getSyndic(id) {
      try { 
        await this.$store.dispatch("syndic/getSyndic", id);
        this.all_syndic = this.$store.getters["syndic/oneSyndic"];
        this.all_syndic.image = 'data:image/jpeg;base64,'+this.all_syndic.image;
        console.log(this.all_syndic);

      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Le syndic n'a pas été trouvé !`,
        });
      }
    },
    async getSupersyndics() {
      try {
        this.loading.holding = true
        await this.$store.dispatch("supersyndic/supersyndicsList");
        this.all_supersyndics = await this.$store.getters["supersyndic/supersyndicsList"]?.data;
        console.log(this.all_supersyndics?.data);

        this.loading.holding = false
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },


        async getModeles(el){
            this.loading.modele = true
            let params ={'query' :el}
  await this.$store.dispatch("modele/modelesList", {
    ...(params.query ? { search: params.query } : {}),
    sort:"id",
    page: params.nr,
    size: 25,
    /*page: {
      number: params.to,
    },*/
  });
      if (this.$store.getters["modele/modelesList"]) {
        if (this.$store.getters["modele/modelesList"].meta.current_page == 1){
          console.log(this.$store.getters["modele/modelesList"].data)
          this.updatedList = this.$store.getters["modele/modelesList"].data

        }
        else {
          this.updatedList = [...this.updatedList,...this.$store.getters["modele/modelesList"].data]

        }
      }
      
      this.all_modeles = this.updatedList
        this.loading.modele = false
    },
        arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    },
    async fetchFile(file) {
      try {
        const response = await axios.get(file, { responseType: 'arraybuffer' });
        const base64 = this.arrayBufferToBase64(response.data);
        this.options_pdf.bg = base64;
        console.log(this.options_pdf)
        this.show_pdf = true;
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    },
        async save_model(){
            let modele = {
                title: this.content.title,
                syndic_id: this.content.syndic_id,
                element:  this.content.theme,
                data: this.content.value,
                categories: this.content.categorie,
                flow: this.content.flow,

            }

          await this.$store.dispatch("modele/addModele", modele)
            let modele2 = this.$store.getters["modele/oneModele"]
            console.log(modele2)
            this.showSwal({message:"Modèle créé",type: "success"});
            this.$router.push({
                name: "Modèle",
                params: { id: modele2.id }
            });
            //
        }
    },
    mounted(){
        this.getSupersyndics()
    }
}

</script>