<template>
    <div>
        <base-input label="Type de valeur">
            <el-select v-model="local_attributs.attr_type" disabled>
                <el-option selected value="codes" label="Codes d'accès">Codes d'accès</el-option>
                <el-option value="reglement_copro" label="Règlement de copro"> Règlement de copro </el-option>
                <el-option value="texte" label="Texte"> Texte </el-option>
                <el-option value="fichiers" label="Dossier de fichiers"> Dossier de fichiers</el-option>
                <!--<el-option value="table"> Tableau libre</el-option>-->
            </el-select>
        </base-input>
        <div v-if="local_attributs.attr_type">
            <Card class="container">
                <div v-if="local_attributs.attr_type == 'codes'">
                    <div v-for="(code, key) in local_attributs.codes" :key="key" class="row">
                        <div class="col-lg-5">
                            <base-input label="Localisation">
                                <el-input v-model="code.localisation" />
                            </base-input>
                        </div>
                        <div class="col-lg-5">
                            <base-input label="Code d'accès">
                                <el-input v-model="code.code" />
                            </base-input>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn btn-sm btn-danger" @click="deleteAttribute(key)">Supprimer</button>
                        </div>

                    </div>
                    <div><button class="btn btn-sm btn-success"
                            @click="local_attributs.codes.push({ localisation: '', code: '' })">Ajouter un code d'accès</button></div>


                </div>

                <base-input label="Nom de l'attribut" v-if="local_attributs.attr_type == 'texte'">
                    <el-input v-model="local_attributs.name" />
                </base-input>
                <base-input label="Valeur de l'attribut" v-if="local_attributs.attr_type == 'texte'">
                    <el-input v-model="local_attributs.value" />
                </base-input>
                <div v-if="local_attributs.attr_type == 'reglement_copro'">

                    <DropZone v-model="local_attributs" :options="{prop:'files'}" name="Fichiers"
                        multiple="control.isMultiple"></DropZone>

                </div>
                <div v-if="local_attributs.attr_type == 'fichiers'">
                    <base-input label="Nom du dossier">
                        <el-input v-model="local_attributs.name" />
                    </base-input>

                    <div>

                    </div>
                    <dropzone-file-upload v-model="local_attributs.files" name="Fichiers"
                        multiple="control.isMultiple"></dropzone-file-upload>

                        
                    <ul 
                        class="dz-preview dz-preview-multiple list-group list-group-lg list-group-flush"
                        ref="previewMultiple">
                    <li class="list-group-item px-0" v-for="(f,key) in local_attributs.fichiers" :key="key">
                        <div class="row align-items-center">
                        <div class="col-auto">
                            <button class="btn btn-info btn-sm" @click="download_file(f)">
                            <i class="fas fa-download"></i>
                            </button>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-info btn-sm" @click="edit_file(f)">
                            <i class="fas fa-pen"  ></i>
                            </button>
                        </div>
                        <div class="col ml--3">
                            <h4 class="mb-1" data-dz-name >{{f.name}}</h4>
                            <p class="small text-muted mb-0">Ajouté le {{new Date(f.updated_at).toLocaleDateString()}}</p>
                        </div>
                        <div class="col-auto">
                            <button  class="btn btn-danger btn-sm" @click="remove_file(key)">
                            <i class="fas fa-trash"></i>
                            </button>
                        </div>
                        </div>
                    </li>
                    </ul>


                </div>



            </Card>

        </div>
                <div style="display:flex;">
                    <div style="display:flex; justify-content: start;" v-if="local_attributs.attr_type == 'texte' || (local_attributs.attr_type == 'fichiers' && local_attributs.parent_id != 0)">
                        <base-button @click="removeAttribut"> Supprimer l'attribut</base-button>
                    </div>
                    <div style="flex:1"> </div>
                    <div style="display:flex; justify-content: end;"> 
                        <base-button @click="saveAttribut"> Appliquer les modifications</base-button>
                    </div>
                    
                </div>

    </div>
</template>
<script>
import BaseButton from '@/components/BaseButton.vue';
import { ElInput, ElSelect } from 'element-plus';
import DropZone from '@/blocks/Controls/DropZone.vue';
import Swal from "sweetalert2";


export default {
    components: {
    BaseButton,
    ElSelect,
    ElInput,
    DropZone,
},
    data() {
        return {
            local_attributs: this.modalAttributes,
            attributes: this.modelValue
        }
    },
    mixins: [Swal],
    methods: {
        deleteAttribute(key) {

            new Swal({
                title: `Voulez-vous vraiment supprimer ce code d'accès ?`,
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: 'Oui, supprimer',
                cancelButtonText: 'Non, annuler!',
                customClass: {
                    confirmButton: 'btn btn-danger btn-fill',
                    cancelButton: 'btn btn-success btn-fill'
                },
            }).then((result) => {
                if (result.value) {
                    this.local_attributs.codes.splice(key, 1)
               
                }
            })



            /*this.item.edit_attributs.splice(key, 1);
            this.$store.dispatch("immeubles/update", this.item);*/
        },
        async download_file(file){
            let idtemp = this.local_attributs.id;
            let immidtemp = this.local_attributs.immeuble_id;
            
            let param = {
                id:idtemp,
                immeuble_id:immidtemp,
                name:file.id
            }
            console.log(param);
            await this.$store.dispatch("immeuble/getFile", param);
            const results = this.$store.getters["immeuble/file"];
            window.location = results;
            //window.open(fileend2);
        },
         edit_file(file){
              new Swal({
                title: 'Quel est le nom de ce fichier ?',
                input: 'text',
                inputValue: file.name,
                inputAttributes: {
                  autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Valider',
                cancelButtonText: 'Annuler',
              
              }).then((result) => {
                if (result.isConfirmed) {
                    file.name = result.value;

                }})
            //window.open(fileend2);
        },
        async remove_file(key){
            new Swal({
                title: `Voulez-vous vraiment supprimer ce code d'accès ?`,
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: 'Oui, supprimer',
                cancelButtonText: 'Non, annuler!',
                customClass: {
                    confirmButton: 'btn btn-danger btn-fill',
                    cancelButton: 'btn btn-success btn-fill'
                },
            }).then((result) => {
                if (result.value) {
                    this.local_attributs.fichiers.splice(key, 1)

                }})
            //window.open(fileend2);
        },
        async saveAttribut(){
            if(this.local_attributs.attr_type == 'codes'){
                this.local_attributs.name = "Codes d'accès";
                this.local_attributs.value =  this.local_attributs.codes;
            }
            if(this.local_attributs.attr_type == 'texte'){
                console.log(this.local_attributs);
            }
                this.local_attributs.type = this.local_attributs.attr_type;

            this.local_attributs.show = false;
            await this.$store.dispatch("immeuble/updateAttributeImmeuble", this.local_attributs);
                    let att = this.$store.getters["immeuble/attr"];
                    var att2 
                    console.log(this.attributes)
                    if(att.parent_id !== null){
                        let att3 = this.attributes.find(x => x.id === att.parent_id);
                        console.log(att3)
                         att2 =  att3.children.find(x => x.id === att.id)
                        console.log(att2)
                    } else {
                         att2 = this.attributes.find(x => x.id === att.id);

                    }
                    console.log(att, att2)
                    att2.name = att.name;
                    att2.value = att.value;
                    att2.fichiers = att.fichiers;
                    
            //this.item = this.$store.getters["immeubles/item"];


        },
        async removeAttribut(){
            new Swal({
                title: `Voulez-vous vraiment supprimer cet attribut ?`,
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: 'Oui, supprimer',
                cancelButtonText: 'Non, annuler!',
                customClass: {
                    confirmButton: 'btn btn-danger btn-fill',
                    cancelButton: 'btn btn-success btn-fill'
                },
            }).then( async (result) => {
                if (result.value) {
                    await this.$store.dispatch("immeuble/remove_attr", this.local_attributs);
                    let att = this.$store.getters["immeuble/attr"];
                    
                    let att3 = this.attributes.find(x => x.id === att.parent_id);
                        console.log(att3)
                        let att2 =  att3.children.find(x => x.id === att.id)

                    //let att2 = this.attributes.find(x => x.id === att.id);
                    console.log(att);
                    console.log(att2);
                    console.log(att3.children.indexOf(att2));
                    if(att2 !== -1 ){
                        att3.children.splice(att3.children.indexOf(att2), 1);
                    }
                    
            this.local_attributs.show = false;

                }})
            
            //this.item = this.$store.getters["immeubles/item"];


        }
    },
    watch: {
        local_attributs: {
            handler: function (val) {
                val.local_attribut
               // this.$emit('update:modelValue', s);
            },
            deep: true
        },
    },
    props: {
        modelValue: {
            type: Object,
            default: () => {
                return {
                    attr_type: 'codes',
                    codes: [],
                    name: '',
                    value: '',
                    files: []
                }
            }
        },
        modalAttributes: {
            type: Array,
            default: () => {
                return []
            }
        },
        control: {
            type: Object,
            default: () => {
                return {
                    isMultiple: false
                }
            }
        }
    }
}
</script>