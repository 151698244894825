<template>
  <div class="card position-sticky top-1 mt-4" style="min-width: 150px;">
    <ul class="nav flex-column bg-white border-radius-lg p-3">
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#localisation"
        >
          <i class="fa fa-map-marker me-2 text-dark opacity-6"></i>
          <span class="text-sm">Localisation</span>
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#finance"
        >
          <i class="fa fa-euro-sign me-2 text-dark opacity-6"></i>
          <span class="text-sm">Aspects financiers</span>
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#copro"
        >
          <i class="fa fa-building me-2 text-dark opacity-6"></i>
          <span class="text-sm">Copropriété</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#bail"
        >
          <i class="fa fa-key me-2 text-dark opacity-6"></i>
          <span class="text-sm">Bail</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#surfaces"
        >
          <i class="fa fa-ruler-combined me-2 text-dark opacity-6"></i>
          <span class="text-sm">Surfaces</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#exterieur"
        >
          <i class="fa fa-tree-city me-2 text-dark opacity-6"></i>
          <span class="text-sm">Extérieur</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#interieur"
        >
          <i class="fa fa-bed me-2 text-dark opacity-6"></i>
          <span class="text-sm">Intérieur</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#others"
        >
          <i class="fa fa-ellipsis me-2 text-dark opacity-6"></i>
          <span class="text-sm">Autres</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#terrain"
        >
          <i class="fa fa-map me-2 text-dark opacity-6"></i>
          <span class="text-sm">Terrain</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#mandat"
        >
          <i class="fa fa-gavel me-2 text-dark opacity-6"></i>
          <span class="text-sm">Mandat</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#diagnostics"
        >
          <i class="fa fa-stethoscope me-2 text-dark opacity-6"></i>
          <span class="text-sm">Diagnostics</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#visites"
        >
          <i class="fa fa-eye me-2 text-dark opacity-6"></i>
          <span class="text-sm">Visites</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#commentaires"
        >
          <i class="fa fa-comments me-2 text-dark opacity-6"></i>
          <span class="text-sm">Commentaires</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#travaux"
        >
          <i class="fa fa-hammer me-2 text-dark opacity-6"></i>
          <span class="text-sm">Travaux</span>
        </a>
      </li>

      <!--
      <li class="nav-item">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#profile"
        >
          <i class="ni ni-spaceship me-2 text-dark opacity-6"></i>
          <span class="text-sm">Profile</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#basic-info"
        >
          <i class="ni ni-books me-2 text-dark opacity-6"></i>
          <span class="text-sm">Basic Info</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#password"
        >
          <i class="ni ni-atom me-2 text-dark opacity-6"></i>
          <span class="text-sm">Change Password</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#2fa"
        >
          <i class="ni ni-ui-04 me-2 text-dark opacity-6"></i>
          <span class="text-sm">2FA</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#accounts"
        >
          <i class="ni ni-badge me-2 text-dark opacity-6"></i>
          <span class="text-sm">Accounts</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#notifications"
        >
          <i class="ni ni-bell-55 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Notifications</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#sessions"
        >
          <i class="ni ni-watch-time me-2 text-dark opacity-6"></i>
          <span class="text-sm">Sessions</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#delete"
        >
          <i class="ni ni-settings-gear-65 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Delete Account</span>
        </a>
      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideNav",
};
</script>
