<template>
    <teleport to="body">
        <div v-if="show" class="modal-backdrop">
            <div class="modal-content">
                <button type="button" class="btn-close btn-close-black" aria-label="Close" @click="annuler"></button>
                <h4 class="text-white text-center mt-2 mb-4"> Suppression d'un dossier </h4>
                <p class="text-white">Vous allez supprimer le dossier "{{ name }}" et tous les dosssiers et fichiers qu'il contient. </p>
                <p class="text-white">Etes-vous sûr de vouloir les supprimer ? </p>
                <div class="row justify-content-center"> 
                    <div class="col-auto">
                        <button class="btn btn-white mt-2" @click="annuler">Annuler</button>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-white mt-2" @click="confirmer">Supprimer</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>

export default {
    name:"DelConfirm",
    props: {
        show: {
            type: Boolean,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    emits: ['close', 'submit'],
    data() {
        return {
            supprimer: false
        }
    },
    methods: {
        annuler() {
            this.$emit('submit', false);
            this.close();
        },
        confirmer() {
            this.$emit('submit', true);
            this.close();
        },
        close () {
            this.$emit('close');
        }
    },
}

</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.425);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: rgb(26, 36, 54);
  position: relative;
  width: 20%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

</style>