/* eslint-disable no-unused-vars */
import FournisseurService from "../services/fournisseur.service.js";

export const fournisseur = {
    namespaced: true,
    state: {
        fournisseursList: null,
        metiersList: null,
        oneFournisseur: null,
        entreprisesList: null,
        oneEntreprise: null,
        image: null,
        interlocuteur: null,
        file: null,
    },
    getters: {
        fournisseursList: state => state.fournisseursList,
        metiersList: state => state.metiersList,
        entreprisesList: state => state.entreprisesList,
        oneFournisseur: state => state.oneFournisseur,
        oneEntreprise: state => state.oneEntreprise,
        interlocuteur: state => state.interlocuteur,
        file: state => state.file,
        image: state => state.image,
    },
    actions: {
        async fournisseursList({ commit }, params) {
            console.log("action")
            const data = await FournisseurService.getListFournisseurs(params);
            console.log(data);
            commit('FOURNISSEURS_LIST', data);
        },
        async metiersList({ commit }, params) {
            console.log("action")
            const data = await FournisseurService.getListMetiers(params);
            console.log(data);
            commit('METIERS_LIST', data);
        },

        async entreprisesList({ commit }, params) {
            console.log("action")
            const data = await FournisseurService.getListEntreprises(params);
            console.log(data);
            commit('ENTREPRISES_LIST', data);
        },
        async getFournisseur({ commit }, itemId) {
            const data = await FournisseurService.getFournisseur(itemId);
            commit('GET_FOURNISSEUR', data);
        },
        async getHashedCompany({ commit }, itemId) {
            const data = await FournisseurService.getHashedCompany(itemId);
            commit('GET_ENTREPRISE', data);
        },
        async getEntreprise({ commit }, itemId) {
            const data = await FournisseurService.getEntreprise(itemId);
            commit('GET_ENTREPRISE', data);
        },

        async addFournisseur({ commit }, item) {
            const data = await FournisseurService.createFournisseur(item);
            commit('GET_FOURNISSEUR', data);
        },
        async addInterlocuteur({ commit }, item) {
            console.log(item);
            const data = await FournisseurService.createInterlocuteur(item);
            commit('GET_INTERLOCUTEUR', data);
        },
        async updateInterlocuteur({ commit }, item) {
            console.log(item);
            const data = await FournisseurService.updateInterlocuteur(item);
            commit('GET_INTERLOCUTEUR', data);
        },
        async getFile({ commit }, item) {
            console.log(item);
            const data = await FournisseurService.getFile(item);
            commit('GET_FILE', data);
        },
        async removeAttributeImmeuble({ commit }, item) {
            console.log(item);
            const data = await FournisseurService.removeAttributeImmeuble(item);
            commit('GET_ATTRIBUTE_IMMEUBLE', data);
        },
        async editImmeuble({ commit }, item) {
            await FournisseurService.editImmeuble(item);
        },
        async deleteImmeuble({ commit }, itemId) {
            await FournisseurService.deleteImmeuble(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await FournisseurService.uploadImageImmeuble(item);
            commit('UPLOAD_IMAGE_IMMEUBLE', data);
        },
    },
    mutations: {
        FOURNISSEURS_LIST(state, data) {
            state.fournisseursList = data;
        },
        METIERS_LIST(state, data) {
            state.metiersList = data;
        },
        ENTREPRISES_LIST(state, data) {
            state.entreprisesList = data;
        },
        GET_FOURNISSEUR(state, data) {
            state.oneFournisseur = data;
        },
        GET_ENTREPRISE(state, data) {
            state.oneEntreprise = data;
        },

        GET_INTERLOCUTEUR(state, data) {
            state.interlocuteur = data;
        },
        GET_FILE(state, data) {
            state.file = data;
        },
        UPLOAD_IMAGE_IMMEUBLE(state, data) {
            state.image = data;
        },

  }
}
