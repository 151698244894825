<template>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="bg-gradient-purple p-1 border-radius-xl d-flex">
          <h6 class="mb-0 text-white px-2">Liste des fournisseurs</h6>
          <div style="flex:1"></div>
          <button class="btn btn-xs btn-round btn-white mb-0" @click="router.push({name:'Ajouter un fournisseur'})">
            <i class="fa fa-plus me-1"></i> Ajouter un fournisseur
          </button>
        </div>

        <div class="container text-center mb-2">
          <div class="mt-2">
            <ArgonInput icon="fa fa-search" formgroup_classes="mb-1" iconDir="left" placeholder="Rechercher" v-model="search"/>
            <el-select
              style="color: red;"
              class="select-primary pagination-select col-sm-4"
              v-model="sort"
              placeholder="Tri"
              name="Tri"
              size="small"
            >
              <el-option
                class="select-primary"
                key="thirdparty_name"
                label="Tri par ordre alphabétique"
                value="thirdparty_name"
              />
              <el-option
                class="select-primary"
                key="nom"
                label="Trier par nom croissant"
                value="nom"
              />
              <el-option
                class="select-primary"
                key="events.last"
                label="Trier par dernier évènement"
                value="events.last"
              />
            </el-select>
            <el-select
              class="select-primary pagination-select col-sm-3"
              v-model="onlyme"
              placeholder="Affichage"
              size="small"
            >
              <el-option
                class="select-primary"
                key="Seulement mes immeubles"
                label="Seulement mes immeubles"
                value="Seulement mes immeubles"
              />
              <el-option
                class="select-primary"
                key="Tous les immeubles actifs du parc"
                label="Tous les immeubles actifs du parc"
                value="Tous les immeubles actifs du parc"
              />
              <el-option
                class="select-primary"
                key="Tous les immeubles du parc"
                label="Tous les immeubles du parc"
                value="Tous les immeubles du parc"
              />
            </el-select>
          </div>
        </div>

        <div class="card">
          <div class="px-0 pb-0 pt-1 card-body">
            <div class="table-responsive" id="infinite-list" style="height:calc(100vh - 200px)" @scroll="handleScroll">
              <table class="table table-responsive">
                <tbody class="text-sm">
                  <tr v-for="four in roleList" :key="four.id" @click="openImmeuble(four)">
                    <td class="text-purple">{{ four.denominationUniteLegale }} {{ four.denominationUsuelle1UniteLegale }}
                        {{ four.prenom1UniteLegale }}   {{ four.nomUniteLegale }}

                    </td>
                    <td style="flex:1;width: 100%;">{{ four.naf.libelle }}</td>

<!--
                    <td style="flex:1;width: 100%;">{{ four.address }}</td>
                    <td style="width: 100%;">{{ immeuble.city }}</td>
                    <td style="width: 100%;">{{ immeuble.main_phone }}</td>-->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import ArgonInput from '../../../components/ArgonInput.vue'
import { ElSelect, ElOption } from 'element-plus'

const store = useStore()
const router = useRouter()

const sort = ref('thirdparty_name')
const onlyme = ref('Seulement mes immeubles')
const updatedList = ref([])
const page_t = ref(1)
const search = ref('')

const roleList = computed(() => getList())
const metaPage = computed(() => store.getters['fournisseur/fournisseursList']?.meta)

const getRoleList = _.debounce(async (params) => {
  await store.dispatch('fournisseur/fournisseursList', {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.query ? { search: params.query } : {}),
    ...(params.onlyme === 'Seulement mes immeubles' ? { only: 'me' } : {}),
    ...(params.onlyme === 'Tous les immeubles du parc' ? { withlost: '1' } : {}),
    page: params.nr,
    size: 25,
  })
}, 300)

const getList = () => {
  if (store.getters['fournisseur/fournisseursList']) {
    if (store.getters['fournisseur/fournisseursList'].meta.current_page === 1) {
      updatedList.value = store.getters['fournisseur/fournisseursList'].data
    } else {
      updatedList.value = [...updatedList.value, ...store.getters['fournisseur/fournisseursList'].data]
    }
  } else {
    updatedList.value = [...updatedList.value]
  }
  return updatedList.value
}

const openImmeuble = (row) => {
  router.push({
    name: 'Fournisseur',
    params: { id: row.id }
  })
}

const getDataFromPage = async (page) => {
  await getRoleList({
    sort: sort.value,
    onlyme: onlyme.value,
    query: search.value,
    nr: page,
    perpage: 15
  })
}

const reactiveSearch = async () => {
  await getRoleList({
    sort: sort.value,
    onlyme: onlyme.value,
    query: search.value,
    nr: 1,
    perpage: 15
  })
}

const handleScroll = (e) => {
  const masonry = e.target
  if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
    if (metaPage.value.current_page === 1) {
      page_t.value = 1
    }
    if (page_t.value <= metaPage.value.current_page && page_t.value <= metaPage.value.last_page) {
      page_t.value += 1
      getDataFromPage(page_t.value)
    }
  }
}

onMounted(async () => {
  await getRoleList({
    sort: sort.value,
    onlyme: onlyme.value,
    query: '',
    nr: 1,
    perpage: 15
  })
})

watch([sort, onlyme, search], reactiveSearch)
</script>

<style>
.dataTable-top {
  display: none;
}
.el-radio-button {
  color: red;
  padding: 20em;
}
.el-radio-button .el-radio-button__inner {
  color: blue;
}
.el-radio-button .el-radio-button__inner:hover {
  color: red;
}
</style>
