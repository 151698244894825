<template>
    <div class="mb-1" >
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
        <h3>{{ modele[options.prop] }}</h3>
    </div>
    <div v-else class="bg-light border-radius-lg p-2" style="display: flex">
        <div class="bg-light border-radius-lg p-2 text-black text-sm">{{ options.name }}</div>
        
    <button class="btn"
        :class="options.class"
        :disabled="options.disabled"
    :click="action"
        >{{ options.placeholder ? options.placeholder : options.name }}</button>

    </div>
        
        </div>
</template>
<script>
//import {ArgonInput} from "@/components/ArgonInput.vue";
export default {
    name: "BasicInput",
    components: {
       // ArgonInput,
    },
    props: {
        action:{
            type: Function,
            default: () => { }
        },
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: () =>[]
        },
    },
    data() {
        return {
            modele: this.modelValue
        }
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
}
</script>