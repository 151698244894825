<template>
    <div class="mb-1" >
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
        <h3>{{ modele[options.prop] }}</h3>
    </div>
    <div v-else class="bg-light border-radius-lg p-2" style="display: flex">
        <div class="bg-light border-radius-lg p-2 text-black text-sm">{{ options.name }}</div>
        
        <el-select v-model="modele[options.prop]" :placeholder="options.placeholder ? options.placeholder :options.name" 
        :filterable="options.filterable"
        >
            <el-option v-for="item in filter_items" :key="item.prop" :value="item.label">{{item.label}}</el-option>
        </el-select>
    </div>
        
        </div>
</template>
<script>
//import {ArgonInput} from "@/components/ArgonInput.vue";
import {ElSelect, ElOption} from 'element-plus'
export default {
    name: "BasicInput",
    components: {
       // ArgonInput,
         ElSelect, ElOption
    },
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: () =>[]
        },
    },
    computed:{
        filter_items(){
            return this.options.items.filter(el=>{
                console.log(el)
        if(el.prop_equals)
            return this.modele[el.get_prop] == el.prop_equals
        else
            return true
    })
        }
    },
    data() {
        return {
            modele: this.modelValue
        }
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
}
</script>