/* eslint-disable no-unused-vars */
import calendarService from "../services/calendar.service.js";

export const calendar = {
    namespaced: true,
    state: {
        eventsList: null,
        oneItem: null,
        image: null,
    },
    getters: {
        eventsList: state => state.eventsList,
        oneEvent: state => state.oneEvent,
        image: state => state.image,
    },
    actions: {
        async eventsList({ commit }, params) {
            console.log("action")
            const data = await calendarService.getListEvents(params);
            console.log(data);
            commit('EVENTS_LIST', data);
        },
        async getEvent({ commit }, itemId) {
            const data = await calendarService.getEvent(itemId);
            commit('GET_EVENT', data);
        },
        async addEvent({ commit }, item) {
            const data = await calendarService.createEvent(item);
            commit('GET_ITEM', data);
        },
        async editEvent({ commit }, item) {
            await calendarService.editEvent(item);
        },
        async deleteEvent({ commit }, itemId) {
            await calendarService.deleteEvent(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await calendarService.uploadImageEvent(item);
            commit('UPLOAD_IMAGE_EVENT', data);
        },
    },
    mutations: {
        EVENTS_LIST(state, data) {
            state.eventsList = data;
        },
        GET_EVENT(state, data) {
            state.oneEvent = data;
        },
        UPLOAD_IMAGE_EVENT(state, data) {
            state.image = data;
        },
  }
}
