<template>
    <div class="mb-1">
        <!--op:{{ options }}-->
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
    <div  class="bg-red border-radius-lg p-2" :class="bounce_immeuble?'fa-bounce':''" style="display: flex;">
        <div class="bg-red border-radius-lg p-2 text-white text-sm pr-3" >{{ options.name }}</div>
        <div class="bg-red border-radius-lg p-1 text-bold text-white w-100" style="margin-top: 2px ;font-weight: 700;">{{ imm_name }}</div>
        <button @click="goImm()" class="btn btn-xs btn-white mb-0"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>
    </div>
    </div>
    <div v-else class="bg-red border-radius-lg p-2" :class="bounce_immeuble?'fa-bounce':''" style="display: flex;">
        <div class="bg-red border-radius-lg p-2 text-white text-sm pr-3" >{{ options.name }}</div>


    <el-select class="mt-1" style="width:100%" round v-model="modele[options.prop]" filterable remote reserve-keyword placeholder="Merci d'entrer un immeuble"
                    remote-show-suffix :remote-method="getImmeuble" :loading="loading.immeuble" :disabled="disabled.immeuble">
                    <el-option v-for="item in all_immeubles" :key="item.id" :label="item.EXTAPI_IMM_ID +' - '+item.nom" :value="item.id" />
                </el-select>
        </div>
        </div>
</template>
<script>
//import {ArgonInput} from "@/components/ArgonInput.vue";
import {ElSelect, ElOption} from "element-plus";
export default {
    name: "Immeuble",
    components: {
       // ArgonInput,
        ElSelect,ElOption,
    },
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: () =>[]
        },
    },
    data() {
        return {
            bounce_immeuble:false,
            modele: this.modelValue,
            imm_name:'',
            imm_id:'',
            all_immeubles:[],
            loading:{
                immeuble:true,
            },
            disabled: {
                perso:false,
                immeuble:false,
                lot:false
            }
        }
    },
    computed:{
        perso_id(){
            return this.modele.perso_id
        }
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)

        },
        modelValue: function (val) {
            this.modele = val
        },
        perso_id: function (val) {
            if (val){
                this.getFromPerso(val);

            }
        }
    },
    mounted(){
    },
    created(){

        if (this.modele.immeuble){
        this.all_immeubles = [this.modele.immeuble];
                this.all_immeublesd = [this.modele.immeuble.id];
                    this.bounce_immeuble = true;
                    if(this.modele.immeuble.id){
                        this.getImmeubleById(this.modele.immeuble.id)
                    }

        } else if (this.modele.immeuble_id) (
            this.getImmeubleById(this.modele.immeuble_id)
        )

        setTimeout(() => {
                    this.bounce_immeuble = false;
                }, 2000);
    },
    methods:{

        goImm(){
            this.$router.push({ name: "Immeuble", params: { id: this.imm_id } });

        },
        getFromPerso(p){
            console.log(p)
        },
        async getImmeuble(p){
                this.loading.immeuble =true;
                let params = {
                    ...(p ? { search: p } : {}),
                    page: 1,
                    size: 20,
                    sort: "events.last",
                };
                console.log(params)
                await this.$store.dispatch("immeuble/immeublesList", params);
                this.all_immeubles = this.$store.getters["immeuble/immeublesList"].data;
                this.all_immeublesd = [];
                    this.all_immeubles.forEach(imm => {
                        this.all_immeublesd.push(imm.id);
                    });
                this.loading.immeuble =false;
                console.log(this.all_immeubles)
        },
        async getImmeubleById(pid){
            await this.$store.dispatch('immeuble/getImmeubleMini', pid);
            let immeuble = this.$store.getters["immeuble/oneImmeubleMini"]
            console.log("IMM_LOG")
            console.log(immeuble)
            this.imm_name = immeuble.nom;
            this.imm_id = immeuble.id;
            this.all_immeubles = [immeuble];
        }
    }
}
</script>
