<template>
  <li class="nav-item" v-if="to">
    <router-link class="nav-link" :to="to">
      <slot name="icon" class="text-center"></slot>
      <span class="sidenav-mini-icon" v-html="miniIcon"> </span>
      <span class="sidenav-normal"> {{ text }} </span>
    </router-link>
  </li>
  <li class="nav-item" v-else>
    <div class="nav-link">
      <slot name="icon" class="text-center"></slot>
      <span class="sidenav-mini-icon" v-html="miniIcon"> </span>
      <span class="sidenav-normal"> {{ text }} </span>
    </div>
  </li>
</template>
<script>
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    miniIcon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
