/* eslint-disable no-unused-vars */
import eventService from "../services/event.service.js";

export const event = {
    namespaced: true,
    state: {
        eventsList: null,
        oneEvent: null,
        image: null,
        ia: null,
        oneFile: null,
    },
    getters: {
        eventsList: state => state.eventsList,
        oneEvent: state => state.oneEvent,
        image: state => state.image,
        ia: state => state.ia,
        oneFile: state => state.oneFile,
    },
    actions: {
        async eventsList({ commit }, params) {
            console.log("action")
            const data = await eventService.getListEvents(params);
            console.log(data);
            commit('EVENTS_LIST', data);
        },
        async getEvent({ commit }, itemId) {
            const data = await eventService.getEvent(itemId);
            commit('GET_EVENT', data);
        },
        async getFile({ commit }, itemId) {
            const data = await eventService.getFile(itemId);
            commit('GET_FILE', data);
        },
        async ia_task({ commit }, params) {
            console.log("action")
            const data = await eventService.ia_task(params);
            console.log(data);
            commit('IA', data);
        },

        async eventsListF({ commit }, params) {
            console.log("action")
            const data = await eventService.getListEventsF(params);
            console.log(data);
            commit('EVENTS_LIST', data);
        },
        async eventsListR({ commit }, params) {
            console.log("action")
            const data = await eventService.getListEventsR(params);
            console.log(data);
            commit('EVENTS_LIST', data);
        },
        async getEventF({ commit }, itemId) {
            const data = await eventService.getEventF(itemId);
            commit('GET_EVENT', data);
        },
        async addEvent({ commit }, item) {
            const data = await eventService.createEvent(item);
            commit('GET_EVENT', data);
        },
        async addTicket({ commit }, item) {
            const data = await eventService.createTicket(item);
            commit('GET_EVENT', data);
        },
        async editEvent({ commit }, item) {
            await eventService.editEvent(item);
        },
        async deleteEvent({ commit }, itemId) {
            await eventService.deleteEvent(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await eventService.uploadImageEvent(item);
            commit('UPLOAD_IMAGE_EVENT', data);
        },
    },
    mutations: {
        EVENTS_LIST(state, data) {
            state.eventsList = data;
        },
        GET_EVENT(state, data) {
            state.oneEvent = data;
        },
        UPLOAD_IMAGE_EVENT(state, data) {
            state.image = data;
        },
        IA(state, data) {
            state.ia = data;
        },
        GET_FILE(state, data) {
            state.oneFile = data;
        },
  }
}
