<template >
    <div v-if="options.type =='ButtonTop'">
     
        <div style="display: flex;justify-content: center;">
            <div class="btn-group" role="group">
                <button v-for="key in options.items.filter(it => it.name)" :key="key.id" 
                 class="btn btn-light" @click="modele[showTabName] =key" 
                 :disabled="blocking.find(el=>el==='all'||el==='tab')"
                 :style="
                    modele[showTabName] ? (
                        (JSON.stringify(modele[showTabName])===JSON.stringify(key) ) ? 
                            'color:'+key.color+';background-color:'+key.background_color
                        : '')
                    : (
                        key.default === true ? 
                            'color:'+key.color+';background-color:'+key.background_color
                        : ''
                    )
                 ">
                    <i :class="key.icon "></i>
                    {{ key.name }}
                </button>
            </div>
        

        </div>
        <div v-for="key in options.items" :key="key.id"   style="">
            <div v-if="key.type == 'ButtonTop'">
                <Block v-if="JSON.stringify(modele[showTabName])===JSON.stringify(key)" :options="key" v-model="modele" :action="action" :blocking="blocking"></Block>

            </div>
            <Block v-else :options="key" v-model="modele" :action="action" :blocking="blocking"></Block>
        </div>
        <div v-if="options.send_button" class="text-end">
            <button class="btn btn-primary" @click="action" :disabled="blocking.find(el=>el==='all'||el==='send')">{{ options.send_button.label }}</button>

        </div>
    </div>
    <div v-else-if="options.type == 'column'" class="row" >
            <Block v-for="item in options.items" :key="item.id"  :options="item" v-model="modele" :action="action" :blocking="blocking" :class="item.size ?'col-lg-'+item.size : ''"></Block>
       

    </div>
    <div v-else-if="options.type =='row'" class="row">

        <h3>{{ options.label }}</h3>
        <div v-for="item in options.items" :key="item.id" :class="item.size ?'col-md-'+item.size : ''">
            <div class="row">
                
            <div v-for="sub_item in item.items" :key="sub_item.id"   :class="sub_item.size ?'col-md-'+sub_item.size : ''">
                <Block :options="sub_item" v-model="modele" :action="action" :blocking="blocking"></Block>

            </div>
            </div>
        </div>

    </div>
    <div v-else-if="options.type=='Immeuble'">
        <Immeuble :options="options"  v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type=='Perso'">
        
        <Perso :options="options"  v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type=='Destinataire'">
        
        <Destinataire :options="options"  v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type=='Lot'">
        <Lot :options="options"  v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type=='Input'">
        <BasicInput  :options="options"  v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type=='Button'">
        <BasicButton  :options="options"  v-model="modele" :blocking="blocking" :action="action"/>
    </div>
    <div v-else-if="options.type=='Select'">
        <BasicSelect  :options="options"  v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type=='Date'">
        <BasicDate  :options="options"  v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type=='RichText'">
        <RichText :options="options" v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type == 'PDFView'">
        <PDFView :options="options" v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type == 'DropZone'">
        <DropZone :options="options" v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type == 'DropZoneRead'">
        <DropZoneRead :options="options" v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type == 'Fournisseur'">
        <Fournisseur :options="options" v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type == 'Fournisseurs'">
        <Fournisseurs :options="options" v-model="modele" :blocking="blocking"/>
   
    </div>
    <div v-else-if="options.type == 'CodeAcces'">
        <CodeAcces :options="options" v-model="modele" :blocking="blocking"/>
    </div>
    <div v-else-if="options.type =='SwitchList'" >
        <div class="bg-light border-radius-lg p-2 mb-1" >
        <div class="bg-light border-radius-lg p-2 text-dark text-sm">{{ options.name }}</div>
        
        <div v-for="item in options.items" :key="item.name"  >
                <Block :options="item" v-model="modele" :action="action" :blocking="blocking"></Block>

        </div>

        </div>

    </div>
    <div v-else-if="options.type=='Switch'">
        
        <BasicSwitch :options="options" v-model="modele" :action="action" :blocking="blocking"/>
            <div v-if="modele[options.prop]">
        
                <Block v-for="item in options['active-items']" :key="item.name"  v-model="modele" :options="item" :blocking="blocking"/>
                
        


            </div>

    </div>
    <div v-else-if="options.type=='Radio'">
        <BasicRadio :options="options" v-model="modele" :action="action" :blocking="blocking"/>
  <div v-if="modele[options.prop] == options.label">
    <Block v-for="item in options['active-items']" :key="item.id"  v-model="modele" :options="item" :action="action" :blocking="blocking"/>

  </div>

    </div>
    <div v-else-if="options.type=='View2'">
        View
    </div>

</template>
<script>
import Immeuble from './Controls/Immeuble.vue';
import RichText from './Controls/RichText.vue';
import PDFView from './Controls/PDFView.vue';
import Perso from './Controls/Perso.vue';
import Destinataire from './Controls/Destinataire.vue';
import Lot from './Controls/Lot.vue';
import BasicInput from './Controls/BasicInput.vue';
import DropZone from './Controls/DropZone.vue';
import Fournisseur from './Controls/Fournisseur.vue';
import Fournisseurs from './Controls/Fournisseurs.vue';
import BasicSwitch from './Controls/BasicSwitch.vue';
import BasicRadio from './Controls/BasicRadio.vue';
import CodeAcces from './Controls/CodeAcces.vue';
import BasicDate from './Controls/BasicDate.vue';
import BasicSelect from './Controls/BasicSelect.vue';
import BasicButton from './Controls/BasicButton.vue';
import DropZoneRead from './Controls/DropZoneRead.vue';
export default {
name: "Block",
components: {
    BasicInput, Immeuble, Perso,Destinataire, Lot, RichText, PDFView, Fournisseur, Fournisseurs,
    DropZone,
    BasicSwitch,
    BasicRadio,
    CodeAcces,
    BasicDate,
    BasicSelect,
    BasicButton,
    DropZoneRead
},
data() {
    return {
        showTab: null,
        showTabName: 'showTab',
        modele:this.modelValue
    }
},
props: {
    modelValue: {
        type: Object,
        default: () => { 
        }
    },
    options: {
        type: Object,
        default: () => { }
    },
    action: {
        type: Function,
        default: () => { }
    },
    blocking: {
        type: Array,
        default: () => []
    },
},
mounted(){
    this.modele[this.showTabName] = false
        console.log(this.options)
        console.log(this.options.showTabName)
        if(this.showTabName === null){
            this.showTabName = 0
        }
        console.log(this.modele[this.showTabName])
    if (this.options.showTabName){
        this.showTabName = this.options.showTabName
    }

    this.showTabIN=  -1;
    if(this.options.items ){
    this.showTabIN= -1;
            this.options.items.forEach(element => {
                console.log("E")
                console.log(this.modele[this.showTabName])
                console.log(element)
                if (this.modele[this.showTabName] && element.name == this.modele[this.showTabName].name){
                    this.showTabIN = this.options.items.indexOf(element)
                }
            })

        if(this.showTabIN ===-1 ){
            this.options.items.forEach(element => {

        if (element.default){
        if(this.showTabName === null){
            this.showTabName = 0
        }
                this.modele[this.showTabName] = element
        }
    });

        } }
        this.$emit('update:modelValue', this.modele)
},
watch:{
    showTab: function (val) {
        //this.modele.showTab = this.options.items[val].id
        console.log(val)
        this.$emit('update:modelValue', this.modele)
    },
    modelValue: function (val) {
        this.modele = val
    },
    modele: function (val) {
        this.$emit('update:modelValue', val)
    },
},

computed: {},
}
</script>