<template>
    <div class="mb-1">
        <!--op:{{ options }}-->
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
        <div  class="bg-gray border-radius-lg p-2" style="display: flex;">
        <div class="bg-gray border-radius-lg p-2 text-white text-sm pr-3" >{{ options.name }}</div>
        <div class="bg-gray border-radius-lg p-1 text-bold text-white w-100" style="margin-top: 2px ;font-weight: 700;">{{ lot_name }}</div>
        <button @click="goLot()" class="btn btn-xs btn-white mb-0"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>
    </div>
    </div>
    <div v-else class="bg-gray border-radius-lg p-2" style="display: flex;">
        <div class="bg-gray border-radius-lg p-2 text-white text-sm">{{ options.name }}</div>
        

    <el-select
     class="mt-1 w-100" v-model="modele[options.prop]" filterable remote reserve-keyword placeholder="Merci d'entrer un lot"
                    remote-show-suffix :remote-method="getImmeuble" :loading="loading.lot" >
                    <el-option v-for="item in all_lots" :key="item.id" :label="item.EXTAPI_LOT_ID +' - '+ item.typedescr.texte+' - Étage ' + item.etage" :value="item.id" />
                </el-select>
        </div>
        </div>
</template>
<script>
//import {ArgonInput} from "@/components/ArgonInput.vue";
import {ElSelect, ElOption} from "element-plus";
export default {
    name: "Lot",
    components: {
       // ArgonInput,
        ElSelect,ElOption,
    },
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: ()=>[]
        },
    },
    data() {
        return {
            modele: this.modelValue,
            lot_name:'',

            all_lots:[],
            loading:{
                lot:true,
            },
            disabled: {
                perso:false,
                immeuble:false,
                lot:false
            },
            default_lots:[]
        }
    },
    computed:{
        immeuble_id(){
            return this.modele.immeuble_id
        },
        perso_id(){
            return this.modele.perso_id
        }
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        },
        perso_id: function (val) {
            if(val){
                this.getFromOthers(val);
            }
        },
        immeuble_id:async function(val){
            if(val){
                await this.getFromBuilding(val);
            }
        }
    },
    created(){

        if (this.modele.lot){
        this.all_lots = [this.modele.lot];
                this.all_lotsd = [this.modele.lot.id];
                    this.bounce_lot = true;
                    if(this.modele.lot.id){
                        this.getLotById(this.modele.lot.id)
                    } 

        } else if (this.modele.lot_id) (
            this.getLotById(this.modele.lot_id)
        )
    },
    methods:{
        goLot(){
            this.$router.push({name:'Lot',params:{id:this.modele.lot_id}})
        },
        async getFromBuilding(imm,pers=null){
            await this.$store.dispatch('lot/lotsList',{perso_id:pers,immeuble_id:imm})
            this.default_lots = this.$store.getters["lot/lotsList"].data

        },

        async getLotById(pid){
            await this.$store.dispatch("lot/getLot", pid);
            this.lot_tmp = this.$store.getters["lot/oneLot"];
            this.perso_id = this.lot_tmp.id;
           
            this.lot_name = this.lot_tmp.EXTAPI_LOT_ID +' - '+ this.lot_tmp.typedescr.texte+' - Étage ' + this.lot_tmp.etage

        },
        async getFromOthers(pers){
            this.all_lots = []
            var found = false
            console.log(this.default_lots)
            this.loading.lot = true
            await this.getFromBuilding(this.modele.immeuble_id,pers)

            
            this.default_lots.forEach(lot => {
                found = false
                if(lot.perso_id == pers){
                    found = true
                } else {
                     /*lot.locataires.forEach(
                        loc => {
                            if(loc.perso.id == pers){
                    found = true
                                
                            }
                        }
                    )
                   
                    lot.perso.imm_links.forEach(
                        imm_link => {
                            if(imm_link.perso &&  imm_link.perso.id == pers){
                    found = true
                            }
                        }
                    )*/

                }
                if (found) {
                    this.all_lots.push(lot)
                }
            });
            this.loading.lot = false
            
        },
    }
}
</script>
<style>
.el-input__wrapper{
    border-radius: 10px!important;
}
</style>