<template>
    <div class="mb-1">
        <!--op:{{ options }}-->
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
    <div  class="bg-yellow border-radius-lg p-2" style="display: flex;">
        <div class="bg-yellow border-radius-lg p-2 text-dark text-sm pr-3" >{{ options.name }}</div>
        <div class="bg-yellow border-radius-lg p-1 text-bold text-dark w-100" style="margin-top: 2px ;font-weight: 700;">{{ perso_name }}</div>
        <button @click="goPerso()" class="btn btn-xs btn-white mb-0"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>
    </div>
    </div>
    <div v-else class="bg-yellow border-radius-lg p-2 text-end" >
        <Modal v-model="addTiers" closeable header="Ajouter un tiers">
            <div class="row">
                <div class="col-lg-4">
                    <ArgonRadio v-model="tiers.civilite.madame"
                                  name="civilite"
                                  id="madame"
                                  class="mb-3">
                        Madame
                    </ArgonRadio>
                    <ArgonRadio v-model="tiers.civilite.monsieur"
                                  name="civilite"
                                    id="monsieur"
                                  class="mb-3">
                        Monsieur
                    </ArgonRadio>
                </div>
                <div class="col-lg-4">
                    <ArgonInput label="Nom" placeholder="Nom"  v-model="tiers.nom"/>
                </div>
                <div class="col-lg-4">
                      <ArgonInput label="Prénom" placeholder="Prénom"  v-model="tiers.prenom"/>
                </div>
                <div class="col-lg-6">
                    <ArgonInput label="Mail" placeholder="Mail"  v-model="tiers.mail"/>
                </div>
                <div class="col-lg-6">
                      <ArgonInput label="Téléphone" placeholder="Téléphone"  v-model="tiers.phone"/>
                </div>



                <div class="col-lg-6">
                      <ArgonInput label="Adresse 1" placeholder="Adresse 1"  v-model="tiers.adresse1"/>
                </div>
                <div class="col-lg-6">
                      <ArgonInput label="Adresse 2" placeholder="Adresse 2"  v-model="tiers.adresse2"/>
                </div>
                <div class="col-lg-3">
                      <ArgonInput label="CP" placeholder="CP"  v-model="tiers.cp"/>
                </div>
                <div class="col-lg-9">
                      <ArgonInput label="Ville" placeholder="Ville"  v-model="tiers.ville"/>
                </div>

                <div class="col-lg-6">
                    <ArgonSwitch v-model="tiers.idem_syndic">Le tiers réside dans l'immeuble que vous gérez</ArgonSwitch>
                     
                </div>
                <div class="col-lg-3" v-if="tiers.idem_syndic">
                      <ArgonInput label="Immeuble" placeholder="Immeuble"  v-model="tiers.immeuble"/>
                </div>
                <div class="col-lg-3" v-if="tiers.idem_syndic">
                        <el-select v-model="tiers.lot" filterable remote reserve-keyword placeholder="Merci d'entrer un lot"
                            :loading="loading.lot">
                            <el-option v-for="item in show_lots" :key="item.id" :label="item.EXTAPI_LOT_ID +' - '+ item.typedescr.texte+' - Étage ' + item.etage" :value="item.id" />
                        </el-select>
                </div>
                <div class="col-lg-6" v-if="!tiers.idem_syndic">
                      <ArgonInput label="Nom du Syndic gérant l'immeuble" placeholder="Nom du Syndic"  v-model="tiers.syndic"/>
                </div>
                <div class="col-lg-3" v-if="!tiers.idem_syndic">
                      <ArgonInput label="Immeuble" placeholder="Immeuble"  v-model="tiers.immeuble"/>
                </div>
                <div class="col-lg-3" v-if="!tiers.idem_syndic">
                      <ArgonInput label="Etage" placeholder="Etage"  v-model="tiers.etage"/>
                </div>
                <div class="col-lg-3" v-if="!tiers.idem_syndic">
                      <ArgonInput label="Numéro de lot" placeholder="Numéro de lot"  v-model="tiers.num_appart"/>

                </div>
                <div class="col-lg-6">
                      <ArgonInput label="Gérant locatif" placeholder="Gérant locatif"  v-model="tiers.gerant"/>
                </div>
                <br/>
                <div class="col-lg-6">
                      <ArgonInput label="Compagnie d'assurance" placeholder="Compagnie d'assurance"  v-model="tiers.comp_assurance"/>
                </div>
                <div class="col-lg-6">
                      <ArgonInput label="Numéro de police Assurance" placeholder="Numéro de police Assurance"  v-model="tiers.num_police_assurance"/>
                </div>
                      
                      





                
                
            </div>
            <div style="display: flex;justify-content: end;">
                <button class="btn btn-success" @click="ajoutTiers();  ">Ajouter</button>
            </div>
                
        </Modal>
        <div style="display: flex;">
        <div class="bg-yellow border-radius-lg p-2 text-dark text-sm">{{ options.name }}</div>
        

        <el-select
            class="mt-1 w-100" v-model="modele[options.prop]" filterable remote reserve-keyword placeholder="Merci d'entrer un déclarant"
                    remote-show-suffix :remote-method="getImmeuble" :loading="loading.perso" :disabled="disabled.perso">
                    <el-option v-for="item in all_persos" :key="item.id" :label="item.nom +' '+item.prenom" :value="item.id" />
                </el-select>
        </div>
        <button class="btn btn-xs mb-0 btn-white" @click="addTiers = true"><i class="fa fa-plus"></i> Ajouter un tiers </button>


    </div>    </div>
</template>
<script>
//import {ArgonInput} from "@/components/ArgonInput.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonRadio from "@/components/ArgonRadio.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import Modal from "@/components/Modal.vue";
import {ElSelect, ElOption} from "element-plus";
export default {
    name: "Immeuble",
    components: {
    // ArgonInput,
    ElSelect, ElOption, Modal,
    ArgonInput,
    ArgonRadio,ArgonSwitch
},
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: ()=>[]
        },
    },
    data() {
        return {
            addTiers:false,
            tiers:{
                civilite:{
                    madame:false,
                    monsieur:false
                },
                nom:'',
                prenom:'',
                mail:'',
                phone:'',
                adresse1:'',
                adresse2:'',
                cp:'',
                ville:'',
                idem_syndic:true,
                syndic:'',
                immeuble:'',
                etage:'',
                num_appart:'',
                gerant:'',
                comp_assurance:'',
                num_police_assurance:'',
                lot:''
            },
            modele: this.modelValue,
            all_persos:[],
            perso_name:'',
            perso_id:'',
            loading:{
                perso:true,
                immeuble:true,
            },
            disabled: {
                perso:false,
                immeuble:false,
                lot:false
            }
        }
    },
    computed:{
        immeuble_id(){
            return this.modele.immeuble_id
        }
    },
    watch: {
        modele: async function (val) {
              await this.getPersoById(this.modele.perso.id)
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        },
        immeuble_id: function () {
            this.getFromImmeuble()
        }
    },
    mounted(){

        if (this.modele.perso){
            this.all_persos = [this.modele.perso];
            this.all_persosd = [this.modele.perso.id];
            this.bounce_perso = true;
            if (this.modele.perso.id){
                this.getPersoById(this.modele.perso.id)
            }
        } else if(this.modele.perso_id){

            this.getPersoById(this.modele.perso_id)
        } else if (this.modele.immeuble_id){
            this.getFromImmeuble()
        }
    },
    methods:{
        goPerso(){
        this.$router.push({
          name: "Perso",
          params: { id: this.perso_id },
        });
        },
        
        async getFromImmeuble(){
            let immeuble =  this.modele.immeuble_id
            this.loading.perso =true;
            let params = {
                immeuble_id: immeuble,
                page: 1,
                size: 20,
                //sort: "events.last",
            };
            console.log(params)
            await this.$store.dispatch("perso/persosFromBuilding", params);
            this.all_persos = JSON.parse(JSON.stringify(this.$store.getters["perso/persosFromBuilding"].data));
            console.log("P")
            console.log(this.all_persos)
            this.all_persosd = [];
                this.all_persos.forEach(imm => {
                    this.all_persosd.push(imm.id);
                });
            this.loading.perso =false;
        },

        async getPerso(p){
                this.loading.perso =true;
                let params = {
                    ...(p ? { search: p } : {}),
                    page: 1,
                    size: 20,
                    //sort: "events.last",
                };
                console.log(params)
                await this.$store.dispatch("perso/persosList", params);
                this.all_persos = this.$store.getters["perso/persosList"].data;
                this.all_persosd = [];
                    this.all_persos.forEach(imm => {
                        this.all_persosd.push(imm.id);
                    });
                this.loading.persos =false;
                console.log(this.all_persos)
        },

        async getPersoById(pid){
            await this.$store.dispatch("perso/getPerso", pid);
            this.perso_tmp = this.$store.getters["perso/onePerso"];
            this.perso_id = this.perso_tmp.id;
            this.perso_name = this.perso_tmp.nom + ' ' + this.perso_tmp.prenom;

        }
    }
}
</script>
<style>
.el-input__wrapper{
    border-radius: 10px!important;
}
</style>