import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListlots(params){
  const response =  await axios.get(`${API_URL}/lots`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListlotsFromBuilding(params){
  let immeuble_id = params.immeuble_id;
  const response =  await axios.get(`${API_URL}/immeubles/${immeuble_id}/coproprietaires`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data, meta: response.data.meta};
}

async function getlot(lotId){
  const response =  await axios.get(`${API_URL}/lots/${lotId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editlot(lot){

  const payload = jsona.serialize({
    stuff: lot,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/lots/${lot.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createlot(lot){

  const payload = jsona.serialize({
    stuff: lot,
    includeNames: []
  });

  const response =  await axios.post(`${API_URL}/lots?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function deletelot(lotId){
  await axios.delete(`${API_URL}/lots/${lotId}`, {headers: authHeader()});
}

async function uploadImagelot(lot) {
    const payload = new FormData();
    payload.append("attachment", lot.image);
  
    const response = await axios.post(`${API_URL}/uploads/lots/${lot.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListlots,getListlotsFromBuilding,
    getlot,
    editlot,
    createlot,
    deletelot,
    uploadImagelot
};