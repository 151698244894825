<!--
=========================================================
* MyCopro par PYC PRODUCTIONS v1.1.0
=========================================================


=========================================================
* Vue Argon Dashboard 2 PRO - v4.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->

<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
  <sidenav v-if="showSidenav && !hideDefaultLayout" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <ActivityBar v-if="showSidenav && !hideDefaultLayout" />
    <Notifications />
    <router-view />
    <app-footer v-show="showFooter && !hideDefaultLayout" />
    <configurator
      v-if="!hideDefaultLayout"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapState } from "vuex";
import ActivityBar from "./views/collaborateur/ActivityBar.vue";
import Notifications from '@kyvg/vue3-notification'


export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    AppFooter,
    ActivityBar,Notifications
  },
  computed: {
    ...mapState([
      "layout",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
    hideDefaultLayout() {
      return this.$route.meta.hideDefaultLayout === true;
    },
  },
};
</script>
