<template>
  <div class="py-1 container-fluid">



    <div class="row">
      <div class="col-12">
        <div class="bg-yellow p-1 border-radius-xl d-flex">
          <h6 class="mb-0 text-dark px-2">Liste des résidents</h6>
          <div style="flex:1"></div>
          <button class="btn btn-xs btn-round btn-white mb-0" v-if="rights.includes('export_mailing')" @click="export_mailing">
            <i class="fa fa-envelope me-1"></i> Export
          </button>
        </div>
        <div class="container text-center mb-2">
          <div class="mt-2">
            <div>

  <div class="form-group form-group-default" >
    <div class="input-group">
      <span  class="input-group-text ">
        <i class="fa fa-search"></i>
      </span>
      <input type="text" class=" form-control form-control-default" placeholder="Rechercher" v-model="search" @keyup="autoSuggest" @keydown.tab="fillAutocomplete" />

    </div>
  </div>

         <!--  <input type="text" name="email" class="custom-input"  v-model="search" @keyup="autoSuggest" @keydown.tab="fillAutocomplete" />
 -->

            </div>

           <!-- <ArgonInput icon="fa fa-search" iconDir="left" placeholder="Rechercher" v-model="search"/>-->
            <div class="row">
                <div class="col-sm-4">
              <el-select
                style="color: red;"
                class="select-primary pagination-select"
                v-model="sort"
                placeholder="Tri"
                name="Tri"
                size="small"
              >
                <el-option
                  class="select-primary"
                  key="EXTAPI_IMM_ID"
                  label="Trier par référence croissante"
                  value="EXTAPI_IMM_ID"
                />
                <el-option
                  class="select-primary"
                  key="nom"
                  label="Trier par nom croissant"
                  value="nom"
                />
                <el-option
                  class="select-primary"
                  key="events.last"
                  label="Trier par dernier évènement"
                  value="events.last"
                />
              </el-select></div>
              <div class="col-sm-3">
              <el-select
                class="select-primary pagination-select"
                v-model="onlyme"
                placeholder="Affichage"
                size="small"
                filterable
              >
                <el-option
                  class="select-primary"
                  key="Seulement mes immeubles"
                  label="Seulement mes immeubles"
                  value="Seulement mes immeubles"
                />
                <el-option
                  class="select-primary"
                  key="Tous les immeubles actifs du parc"
                  label="Tous les immeubles actifs du parc"
                  value="Tous les immeubles actifs du parc"
                />
                <el-option
                  class="select-primary"
                  key="Tous les immeubles du parc"
                  label="Tous les immeubles du parc"
                  value="Tous les immeubles du parc"
                />
                <el-option
                  v-for="coll in collabs"
                  class="select-primary"
                  :key="coll.id"
                  :label="coll.name"
                  :value="coll.id"
                />
              </el-select></div>
              <div class="col-sm-3">
              <el-select
                class="select-primary pagination-select"
                v-model="qualite"
                placeholder="Qualite"
                size="small"
                filterable
              >
                <el-option
                  class="select-primary"
                  key="Toutes les qualités"
                  label="Toutes les qualités"
                    value="Toutes les qualités"
                />
                <el-option
                  class="select-primary"
                    key="Propriétaire"
                    label="Propriétaire"
                    value="proprietaire"
                />
                <el-option
                  class="select-primary"
                  key="Copropriétaire"
                    label="Copropriétaire"
                    value="coproprietaire"
                />
                <el-option
                  class="select-primary"
                    key="Locataire"
                    label="Locataire"
                    value="locataire"
                />
              </el-select></div>

              <div class="col-sm-2">
              <el-select
                style="color: red;"
                class="select-primary pagination-select"
                v-model="city"
                placeholder="Code Postal"
                name="Code Postal"
                size="small"
                filterable
                allow-create
                no-data-text="Merci d'entrer un code postal"
              >
                <el-option
                  class="select-primary"
                  key="Tous les codes postaux"
                  label="Tous les codes postaux"
                  value="Tous les codes postaux"
                />
              </el-select></div>
            </div>
          </div>

        </div>


        <div class="card">
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive" id="infinite-list" style="height:calc(100vh - 200px)" @scroll="handleScroll">
              <table class="table table-responsive">
                <thead class="thead-light text-xs">
                  <tr>
                    <th title="civilite">Civilite</th>
                    <th title="nom">Nom Prénom</th>
                    <th title="nom">Email 1</th>
                    <th title="nom">Téléphone 1</th>

                    <th title="immeuble">Immeuble</th>
                  </tr>
                </thead>
                <tbody class="text-xs">
                  <tr v-for="perso in processedRoleList" :key="perso.id" @click="openResident(perso)">
                    <td class="text-danger">{{ perso.civilite }}</td>
                    <td style="flex:1">{{ perso.nom }} {{ perso.prenom }}</td>
                    <td style="flex:1">{{ perso.mails[0]?.mail }}</td>
                    <td style="flex:1">{{ perso.phones[0]?.phone }}</td>
                    <td>
                      <a v-for="i in perso.imm_aff" :key="i" class="badge badge-xs badge-danger" @click="goImm(i)">{{ i.nom }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import _ from 'lodash';
//import ArgonInput from "../../../components/ArgonInput.vue";
import Swal from 'sweetalert2';

const store = useStore();
const router = useRouter();

const search = ref('');
const page_t = ref(1);
const rights = ref([]);

const sortDirection = ref("nom");
const getCurrentPage = ref(1);
const qualite = ref("Toutes les qualités");
/*
const filteredMail = ref([]);

const domains = ['gmail.com', 'outlook.fr', 'outlook.com', 'icloud.com', 'orange.fr','free.fr','wanadoo.fr','hotmail.fr','hotmail.com','laposte.net','club-internet.fr','sfr.fr', 'yahoo.com', 'yahoo.fr','live.fr','numericable.fr','bbox.fr','aliceadsl.fr','noos.fr'];
*/
const suggestions = ['gmail.com', 'outlook.fr', 'outlook.com', 'icloud.com', 'orange.fr','free.fr','wanadoo.fr','hotmail.fr','hotmail.com','laposte.net','club-internet.fr','sfr.fr', 'yahoo.com', 'yahoo.fr','live.fr','numericable.fr','bbox.fr','aliceadsl.fr','noos.fr'];
const autoCompPlaceholder = ref('');
const fillAutocomplete = () => {
      search.value = search.value + autoCompPlaceholder.value;
      autoCompPlaceholder.value = '';
    }
const autoSuggest = () =>{
      var input = search.value;
      autoCompPlaceholder.value = '';
      if(input.includes('@')) {
        var index = input.indexOf('@');
        if(input[index+1])
        {
        	var inputToAutocomplete = input.substring(index+1);
          var flag = false;
          suggestions.forEach(function (suggestion) {
            if(flag) return;
            if(suggestion.indexOf(inputToAutocomplete) == 0) {
              autoCompPlaceholder.value = autoCompPlaceholder.value + suggestion.substring(inputToAutocomplete.length);
              flag = true;
            }
          });
        }
      }
    }

const export_mailing = () => {
    // new Swal with export options
    new Swal({
        title: "Export des résidents",
        icon: "info",
        text:"Importer le CSV en UTF8 dans Excel",
        // type syndic, gerance, tous
        input: "select",
        inputOptions: {
            syndic: "Syndic",
            gerance: "Gérance",
            tous: "Tous",
        },
        inputPlaceholder: "Version",
        showCancelButton: true,
        confirmButtonText: "Exporter",

    }).then((value) => {
        if (value.value == "syndic" || value.value == "gerance" || value.value == "tous") {
            new Swal({
                title:"Numéro de syndic ?",
                input: "text",
                showCancelButton: true,
                confirmButtonText: "Exporter",
            }).then((syndic_id) => {
                if(syndic_id.value){
                    store.dispatch("perso/exportPersos", {
                        type: value.value,
                        syndic_id:syndic_id.value
                    });
                }

            })
            /*
            new Swal({
                title: "Export en cours",
                icon: "info",
                text: "Veuillez patienter...",
                showConfirmButton: false,
            });



           store.dispatch("perso/exportPersos", {
                type: value,
                syndic_id:1
            });*/
        }
    });
/*
    store.dispatch("perso/exportPersos", {
        type:"syndic",
        syndic_id:1

    });*/
}


const goImm = (id) => {
  router.push({
    name: "Immeuble",
    params: { id }
  });
};

const getRoleList = _.debounce(async (params) => {
  if (params.query.length > 3) {
    await store.dispatch("perso/persosList", {
      ...(params.sort ? { sort: params.sort } : {}),
      ...(qualite.value == 'Toutes les qualités' ? {} : { qualite: qualite.value }),
      ...(params.query ? { search: params.query } : {}),
      page: params.nr,
      size: 25,
    });
  }
}, 300);

const updatedList = ref([])

const getList = () => {
  if (store.getters["perso/persosList"]) {
    if (store.getters["perso/persosList"].meta.current_page === 1) {
      updatedList.value = store.getters["perso/persosList"].data;
    } else {
      updatedList.value = [...updatedList.value, ...store.getters["perso/persosList"].data];
    }
  }
  return updatedList.value;
};


const processedRoleList = computed(() => {
    let roleList =getList();

  return roleList.map(personn => {
    const imm_aff = personn.imm_links.reduce((acc, imm) => {
      if ( imm.immeuble_mini && !acc.includes(imm.immeuble_mini)) {
        if(imm.actif > 0 || (imm.bail_actif && imm.bail_actif.date_fin_bail_effective === null)){
            if(acc.findIndex((i) => i.id === imm.immeuble_mini.id) === -1){
                acc.push(imm.immeuble_mini);
            }

        }
      }
      return acc;
    }, []);
    return { ...personn, imm_aff };
  });
});

const metaPage = computed(() => store.getters["perso/persosList"]?.meta);

watch(metaPage, (newVal) => {
  if (newVal) {
    getCurrentPage.value = newVal.current_page;
  }
}, { deep: true });

watch(search, () => {
  reactiveSearch();
});

onMounted(async () => {
  const masonry = document.querySelector('#infinite-list');
  masonry.addEventListener('scroll', handleScroll);
  let r = sessionStorage.getItem('rights');
    if(r){
        rights.value = JSON.parse(r);
    }

  await getRoleList({
    sort: sortDirection.value,
    query: '',
    nr: getCurrentPage.value,
    perpage: 15
  });
});

const handleScroll = (e) => {
  const masonry = e.target;
  if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
    if (metaPage.value.current_page === 1) {
      page_t.value = 1;
    }
    if (page_t.value <= metaPage.value.current_page && page_t.value <= metaPage.value.last_page) {
      page_t.value += 1;
      getDataFromPage(page_t.value);
    }
  }
};

const openResident = (row) => {
  router.push({
    name: "Perso",
    params: { id: row.id }
  });
};

const getDataFromPage = async (page) => {
  await getRoleList({
    sort: sortDirection.value,
    query: search.value,
    nr: page,
    perpage: 15
  });
};

const reactiveSearch = async () => {
  await getRoleList({
    sort: sortDirection.value,
    query: search.value,
    nr: 1,
    perpage: 15
  });
};
</script>

<style scoped>
.dataTable-top {
  display: none;
}
.custom-input {
  width: 70%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px 20px;
  margin: 8px;
}
.virtual-text{
  width: auto;
  height: 100%;
  position: absolute;
  top: 105px;
  left: 28px;
  overflow: hidden;
}
.hidden-text {
  width: auto;
  visibility: hidden;
  /* border: 1px solid green; */
  float: left;
  font-size: 15px;
}
.autocomplete {
  color: silver;
  z-index: 100;
  background-color: transparent;
  width: auto;
  border-bottom: none;
  float: left;
  outline: none;
  border: none;
  /* border: 1px solid red; */
  line-height: 1.5;
  padding: 12px 0px;
  margin: 8px 2px;
}

</style>
