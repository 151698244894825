import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListbails(params){
  const response =  await axios.get(`${API_URL}/baux`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListbailsFromBuilding(params){
  let immeuble_id = params.immeuble_id;
  const response =  await axios.get(`${API_URL}/immeubles/${immeuble_id}/baux`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data, meta: response.data.meta};
}

async function getbail(bailId){
  const response =  await axios.get(`${API_URL}/baux/${bailId}`, {headers: authHeader()});
  return response.data;
}

async function editbail(bail){

  const payload = jsona.serialize({
    stuff: bail,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/bails/${bail.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createbail(bail){

  const payload = jsona.serialize({
    stuff: bail,
    includeNames: []
  });

  const response =  await axios.post(`${API_URL}/bails?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function deletebail(bailId){
  await axios.delete(`${API_URL}/bails/${bailId}`, {headers: authHeader()});
}

async function uploadImagebail(bail) {
    const payload = new FormData();
    payload.append("attachment", bail.image);
  
    const response = await axios.post(`${API_URL}/uploads/bails/${bail.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListbails,getListbailsFromBuilding,
    getbail,
    editbail,
    createbail,
    deletebail,
    uploadImagebail
};