import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListControleurs(params){
  const response =  await axios.get(`${API_URL}/admin/controllers`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getControleur(ControleurId){
  const response =  await axios.get(`${API_URL}/admin/controllers/${ControleurId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editControleur(controller){

  const response =  await axios.patch(`${API_URL}/admin/controllers/${controller.id}?include=category,tags`, controller, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createControleur(controller){


  const response =  await axios.post(`${API_URL}/admin/controllers?include=category,tags`, controller, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function deleteControleur(ControleurId){
  await axios.delete(`${API_URL}/admin/controllers/${ControleurId}`, {headers: authHeader()});
}

async function uploadImageControleur(controller) {
    const payload = new FormData();
    payload.append("attachment", controller.image);
  
    const response = await axios.post(`${API_URL}/uploads/controllers/${controller.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListControleurs,
    getControleur,
    editControleur,
    createControleur,
    deleteControleur,
    uploadImageControleur
};