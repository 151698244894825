import store from "../store";

export default async function guest() {

  if (store.getters["auth/isAuthenticated"]) {
    return { name: "Dashboard" };
  }
  
  //next();
}
