<template>
    <teleport to="body">
        <div v-if="show" class="modal-backdrop">
            <div class="modal-content">
                <button type="button" class="btn-close btn-close-black" aria-label="Close" @click="close"></button>
                <h4 class="text-white text-center"> Gérer les droits </h4>
                <div class="row align-items-center">
                    <div class="col-lg-10">
                    <el-autocomplete
                        v-model="query"
                        :fetch-suggestions="fetchSuggestions"
                        value-key="email"
                        placeholder="Adresse mail de l'utilisateur"
                        @select="handleSelect"
                        class="autocomplete-input"
                        >
                        <template #suffix>
                            <i class="el-icon-search"></i>
                        </template>
                    </el-autocomplete>
                    </div>
                    <div class="col-lg-2">
                    <el-button @click="handleBlur" class="bg-white">Chercher</el-button>
                    </div>
                </div>
                <p v-if="noUser">Cette adresse mail ne correspond à aucun utilisateur présent dans la base</p>
                <div v-if="ready">
                    <h4 class="mt-2 text-white text-center"> Droits de {{ this.query }}</h4>
                    <div class="card p-3 bg-light text-black mb-2">
                        <h6 class="text-center"> Dossier courant </h6>
                        <table style="table-layout: fixed">
                            <tr>
                                <td class="col-uni"> {{ currentFolder.name }}</td>
                                <td v-if="autorCurrentFolderSee()" class="col-uni"> {{ currentFolderRights.droit }}</td>
                                <td v-else class="col-uni"> - </td>
                                <td class="col-uni"> - </td>
                            </tr>
                        </table>
                        <h6 v-if="data[0].length>0" class="mt-2 text-center"> Dossiers </h6>
                        <table class="bigTable">
                            <tr v-for="(folder, index) in data[0]" :key="folder.id">
                                <td class="col-uni"> {{ folder.name }} </td>
                                <td v-if="changeFolders[index]==false && autorFolderSee(folder)==true" class="col-uni"> {{ findFolderRight(folder.id) }} </td>
                                <td v-if="changeFolders[index]==false && autorFolderSee(folder)==false" class="col-uni"> - </td>
                                <td v-if="changeFolders[index]==true" class="col-uni">
                                    <el-select v-model="selectedOption" placeholder="Sélectionnez une option">
                                        <el-option
                                            v-for="(option, index) in optionsFolder"
                                            :key="index"
                                            :label="option"
                                            :value="option">
                                        </el-option>
                                    </el-select>
                                </td>
                                <td v-if="changeFolders[index]==false && autorFolderChange(folder)==true" class="col-uni"> <el-button @click="changeRightFolder(folder, index)" class="bg-white">Changer</el-button> </td>
                                <td v-if="changeFolders[index]==false && autorFolderChange(folder)==false" class="col-uni"> - </td>
                                <td v-if="changeFolders[index]==true" class="col-uni"> <el-button @click="chooseRightFolder(folder, index)" class="bg-white">Valider</el-button> </td>
                            </tr>
                        </table>
                        <h6 v-if="data[2].length>0" class="mt-2 text-center"> Fichiers </h6>
                        <table class="bigTable">
                            <tr v-for="(file, index) in data[2]" :key="file.id">
                                <td class="col-uni"> {{ file.name }} </td>
                                <td v-if="changeFiles[index]==false && autorFileSee(file)==true" class="col-uni"> {{ findFileRight(file.id) }} </td>
                                <td v-if="changeFiles[index]==false && autorFileSee(file)==false" class="col-uni"> - </td>
                                <td v-if="changeFiles[index]==true" class="col-uni">
                                    <el-select v-model="selectedOption" placeholder="Sélectionnez une option">
                                        <el-option
                                            v-for="(option, index) in optionsFile"
                                            :key="index"
                                            :label="option"
                                            :value="option">
                                        </el-option>
                                    </el-select>
                                </td>
                                <td v-if="changeFiles[index]==false && autorFileChange(file)==true" class="col-uni"> <el-button @click="changeRightFile(file, index)" class="bg-white">Changer</el-button> </td>
                                <td v-if="changeFiles[index]==false && autorFileChange(file)==false" class="col-uni"> - </td>
                                <td v-if="changeFiles[index]==true" class="col-uni"> <el-button @click="chooseRightFile(file, index)" class="bg-white">Valider</el-button> </td>
                            </tr>
                        </table>
                    </div>
                </div>
                
                <h4 class=" mt-2 text-center text-white"> Légende </h4>
                <div class="card p-3 bg-light text-black">
                    <div class ="row"> 
                        <div class="col-lg-6">
                            <h6> Dossiers </h6>
                            <tr> O : accès au dossier </tr>
                            <tr> U : O + création de dossiers et import de fichiers </tr>
                            <tr> A : U + supprimer et renommer le dossier + modifier les droits </tr>
                        </div>
                        <div class="col-lg-6">
                            <h6> Fichiers </h6>
                            <tr> L : lire le fichier </tr>
                            <tr> C : L + commenter le fichier </tr>
                            <tr> E : C + modifier le fichier </tr>
                            <tr> A : E + supprimer et déplacer le fichier + modifier les droits</tr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
export default {
    name:"MyRights",
    props: {
        show: {
            type: Boolean,
            required: true
        },
        data: {
            type: Array,
            default: () => [],
        },
        thisFolder: {
            type: Number,
            default: 0,
        },
        myId: {
            type: Number,
            default: 0,
        },
        myRight: {
            type: String,
            default: ''
        }
    },
    emits: ['close','submit'],
    data() {
        return {
            selectedUser: null,
            noUser: false,
            ready: false,
            query: '',
            foldersRights:null,
            filesRights:null,
            currentFolder:null,
            currentFolderRights:null,
            optionsFolder:null,
            optionsFile:null,
            selectedOption:null,
            changeFolders:this.data[6],
            changeFiles:this.data[7],
            tableDroitsFichiers: ['N','L','C','E','A','S'],
            tableDroitsDossiers: ['N','O','U','A','S'],
            //element: null,
            //option: null,
        };
  },
    methods: {
        /*selectOption(option) {
            this.$emit('submit', option);
            this.close();
        },*/
        fetchSuggestions(queryString, callback) {
            const collabs = this.data[5]
            const suggestions = [];
            collabs.forEach(user => {
                if (user.email.toLowerCase().includes(queryString.toLowerCase())) {
                    suggestions.push(user); // Ajouter l'utilisateur au tableau des suggestions
                }
            });
            callback(suggestions);
        },
        handleSelect(item) {
            console.log(this.data)
            this.noUser=false
            this.query = item.email;
            this.selectedUser = item;  
            this.getRights(item.id);          
        },
        handleBlur() {
            const collabs = this.data[5]
            // Traitez la valeur entrée par l'utilisateur après la perte du focus
            if (!collabs.some(user => user.email === this.query)) {
                const users = this.data[4];
                const index = users.findIndex(user => user.email === this.query)
                if (index == -1) {
                    this.noUser=true
                }
                else {
                    this.noUser=false
                    const id = users[index].id
                    this.selectedUser = { id: id, email: this.query }
                    this.getRights(id);
                }
            }
        },
        async getRights(userId) {
            const folderId = this.thisFolder;
            try {
                await this.$store.dispatch("intranet/getUserRights", {userId, folderId});
            }
            finally {
                this.foldersRights = this.$store.getters["intranet/userRights"].dossiersDroit;
                this.filesRights = this.$store.getters["intranet/userRights"].fichiersDroit;
                this.currentFolder = this.$store.getters["intranet/userRights"].thisDossier;
                this.currentFolderRights = this.$store.getters["intranet/userRights"].thisDossierRight;
                this.ready=true
            }
        },
        findFolderRight(folderId) {
            const index = this.foldersRights.findIndex(item => item.dossier === folderId);
            const droit = this.foldersRights[index].droit;
            return droit;
        },
        findFileRight(fileId) {
            const index = this.filesRights.findIndex(item => item.fichier === fileId);
            const droit = this.filesRights[index].droit;
            return droit;
        },

        autorFileSee(file) {
            const droit = this.findFileRight(file.id)
            const index = this.data[3].findIndex(item => item.fichier === file.id);
            const monDroit = this.data[3][index].droit;
            const indexDroit = this.tableDroitsFichiers.findIndex(item => item == droit);
            const monIndexDroit = this.tableDroitsFichiers.findIndex(item => item == monDroit);
            return (monIndexDroit >= indexDroit)
        },
        autorFileChange(file) {
            if (this.selectedUser) {
                const droit = this.findFileRight(file.id)
                const index = this.data[3].findIndex(item => item.fichier === file.id);
                const monDroit = this.data[3][index].droit;
                const indexDroit = this.tableDroitsFichiers.findIndex(item => item == droit);
                const monIndexDroit = this.tableDroitsFichiers.findIndex(item => item == monDroit);
                const user = this.selectedUser.id
                return ((monIndexDroit > indexDroit)&& (monIndexDroit > 3)&&(this.myId!=user))
            }
            else {
                return false
            }
        },
        autorFolderSee(folder) {
            const droit = this.findFolderRight(folder.id)
            const index = this.data[1].findIndex(item => item.dossier === folder.id);
            const monDroit = this.data[1][index].droit;
            const indexDroit = this.tableDroitsDossiers.findIndex(item => item == droit);
            const monIndexDroit = this.tableDroitsDossiers.findIndex(item => item == monDroit);
            return (monIndexDroit >= indexDroit)
        },
        autorFolderChange(folder) {
            if (this.selectedUser) {
                const droit = this.findFolderRight(folder.id)
                const index = this.data[1].findIndex(item => item.dossier === folder.id);
                const monDroit = this.data[1][index].droit;
                const indexDroit = this.tableDroitsDossiers.findIndex(item => item == droit);
                const monIndexDroit = this.tableDroitsDossiers.findIndex(item => item == monDroit);
                const user = this.selectedUser.id
                return ((monIndexDroit > indexDroit)&& (monIndexDroit > 2)&&(this.myId!=user))
            }
            else {
                return false
            }
        },
        autorCurrentFolderSee() {
            const droit = this.currentFolderRights.droit
            const monDroit = this.myRight;
            const indexDroit = this.tableDroitsDossiers.findIndex(item => item == droit);
            const monIndexDroit = this.tableDroitsDossiers.findIndex(item => item == monDroit);
            return (monIndexDroit >= indexDroit)
        },
        changeRightFile(file, index) {
            this.changeFiles[index]=true;
            const monIndex = this.data[3].findIndex(item => item.fichier === file.id);
            const monDroit = this.data[3][monIndex].droit;
            const monIndexDroit = this.tableDroitsFichiers.findIndex(item => item == monDroit);
            this.optionsFile = this.tableDroitsFichiers.slice(0,monIndexDroit);
        },
        changeRightFolder(folder, index) {
            this.changeFolders[index]=true;
            const monIndex = this.data[1].findIndex(item => item.dossier === folder.id);
            const monDroit = this.data[1][monIndex].droit;
            const monIndexDroit = this.tableDroitsDossiers.findIndex(item => item == monDroit);
            this.optionsFolder = this.tableDroitsDossiers.slice(0,monIndexDroit);
        },
        async chooseRightFile(file, index) {
            if (this.selectedOption) {
                const type = 'f';
                const userId = this.selectedUser.id;
                const droit = this.selectedOption;
                const id = file.id;
                try {
                    await this.$store.dispatch("intranet/changeRight", {userId, type, id, droit});
                }
                finally {
                    const indexFile = this.filesRights.findIndex(item => item.fichier == id);
                    this.filesRights[indexFile].droit=droit;
                }
            }
            this.changeFiles[index]=false;

        },
        async chooseRightFolder(folder, index) {
            if (this.selectedOption) {
                const type = 'd';
                const userId = this.selectedUser.id;
                const droit = this.selectedOption;
                const id = folder.id;
                try {
                    await this.$store.dispatch("intranet/changeRight", {userId, type, id, droit});
                }
                finally {
                    const indexFolder = this.foldersRights.findIndex(item => item.dossier == id);
                    this.foldersRights[indexFolder].droit=droit;
                }
            }
            this.changeFolders[index]=false;

        },
        close () {
            this.query='';
            this.selectedUser=null;
            this.ready=false;
            this.$emit('close');
        }
    },
}

</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.425);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: rgb(26, 36, 54);
  position: relative;
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.autocomplete-container {
  display: flex;
  align-items: center;
}

.autocomplete-input {
  flex: 1; /* Permet au champ de prendre l'espace disponible */
  margin-right: 30px; /* Espacement entre le champ et le bouton */
}

.bigTable {
    table-layout: fixed;
}

.col-uni {
    width: 33%;
    text-align: center;
}

</style>