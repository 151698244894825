<template>
    <div class="border-radius-xl"
      style="background: #E8F1EE;" >
        <div class="bg-gradient-success border-radius-xl p-2 " >
            <div class="row">
                <div class="col-9" @click="goMails" style="cursor: pointer;">
                    <h4 class="h6 mb-0 text-white">Liste des mails</h4>
                </div>
                <div class="col-3 text-end">
                    <button class="btn btn-xs btn-light mb-0"  @click="$emit('update:modals', {show:true, text:'Intro'})">
                    <span class="btn-inner--icon"
                        ><i class="fas fa-edit"></i
                    ></span>
                    </button>
                </div>
            </div>

        </div>
    <div
      class="px-0 pb-1 pt-2"

      body-classes=""
      td-classes="pd-5"
      footer-classes=" py-0"
    >
    <div class="table-responsive" id="infinite-list-mails" style="height:calc((100vh - 400px) / 2)">
              <table   class="table table-responsive">
                <tbody class="text-sm" >
                  <tr v-for="row in roleList" v-bind:key="row.id" >
                    <div   @click="editItem(row)" class=" d-flex align-items-start flex-column justify-content-center" style=" padding: 5px; cursor: pointer;margin: 0px;" >

                 <h6 class="mb-0 text-sm" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 20px; ">{{ row.from }}</h6>
                 <h6 class="mb-0 text-sm" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 20px; ">{{ row.message }}</h6>
                 <p class="mb-0  text-xs" style="color: grey; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 16px; ">

           <button v-if="row.building" class="btn btn-danger btn-sm" style="margin: 0;padding: 0;"><i
           class="fa fa-building"></i>{{ row.building.EXTAPI_IMM_ID }}</button>

          <b style="color:rgb( 0,209, 0);font-weight: 600;">{{ new Date(row.received).toLocaleDateString("fr", {minute: 'numeric',  hour: 'numeric', year: 'numeric', month: 'numeric',day: 'numeric' } ) }}</b>  {{ row.bodyPreview }} </p>



             </div>

                  </tr>
                </tbody>
              </table>
            </div>
        </div>
    </div>
</template>

<script>
//import { DataTable } from "simple-datatables";
import store from "../../../store";
//import BasePagination from "../../../components/BasePagination.vue";
import eventTable from "../../../mixins/eventTable.js";
import _ from "lodash";

var sortDirection = "EXTAPI_IMM_ID";
//var getCurrentPage = 1;
//var searchQuery = '';

const getRoleList = _.debounce(async function (params) {
  await store.dispatch("mail/mailsList", {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.query ? { search: params.query } : {}),

    page: params.nr,
    size: params.per_page,
    /*page: {
      number: params.to,
    },*/
  });
}, 300);


export default {
  name: "ListRolePage",
  components: {},
  mixins: [eventTable],
  data() {
    return {
      mailid: null,
      updatedList: [],
      dataRoles: [],
      pagination: {},
      tableRoles: null,
      keys: null,
      page_t: 1,
      search: ''
    }
  },
  computed: {
    roleList() {
      return this.$store.getters["mail/mailsList"]?.data || [];
    },
    metaPage() {
      return this.$store.getters["mail/mailsList"]?.meta;
    }
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch"
    }
  },
  async mounted() {
    this.setupInfiniteScroll();
    await this.fetchInitialData();
  },
  beforeUnmount() {
    // Reset global variables
    sortDirection = "EXTAPI_IMM_ID";
    //searchQuery = '';
    //getCurrentPage = 1;
  },
  methods: {
    setupInfiniteScroll() {
      const masonry = document.querySelector('#infinite-list-mails');
      masonry.addEventListener('scroll', this.handleScroll);
    },
    handleScroll(e) {
      const masonry = e.target;
      if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
        this.loadMoreData();
      }
    },
    async loadMoreData() {
      if (this.page_t <= this.metaPage.current_page && this.page_t <= this.metaPage.last_page) {
        this.page_t += 1;
        await this.getDataFromPage(this.page_t);
      }
    },
    async fetchInitialData() {
      await this.getDataFromPage(1);
    },
    async getDataFromPage(page) {
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        per_page: 15
      });
    },
    async reactiveSearch() {
      //searchQuery = this.search;
      await this.getDataFromPage(1);
    },
    async reactivePagination() {
      if (this.metaPage) {
        this.pagination = {
          currentPage: this.metaPage.current_page,
          perPage: this.metaPage.per_page,
          total: this.metaPage.total
        };
        //getCurrentPage = this.pagination.currentPage;
      }
    },
    goMails(){
      this.$router.push({
        name: "Liste des mails",
        query: { id: null }
      })
    },
      editItem(row) {
        this.mailid = row.id;
              this.$router.push({
                  name: "Liste des mails",
           query: { id: row.id }
              })
        this.showlist =false;
/*
            this.$router.push({

                path: "/s/mails",
                params: { id: row.id }
            });
       */

      },
    getList(){
      if (this.$store.getters["mail/mailsList"]) {
        if (this.$store.getters["mail/mailsList"].meta.current_page == 1){
          console.log(this.$store.getters["mail/mailsList"].data)
          this.updatedList = this.$store.getters["mail/mailsList"].data

        }
        else {
          this.updatedList = [...this.updatedList,...this.$store.getters["mail/mailsList"].data]

        }
      }

      else{
        this.updatedList = [...this.updatedList]
      }
      return this.updatedList
    },
    openImmeuble(row){
            this.$router.push({
                name: "Mail",
                params: { id: row.id }
            });
          },
  },
};
</script>
<style>
.dataTable-top {
  display: none;
}
</style>
