import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListViews(params){
  const response =  await axios.get(`${API_URL}/admin/views`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getView(ViewId){
  const response =  await axios.get(`${API_URL}/admin/views/${ViewId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}
async function getDetailedView(ViewId){
  const response =  await axios.get(`${API_URL}/views/${ViewId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editView(view){


  const response =  await axios.patch(`${API_URL}/admin/views/${view.id}?include=category,tags`, view, {headers: authHeader()});
  return jsona.deserialize(response.data);
}
async function dupplicateView(view){


  const response =  await axios.post(`${API_URL}/admin/views/${view.id}?include=category,tags`, view, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createView(view){

  const payload = jsona.serialize({
    stuff: view,
    includeNames: []
  });

  const response =  await axios.post(`${API_URL}/admin/views?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function deleteView(ViewId){
  await axios.delete(`${API_URL}/admin/views/${ViewId}`, {headers: authHeader()});
}

async function uploadImageView(view) {
    const payload = new FormData();
    payload.append("attachment", view.image);
  
    const response = await axios.post(`${API_URL}/uploads/views/${view.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListViews,
    getView,getDetailedView,
    editView,
    createView,
    deleteView,
    uploadImageView,
    dupplicateView
};