<template>
  <div class="py-1 container-fluid">
    <NewEvent v-model="item" v-model:newEventModal="newEventModal"/>
    <div class="row">
      <div class="col-12">

      <div class="container text-center mb-2  ">
        <div class="bg-gradient-primary p-3 border-radius-xl row">
          <div class="col-3 col-md-0">

          </div>
          <div class="col-6 col-md-6">
          
                <h5 class="mb-0 text-white">Liste des évènements</h5>
          </div>
          <div class="col-3 col-md-3 text-end">
            <button class="btn btn-xs btn-white mb-0" @click="newEventModal = true"><i class="fa fa-plus"></i>  Ajouter un évènement</button>
          </div>
        </div>
        <div class="mt-2">
        <ArgonInput icon="fa fa-search" iconDir="left" placeholder="Rechercher" v-model="search"/>

        </div>
        <!--
            <div class="dataTable-search ">
                <input   class="form-control rounded-lg dataTable-input search-input-table" placeholder="Recherche..." type="text">
            </div>-->
      </div>
              <!--<div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Add Role' }" 
                    class="mb-0 btn bg-gradient-success btn-sm">
                      <i class="fa fa-plus me-1"/> Add Role
                  </router-link>
                </div>
              </div>-->


        <div class="card">
          <!-- Card header -->
          <div class="px-0 pb-0 card-body"><!--{{ roleList.length }}-->
            <div class="table-responsive" id="infinite-list" style="height:calc(100vh - 200px)">
              <table   class="table table-responsive">
                <thead class="thead-light">
                  <tr>
                    <th title="EXTAPI_IMM_ID">Immeuble</th>
                    <th title="nom">Titre</th>
                    <th title="created_at">Mis à jour</th>
                  </tr>
                </thead>
                <tbody class="text-sm" > 
                  <tr v-for="evenement in roleList" v-bind:key="evenement.id" @click="openImmeuble(evenement)">
                    <td class="text-danger" v-if="evenement.immeuble">
                      {{ evenement.immeuble.EXTAPI_IMM_ID }}
                    </td>
                    <td style="flex:1">{{ evenement.title }}</td>
                    <td>{{ evenement.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { DataTable } from "simple-datatables";
import store from "../../../store";
//import BasePagination from "../../../components/BasePagination.vue";
import _ from "lodash";
import ArgonInput from "../../../components/ArgonInput.vue";
//import Modal from "../../../components/Modal.vue";
import NewEvent from "./NewEvent.vue";
//import ModalNew from "../../../components/ModalNew.vue";

var sortDirection = "id";
var getCurrentPage = 1;
var searchQuery = '';

const getRoleList = _.debounce(async function (params) { 
  await store.dispatch("event/eventsList", {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.query ? { search: params.query } : {}),
  
    page: params.nr,
    size: params.per_page,
    /*page: {
      number: params.to,
    },*/
  });
}, 300);

export default {
  name: "ListRolePage",
  components: {
    //BasePagination,
    ArgonInput,
    NewEvent
},
  data() {
    return {
      updatedList:[],
      dataRoles: [],
      pagination: {},
      tableRoles: null,
      keys: null,
      page_t: 1,
      search: '',
      mainLoading: true,
      item:{
        parent_id: 0,
        title: '',
        controller_id: 1,
        ticket_id:0,
        view_id: 1,
      },      newEventModal: false
    }
  },
  props:{
    newEventProp: {
      type: Object,
      default: ()=>{}
    }
  },
  computed: {
    roleList() {
      return this.getList();
    },
    metaPage() {
      return this.$store.getters["event/eventsList"]?.meta;
    },
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch"
    },
    $route(to, from) {
      console.log(to, from)
      this.calculateFromDump()
      setTimeout(() => {
        this.newEventModal = true
        //this.item = this.dumped
      }, 200);
    }

  },
  async mounted() {

    const masonry = document.querySelector('#infinite-list');

    if (this.$route.params.newEventProp){
      this.dumped = JSON.parse(this.$route.params.newEventProp)
    if (this.dumped){
      setTimeout(() => {
        this.newEventModal = true
        //this.item = this.dumped
      }, 200);
      
    }}

    masonry.addEventListener('scroll', e => {
    console.log(e)
      if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
        if (this.metaPage.current_page == 1){
          this.page_t = 1
        }
        if (this.page_t <= this.metaPage.current_page && this.page_t <= this.metaPage.last_page) {
        this.page_t+=1;
        this.getDataFromPage(this.page_t);
        }
      }
    })

      await getRoleList({
        sort: sortDirection,
        query: '',
        nr: getCurrentPage,
        perpage: 15//this.tableRoles.options.perPage
      });
  },
  beforeUnmount() {
    sortDirection = "id";
    searchQuery = '';
    getCurrentPage = 1;
  },
  created(){
    this.calculateFromDump()
  },
  methods: {
    calculateFromDump(){

    if (this.$route.params.newEventProp)
      this.dumped = JSON.parse(this.$route.params.newEventProp)

    if (this.dumped){
      if (this.dumped.immeuble_id){
        this.item.immeuble_id = this.dumped.immeuble_id
        this.item.immeuble= this.dumped.immeuble

      }
      if (this.dumped.perso_id){
        this.item.perso_id = this.dumped.perso_id
        this.item.perso= this.dumped.perso

      }
      if (this.dumped.type){
        this.item.received_type = this.dumped.type
      }

if(this.dumped.titre){
  this.item.title = this.dumped.titre
}

      if(this.dumped.description){
        this.item.description = this.dumped.description
      }

    }
    },

    getList(){
      if (this.$store.getters["event/eventsList"]) {
        if (this.$store.getters["event/eventsList"].meta.current_page == 1){
          this.updatedList = this.$store.getters["event/eventsList"].data

        }
        else {
          this.updatedList = [...this.updatedList,...this.$store.getters["event/eventsList"].data]

        }
      }
      
      return this.updatedList
    },
    openImmeuble(row){
            this.$router.push({
                name: "Évènement",
                params: { id: row.id }
            });
          },
    async getDataFromPage(page) {
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: 15//this.tableRoles.options.perPage
      });
    },

    async reactiveSearch() {
      searchQuery = this.search;
      console.log(searchQuery)
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        nr: 1,
        perpage: 15//this.tableRoles.options.perPage
      });
    },

    async reactivePagination() {
      
      this.pagination = await this.metaPage;

      
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      
      }
      getCurrentPage = this.pagination.current_page;
      return this.pagination;
    },

    async reactiveTable() {/*
      this.dataRoles = [];
      if (this.roleList.length > 0) {
        this.roleList.forEach(row => {
          this.dataRoles.push(
            [

            `${row.EXTAPI_IMM_ID}`,
              `<h6 class="my-auto">${row.nom}</h6>`,
              row.created_at,
              this.actionEditButton(row.id, "Immeuble") + this.actionDeleteButton(row.id, "Delete Role")
            ]
          )
        });
        this.tableRoles.data = [];
        this.tableRoles.refresh();
        this.tableRoles.insert({ data: this.dataRoles });
        this.removeEvent();
        this.eventToCall({
          table: this.tableRoles,
          redirectPath: "Edit Role",
          deletePath: "role/deleteRole",
          getPath: "role/rolesList",
          textDelete: "Role deleted successfully!",
          textDefaultData: 'roles'
        });
      }
      else {
        this.tableRoles.setMessage('Aucun résultat trouvé');
      }*/
    }
  },
};
</script>
<style>
.dataTable-top {
  display: none;
}
</style>