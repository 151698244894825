<template>
    <div v-if="connexion">
        <p>Veuillez indiquer le code d'accès fourni par votre syndic pour votre copropriété</p>
        <input class="form-control" v-model="access"/>
        <button class="btn btn-danger" @click="checkImmeuble">Se connecter</button>
        <p>
            Vous pourrez ajouter ultérieurement d'autres immeubles sur votre compte MyCopro en cliquant sur "Ajouter un immeuble" sur votre profil
        </p>
    </div>
    <div v-else>
        <h3>{{building_name}}</h3>
        <p>Votre adresse email a été confirmée par le syndic de l'immeuble</p>

        <div>
            <table>
                <tr>
                    <td>
                        <ArgonSwitch v-model="confidentialite.entreprise"></ArgonSwitch>
                    </td>
                    <td>Dans le cadre d'<b>interventions</b> sur la résidence, j'accepte que mes coordonnées <u>mail et/ou téléphone</u> soient communiquées aux <b>entreprises</b> aux fins de les accueillir dans les parties privées ou communes
                    </td>
                </tr>
                <tr>
                    <td>
                        <ArgonSwitch v-model="confidentialite.syndic"></ArgonSwitch>
                    </td>
                    <td>
                     J'accepte que mes coordonnées <u>mail et/ou téléphone</u> soient utilisées par le <b>syndic</b> pour me communiquer toute information relative à la <b>vie de l'immeuble</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ArgonSwitch v-model="confidentialite.agence"></ArgonSwitch>
                    </td>
                    <td>
                        Dans le cadre de <b>communications</b> réalisées par l'<b>agence immobilière</b>, j'accepte que mon <u>mail</u> soit utilisé dans le but de recevoir toute information de nature <b>pratique ou commerciale</b>
                    </td>

                </tr>
                <tr>
                    <td>
                        <ArgonSwitch v-model="confidentialite.partenaires"></ArgonSwitch>
                    </td>
                    <td>
                        Dans le cadre de <b>communications</b> réalisées par les <b>partenaires</b> de l'agence immobilière, j'accepte que mon <u>mail</u> soit utilisé dans le but de recevoir toute information de nature <b>commerciale</b>
                    </td>

                </tr>
            </table>
                     
        </div>
        <p>Vous pourrez modifier ces options à tout moment dans votre espace MyCopro</p>
        <button class="btn btn-danger" @click="rejoindre">Rejoindre</button>
    </div>
    
</template>
<script>
import ArgonSwitch from "@/components/ArgonSwitch.vue";
export default {
  name: "AjouterImmeuble",
  components: {
    ArgonSwitch
  },
  data() {
    return {
      loading: {
        activities: false
      },
      building_name:"",
      confidentialite:{
        entreprise:false,
        syndic:false,
        agence:false,
        partenaires:false
      },
      email:"test",
      access:"",
      connexion:true
    }
},
methods:{
    async checkImmeuble(){
        try{
        await this.$store.dispatch("immeuble/r_checkImmeuble", {access:this.access});
        let l = await this.$store.getters["immeuble/checkImmeuble"]
        if(l[1] ==1){
            this.building_name = l[0]
        this.connexion = false

        } else {
                this.$notify({
                type: "danger",
                message: "Vous n'avez pas accès à cet immeuble.",
                });
        }

        } catch(e){
                this.$notify({
                type: "danger",
                message: "Vous n'avez pas accès à cet immeuble ou avez entré un code erroné.",
                });

        }
    },

    async rejoindre(){
        //access,email,idspe,travaux_v,syndic_v,agence_v,part_agence_v
        console.log(this.confidentialite)
        let sending = {
            access:this.access,
            email:this.email,
            travaux_v:this.confidentialite.entreprise,
            syndic_v:this.confidentialite.syndic,
            agence_v:this.confidentialite.agence,
            part_agence_v:this.confidentialite.partenaires
        }
        await this.$store.dispatch("coproprietaires/addCoproprietaire", sending);


    }
}
}

</script>