import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListcoproprietaires(params){
  const response =  await axios.get(`${API_URL}/coproprietaires`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListcoproprietairesFromBuilding(params){
  let immeuble_id = params.immeuble_id;
  const response =  await axios.get(`${API_URL}/immeubles/${immeuble_id}/coproprietaires`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data, meta: response.data.meta};
}

async function getcoproprietaire(coproprietaireId){
  const response =  await axios.get(`${API_URL}/coproprietaires/${coproprietaireId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editcoproprietaire(coproprietaire){

  const payload = jsona.serialize({
    stuff: coproprietaire,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/coproprietaires/${coproprietaire.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createcoproprietaire(coproprietaire){

  const payload = jsona.serialize({
    stuff: coproprietaire,
    includeNames: []
  });

  const response =  await axios.post(`${API_URL}/r/coproprietaires`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function deletecoproprietaire(coproprietaireId){
  await axios.delete(`${API_URL}/coproprietaires/${coproprietaireId}`, {headers: authHeader()});
}

async function uploadImagecoproprietaire(coproprietaire) {
    const payload = new FormData();
    payload.append("attachment", coproprietaire.image);
  
    const response = await axios.post(`${API_URL}/uploads/coproprietaires/${coproprietaire.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListcoproprietaires,getListcoproprietairesFromBuilding,
    getcoproprietaire,
    editcoproprietaire,
    createcoproprietaire,
    deletecoproprietaire,
    uploadImagecoproprietaire
};