<template>
  <!--<div id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <argon-avatar
          :img="img"
          alt="team-3"
          size="xl"
          shadow="sm"
          border-radius="lg"
        />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">Mark Johnson</h5>
          <p class="mb-0 font-weight-bold text-sm">CEO / Co-Founder</p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <label class="form-check-label mb-0 me-2">
          <small id="profileVisibility">Switch to invisible</small>
        </label>
        <argon-switch
          id="switch-invisible"
          name="Switch to Invisible"
          checked
        />
      </div>
    </div>
  </div>-->
  <div id="localisation" class="card mt-4">
    <div class="card-header">
      <h5>Localisation</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Adresse</label>
          <textarea class="form-control" placeholder="Adresse" v-model="modele['122']"></textarea>
        </div>
        <div class="col-4">
          <label class="form-label">CP</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['52']" />
        </div>
        <div class="col-8">
          <label class="form-label">Ville</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['54']" />
        </div>
        <div class="col-4">
          <label class="form-label">CP internet</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['1933']" />
        </div>
        <div class="col-8">
          <label class="form-label">Ville internet</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['1861']" />
        </div>

        <div class="col-6">
          <label class="form-label">Nom résidence</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['1970']" />
        </div>
        <div class="col-6">
          <label class="form-label">Secteur</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['123']" />
        </div>
        <div class="col-12">
          <label class="form-label">Situation</label>
          <textarea class="form-control" placeholder="Adresse" v-model="modele['55']"></textarea>
        </div>

        <div class="col-4">
          <label class="form-label">Bord de mer</label><br />
          <MidToggleVue v-model="modele['2383']" />
        </div>
        <div class="col-4">
          <label class="form-label">Vue</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['154']" />
        </div>
        <div class="col-4">
          <label class="form-label">Étage</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['39']" />
        </div>
        <div class="col-4">
          <label class="form-label">Nombre étages</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['100']" />
        </div>
        <div class="col-4">
          <label class="form-label">Rez de chaussée</label><br />
          <MidToggleVue v-model="modele['1760']" />
        </div>
        <div class="col-4">
          <label class="form-label">Dernier étage</label><br />
          <MidToggleVue v-model="modele['1754']" />
        </div>

        <div class="col-6">
          <label class="form-label">Distance commerces (en km)</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['108']" />
        </div>
        <div class="col-6">
          <label class="form-label">Accès Bus (en min)</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['137']" />
        </div>
        <div class="col-6">
          <label class="form-label">Accès Tramway (en min)</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['1764']" />
        </div>
        <div class="col-6">
          <label class="form-label">Accès École (en min)</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['139']" />
        </div>
        <div class="col-6">
          <label class="form-label">Accès Gare (en min)</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['140']" />
        </div>



      </div>
    </div>
  </div>
  <div id="finance" class="card mt-4">
    <div class="card-header">
      <h5>Aspects financiers</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-4">
          <label class="form-label">Bien soumis à l'encadrement des loyers</label><br />
          <MidToggleVue v-model="modele['2763']" />
        </div>


        <div class="col-4">
          <label class="form-label">Loyer de base (C_2768)</label>
          <input type="text" class="form-control" placeholder="Loyer de base" v-model="modele['31']" disabled />

        </div>
        <div class="col-4">
          <EndInput v-model="modele['2764']" title="Loyer de référence majoré (loyer de base à ne pas dépasser)"
            end="€ / mois" />

        </div>
        <div class="col-4">
          <label class="form-label">Loyer mensuel HC</label>
          <input type="text" class="form-control" placeholder="Loyer de base" v-model="modele['31']" disabled />

        </div>
        <div class="col-4">
          <EndInput v-model="modele['2390']" title="Montant complément loyer" end="€" />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['32']" title="Charges forfaitaires" end="€" />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['1979']" title="Charges copropriété" end="€ / mois" />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['247']" title="Provision sur charges" end="€" />
        </div>
        <div class="col-4">
          <label class="form-label">Prestations / Charges</label>
          <textarea class="form-control" placeholder="" v-model="modele['1735']"></textarea>

        </div>
        <div class="col-4">
          <label class="form-label">Loyer charges comprises (C_2152)</label>
          <input type="text" class="form-control" placeholder="Non renseigné"
            :value="parseFloat(modele['31']) + parseFloat(modele['247'])" disabled />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['53']" title="Honoraires acquéreur" end="€" />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['1902']" title="Honoraires (en %)" end="%" />
        </div>
        <div class="col-4">
          <label class="form-label">Honoraires Locataire (C_1727)</label>
          <input type="text" class="form-control" placeholder="Non renseigné"
            :value="parseFloat(modele['2343']) + parseFloat(modele['2338']) + parseFloat(modele['2324']) + parseFloat(modele['2326'])"
            disabled />
        </div>
        <div class="col-4">
          <label class="form-label">Honoraires Bailleur (C_1930)</label>
          <input type="text" class="form-control" placeholder="Non renseigné"
            :value="parseFloat(modele['2344']) + parseFloat(modele['2339']) + parseFloat(modele['2325']) + parseFloat(modele['2327'])"
            disabled />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['2228']" title="Honoraires de rédaction du bail" end="€" />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['2230']" title="Honoraires d'état des lieux" end="€" />
        </div>

        <div class="col-6">
          <label class="form-label">Description Honoraires</label>
          <textarea class="form-control" placeholder="" v-model="modele['1911']"></textarea>

        </div>
        <div class="col-6">
          <label class="form-label">Description Honoraires Location</label>
          <textarea class="form-control" placeholder="" v-model="modele['1986']"></textarea>

        </div>
        <div class="col-4">
          <EndInput v-model="modele['262']" title="Dépôt de garantie" end="€" />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['131']" title="Reste au vendeur" end="€" />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['223']" title="Prix de départ" end="€" />
        </div>
        <div class="col-4">
          <EndInput v-model="modele['1936']" title="Prix estimé (Mini)" end="€" />
        </div>

        <div class="col-4">
          <EndInput v-model="modele['128']" title="Prix estimé (Maxi)" end="€" />
        </div>
        <div class="col-4">
          <label class="form-label">Mode Estimation</label>
          <select v-model="modele['2342']" class="form-select">
            <option value="" selected="selected" title=""></option>
            <option value="loyer_charges_comprises" title="Loyer Charges comprises">Loyer Charges comprises</option>
            <option value="loyer_hors_charges" title="Loyer Hors charges">Loyer Hors charges</option>
            <option value="net_vendeur" title="Net vendeur">Net vendeur</option>
            <option value="Prix_Vente" title="Prix de vente">Prix de vente</option>
          </select>
        </div>
        <div class="col-4">
          <label class="form-label">Date Estimation</label>
          <flat-pickr v-model="modele['1862']" class="form-control  datetimepicker" placeholder="Disponible le"
            :config="config"></flat-pickr>


        </div>
        <div class="col-4">
          <label class="form-label">Date remise Estimation</label>
          <flat-pickr v-model="modele['2378']" class="form-control  datetimepicker" placeholder="Disponible le"
            :config="config"></flat-pickr>


        </div>
        <div class="col-4">
          <EndInput v-model="modele['208']" title="Prix travaux" end="€" />
        </div>



        <div class="col-6">
          <EndInput v-model="modele['2323']" title="Honoraires rédaction acte (locataire)" end="€" />
        </div>

        <div class="col-6">
          <EndInput v-model="modele['2323']" title="Honoraires rédaction acte (bailleur)" end="€" />
        </div>

        <div class="col-6">
          <EndInput v-model="modele['2324']" title="Honoraires état des lieux (locataire)" end="€" small />
        </div>

        <div class="col-6">
          <EndInput v-model="modele['2325']" title="Honoraires état des lieux (bailleur)" end="€" small />
        </div>

        <div class="col-6">
          <EndInput v-model="modele['2326']" title="Honoraires frais divers (locataire)" end="€" small />
        </div>

        <div class="col-6">
          <EndInput v-model="modele['2327']" title="Honoraires frais divers (bailleur)" end="€" small />
        </div>


        <div class="col-6">
          <EndInput v-model="modele['2338']" title="Honoraires visite, dossier, acte (locataire)" end="€" small />
        </div>

        <div class="col-6">
          <EndInput v-model="modele['2339']" title="Honoraires visite, dossier, acte (bailleur)" end="€" small />
        </div>

        <div class="col-6">
          <EndInput v-model="modele['2343']" title="Hono négo et entremise (locataire)" end="€" small />
        </div>

        <div class="col-6">

          <EndInput v-model="modele['2344']" title="Hono négo et entremise (bailleur)" end="€" small />
        </div>


        <div class="col-6">
          <EndInput v-model="modele['87']" title="Taxe Habitation" end="€" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['89']" title="Taxe foncière" end="€" />
        </div>
        <div class="col-12">
          <label class="form-label">Détail taxe foncière</label>
          <textarea class="form-control" placeholder="" v-model="modele['1946']"></textarea>
        </div>
        <div class="col-6">
          <EndInput v-model="modele['1865']" title="Montant Séquestre" end="€" />
        </div>
        <div class="col-6">
          <label class="form-label"> Régularisation des charges</label>
          <select v-model="modele['2391']" class="form-control">
            <option value="Forfaitaires_mensuelles" title="Forfaitaires mensuelles">Forfaitaires mensuelles</option>
            <option value="Previsionnelles_mensuelles_annuelle"
              title="Provisionnelles mensuelles avec régularisation annuelle">Provisionnelles mensuelles avec
              régularisation annuelle</option>
            <option value="Remboursement_annuel_locataire" title="Remboursement annuel par le locataire">Remboursement
              annuel par le locataire</option>
            <option value="Sur_justificatif" title="Sur justificatif">Sur justificatif</option>
          </select>
        </div>

      </div>
    </div>
  </div>


  <div id="copro" class="card mt-4">
    <div class="card-header">
      <h5>Copropriété</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Bien en copropriété</label><br />
          <MidToggleVue v-model="modele['2206']" />
        </div>
        <div class="col-6">
          <label class="form-label">dont lots d'habitation</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2232']" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2208']" title="Charges annuelles (ALUR)" end="€" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2797']" title="Cotisation fonds travaux" end="€" />
        </div>
        <div>
          <label class="form-label">Procédures dilligentées</label><br />
          <!--<ArgonSwitch v-model="modele['2209']" />-->TO DO (2209)
        </div>
        <div class="col-6">
          <label class="form-label">Détail procédure</label>
          <textarea class="form-control" placeholder="" v-model="modele['2340']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Quote part de charges</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2341']" />
        </div>

      </div>
    </div>
  </div>

  <div id="bail" class="card mt-4">
    <div class="card-header">
      <h5>Bail</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-3">
          <label class="form-label">Bail en cours</label><br />
          <MidToggleVue v-model="modele['2206']" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['2208']" title="Durée du bail" end="année(s)" />
        </div>
        <div class="col-3">
          <label class="form-label">Date échéance bail</label>

          <flat-pickr v-model="modele['2144']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-3">
          <label class="form-label">Type de bail</label>

          <select name="C_1801" required id="C_1801" class="form-select  form-control-sm" v-model="modele['1801']">
            <option value="Bail_construire" title="Bail à construire (hors loi 89)">Bail à
              construire (hors loi 89)</option>
            <option value="BailAdministratif" title="Bail administratif (hors loi 89)">Bail
              administratif (hors loi 89)</option>
            <option value="Bail_civil" title="Bail civil (hors loi 89)">Bail civil (hors loi
              89)
            </option>
            <option value="BailCommercial" title="Bail commercial">Bail commercial</option>
            <option value="Commercial10" title="Bail commercial 10 ans">Bail commercial 10
              ans
            </option>
            <option value="Commercial12" title="Bail commercial 12 ans">Bail commercial 12
              ans
            </option>
            <option value="Commercial369" title="Bail commercial 3/6/9">Bail commercial
              3/6/9
            </option>
            <option value="Commercial69" title="Bail commercial 6/9">Bail commercial 6/9
            </option>
            <option value="Commercial9" title="Bail commercial 9 ans ferme">Bail commercial
              9
              ans ferme</option>
            <option value="BailSousLocation" title="Bail de sous-location (Loi 89)">Bail de
              sous-location (Loi 89)</option>
            <option value="BailStationnement" title="Bail de stationnement / parking">Bail
              de
              stationnement / parking</option>
            <option value="BailDerogatoire" title="Bail dérogatoire (Loi 89)">Bail
              dérogatoire
              (Loi 89)</option>
            <option value="DerogatoireCD" title="Bail dérogatoire de courte durée (Loi 89)">
              Bail
              dérogatoire de courte durée (Loi 89)</option>
            <option value="Bail_emphy" title="Bail emphytéotique (hors loi 89)">Bail
              emphytéotique (hors loi 89)</option>
            <option value="BailExtinction" title="Bail en extinction">Bail en extinction
            </option>
            <option value="ResidencePrincipale" title="Bail habitation (Loi 89)">Bail
              habitation
              (Loi 89)</option>
            <option value="BailHabitation" title="Bail loi 48 (hors loi 89)">Bail loi 48
              (hors
              loi 89)</option>
            <option value="MeubleResidencePrincipale" title="Bail meublé résidence principale (Loi 89)">Bail meublé
              résidence
              principale (Loi 89)</option>
            <option value="Mixte" title="Bail mixte (Loi 89)">Bail mixte (Loi 89)</option>
            <option value="BailProfessionnel" title="Bail professionnel">Bail professionnel
            </option>
            <option value="BailVerbal" title="Bail verbal (hors loi 89)">Bail verbal (hors
              loi
              89)</option>
            <option value="ConventionOccupationPrécaire" title="Convention d'occupation précaire (hors loi 89)">Convention
              d'occupation
              précaire (hors loi 89)</option>
            <option value="BailMeubleHorsResidencePrincipale" title="Meublé hors résidence principale (hors loi 89)">
              Meublé hors résidence
              principale (hors loi 89)</option>
            <option value="Usufruit" title="Usufruit">Usufruit</option>
          </select>
        </div>
        <div class="col-3">
          <label class="form-label">Date de libération</label>

          <flat-pickr v-model="modele['2145']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-3">
          <label class="form-label">Date de disponibilité</label>

          <flat-pickr v-model="modele['2147']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-3">
          <label class="form-label">Date de non disponibilité</label>

          <flat-pickr v-model="modele['2225']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-3">
          <label class="form-label">Date de non disponibilité</label>

          <flat-pickr v-model="modele['2226']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Motif de non disponibilité</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2226']" />
        </div>

      </div>
    </div>
  </div>


  <div id="surfaces" class="card mt-4">
    <div class="card-header">
      <h5>Surfaces</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-3">
          <EndInput v-model="modele['34']" title="Surface" end="m²" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['1755']" title="Surface estimée" end="m² ≈" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['2174']" title="Surface loi Carrez" end="m² " />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['2758']" title="Surface construite" end="m² ≈" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['37']" title="Surface au sol" end="m²" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['225']" title="Surface aménageable" end="m² ≈" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['1730']" title="Surface utile" end="m²" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['2001']" title="Surface utile pondérée" end="m²" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['1734']" title="Surface de parking" end="m²" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['91']" title="Surface dépendance(s)" end="m²" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['36']" title="Surface terrain" end="m²" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['1807']" title="Surface divisible" end="m²" />
        </div>

        <div class="col-3">
          <label class="form-label">Loi Carrez</label><br />
          <MidToggleVue v-model="modele['130']" />
        </div>
        <div class="col-3">
          <EndInput v-model="modele['1996']" title="Surface jardin" end="m²" />
        </div>

      </div>
    </div>
  </div>


  <div id="exterieur" class="card mt-4">
    <div class="card-header">
      <h5>Extérieur</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-3">
          <label class="form-label">Jardin</label><br />
          <MidToggleVue v-model="modele['2166']" />
        </div>
        <div class="col-3">
          <label class="form-label">Année de construction</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['51']" />
        </div>
        <div class="col-6">
          <label class="form-label">Couverture</label>
          <select class="form-select" name="C_95" id="C_95" v-model="modele['95']">
            <option value="" selected="selected" title=""></option>
            <option value="Ardoises" title="Ardoises">Ardoises</option>
            <option value="ArdoisesFibrociment" title="Ardoises &amp; Fibrociment">Ardoises &amp; Fibrociment</option>
            <option value="ArdoisesMixtes" title="Ardoises Mixtes">Ardoises Mixtes</option>
            <option value="ArdoisesNaturelles" title="Ardoises Naturelles">Ardoises Naturelles</option>
            <option value="ArdoisesSynthetiques" title="Ardoises synthétiques">Ardoises synthétiques</option>
            <option value="Autre" title="Autre">Autre</option>
            <option value="BacAcier" title="Bac acier">Bac acier</option>
            <option value="Chaumes" title="Chaumes">Chaumes</option>
            <option value="Colombages" title="Colombages">Colombages</option>
            <option value="Cuivre" title="Cuivre">Cuivre</option>
            <option value="Eternite" title="Eternite">Eternite</option>
            <option value="Fibrociment" title="Fibrociment">Fibrociment</option>
            <option value="Lauze" title="Lauze">Lauze</option>
            <option value="Shingle" title="Shingle">Shingle</option>
            <option value="Terrasse" title="Terrasse">Terrasse</option>
            <option value="Tôle" title="Tôle">Tôle</option>
            <option value="Tuile_Alsacienne" title="Tuile Alsacienne">Tuile Alsacienne</option>
            <option value="Tuiles" title="Tuiles">Tuiles</option>
            <option value="Tuiles_bavant" title="Tuiles de bavent">Tuiles de bavent</option>
            <option value="TuilesMecaniques" title="Tuiles Mécaniques">Tuiles Mécaniques</option>
            <option value="Zinc" title="Zinc">Zinc</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Forme Toiture</label>
          <select class="form-select" name="C_2759" id="C_2759" v-model="modele['2759']">
            <option value="" selected="selected" title=""></option>
            <option value="ToitAutres" title="Autre">Autre</option>
            <option value="ToitBlancBeton" title="Toit blanc avec béton">Toit blanc avec béton</option>
            <option value="ToitBatieres" title="Toit en bâtières">Toit en bâtières</option>
            <option value="ToitMansarde" title="Toit mansarde">Toit mansarde</option>
            <option value="ToitPente" title="Toit pente">Toit pente</option>
            <option value="ToitPlat" title="Toit plat">Toit plat</option>
            <option value="ToitPlatBois" title="Toit plat avec bois">Toit plat avec bois</option>
            <option value="ToitPlatRoofing" title="Toit plat avec roofing">Toit plat avec roofing</option>
            <option value="ToitureComposee" title="Toiture composée">Toiture composée</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Neuf - Ancien</label>
          <select class="form-select" name="C_211" id="C_211" v-model="modele['211']">
            <option value="" selected="selected" title=""></option>
            <option value="Ancien" title="Ancien">Ancien</option>
            <option value="Neuf" title="Neuf">Neuf</option>
            <option value="Recent" title="Récent">Récent</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Standing</label>
          <select class="form-select" name="C_93" id="C_93" v-model="modele['93']">
            <option value="" selected="selected" title=""></option>
            <option value="Bon" title="Bon">Bon</option>
            <option value="Grand standing" title="Grand standing">Grand standing</option>
            <option value="Moyen" title="Moyen">Moyen</option>
            <option value="Normal" title="Normal">Normal</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">État général</label>
          <select class="form-select" name="C_92" id="C_92" v-model="modele['92']">
            <option value="" title=""></option>
            <option value="A Rafraichir" title="A Rafraîchir">A Rafraîchir</option>
            <option value="Bon Etat" title="Bon Etat">Bon Etat</option>
            <option value="Excellent" title="Excellent">Excellent</option>
            <option value="Habitable" title="Habitable">Habitable</option>
            <option value="Mauvais" title="Mauvais">Mauvais</option>
            <option value="Moyen" title="Moyen">Moyen</option>
            <option value="Travaux à prévoir" title="Travaux à prévoir">Travaux à prévoir</option>
            <option value="Très bon état" selected="selected" title="Très bon état">Très bon état</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Vis à Vis</label><br />
          <MidToggleVue v-model="modele['2408']" />
        </div>
        <div class="col-6">
          <label class="form-label"> Travaux Copro Prévus</label>
          <textarea class="form-control" placeholder="" v-model="modele['160']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">État extérieur</label>
          <select class="form-select" name="C_104" id="C_104" v-model="modele['104']">
            <option value="" selected="selected" title=""></option>
            <option value="A rafraichir" title="A rafraîchir">A rafraîchir</option>
            <option value="A réhabiliter" title="A réhabiliter">A réhabiliter</option>
            <option value="A_Renover" title="A rénover">A rénover</option>
            <option value="Bon" title="Bon">Bon</option>
            <option value="Excellent" title="Excellent">Excellent</option>
            <option value="Mauvais" title="Mauvais">Mauvais</option>
            <option value="Moyen" title="Moyen">Moyen</option>
            <option value="Somptueux" title="Somptueux">Somptueux</option>
            <option value="Travaux à prévoir" title="Travaux à prévoir">Travaux à prévoir</option>
            <option value="Très bon" title="Très bon">Très bon</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">État Communs</label>
          <select class="form-select" name="C_1736" id="C_1736" v-model="modele['1736']">
            <option value="" selected="selected" title=""></option>
            <option value="Bon" title="Bon">Bon</option>
            <option value="Excellent" title="Excellent">Excellent</option>
            <option value="Mauvais" title="Mauvais">Mauvais</option>
            <option value="Moyen" title="Moyen">Moyen</option>
            <option value="TB" title="Très bon">Très bon</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Construction</label>
          <select class="form-select" name="C_113" id="C_113" v-model="modele['113']">
            <option value="" selected="selected" title=""></option>
            <option value="Bardage" title="Bardage">Bardage</option>
            <option value="Béton" title="Béton">Béton</option>
            <option value="Béton cellulaire" title="Béton cellulaire">Béton cellulaire</option>
            <option value="Biobrique" title="Bio-brique">Bio-brique</option>
            <option value="Bois" title="Bois">Bois</option>
            <option value="BoisBrique" title="Bois + Brique">Bois + Brique</option>
            <option value="Brique" title="Brique">Brique</option>
            <option value="Colombages" title="Colombages">Colombages</option>
            <option value="Crepis" title="Crépis">Crépis</option>
            <option value="Enduit" title="Enduit">Enduit</option>
            <option value="Granit" title="Granit">Granit</option>
            <option value="Machefer" title="Mâchefer">Mâchefer</option>
            <option value="Marbre" title="Marbre">Marbre</option>
            <option value="Meulière" title="Meulière">Meulière</option>
            <option value="Monobloc" title="Monobloc">Monobloc</option>
            <option value="Mosaïque" title="Mosaïque">Mosaïque</option>
            <option value="OssatureBois" title="Ossature bois">Ossature bois</option>
            <option value="Ossature_bois" title="Ossature bois">Ossature bois</option>
            <option value="OssatureMetallique" title="Ossature métallique">Ossature métallique</option>
            <option value="Parpaing" title="Parpaing">Parpaing</option>
            <option value="ParpaingsBrique" title="Parpaing + Brique">Parpaing + Brique</option>
            <option value="Pierre" title="Pierre">Pierre</option>
            <option value="PierreParis" title="Pierre de Paris">Pierre de Paris</option>
            <option value="Pierredetaille" title="Pierre de taille">Pierre de taille</option>
            <option value="Pierre et brique" title="Pierre et brique">Pierre et brique</option>
            <option value="PierreParpaing" title="Pierre et Parpaing">Pierre et Parpaing</option>
            <option value="PierreTerre" title="Pierre et terre">Pierre et terre</option>
            <option value="Préfabriqué" title="Préfabriqué">Préfabriqué</option>
            <option value="Terre" title="Terre">Terre</option>
            <option value="Torchis" title="Torchis">Torchis</option>
            <option value="Travertin" title="Travertin">Travertin</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Style</label>
          <select class="form-select" name="C_226" id="C_226" v-model="modele['226']">
            <option value="" selected="selected" title=""></option>
            <option value="Ancien" title="Ancien">Ancien</option>
            <option value="AncienneFerme" title="Ancienne ferme">Ancienne ferme</option>
            <option value="AngloNormand" title="Anglo-normand">Anglo-normand</option>
            <option value="Annees20" title="Années 20">Années 20</option>
            <option value="Annees_2000" title="Années 2000">Années 2000</option>
            <option value="Annees30" title="Années 30">Années 30</option>
            <option value="Annees50" title="Années 50">Années 50</option>
            <option value="Annees60" title="Années 60">Années 60</option>
            <option value="Annees70" title="Années 70">Années 70</option>
            <option value="Annees80" title="Années 80">Années 80</option>
            <option value="Annees90" title="Années 90">Années 90</option>
            <option value="Arcachonaise" title="Arcachonnaise">Arcachonnaise</option>
            <option value="Architecte" title="Architecte">Architecte</option>
            <option value="BaroquePortugais" title="Baroque portugais">Baroque portugais</option>
            <option value="BelEtage" title="Bel-étage">Bel-étage</option>
            <option value="Bourgeois" title="Bourgeois">Bourgeois</option>
            <option value="Briques" title="Briques">Briques</option>
            <option value="Cabanon" title="Cabanon">Cabanon</option>
            <option value="Charentaise" title="Charentaise">Charentaise</option>
            <option value="Chateau" title="Château">Château</option>
            <option value="Chaumiere" title="Chaumière">Chaumière</option>
            <option value="Colombage" title="Colombage">Colombage</option>
            <option value="Colonial" title="Colonial">Colonial</option>
            <option value="Contemporain" title="Contemporain">Contemporain</option>
            <option value="CorpsFerme" title="Corps de ferme">Corps de ferme</option>
            <option value="Duplex" title="Duplex">Duplex</option>
            <option value="Echoppe" title="Echoppe">Echoppe</option>
            <option value="Fermette" title="Fermette">Fermette</option>
            <option value="Grange" title="Grange">Grange</option>
            <option value="Assienda" title="Hacienda">Hacienda</option>
            <option value="Haussmannien" title="Haussmannien">Haussmannien</option>
            <option value="Landaise" title="Landaise">Landaise</option>
            <option value="Logis" title="Logis">Logis</option>
            <option value="Longere" title="Longère">Longère</option>
            <option value="MaisonBasque" title="Maison Basque">Maison Basque</option>
            <option value="MaisonBriarde" title="Maison Briarde">Maison Briarde</option>
            <option value="MaisonMaitre" title="Maison de maître">Maison de maître</option>
            <option value="MaisonDePays" title="Maison de pays">Maison de pays</option>
            <option value="MaisonVille" title="Maison de ville">Maison de ville</option>
            <option value="MaisonPlainPied" title="Maison plain pied">Maison plain pied</option>
            <option value="MaisonSemiPlainPied" title="Maison semi plain-pied">Maison semi plain-pied</option>
            <option value="Manoir" title="Manoir">Manoir</option>
            <option value="Mas" title="Mas">Mas</option>
            <option value="Merjoyante" title="Merjoyante">Merjoyante</option>
            <option value="Moderne" title="Moderne">Moderne</option>
            <option value="NeoColonial" title="Neo colonial">Neo colonial</option>
            <option value="NeoBreton" title="Néo-Breton">Néo-Breton</option>
            <option value="NeoProvencale" title="Néo-Provençal">Néo-Provençal</option>
            <option value="Neuf" title="Neuf">Neuf</option>
            <option value="Normande" title="Normande">Normande</option>
            <option value="Palais" title="Palais">Palais</option>
            <option value="Pavillon" title="Pavillon">Pavillon</option>
            <option value="Presbytere" title="Presbytère">Presbytère</option>
            <option value="ProprieteCampagne" title="Propriété de campagne">Propriété de campagne</option>
            <option value="Provencale" title="Provençal">Provençal</option>
            <option value="Ruines" title="Ruines">Ruines</option>
            <option value="Traditionnel" title="Traditionnel">Traditionnel</option>
            <option value="Triplex" title="Triplex">Triplex</option>
            <option value="Troglodyte" title="Troglodyte">Troglodyte</option>
            <option value="Victorien" title="Victorien">Victorien</option>
            <option value="XIX" title="XIX">XIX</option>
            <option value="XVII" title="XVII">XVII</option>
            <option value="XVIII" title="XVIII">XVIII</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Fenêtres</label>
          <select class="form-select" name="C_117" id="C_117" v-model="modele['117']">
            <option value="" selected="selected" title=""></option>
            <option value="Aluminium" title="Aluminium">Aluminium</option>
            <option value="Aluminium_double" title="Aluminium Double Vitrage">Aluminium Double Vitrage</option>
            <option value="Aluminium_simple" title="Aluminium Simple Vitrage">Aluminium Simple Vitrage</option>
            <option value="Bois double vitrage" title="Bois double vitrage">Bois double vitrage</option>
            <option value="Bois simple vitrage" title="Bois simple vitrage">Bois simple vitrage</option>
            <option value="DoubleVitrage" title="Double vitrage">Double vitrage</option>
            <option value="MixAluPvc" title="Mixte alu et pvc">Mixte alu et pvc</option>
            <option value="MixteBoisPvc" title="Mixte Bois - PVC">Mixte Bois - PVC</option>
            <option value="MixteBoisAlu" title="Mixte Bois et Alu">Mixte Bois et Alu</option>
            <option value="P.V.C." title="P.V.C.">P.V.C.</option>
            <option value="PVC_Double" title="PVC Double Vitrage">PVC Double Vitrage</option>
            <option value="PVC_Simple" title="PVC Simple Vitrage">PVC Simple Vitrage</option>
            <option value="Simple et double vitrage" title="Simple et double vitrage">Simple et double vitrage</option>
            <option value="Survitrage" title="Survitrage">Survitrage</option>
            <option value="TripleVitrage" title="Triple Vitrage">Triple Vitrage</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Fenêtres</label>
          <select class="form-select" name="C_237" id="C_237" v-model="modele['237']">
            <option value="" selected="selected" title=""></option>
            <option value="Aluminium" title="Aluminium">Aluminium</option>
            <option value="Bois" title="Bois">Bois</option>
            <option value="ElectriqueMétalique" title="Electrique Métallique">Electrique Métallique</option>
            <option value="ElectriquePVC" title="Electrique PVC">Electrique PVC</option>
            <option value="Metalique" title="Métallique">Métallique</option>
            <option value="Pliants" title="Pliants">Pliants</option>
            <option value="PVC" title="PVC">PVC</option>
            <option value="PVC_Roulant" title="PVC Roulant">PVC Roulant</option>
            <option value="Roulants" title="Roulants">Roulants</option>
            <option value="Roulants_Elec" title="Roulants électriques">Roulants électriques</option>
            <option value="Sans" title="Sans">Sans</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Isolation</label>
          <select class="form-select" name="C_222" id="C_222" v-model="modele['222']">
            <option value="" selected="selected" title=""></option>
            <option value="Aucune" title="Aucune">Aucune</option>
            <option value="DoublePeau" title="Double peau">Double peau</option>
            <option value="Exterieur" title="isolation par l'extérieur">isolation par l'extérieur</option>
            <option value="LaineRoche" title="Laine de roche">Laine de roche</option>
            <option value="LaineVerre" title="Laine de verre">Laine de verre</option>
            <option value="Oui" title="Oui">Oui</option>
            <option value="Toit" title="Par le toit">Par le toit</option>
            <option value="ToitMurs" title="Par le toit et les murs">Par le toit et les murs</option>
            <option value="Murs" title="Par les murs">Par les murs</option>
            <option value="SimplePeau" title="Simple peau">Simple peau</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Certificat assainissement</label><br />
          <MidToggleVue v-model="modele['224']" />
        </div>
        <div class="col-6">
          <label class="form-label">Certificat assainissement</label><br />
          <select class="form-select" name="C_120" id="C_120" v-model="modele['120']">
            <option value="" selected="selected" title=""></option>
            <option value="FosseRefaire" title="Fosse à refaire">Fosse à refaire</option>
            <option value="FosseMorte" title="Fosse morte">Fosse morte</option>
            <option value="Fosse septique" title="Fosse septique">Fosse septique</option>
            <option value="FosseToutesEaux" title="Fosse toutes eaux">Fosse toutes eaux</option>
            <option value="Micro_station" title="Micro-station">Micro-station</option>
            <option value="Sans" title="Sans">Sans</option>
            <option value="Séparation des eaux" title="Séparation des eaux">Séparation des eaux</option>
            <option value="ToutAEgout" title="Tout à l'égout">Tout à l'égout</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="interieur" class="card mt-4">
    <div class="card-header">
      <h5>Intérieur</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Chambres</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['38']" />
        </div>
        <div class="col-6">
          <label class="form-label">Chambres possibles</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2098']" />
        </div>
        <div class="col-6">
          <label class="form-label">Cuisine</label>
          <select class="form-select" name="C_42" id="C_42" v-model="modele['42']">
            <option value="" selected="selected" title=""></option>
            <option value="Amenagée" title="Aménagée">Aménagée</option>
            <option value="AmenageeEquipee" title="Aménagée/équipée">Aménagée/équipée</option>
            <option value="Américaine" title="Américaine">Américaine</option>
            <option value="Américaine Amenagée" title="Américaine Amenagée">Américaine Amenagée</option>
            <option value="Américaine Amenagée Equipée" title="Américaine Amenagée Equipée">Américaine Amenagée Equipée
            </option>
            <option value="Américaine Equipée" title="Américaine Equipée">Américaine Equipée</option>
            <option value="Américaine meublée" title="Américaine meublée">Américaine meublée</option>
            <option value="Coin Repas" title="Coin Repas">Coin Repas</option>
            <option value="Equipee" title="Equipée">Equipée</option>
            <option value="Independante" title="Indépendante">Indépendante</option>
            <option value="Indépendante Aménagée" title="Indépendante Aménagée">Indépendante Aménagée</option>
            <option value="IndependanteEquipee" title="Indépendante Equipée">Indépendante Equipée</option>
            <option value="Indépendante meublée" title="Indépendante meublée">Indépendante meublée</option>
            <option value="IndependanteAmenageeEquipee" title="Indépendante, aménagée et équipée">Indépendante, aménagée
              et équipée</option>
            <option value="Kitchenet" title="Kitchenette">Kitchenette</option>
            <option value="Meublée" title="Meublée">Meublée</option>
            <option value="Nue" title="Nue">Nue</option>
            <option value="Ouverte" title="Ouverte">Ouverte</option>
            <option value="Ouverte Aménagée" title="Ouverte Aménagée">Ouverte Aménagée</option>
            <option value="Ouverte Aménagée Equipée" title="Ouverte Aménagée Equipée">Ouverte Aménagée Equipée</option>
            <option value="Ouverte Equipée" title="Ouverte Equipée">Ouverte Equipée</option>
            <option value="Ouverte meublée" title="Ouverte meublée">Ouverte meublée</option>
            <option value="Sans cuisine" title="Sans cuisine">Sans cuisine</option>
            <option value="Semi-ouverte" title="Semi-ouverte">Semi-ouverte</option>
            <option value="Semi-ouverte Aménagée" title="Semi-ouverte Aménagée">Semi-ouverte Aménagée</option>
            <option value="Semi-ouverte Aménagée Equipée" title="Semi-ouverte Aménagée Equipée">Semi-ouverte Aménagée
              Equipée</option>
            <option value="Semi-ouverte Equipée" title="Semi-ouverte Equipée">Semi-ouverte Equipée</option>
            <option value="Semi-ouverte meublée" title="Semi-ouverte meublée">Semi-ouverte meublée</option>
            <option value="Simple" title="Simple">Simple</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Nombre niveaux</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['40']" />
        </div>
        <div class="col-6">
          <label class="form-label">Exposition Séjour</label>
          <select class="form-select" name="C_1728" id="C_1728" v-model="modele['1728']">
            <option value="" selected="selected" title=""></option>
            <option value="E" title="EST">EST</option>
            <option value="EN" title="EST - NORD">EST - NORD</option>
            <option value="EO" title="EST - OUEST">EST - OUEST</option>
            <option value="ES" title="EST - SUD">EST - SUD</option>
            <option value="N" title="NORD">NORD</option>
            <option value="NE" title="NORD EST">NORD EST</option>
            <option value="NO" title="NORD OUEST">NORD OUEST</option>
            <option value="O" title="OUEST">OUEST</option>
            <option value="ON" title="OUEST - NORD">OUEST - NORD</option>
            <option value="OS" title="OUEST - SUD">OUEST - SUD</option>
            <option value="S" title="SUD">SUD</option>
            <option value="SN" title="SUD - NORD">SUD - NORD</option>
            <option value="SE" title="SUD EST">SUD EST</option>
            <option value="SO" title="SUD OUEST">SUD OUEST</option>
          </select>
        </div>


        <div class="col-6">
          <label class="form-label">Séjour double</label><br />
          <MidToggleVue v-model="modele['155']" />
        </div>
        <div class="col-6">
          <label class="form-label">Type Chauffage</label><br />
          <select class="form-select" name="C_45" id="C_45" v-model="modele['45']">
            <option value="" selected="selected" title=""></option>
            <option value="Collectif" title="Collectif">Collectif</option>
            <option value="CollectifComptageIndividuel" title="Collectif avec comptage individuel">Collectif avec comptage
              individuel</option>
            <option value="Individuel" title="Individuel">Individuel</option>
            <option value="Mixte" title="Mixte">Mixte</option>
            <option value="Sans" title="Sans">Sans</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Méca. Chauffage</label><br />
          <select class="form-select" name="C_98" id="C_98" v-model="modele['98']">
            <option value="" selected="selected" title=""></option>
            <option value="Accumulateurs" title="Accumulateurs">Accumulateurs</option>
            <option value="Air pulsé" title="Air pulsé">Air pulsé</option>
            <option value="Au Sol" title="Au Sol">Au Sol</option>
            <option value="Convecteurs" title="Convecteurs">Convecteurs</option>
            <option value="Mixte_Sol_Convecteur" title="Mixte Sol / Convecteurs">Mixte Sol / Convecteurs</option>
            <option value="Plafond" title="Plafond">Plafond</option>
            <option value="Radians" title="Radiants">Radiants</option>
            <option value="Radiateur" title="Radiateur">Radiateur</option>
            <option value="Sol et plafond" title="Sol et plafond">Sol et plafond</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Méca. Chauffage</label><br />
          <select class="form-select" name="C_99" id="C_99" v-model="modele['9']">
            <option value="" selected="selected" title=""></option>
            <option value="Aerothermie" title="Aérothermie">Aérothermie</option>
            <option value="Aucun" title="Aucun">Aucun</option>
            <option value="Autres" title="Autres">Autres</option>
            <option value="Bois" title="Bois">Bois</option>
            <option value="Bois_Electrique" title="Bois + Electrique">Bois + Electrique</option>
            <option value="Charbon" title="Charbon">Charbon</option>
            <option value="Chaudière à granulés" title="Chaudière à granulés">Chaudière à granulés</option>
            <option value="Chauffagedeville" title="Chauffage de ville">Chauffage de ville</option>
            <option value="ChauffeEauThermo" title="Chauffe-eau thermodynamique">Chauffe-eau thermodynamique</option>
            <option value="Climatisation_Reversible" title="Climatisation réversible">Climatisation réversible</option>
            <option value="Climatisation_Reversible_Electrique" title="Climatisation réversible + électrique">
              Climatisation réversible + électrique</option>
            <option value="Electrique" title="Electrique">Electrique</option>
            <option value="Eolienne" title="Eolienne">Eolienne</option>
            <option value="Fuel" title="Fuel">Fuel</option>
            <option value="Fuel_Electrique" title="Fuel + électrique">Fuel + électrique</option>
            <option value="Gaz" title="Gaz">Gaz</option>
            <option value="Turbine" title="Gaz (turbine)">Gaz (turbine)</option>
            <option value="GazChauffeEauSolaire" title="Gaz + chauffe-eau solaire">Gaz + chauffe-eau solaire</option>
            <option value="GazChauffeEauThermo" title="Gaz + chauffe-eau thermodynamique">Gaz + chauffe-eau
              thermodynamique</option>
            <option value="GazPompeChaleur" title="Gaz + Pompe à chaleur">Gaz + Pompe à chaleur</option>
            <option value="Gazdeville" title="Gaz de ville">Gaz de ville</option>
            <option value="Geothermie" title="Géothermie">Géothermie</option>
            <option value="Mixte" title="Mixte">Mixte</option>
            <option value="PanneauxSolaires" title="Panneaux solaires">Panneaux solaires</option>
            <option value="poele" title="Poêle à granulés">Poêle à granulés</option>
            <option value="Poêle hybride bois et granulés" title="Poêle hybride bois et granulés">Poêle hybride bois et
              granulés</option>
            <option value="PompeChaleur" title="Pompe à chaleur">Pompe à chaleur</option>
            <option value="PompeChaleurChauffeEau" title="Pompe à chaleur + chauffe-eau thermodynamique/solaire">Pompe à
              chaleur + chauffe-eau thermodynamique/solaire</option>
            <option value="Pompe à chaleur + fuel" title="Pompe à chaleur + fuel">Pompe à chaleur + fuel</option>
            <option value="pompe_chaleur_air_air" title="Pompe à chaleur air/air">Pompe à chaleur air/air</option>
            <option value="Pompe_a_chaleur_air_eau" title="Pompe à chaleur air/eau">Pompe à chaleur air/eau</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Commentaire chauffage</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['221']" />
        </div>
        <div class="col-6">
          <label class="form-label">Eau chaude</label>
          <select class="form-select" name="C_101" id="C_101" v-model="modele['101']">
            <option value="" selected="selected" title=""></option>
            <option value="Ballon électrique" title="Ballon électrique">Ballon électrique</option>
            <option value="Chaudière" title="Chaudière">Chaudière</option>
            <option value="Chauffage central" title="Chauffage central">Chauffage central</option>
            <option value="ChauffeEau" title="Chauffe eau">Chauffe eau</option>
            <option value="Collective" title="Collective">Collective</option>
            <option value="collective_compteur" title="Collective avec compteur">Collective avec compteur</option>
            <option value="collective_milliemes" title="collective millièmes">collective millièmes</option>
            <option value="Gaz" title="Gaz">Gaz</option>
            <option value="Geothermie" title="Géothermie">Géothermie</option>
            <option value="Individuel" title="Individuelle">Individuelle</option>
            <option value="PanneauxSolaires" title="Panneaux solaires">Panneaux solaires</option>
            <option value="Thermodynamique" title="Thermodynamique">Thermodynamique</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Commentaire chauffage</label>
          <select class="form-select" name="C_119" id="C_119" v-model="modele['119']">
            <option value="" selected="selected" title=""></option>
            <option value="Autre" title="Autre">Autre</option>
            <option value="Conduit_existant" title="Conduit existant">Conduit existant</option>
            <option value="Foyer_ouvert" title="Foyer ouvert">Foyer ouvert</option>
            <option value="Insert" title="Insert">Insert</option>
            <option value="Oui" title="Oui">Oui</option>
            <option value="Non" title="Pas de conduit">Pas de conduit</option>
            <option value="PoeleABois" title="Poêle à bois">Poêle à bois</option>
            <option value="PoeleAFioul" title="Poêle à fioul">Poêle à fioul</option>
            <option value="Poele_Granules" title="Poêle à granulés">Poêle à granulés</option>
            <option value="PoeleDeMasse" title="Poêle de masse">Poêle de masse</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Calme</label><br />
          <MidToggleVue v-model="modele['142']" />
        </div>
        <div class="col-6">
          <label class="form-label">Clair</label><br />
          <MidToggleVue v-model="modele['143']" />
        </div>



      </div>
    </div>
  </div>

  <div id="others" class="card mt-4">
    <div class="card-header">
      <h5>Autres</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-3">
          <label class="form-label">Ascenseur</label><br />
          <MidToggleVue v-model="modele['41']" />
        </div>
        <div class="col-3">
          <label class="form-label">Cave(s)</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['50']" />
        </div>
        <div class="col-3">
          <label class="form-label">Grenier</label><br />
          <MidToggleVue v-model="modele['115']" />
        </div>
        <div class="col-6">
          <label class="form-label">Nombre de terrasses</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['47']" />
        </div>
        <div class="col-6">
          <label class="form-label">Type de stationnement</label>
          TO DO (172)
        </div>
        <div class="col-3">
          <label class="form-label">Nombre places parking</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['49']" />
        </div>
        <div class="col-3">
          <label class="form-label">Nombre garages/Box</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['48']" />
        </div>
        <div class="col-3">
          <label class="form-label">Nombre box</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2179']" />
        </div>
        <div class="col-3">
          <label class="form-label">Nombre parking intérieur</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2181']" />
        </div>
        <div class="col-3">
          <label class="form-label">Nombre parking extérieur</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2183']" />
        </div>
        <div class="col-3">
          <label class="form-label">Fibre Optique</label><br />
          <MidToggleVue v-model="modele['2138']" />
        </div>
        <div class="col-12">
          <label class="form-label">Commentaire internet</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2757']" />
        </div>
        <div class="col-3">
          <label class="form-label">Interphone</label><br />
          <MidToggleVue v-model="modele['156']" />
        </div>
        <div class="col-3">
          <label class="form-label">Digicode</label><br />
          <MidToggleVue v-model="modele['136']" />
        </div>
        <div class="col-3">
          <label class="form-label">Visiophone</label><br />
          <MidToggleVue v-model="modele['213']" />
        </div>
        <div class="col-3">
          <label class="form-label">Alarme</label><br />
          <MidToggleVue v-model="modele['227']" />
        </div>
        <div class="col-3">
          <label class="form-label">Piscine</label><br />
          <MidToggleVue v-model="modele['110']" />
        </div>
        <div class="col-3">
          <label class="form-label">Accès PMR</label><br />
          <MidToggleVue v-model="modele['102']" />
        </div>
        <div class="col-3">
          <label class="form-label">Climatisation</label><br />
          <MidToggleVue v-model="modele['112']" />
        </div>
        <div class="col-3">
          <label class="form-label">Sous-sol</label><br />
          <MidToggleVue v-model="modele['109']" />
        </div>
        <div class="col-3">
          <label class="form-label">Panneau Solaire</label><br />
          <MidToggleVue v-model="modele['2760']" />
        </div>
        <div class="col-3">
          <label class="form-label">Gardien</label><br />
          <MidToggleVue v-model="modele['145']" />
        </div>
        <div class="col-3">
          <label class="form-label">Véranda</label><br />
          <MidToggleVue v-model="modele['118']" />
        </div>
        <div class="col-12">
          <label class="form-label">Travaux à prévoir</label>
          <textarea class="form-control" placeholder="Adresse" v-model="modele['239']"></textarea>
        </div>
      </div>
    </div>
  </div>


  <div id="terrain" class="card mt-4">
    <div class="card-header">
      <h5>Terrain</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <EndInput v-model="modele['2002']" title="Emprise Servitude" end="m²" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['1940']" title="Longueur" end="m" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['1938']" title="SHON" end="m²" />
        </div>
        <div class="col-6">
          <label class="form-label">Bâtiment à démolir</label><br />
          <MidToggleVue v-model="modele['185']" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2241']" title="Surface de plancher" end="m²" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2032']" title="Surface à démolir" end="m²" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2033']" title="Hauteur à démolir" end="m" />
        </div>
        <div class="col-6">
          <label class="form-label">Viabilité</label><br />
          <MidToggleVue v-model="modele['175']" />
        </div>
        <div class="col-6">
          <label class="form-label">COS</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['267']" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2000']" title="Surface minimale pour batir" end="m²" />
        </div>
        <div class="col-6">
          <label class="form-label">CES</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['195']" />
        </div>
        <div class="col-6">
          <label class="form-label">Aspect</label>
          TO DO (194)
        </div>
        <div class="col-6">
          <label class="form-label">PLU</label><br />
          <MidToggleVue v-model="modele['190']" />
        </div>
        <div class="col-6">
          <label class="form-label">Référence du lot</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['191']" />
        </div>
        <div class="col-6">
          <label class="form-label">Nombre de lots</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['192']" />
        </div>
        <div class="col-6">
          <label class="form-label">Régit particulier lotissement</label><br />
          <MidToggleVue v-model="modele['193']" />
        </div>
        <div class="col-6">
          <label class="form-label">Aménagement</label>
          <select class="form-select" name="C_216" id="C_216" v-model="modele['216']">
            <option value="" selected="selected" title=""></option>
            <option value="AAmenager" title="A aménager">A aménager</option>
            <option value="Arbore" title="Arboré">Arboré</option>
            <option value="EnFriche" title="En Friche">En Friche</option>
            <option value="Paysage" title="Paysagé">Paysagé</option>
            <option value="Pelouse" title="Pelouse">Pelouse</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Câble</label>
          <select class="form-select" name="C_182" id="C_182" v-model="modele['182']">
            <option value="" selected="selected" title=""></option>
            <option value="Non" title="Non">Non</option>
            <option value="Oui" title="Oui">Oui</option>
            <option value="Possible" title="Possible">Possible</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">EDF</label>
          TO DO (181)
        </div>
        <div class="col-6">
          <label class="form-label">Certificat d'urbanisme</label><br />
          <MidToggleVue v-model="modele['189']" />
        </div>
        <div class="col-6">
          <label class="form-label">Construction libre</label><br />
          <MidToggleVue v-model="modele['188']" />
        </div>
        <div class="col-6">
          <label class="form-label">Gaz</label><br />
          <MidToggleVue v-model="modele['180']" />
        </div>
        <div class="col-6">
          <label class="form-label">Eau ville</label>
          <select class="form-select" name="C_179" id="C_179" v-model="modele['179']">
            <option value="" selected="selected" title=""></option>
            <option value="Non" title="Non">Non</option>
            <option value="Oui" title="Oui">Oui</option>
            <option value="Possible" title="Possible">Possible</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Téléphone</label>
          <select class="form-select" name="C_176" id="C_176" v-model="modele['176']">
            <option value="" selected="selected" title=""></option>
            <option value="Non" title="Non">Non</option>
            <option value="Oui" title="Oui">Oui</option>
            <option value="Possible" title="Possible">Possible</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Zonage</label>
          <select class="form-select" name="C_242" id="C_242" v-model="modele['242']">
            <option value="" selected="selected" title=""></option>
            <option value="A" title="A (N.C)">A (N.C)</option>
            <option value="AU" title="A.U. (N.A-N.B)">A.U. (N.A-N.B)</option>
            <option value="Autre" title="Autre">Autre</option>
            <option value="N" title="N (N.D)">N (N.D)</option>
            <option value="U" title="U (U)">U (U)</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Assainissement</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['243']" />


        </div>
        <div class="col-6">
          <label class="form-label">Eaux pluviales</label>
          <select class="form-select" name="C_177" id="C_177" v-model="modele['177']">
            <option value="" selected="selected" title=""></option>
            <option value="Non" title="Non">Non</option>
            <option value="Oui" title="Oui">Oui</option>
            <option value="Possible" title="Possible">Possible</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Eaux usées</label>
          <select class="form-select" name="C_178" id="C_178" v-model="modele['178']">
            <option value="" selected="selected" title=""></option>
            <option value="Non" title="Non">Non</option>
            <option value="Oui" title="Oui">Oui</option>
            <option value="Possible" title="Possible">Possible</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Assainissement collectif</label><br />
          <MidToggleVue v-model="modele['183']" />
        </div>
        <div class="col-6">
          <label class="form-label">Assainissement collectif</label><br />
          <select class="form-select" name="C_184" id="C_184" v-model="modele['184']">
            <option value="" selected="selected" title=""></option>
            <option value="Afu" title="Afu">Afu</option>
            <option value="Aglomeration" title="Agglomération">Agglomération</option>
            <option value="Centre ville" title="Centre ville">Centre ville</option>
            <option value="HorsAglomeration" title="Hors agglomération">Hors agglomération</option>
            <option value="ZAC" title="ZAC">ZAC</option>
            <option value="ZAD" title="ZAD">ZAD</option>
            <option value="ZIF" title="ZIF">ZIF</option>
            <option value="Zone artisanal" title="Zone artisanale">Zone artisanale</option>
            <option value="Zone industriel" title="Zone industrielle">Zone industrielle</option>
            <option value="ZoneRural" title="Zone rural">Zone rural</option>
            <option value="ZUP" title="ZUP">ZUP</option>
          </select>
        </div>





        <div class="col-6">
          <label class="form-label"> Emplacement compteur, assainissement</label>
          <textarea class="form-control" placeholder="" v-model="modele['241']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Informations complémentaires</label>
          <textarea class="form-control" placeholder="" v-model="modele['238']"></textarea>
        </div>


      </div>
    </div>
  </div>

  <div id="mandat" class="card mt-4">
    <div class="card-header">
      <h5>Mandat</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Référence Affaire</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['57']" />
        </div>
        <div class="col-6">
          <label class="form-label">N° mandat</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['26']" />
        </div>
        <div class="col-6">
          <label class="form-label">Dernière mise à jour</label>

          <flat-pickr v-model="modele['29']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Origine de l'information</label>
          <select name="C_2345" id="C_2345" v-model="modele['2345']">
            <option value="" selected="selected" title=""></option>
            <option value="Agent_Affaires" title="Agent d'affaires">Agent d'affaires</option>
            <option value="Autre" title="Autre">Autre</option>
            <option value="Client" title="Client">Client</option>
            <option value="Institutionnels" title="Institutionnels (CCI, mairie,...)">Institutionnels (CCI, mairie,...)
            </option>
            <option value="Partenaire_Reseau" title="Partenaire, réseau">Partenaire, réseau</option>
            <option value="Pétroliers" title="Pétroliers">Pétroliers</option>
            <option value="Promoteur" title="Promoteur">Promoteur</option>
            <option value="Prospect" title="Prospect">Prospect</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Garantie Loyer</label>
          <select name="C_1856" id="C_1856" v-model="modele['1856']">
            <option value="" selected="selected" title=""></option>
            <option value="Caution" title="Caution">Caution</option>
            <option value="Garant" title="Garant">Garant</option>
            <option value="GLI" title="GLI">GLI</option>
            <option value="GLI_Caution" title="GLI_Caution">GLI_Caution</option>
            <option value="Non" title="Non">Non</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Forme Mandat</label>
          <select name="C_1914" id="C_1914" v-model="modele['1914']">
            <option value="" title=""></option>
            <option value="Location" selected="selected" title="Location">Location</option>
            <option value="Recherche" title="Recherche">Recherche</option>
            <option value="Vente" title="Vente">Vente</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Type Mandat</label>
          <select name="C_124" id="C_124" v-model="modele['124']">
            <option value="" selected="selected" title=""></option>
            <option value="Delegation" title="Délégation">Délégation</option>
            <option value="Exclusif" title="Exclusif">Exclusif</option>
            <option value="Gestion" title="Gestion">Gestion</option>
            <option value="Privilegie" title="Privilégié">Privilégié</option>
            <option value="Recherche" title="Recherche">Recherche</option>
            <option value="SemiExclusif" title="Semi-Exclusif">Semi-Exclusif</option>
            <option value="Simple" title="Simple">Simple</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Informations complémentaires</label>
          <textarea class="form-control" placeholder="" v-model="modele['1908']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Date Mandat</label>

          <flat-pickr v-model="modele['163']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Date Mandat</label>

          <flat-pickr v-model="modele['163']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Disponibilité</label>

          <flat-pickr v-model="modele['90']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Inter Cabinet</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['244']" />
        </div>
        <div class="col-6">
          <label class="form-label">Démarchage à domicile</label><br />
          <MidToggleVue v-model="modele['2189']" />
        </div>
        <div class="col-6">
          <label class="form-label">Accord de commercialisation</label><br />
          <MidToggleVue v-model="modele['2331']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date échéance</label>

          <flat-pickr v-model="modele['66']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Date renouvellement</label>

          <flat-pickr v-model="modele['125']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Notaire</label>
          <textarea class="form-control" placeholder="" v-model="modele['164']"></textarea>
          TO DO (164)
        </div>
        <div class="col-6">
          <label class="form-label">Syndic</label>
          <textarea class="form-control" placeholder="" v-model="modele['165']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Géomètre</label>
          <textarea class="form-control" placeholder="" v-model="modele['1796']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Apporteur d'affaire</label>
          <textarea class="form-control" placeholder="" v-model="modele['1765']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Nom section cadastrale</label>
          <textarea class="form-control" placeholder="" v-model="modele['2379']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Numéro cadastre</label>
          <textarea class="form-control" placeholder="" v-model="modele['207']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Lieu dit cadastre</label>
          <textarea class="form-control" placeholder="" v-model="modele['2380']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Contenance</label>
          <textarea class="form-control" placeholder="" v-model="modele['2381']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Dommage Ouvrage</label><br />
          <MidToggleVue v-model="modele['168']" />
        </div>
        <div class="col-6">
          <label class="form-label">Certificat conformité</label><br />
          <MidToggleVue v-model="modele['169']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date achèvement travaux</label>

          <flat-pickr v-model="modele['170']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Certificat conformité</label><br />
          <MidToggleVue v-model="modele['2538']" />
        </div>
        <div class="col-6">
          <label class="form-label">Cause vente/location</label><br />
          <select name="C_199" id="C_199" v-model="modele['199']">
            <option value="" selected="selected" title=""></option>
            <option value="Achat_souhait" title="Achat (souhait)">Achat (souhait)</option>
            <option value="Achat" title="Achat réalisé">Achat réalisé</option>
            <option value="ArretLocation" title="Arrêt location">Arrêt location</option>
            <option value="Autre investissement" title="Autre investissement">Autre investissement</option>
            <option value="Bien actuel non adapté" title="Bien actuel non adapté">Bien actuel non adapté</option>
            <option value="Construction" title="Construction en cours">Construction en cours</option>
            <option value="ConstructionSouhait" title="Constuction (Souhait)">Constuction (Souhait)</option>
            <option value="Destockage" title="Destockage">Destockage</option>
            <option value="Maladie" title="Maladie">Maladie</option>
            <option value="Mutation" title="Mutation">Mutation</option>
            <option value="Personnel" title="Personnel">Personnel</option>
            <option value="Perte emploi" title="Perte emploi">Perte emploi</option>
            <option value="Professionnel" title="Professionnel">Professionnel</option>
            <option value="acquisition" title="projet d'acquisition">projet d'acquisition</option>
            <option value="Retraite" title="Retraite">Retraite</option>
            <option value="Separation" title="Séparation">Séparation</option>
            <option value="Succession" title="Succession">Succession</option>
            <option value="TropGrand" title="Trop grand">Trop grand</option>
            <option value="TropPetit" title="Trop petit">Trop petit</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Détail Cause Vente/Location</label>
          <textarea class="form-control" placeholder="" v-model="modele['1867']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Vente aux enchères</label><br />
          <MidToggleVue v-model="modele['2761']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Butoir vente</label>

          <flat-pickr v-model="modele['251']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label">Désignation du bien</label>
          <textarea class="form-control" placeholder="" v-model="modele['206']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Affaire FNAIM</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['56']" />
        </div>





      </div>
    </div>
  </div>


  <div id="diagnostics" class="card mt-4">
    <div class="card-header">
      <h5>Diagnostics</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">

        <div class="col-4">
          <label class="form-label">Concerné par un Etat des Risques et Pollutions (ERP)</label>
          <argon-switch v-model="modele['2782']">
            Oui
          </argon-switch>
        </div>
        <div class="col-4">
          <label class="form-label">Date d'établissement Etat des Risques et Pollutions(ERP)</label>

          <flat-pickr :disabled="!modele['2782']" v-model="modele['2783']" class="form-control  datetimepicker"
            placeholder="Non renseignée" :config="config"></flat-pickr>
        </div>
        <div class="col-4">
          <label class="form-label"> Organisation Diagnostics</label>
          <select name="C_220" id="C_220" v-model="modele['220']">
            <option value="" selected="selected" title=""></option>
            <option value="Agence" title="Agence">Agence</option>
            <option value="Vendeur" title="Vendeur">Vendeur</option>
          </select>
        </div>

        <div class="col-6">
          <label class="form-label">Diagnostic Carrez</label><br />
          <MidToggleVue v-model="modele['2206']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Diagnostic Carrez</label>

          <flat-pickr v-model="modele['1846']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>


        <div class="col-6">
          <label class="form-label">Diagnostic Amiante</label><br />
          <MidToggleVue v-model="modele['166']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Diagnostic Carrez</label>

          <flat-pickr v-model="modele['1848']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>


        <div class="col-6">
          <label class="form-label">Diagnostic Parasitaire</label><br />
          <MidToggleVue v-model="modele['201']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Diagnostic Parasitaire</label>

          <flat-pickr v-model="modele['1847']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>

        <div class="col-6">
          <label class="form-label">Diagnostic Plomb</label><br />
          <MidToggleVue v-model="modele['167']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Diagnostic Plomb</label>

          <flat-pickr v-model="modele['1849']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>

        <div class="col-6">
          <label class="form-label">Diagnostic Termites</label><br />
          <MidToggleVue v-model="modele['2185']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Diagnostic Termites</label>

          <flat-pickr v-model="modele['2186']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>

        <div class="col-6">
          <label class="form-label">Diagnostic Gaz</label><br />
          <MidToggleVue v-model="modele['1766']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Diagnostic Gaz</label>

          <flat-pickr v-model="modele['1852']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>

        <div class="col-6">
          <label class="form-label">Diagnostic Électrique</label><br />
          <MidToggleVue v-model="modele['1767']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Diagnostic Électrique</label>

          <flat-pickr v-model="modele['1853']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>

        <div class="col-6">
          <label class="form-label">Diagnostic Assainissement</label><br />
          <MidToggleVue v-model="modele['2239']" />
        </div>
        <div class="col-6">
          <label class="form-label">Date Diagnostic Assainissement</label>

          <flat-pickr v-model="modele['2285']" class="form-control  datetimepicker" placeholder="Non renseignée"
            :config="config"></flat-pickr>
        </div>



        <div class="col-6">
          <label class="form-label">Diagnostic Perf. Numérique</label><br />
          <select class="form-select" name="C_2420" id="C_2420" v-model="modele['2420']">
            <option value="" selected="selected" title=""></option>
            <option value="A" title="A">A</option>
            <option value="B" title="B">B</option>
            <option value="C" title="C">C</option>
            <option value="D" title="D">D</option>
            <option value="E" title="E">E</option>
            <option value="F" title="F">F</option>
            <option value="G" title="G">G</option>
            <option value="H" title="H">H</option>
            <option value="I" title="I">I</option>
            <option value="NCO" title="Non communiqué">Non communiqué</option>
            <option value="NC" title="Non requis">Non requis</option>
            <option value="NI" title="Vierge">Vierge</option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Soumis à l'affichage du DPE </label>
          <ArgonSwitch v-model="modele['218']" />
        </div>
        <div class="col-6">
          <label class="form-label"> Date établissement Diagnostic Energétique</label>

          <flat-pickr :disabled="!modele['218']" v-model="modele['1850']" class="form-control  datetimepicker"
            placeholder="Non renseignée" :config="config"></flat-pickr>
        </div>
        <div class="col-6">
          <label class="form-label"> Date établissement Diagnostic Energétique</label>
          <select name="C_1831" id="C_1831" v-model="modele['1831']" :disabled="!modele['218']">
            <option value="" selected="selected" title=""></option>
            <option value="A" title="A">A</option>
            <option value="B" title="B">B</option>
            <option value="C" title="C">C</option>
            <option value="D" title="D">D</option>
            <option value="E" title="E">E</option>
            <option value="F" title="F">F</option>
            <option value="G" title="G">G</option>

          </select>
        </div>


        <div class="col-6">
          <EndInput v-model="modele['2755']"
            title="Estimation du coût annuel des énergies pour un usage standard (DPE réalisé avant le 01/07/2021)"
            end="€" />
        </div>
        <div class="col-6">
          <label class="form-label">Consommation énergie finale</label>
          <select name="C_2794" id="C_2794" v-model="modele['2794']">
            <option value="" selected="selected" title=""></option>
            <option value="A" title="A">A</option>
            <option value="B" title="B">B</option>
            <option value="C" title="C">C</option>
            <option value="D" title="D">D</option>
            <option value="E" title="E">E</option>
            <option value="F" title="F">F</option>
            <option value="G" title="G">G</option>
            <option value="NC" title="Non requis">Non requis</option>
          </select>
        </div>
        <div class="col-6">
          <EndInput v-model="modele['1944']" title="Valeur consommation énergie primaire" end="kWh/m2 par an" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2795']" title="Valeur consommation énergie finale" end="kWh/m2 par an" />
        </div>

        <div class="col-6">
          <label class="form-label">Gaz Effet de Serre</label>
          <select name="C_1832" id="C_1832" v-model="modele['1832']" :disabled="!modele['218']">
            <option value="" selected="selected" title=""></option>
            <option value="A" title="A">A</option>
            <option value="B" title="B">B</option>
            <option value="C" title="C">C</option>
            <option value="D" title="D">D</option>
            <option value="E" title="E">E</option>
            <option value="F" title="F">F</option>
            <option value="G" title="G">G</option>

          </select>
        </div>
        <div class="col-6">
          <EndInput v-model="modele['1950']" title=" Valeur Gaz Effet de serre" end="Kg CO2/m2/an" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2736']"
            title="Montant minimum estimé des dépenses annuelles d'énergie pour un usage standard" end="€" />
        </div>
        <div class="col-6">
          <label class="form-label"> Année de référence des prix de l'énergie</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2753']" />
        </div>
        <div class="col-6">
          <EndInput v-model="modele['2737']"
            title="Montant maximum estimé des dépenses annuelles d'énergie pour un usage standard" end="€" />
        </div>





        <div class="col-6">
          <label class="form-label">Informations complémentaires</label>
          <textarea class="form-control" placeholder="" v-model="modele['236']"></textarea>
        </div>

      </div>
    </div>
  </div>


  <div id="visites" class="card mt-4">
    <div class="card-header">
      <h5>Visites</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-12">
          <label class="form-label">Bien occupé</label><br />
          <MidToggleVue v-model="modele['263']" />
        </div>
        <div class="col-6">
          <label class="form-label">Heures de visite</label>
          <textarea class="form-control" placeholder="" v-model="modele['149']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Nom contact</label>
          <textarea class="form-control" placeholder="" v-model="modele['150']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">N° digicode</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['147']" />
        </div>
        <div class="col-6">
          <label class="form-label">N° digicode</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['151']" />
        </div>

        <div class="col-6">
          <label class="form-label">Porte</label>
          <textarea class="form-control" placeholder="" v-model="modele['141']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Consignes/Itinéraires</label>
          <textarea class="form-control" placeholder="" v-model="modele['152']"></textarea>
        </div>


        <div class="col-6">
          <label class="form-label">Numéro Clé</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['153']" />
        </div>
        <div class="col-6">
          <label class="form-label">Clé rendu le</label>
          <flat-pickr v-model="modele['200']" class="form-control  datetimepicker"
            placeholder="Non renseignée" :config="config"></flat-pickr>
        </div>

        <div class="col-6">
          <label class="form-label">N° cave</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['202']" />
        </div>
        <div class="col-6">
          <label class="form-label">N° parking</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['203']" />
        </div>

        <div class="col-4">
          <label class="form-label">Panneau</label><br />
          <MidToggleVue v-model="modele['204']" />
        </div>
        <div class="col-4">
          <label class="form-label">Date pose panneau</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['2034']" />
        </div>
        <div class="col-4">
          <label class="form-label">Date retrait panneau</label>
          <input type="text" class="form-control" placeholder="Non renseigné" v-model="modele['205']" />
        </div>

        <div class="col-12">
          <label class="form-label">Agences autorisées</label>
          <textarea class="form-control" placeholder="" v-model="modele['1733']"></textarea>
        </div>

      </div>
    </div>
  </div>

  <div id="commentaires" class="card mt-4">
    <div class="card-header">
      <h5>Commentaires</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Observations générales</label>
          <textarea class="form-control" placeholder="" v-model="modele['284']"></textarea>
        </div>
        <div class="col-6">
          <label class="form-label">Observations</label>
          <textarea class="form-control" placeholder="" v-model="modele['135']"></textarea>
        </div>

        <div class="col-6">
          <label class="form-label">Commentaires RDO</label>
          <textarea class="form-control" placeholder="" v-model="modele['2173']"></textarea>
        </div>


      </div>
    </div>
  </div>

  <!--
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">First Name</label>
          <argon-input id="firstname" type="text" placeholder="Alec" />
        </div>
        <div class="col-6">
          <label class="form-label">Last Name</label>
          <argon-input id="lastname" type="text" placeholder="Thompson" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">I'm</label>
          <select id="choices-gender" class="form-control" name="choices-gender">
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-5 col-5">
              <label class="form-label mt-2">Birth Date</label>
              <select id="choices-month" class="form-control" name="choices-month"></select>
            </div>
            <div class="col-sm-4 col-3">
              <label class="form-label mt-2">&nbsp;</label>
              <select id="choices-day" class="form-control" name="choices-day"></select>
            </div>
            <div class="col-sm-3 col-4">
              <label class="form-label mt-2">&nbsp;</label>
              <select id="choices-year" class="form-control" name="choices-year"></select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Email</label>
          <argon-input id="email" type="email" placeholder="example@email.com" />
        </div>
        <div class="col-6">
          <label class="form-label mt-2">Confirmation Email</label>
          <argon-input id="confirm-email" type="email" placeholder="example@email.com" />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Your location</label>
          <argon-input id="location" type="text" placeholder="Sydney, A" />
        </div>
        <div class="col-6">
          <label class="form-label mt-2">Phone Number</label>
          <argon-input id="phone" type="text" placeholder="+40 745 765 439" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 align-self-center">
          <label class="form-label mt-2">Language</label>
          <select id="choices-language" class="form-control" name="choices-language">
            <option value="English">English</option>
            <option value="French">French</option>
            <option value="Spanish">Spanish</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="form-label mt-2">Skills</label>
          <input id="choices-skills" class="form-control" type="text" value="vuejs, angular, react"
            placeholder="Enter something" onfocus="focused(this)" onfocusout="defocused(this)" />
        </div>
      </div>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <label class="form-label">Current password</label>
      <argon-input id="password" type="password" placeholder="Current Password" />
      <label class="form-label">New password</label>
      <argon-input id="new-password" type="password" placeholder="New Password" />
      <label class="form-label">Confirm new password</label>
      <argon-input id="confirm-password" type="password" placeholder="Confirm password" />
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="sm">Update password</argon-button>
    </div>
  </div>
  <div id="2fa" class="card mt-4">
    <div class="card-header d-flex">
      <h5 class="mb-0">Two-factor authentication</h5>
      <argon-badge color="success" class="ms-auto">Enabled</argon-badge>
    </div>
    <div class="card-body">
      <div class="d-flex">
        <p class="my-auto">Security keys</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          No Security Keys
        </p>
        <argon-button color="dark" variant="outline" size="sm" class="mb-0" type="button">Add</argon-button>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">SMS number</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">+4012374423</p>
        <argon-button color="dark" variant="outline" size="sm" class="mb-0" type="button">Edit</argon-button>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">Authenticator app</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          Not Configured
        </p>
        <argon-button color="dark" variant="outline" size="sm" class="mb-0" type="button">Set up</argon-button>
      </div>
    </div>
  </div>
  <div id="accounts" class="card mt-4">
    <div class="card-header">
      <h5>Accounts</h5>
      <p class="text-sm">
        Here you can setup and manage your integration settings.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img1" alt="logo_slack" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Slack</h5>
            <a class="text-sm text-body" href="javascript:;">
              Show less
              <i class="fas fa-chevron-up text-xs ms-1" aria-hidden="true"></i>
            </a>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-slack" name="Enabled" checked />
        </div>
      </div>
      <div class="ps-5 pt-3 ms-3">
        <p class="mb-0 text-sm">
          You haven't added your Slack yet or you aren't authorized. Please add
          our Slack Bot to your account by clicking on
          <a href="javascript">here</a>. When you've added the bot, send your
          verification code that you have received.
        </p>
        <div class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4">
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Verification Code
          </p>
          <argon-input id="verification-code" type="text" value="1172913" class="my-auto w-20 ms-sm-auto" />
        </div>
        <div class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
          <p class="text-sm font-weight-bold my-auto ps-sm-2">
            Connected account
          </p>
          <h6 class="text-sm ms-auto me-3 my-auto">hello@creative-tim.com</h6>
          <argon-button color="danger" variant="gradient" size="sm" class="my-sm-auto mt-2 mb-0" type="button"
            name="button">Delete</argon-button>
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img2" alt="logo_spotify" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Spotify</h5>
            <p class="mb-0 text-sm">Music</p>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-spotify" name="Spotify" checked />
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img3" alt="logo_atlassian" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Atlassian</h5>
            <p class="mb-0 text-sm">Payment vendor</p>
          </div>
        </div>
        <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
        <div class="my-auto">
          <argon-switch id="enable-atlassian" name="Atlassian" checked />
        </div>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <argon-avatar class="width-48-px" :img="img4" alt="logo_asana" />
        <div class="my-auto ms-3">
          <div class="h-100">
            <h5 class="mb-0">Asana</h5>
            <p class="mb-0 text-sm">Organize your team</p>
          </div>
        </div>
        <div class="my-auto ms-auto">
          <argon-switch id="enable-asana" name="Asana" />
        </div>
      </div>
    </div>
  </div>
  <div id="notifications" class="card mt-4">
    <div class="card-header">
      <h5>Notifications</h5>
      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Activity</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Email</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Push</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Mentions</span>
                  <span class="text-xs font-weight-normal">Notify when another user mentions you in a comment</span>
                </div>
              </td>
              <td>
                <argon-switch id="notify-email" name="Email"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
              <td>
                <argon-switch id="notify-push" name="Push"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center" />
              </td>
              <td>
                <argon-switch id="notify-sms" name="Sms"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center" />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Comments</span>
                  <span class="text-xs font-weight-normal">Notify when another user comments your item.</span>
                </div>
              </td>
              <td>
                <argon-switch id="notify-comment-email" name="Email"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
              <td>
                <argon-switch id="notify-comment-push" name="Push"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
              <td>
                <argon-switch id="notify-comment-sms" name="SMS"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center" />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Follows</span>
                  <span class="text-xs font-weight-normal">Notify when another user follows you.</span>
                </div>
              </td>
              <td>
                <argon-switch id="notify-follow-email" name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
              <td>
                <argon-switch id="notify-follow-push" name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
              <td>
                <argon-switch id="notify-follow-sms" name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <p class="text-sm mb-0">Log in from a new device</p>
                </div>
              </td>
              <td>
                <argon-switch id="notify-login-email" name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
              <td>
                <argon-switch id="notify-login-push" name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
              <td>
                <argon-switch id="notify-login-sms" name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center" checked />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">
        This is a list of devices that have logged into your account. Remove
        those that you do not recognize.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
            <p class="mb-0 text-xs">Your current session</p>
          </div>
        </div>
        <argon-badge color="success" size="sm" class="my-auto ms-auto me-3">Active</argon-badge>
        <p class="text-secondary text-sm my-auto me-3">EU</p>
        <a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Chrome on macOS</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-mobile text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Safari on iPhone</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a href="javascript:;" class="text-primary text-sm icon-move-right my-auto">
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>-->
  <div id="delete" class="card mt-4">
    <!--<div class="card-header">
      <h5>Delete Account</h5>
      <p class="text-sm mb-0">
        Once you delete your account, there is no going back. Please be certain.
      </p>
    </div>-->
    <!--<div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <argon-switch id="delete-account" name="Delete Account" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm">Confirm</span>
          <span class="text-xs d-block">I want to delete my account.</span>
        </div>
      </div>
      <argon-button color="secondary" variant="outline" class="mb-0 ms-auto" type="button"
        name="button">Deactivate</argon-button>
      <argon-button color="danger" variant="gradient" class="mb-0 ms-2" type="button" name="button">Delete
        Account</argon-button>
    </div>-->
  </div>
</template>

<script>
import * as Choices from "choices.js";
//import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
//import ArgonBadge from "@/components/ArgonBadge.vue";
//import ArgonAvatar from "@/components/ArgonAvatar.vue";
//import ArgonInput from "@/components/ArgonInput.vue";
import img from "../../../../assets/img/team-3.jpg";
import img1 from "../../../../assets/img/small-logos/logo-slack.svg";
import img2 from "../../../../assets/img/small-logos/logo-spotify.svg";
import img3 from "../../../../assets/img/small-logos/logo-atlassian.svg";
import img4 from "../../../../assets/img/small-logos/logo-asana.svg";
import MidToggleVue from "./MidToggle.vue";
import flatPickr from "vue-flatpickr-component";
import EndInput from "./EndInput.vue";

export default {
  name: "SideNavItem",
  components: {
    //ArgonButton,
    ArgonSwitch,
    //ArgonBadge,
    //ArgonAvatar,
    //ArgonInput,
    MidToggleVue,
    flatPickr,
    EndInput,
  },
  data() {
    return {
      config: {
        allowInput: true,
      },
      img,
      img1,
      img2,
      img3,
      img4,
      modele: this.modelValue
    };
  },
  // read v-model

  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  watch: {

    modele: function (val) {
      this.$emit('update:modelValue', val)
    },
    modelValue: function (val) {
      this.modele = val
    }
  },
  mounted() {
    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender, { allowHTML: true });
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language, { allowHTML: true });
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month, { allowHTML: true });
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day, { allowHTML: true });
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year, { allowHTML: true });
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
        allowHTML: true,
      });
    }
  },
};
</script>
<style>
.input-group-text {
  border-right: #d2d6da !important;
  border: 1px solid #d2d6da !important;
}
</style>