<template>
    <div class="mb-1">
        <!--op:{{ options }}-->
        <div v-if="blocking.find(el => el === options.prop) || blocking.find(el => el === 'all')">
            <div class="bg-purple border-radius-lg p-2" style="display: flex;">
                <div class="bg-purple border-radius-lg p-2 text-white text-sm pr-3">{{ options.name }}</div>
                <div class="bg-purple border-radius-lg p-1 text-bold text-white w-100"
                    style="margin-top: 2px ;font-weight: 700;">
                    <div v-for="(four,key) in fournisseurs" :key="four.id" :class="(fournisseurs.length >1)? 'border border-white  border-radius-lg mb-1':''">
           
           <Fournisseur :options="options" v-model="fournisseurs[key]" :blocking="blocking" :fournisseurs_len="fournisseurs.length" :fournisseurs="fournisseurs"/>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="bg-purple border-radius-lg p-2">
       
            <div v-for="(four,key) in fournisseurs" :key="four.id" :class="(fournisseurs.length >1)? 'border border-white  border-radius-lg mb-1':''">
           
                <Fournisseur :options="options" v-model="fournisseurs[key]" :blocking="blocking" :fournisseurs_len="fournisseurs.length" :fournisseurs="fournisseurs"/>
                <!--<div style="display: flex;">
                    <div v-if="fournisseurs.length > 1"><button class="btn btn-danger btn-xs mt-1" @click="removeFour(key)"><i class="fa fa-trash"></i></button></div>
                    <div class="bg-purple border-radius-lg p-2 text-white text-sm pr-3">{{ options.name }}</div>


                    <el-select class="mt-1" style="width:100%" round v-model="four.fournisseur" filterable remote
                        reserve-keyword placeholder="Merci d'entrer un fournisseur" remote-show-suffix
                        :remote-method="getFournisseurs" :loading="loading.immeuble" :disabled="disabled.immeuble">
                        <el-option v-for="item in all_immeubles" :key="item.id" :label="item.thirdparty_name"
                            :value="item.id" />
                    </el-select>
                </div>

                <div style="display: flex;" v-if="four.fournisseur">
                    <div class="bg-purple border-radius-lg p-2 text-white text-sm pr-3">Interlocuteur</div>
                    <el-select class="mt-1" style="width:100%" v-model="four.interlocuteur" round filterable reserve-keyword
                        placeholder="Merci d'entrer un interlocuteur">
                        <el-option v-for="item in interlocuteurs" :key="item.id"
                            :label="item.firstname + ' ' + item.lastname" :value="item.id" />
                    </el-select>
                    <button class="btn btn-white btn-xs mt-1" @click="modal_inter.new_interlocuteur.show = true; modal_inter.new_interlocuteur.item = four.interlocuteur"><i class="fa fa-plus"></i></button>
                </div>-->
            </div>

        <button v-if="fournisseurs.length < 6" class="btn btn-white btn-xs mt-0 mb-0" @click="addFournisseur"><i
                class="fa fa-plus"></i>Ajouter un autre fournisseur</button>
        </div>
    </div>
</template>
<script>
//import {ArgonInput} from "@/components/ArgonInput.vue";
//import { ElSelect, ElOption } from "element-plus";
//import AjoutEmploye from "../Actions/AjoutEmploye.vue";
//import Modal from "@/components/Modal.vue";
import Fournisseur from "./Fournisseur.vue";
export default {
    name: "Immeuble",
    components: {
        // ArgonInput,
        //ElSelect, ElOption,//AjoutEmploye,
        //Modal,
        Fournisseur
    },
    props: {
        options: {
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: () => { }
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        blocking: {
            type: Array,
            default: () => { }
        },
    },
    data() {
        return {
            fournisseurs: [{
            }
            ],
            modal_inter:{
        new_interlocuteur: {
          show: false,
          title: 'Nouvelle Fiche Interlocuteur',
          item: {
            firstname: '',
            lastname: '',
            civilite: '',
            phones: [],
            mails: []
          }
        },

            },
            interlocuteurs: [],
            interlocuteur: {},
            fournisseur: {},
            modele: this.modelValue,
            all_immeubles: [],
            loading: {
                immeuble: true,
            },
            disabled: {
                perso: false,
                immeuble: false,
                lot: false
            }
        }
    },
    watch: {
        fournisseurs: {
            handler(val) {
                        console.log(val)
                        this.modele[this.options.prop] = val
                        this.$emit('update:modelValue', this.modele)
            },
    deep: true
        },
        modelValue: function (val) {
            this.modele = val
        }

    },
    mounted() {
        if (this.modele[this.options.prop]) {
            this.fournisseurs = this.modele[this.options.prop]
            console.log(this.fournisseurs)
            //this.getFournisseur(this.fournisseur)
        }
        /*

        if (this.modele.perso){
            this.all_persos = [this.modele.perso];
            this.all_persosd = [this.modele.perso.id];
            this.bounce_perso = true;
            if (this.modele.perso.id){
                this.getPersoById(this.modele.perso.id)
            }
        } else if(this.modele.perso_id){

            this.getPersoById(this.modele.perso_id)
        } else if (this.modele.immeuble_id){
            this.getFromImmeuble()
        }
        */
        if (this.modele[this.options.prop]) {
           // this.getFournisseurById(this.modele[this.options.prop])
        }

    },
    methods: {
        addFournisseur() {
            this.fournisseurs.push({ })
        },
        
        async getFournisseurs(p) {
            this.loading.immeuble = true;
            let params = {
                ...(p ? { search: p } : {}),
                page: 1,
                size: 20,
                sort: "events.last",
            };
            console.log(params)
            await this.$store.dispatch("fournisseur/fournisseursList", params);
            this.all_immeubles = this.$store.getters["fournisseur/fournisseursList"].data;
            this.all_immeublesd = [];
            this.all_immeubles.forEach(imm => {
                this.all_immeublesd.push(imm.id);
            });
            this.loading.immeuble = false;
            console.log(this.all_immeubles)
        },
        async getFournisseur() {
            await this.$store.dispatch("fournisseur/getFournisseur", this.fournisseur);
            this.more = this.$store.getters["fournisseur/oneFournisseur"];
            this.interlocuteurs = []
            this.interlocuteurs = this.more.employees
            console.log(this.modele.fournisseur)
        },


        async getFournisseurById(pid) {
            await this.$store.dispatch("fournisseur/getFournisseur", pid);
            this.perso_tmp = this.$store.getters["fournisseur/oneFournisseur"];
            this.perso_id = this.perso_tmp.id;
            this.perso_name = this.perso_tmp.nom + ' ' + this.perso_tmp.prenom;

        }
    }
}
</script>