<script setup>
import { onMounted } from "vue";
import Chart from "chart.js/auto";

const props = defineProps({
  id: {
    type: String,
    default: "default-doughnut-chart",
  },
  height: {
    type: String,
    default: "200",
  },
  title: {
    type: String,
    default: "Default Doughnut Chart",
  },
  chart: {
    type: Object,
    required: true,
    labels: String,
    datasets: {
      type: Array,
      label: String,
      data: Array,
    },
  },
  actions: {
    type: Object,
    route: String,
    color: String,
    label: String,
    default: () => ({
      color: "secondary",
      label: "See all refferals",
    }),
  },
});

onMounted(() => {
  let chart = document.getElementById(props.id).getContext("2d");

  let chartStatus = Chart.getChart(props.id);
  if (chartStatus != undefined) {
    chartStatus.destroy();
  }

  new Chart(chart, {
    type: "doughnut",
    data: {
      labels: props.chart.labels,
      datasets: [
        {
          label: props.chart.datasets[0].label,
          weight: 9,
          cutout: 60,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: [
            "#2152ff",
            "#3A416F",
            "#f53939",
            "#a8b8d8",
            "#4BB543 ",
          ],
          data: props.chart.datasets[0].data,
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  });
});
</script>
<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <h6 class="mb-0">{{ props.title }}</h6>
        <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center ms-auto"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="See which websites are sending traffic to your website"
        >
          <i class="fas fa-info"></i>
        </button>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row">
        <div class="text-center col-lg-5 col-12">
          <div class="mt-5 chart">
            <canvas
              :id="props.id"
              class="chart-canvas"
              :height="props.height"
            ></canvas>
          </div>
          <a
            class="mt-4 btn btn-sm"
            :href="props.actions.route"
            :class="`bg-gradient-${props.actions.color}`"
          >
            {{ props.actions.label }}
          </a>
        </div>
        <div class="col-lg-7 col-12">
          <div class="table-responsive">
            <table class="table mb-0 align-items-center">
              <tbody>
                <tr>
                  <td>
                    <div class="px-2 py-1 d-flex">
                      <div>
                        <img
                          src="@/assets/img/small-logos/logo-xd.svg"
                          class="avatar avatar-sm me-2"
                          alt="logo_xd"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">Adobe</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <span class="text-xs font-weight-bold"> 25% </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="px-2 py-1 d-flex">
                      <div>
                        <img
                          src="@/assets/img/small-logos/logo-atlassian.svg"
                          class="avatar avatar-sm me-2"
                          alt="logo_atlassian"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">Atlassian</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <span class="text-xs font-weight-bold"> 3% </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="px-2 py-1 d-flex">
                      <div>
                        <img
                          src="@/assets/img/small-logos/logo-slack.svg"
                          class="avatar avatar-sm me-2"
                          alt="logo_slack"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">Slack</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <span class="text-xs font-weight-bold"> 12% </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="px-2 py-1 d-flex">
                      <div>
                        <img
                          src="@/assets/img/small-logos/logo-spotify.svg"
                          class="avatar avatar-sm me-2"
                          alt="logo_spotify"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">Spotify</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <span class="text-xs font-weight-bold"> 7% </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="px-2 py-1 d-flex">
                      <div>
                        <img
                          src="@/assets/img/small-logos/logo-jira.svg"
                          class="avatar avatar-sm me-2"
                          alt="logo_jira"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">Jira</h6>
                      </div>
                    </div>
                  </td>
                  <td class="text-sm text-center align-middle">
                    <span class="text-xs font-weight-bold"> 10% </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
