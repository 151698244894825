<template>
    <teleport to="body">
        <transition name="fade">
            <div v-if="modelValue" class="pt-3 position-fixed top-0 start-0 h-100 w-100" style="background-color: rgba(0, 0, 0, 0.25);z-index: 10;">
                <div id="backdrop" @click="backdropClick" class="modal1-dialog h-100 overflow-auto">
                    <div class="card px-0" :class="(container == null) ? 'container' : `container-${container}`" :style="(maxwidth != null) ? `max-width: ${maxwidth}` : null">
                        <div class="card-header h3 pb-1">
                            <div class="row">
                                <div class="col-sm-3">
                            <button v-if="closeable" @click='this.$emit("update:modelValue", false)'
                                class="btn btn-sm btn-danger float-start">
                                <i class="fa fa-times"></i>
                                Fermer
                            </button>

                                </div>
                                <div class="col-sm-6 text-center h6">
                                    {{ header }}
                                </div>
                                <div class="col-sm-3 text-end">
                            <button v-if="showDraft" @click='this.saveDraft' class="btn btn-sm " style="background-color: green;color: white;"  >
                                <i class="fas fa-save"></i> Enregistrer comme brouillon
                            </button>

                                </div>

                            </div>


                            </div>
                        <div class="card-body pt-0">
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script>
export default {
    name: "ModalNew",
    emits:['update:modelValue'],
    props:{
        showDraft:{
            type:Boolean,
            default:true,
            required:false
        },
        saveDraft: {
            type: Function,
            required: false,
            default: () => console.log('saveDraft')
        },
        modelValue: {
            type: Boolean,
            required: true,
            default: false
        },
        closeable: {
            type: Boolean,
            required: false,
            default: false
        },
        header: {
            type: String,
            required: false,
            default: null
        },
        container: {
            type: String,
            required: false,
            default: null
        },
        maxwidth: {
            type: String,
            required: false,
            default: null
        }
    },
    }
/*

const backdropClick = (event) => {
    if (event.target.id == 'backdrop' && props.closeable == true) {
        emit("update:modelValue", false);
    }
}
*/


</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s
}
.fade-enter-from, .fade-leave-to{
  opacity: 0
}

</style>
