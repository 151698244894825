import axios from "axios";
import authHeader from "./auth-header";
import Jsona from "jsona";
import qs from "qs";

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListMails(params) {
  const response = await axios.get(`${API_URL}/mails`, {
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader(),
  });

  return { data: response.data.data, meta: response.data.meta };
}

async function getMailBasic(EventId) {
  console.log(EventId);
  const response = await axios.get(`${API_URL}/mails/${EventId}/b`, { headers: authHeader() });
  return response.data;
}

async function getMail(EventId){
  console.log(EventId)
  const response =  await axios.get(`${API_URL}/mails/${EventId}?include=category,tags`, {
    headers: authHeader()
  });
  //console.log('DATA Mail', response.data.mails[0].id);
  return response.data;
}
async function getMailAttachment(params){
  console.log(params)
  const response =  await axios.get(`${API_URL}/mails/${params.mail_id}/attachments/${params.id}`, {
    headers: authHeader()
  });
  //console.log('DATA MailAttachment ', response.data);
  if(response.data.contentType === 'application/pdf'){
    analyzeMailAttachment(params.mail_id,params.id);
  }
  return response.data;
}

async function analyzeMailAttachment(mail_id,id) {
    try {
      const response = await axios.post(`${API_URL}/mails/${mail_id}/attachments/${id}`, 0,/*JSON.stringify(data),*/ {headers: authHeader()});
      console.log('Réponse requête POST analyse', response.data);
    } catch (error) {
      console.error(error);
    }
  //}
}

async function connect(params) {
  const options = {
    params: params,
    headers: authHeader(),
  };

  await axios.get(`${API_URL}/microsoft/signin`, options).then((response) => {
    console.log(response.data);
    localStorage.setItem("microsoft_auth_state", response.data.oauthState);
    localStorage.setItem("microsoft_auth_url", response.data.authUrl);
    return {
      url: response.data.authUrl,
      oauthState: response.data.oauthState,
    };
  });
}

async function validateconnect(params) {
  const options = {
    params: {
      state: params.state,
      code: params.code,
      oauthState: localStorage.getItem("microsoft_auth_state"),
    },
    headers: authHeader(),
  };
  console.log(options);

  return await axios.get(`${API_URL}/microsoft/callback`, options).then((response) => {
    console.log(response.data);
    return {
      valid: response.data,
    };
  });
}

async function correct(item) {
  return await axios
    .post(`${API_URL}/mails/${item.id}/correct`, item, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
}

async function ia(item) {
  console.log(item);
  return await axios
    .post(`${API_URL}/mails/${item.id}/ai_answer`, item, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
}
async function ia_tasks(item) {
  console.log(item);
  return await axios
    .post(`${API_URL}/mails/${item.id}/ai_tasks`, item, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
}

async function editMail(Event) {
  const payload = jsona.serialize({
    stuff: Event,
    includeNames: [],
  });

  const response = await axios.patch(
    `${API_URL}/mails/${Event.id}?include=category,tags`,
    payload,
    { headers: authHeader() },
  );
  return jsona.deserialize(response.data);
}

function mailfolders() {
  const options = {
    headers: authHeader(),
  };

  return axios.get(`${API_URL}/mailfolders`, options).then((response) => {
    return response.data;
  });
}

function reply(item) {
  const formData = new FormData();

  Object.entries(item).forEach(([key, value]) => {
    if (key !== "files") {
      formData.append(key, value);
    }
  });
  if (item.files) {
    item.files.forEach((file, i) => {
      formData.append("files[]", file, file.name || "filename" + i + ".jpg");
    });
  }

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  };

  return axios.post(`${API_URL}/mails/${item.id}/reply`, formData, options).then((response) => {
    return response.data;
  });
}
async function createMail(item) {
  const formData = new FormData();

  Object.entries(item).forEach(([key, value]) => {
    if (key !== "files") {
      formData.append(key, value);
    }
  });
  if (item.files) {
    item.files.forEach((file, i) => {
      formData.append("files[]", file, file.name || "filename" + i + ".jpg");
    });
  }

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "multipart/form-data",
      ...authHeader(),
    },
  };

  const response = await axios.post(`${API_URL}/mails?include=category,tags`, formData, options);
  return jsona.deserialize(response.data);
}

async function deleteMail(EventId) {
  await axios.delete(`${API_URL}/mails/${EventId}`, { headers: authHeader() });
}

async function uploadImageMail(Event) {
  const payload = new FormData();
  payload.append("attachment", Event.image);

  const response = await axios.post(`${API_URL}/uploads/mails/${Event.id}/image`, payload, {
    headers: authHeader(),
  });
  return response.data.url;
}

  export default {
  correct,
  getListMails,
  connect,
  ia,
  ia_tasks,
  validateconnect,
  reply,
  mailfolders,
  getMail,
  getMailBasic,
  getMailAttachment,
  editMail,
  createMail,
  deleteMail,
  uploadImageMail,
};
