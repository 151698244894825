<template>
<div>
    
              <!--
                  <dropzone-file-upload v-model="item.files" multiple></dropzone-file-upload>-->

                <div class="text-end">
                 <!-- <button @click="repondre(item,true)" class="btn btn-white">Enregistrer en brouillon</button>-->
                  <button @click="repondre(item,false)" class="btn btn-success">Répondre <i class="fa fa-arrow-right"></i></button>
                </div>
                    <div v-if="!loading.ai && !ia_done">
                    <base-button type="success" icon size="xs" @click="ask_ai">
                      <span class="btn-inner--icon"><i class="fas fa-brain"></i></span>
                      <span class="btn-inner--text">Générer réponse par IA</span>
                    </base-button>

                  </div>
                  <div v-if="loading.ai" class="text-center">
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                  <NewMail v-model="repMailContent"/>

</div>
</template>
<script>
import NewMail from '../mails/NewMail.vue';
import BaseButton from '../../../components/BaseButton.vue';
/*
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import PickList from 'primevue/picklist';
*/
export default {
  components: {
    NewMail,
    BaseButton,
   /* Splitter,SplitterPanel,
    PickList*/
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          id: '',
          from: '',
          to: [],
          cc: [],
          cci: [],
          subject: '',
          body: '',
          files: [],
          options: {
            tocc: {
              active: false,
              toggle: true
            }
          }
        }
      }
    },
    ia_done_def:{
      type:Boolean,
      default:false
    },
    sent: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
        taskMode:false,
      item: this.modelValue,
      ia_done:this.ia_done_def,
      send: this.sent,
      tasks_list:'',
        tasks: [],
      repMailContent: {
        to: [],
        cc: [],
        cci: [],
        subject: '',
        body: '',
        files: [],
        options: {
          tocc: {
            active: true,
            toggle: false
          }
        }
      },
      loading: {
        ai: false
      }
    }
  },
  watch: {
    item: function (val) {
      this.$emit('update:modelValue', val)
    },
    modelValue: function (val) {
      this.item = val
    },
    send: function (val) {
      this.$emit('update:sent', val)
    },
  },
  methods:{
    async ask_ai() {
      if (this.loading.ai) {
        return;
      }
      this.loading.ai = true;
      await this.$store.dispatch("mail/ia", { id: this.item.id,from:this.item.from,from_email:this.item.from_email,to:this.item.to,body:this.item.body,subject:this.item.subject });
      let ia = await this.$store.getters["mail/ia"];
      console.log('AIinside', ia)
      this.repMailContent.body = ia.replace(/(\r\n|\r|\n)/g, '<br>');
      this.loading.ai = false;
      this.ia_done = true;
      localStorage.setItem('ia_mail_'+this.item.id, JSON.stringify({content:ia,expiry: new Date().getTime()+1000*60*60*24*7 }));

    },
    async ask_ai_tasks() {
      if (this.loading.ai) {
        return;
      }
      this.loading.ai = true;
      await this.$store.dispatch("mail/ia_tasks", { id: this.item.id, body: this.repMailContent.body });
      let ia = await this.$store.getters["mail/ia"];
      console.log('AIinside', ia)
      try{
        ia = JSON.parse(ia);
      this.tasks = ia;
      } catch (e) {
        console.log(e);
      }
      this.tasks_list = ia;
      this.loading.ai = false;

    },
    async repondre(row, draft=false) {
      try {
        //this.item.id=row.id;
        console.log(this.files)
      localStorage.setItem('sent_mail_'+this.item.id, JSON.stringify({content:this.repMailContent,expiry: new Date().getTime()+1000*60*60*24*3 }));

        const myitem = { id: row.id, body: this.repMailContent.body, cc:this.repMailContent.cc, cci:this.repMailContent.cci, files: this.repMailContent.files, draft: draft}
        console.log(myitem)
        row.rep = false;
        row.imm = false;
        row.toggleEnd = true
       await this.$store.dispatch("mail/reply", myitem);
        this.item = await this.$store.getters["mail/oneMail"]; 

        this.$notify({
          type: "success",
          message: "E-mail envoyé avec succès.",
        });
        this.send = true;
        //this.$forceUpdate();
        //this.get();
        //console.log(row)
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Le mail n'a pas pu être créé !`,
        });
        //this.setApiValidation(error.response.data.errors);
      }
    },
  },
    async mounted(){

        if (localStorage.getItem('ia_mail_'+this.item.id)){
        let d =JSON.parse(localStorage.getItem('ia_mail_'+this.item.id))
        if (new Date().getTime() > d.expiry) {
					localStorage.removeItem('ia_mail_'+this.item.id)
					
				} else {
        this.repMailContent.body = d.content.replace(/(\r\n|\r|\n)/g, '<br>');
        this.ia_done = true;

                }
        }
    }
}


</script>