<template>
    <div class="row p-4">
        <div class="col-lg-6 ">
            <div class="row">
                <div class="col-md-9">
            <h1 class="h4">Modifier un contrôleur</h1>

                </div>
                <div class="col-auto text-end">
                    <button class="btn btn-danger" @click="save_model"><i class="fa fa-save"></i> Enregistrer</button>
                </div>
            </div>
            <input   type="text" v-model="content.title" class="mb-2 form-control" placeholder="Titre">

            <input type="text" v-model="content.syndic_id" class="mb-2 form-control" placeholder="ID syndic" >

            <input type="text" v-model="content.agence_id" class="mb-2 form-control" placeholder="ID agence" >


        </div>
        <div class="col-lg-6">
            <Vue3Jsoneditor ref="editor" v-model="content.value" height="90vh" />
        </div>
    </div>
</template>
<script>
import Vue3Jsoneditor from 'v3-jsoneditor/src/Vue3Jsoneditor.vue'
import axios from 'axios';
import showSwal from '../../../mixins/showSwal';
//import bg_capitole from '../../../assets/img/bg_capitole.png';
export default {
    components: {
        Vue3Jsoneditor
},
    data() {
        return {
            content: {
                value:{
                "hello": "vue"
            },
            syndic_id:1,
            agence_id:1,
        },
        
            options: {
                prop: 'value'
            },
            options_pdf:{
                bg: null,
                prop: 'render',
                get_prop: 'value',
            },
            show_pdf: false,
        }
    },
    mixins: [showSwal],

    methods: {
        onError() {
            console.log('error')
        },
        arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    },
    async fetchFile(file) {
      try {
        const response = await axios.get(file, { responseType: 'arraybuffer' });
        const base64 = this.arrayBufferToBase64(response.data);
        this.options_pdf.bg =  base64;
        console.log(this.options_pdf)
        this.show_pdf = true;
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    },
        async save_model(){
            let view = {
                title: this.content.title,
                syndic_id: this.content.syndic_id,
                data: this.content.value,
                id: this.$route.params.id
            }

          await this.$store.dispatch("controleur/editControleur", view)
            let view2 = this.$store.getters["controleur/oneControleur"]
            console.log(view2)
            
            this.showSwal({message:"Contrôleur modifié",type: "success"});
            //
        }
    },
  async mounted() {
    await this.$store.dispatch("controleur/getControleur", this.$route.params.id);
    this.item = await this.$store.getters["controleur/oneControleur"];
    this.content.syndic_id = this.item.syndic_id;
    this.content.value = JSON.parse(this.item.controller);
    this.content.title = this.item.title;

    }
}

</script>