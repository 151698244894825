/* eslint-disable no-unused-vars */
import mailService from "../services/mail.service.js";

export const mail = {
  namespaced: true,
  state: {
    mailsList: null,
    oneItem: null,
    image: null,
    connect: null,
    ia: null,
    mailfolders: null,
    mailsLoaded: [],
    oneMail: null,
    oneAttachment: null,
    mailContexte: null,
  },
  getters: {
    mailsList: (state) => state.mailsList,
    oneMail: (state) => state.oneMail,
    image: (state) => state.image,
    connect: (state) => state.connect,
    ia: (state) => state.ia,
    mailfolders: (state) => state.mailfolders,
    oneAttachment: (state) => state.oneAttachment,
    mailContexte: (state) => state.mailContexte,
  },
  actions: {
    async connectAuth({ commit }, params) {
      console.log("action");
      const data = await mailService.connect(params);
      console.log(data);
      commit("CONNECT", data);
    },
    async correct({ commit }, params) {
      console.log("action");
      const data = await mailService.correct(params);
      console.log(data);
      commit("CORRECT", data);
    },
    async ia({ commit }, params) {
      console.log("action");
      const data = await mailService.ia(params);
      console.log(data);
      commit("IA", data);
    },
    async ia_tasks({ commit }, params) {
      console.log("action");
      const data = await mailService.ia_tasks(params);
      console.log(data);
      commit("IA", data);
    },

    async validateconnect({ commit }, params) {
      console.log("action");
      const data = await mailService.validateconnect(params);
      console.log(data);
      commit("CONNECT", data);
    },
    async mailsList({ commit }, params) {
      console.log("action");
      const data = await mailService.getListMails(params);
      console.log(data);
      commit("MAILS_LIST", data);
    },
    async getMailContexte({ commit }, itemId) {
      let data = await mailService.getMail(itemId);
      commit("GET_MAIL_CONTEXTE", data);
    },
    async getMail({ commit }, itemId) {
      let mailsLoaded = sessionStorage.getItem("mailsLoaded");
      if (!mailsLoaded) {
        mailsLoaded = [];
        mailsLoaded = JSON.stringify(mailsLoaded);
      }

      mailsLoaded = JSON.parse(mailsLoaded);
      if (mailsLoaded.length > 3) {
        mailsLoaded.shift();
      }

      if (mailsLoaded.find((mail) => mail.mails[0].id === itemId) !== undefined) {
        //
        const data = mailsLoaded.find((mail) => mail.mails[0].id === itemId);
        console.log("getMailData", data);
        commit("GET_MAIL", data);
      } else {
        // getMail if not already loaded
        let data = await mailService.getMailBasic(itemId);

        commit("GET_MAIL", data);
        // clear old mails
        mailsLoaded.push(data);
      }
      try {
        sessionStorage.setItem("mailsLoaded", JSON.stringify(mailsLoaded));
      } catch (e) {
        console.log(e);
      }
    },
    async getAttachment({ commit }, item) {
      const data = await mailService.getMailAttachment(item);
      commit("GET_ATTACHMENT", data);
    },

    async reply({ commit, dispatch }, params) {
      await mailService.reply(params).then((item) => {
        commit("GET_MAIL", item);
      });
    },
    async mailfolders({ commit, dispatch }, params) {
      await mailService.mailfolders(params).then((item) => {
        commit("FOLDERS", item);
      });
    },
    async addMail({ commit }, item) {
      const data = await mailService.createMail(item);
      commit("GET_ITEM", data);
    },
    async editMail({ commit }, item) {
      await mailService.editMail(item);
    },
    async deleteMail({ commit }, itemId) {
      await mailService.deleteMail(itemId);
    },
    async uploadImageItem({ commit }, item) {
      const data = await mailService.uploadImageMail(item);
      commit("UPLOAD_IMAGE_MAIL", data);
    },
  },
  mutations: {
    MAILS_LIST(state, data) {
      state.mailsList = data;
    },
    GET_MAIL(state, data) {
      state.oneMail = data;
    },
    UPLOAD_IMAGE_MAIL(state, data) {
      state.image = data;
    },
    CONNECT(state, data) {
      state.connect = data;
    },
    IA(state, data) {
      state.ia = data;
    },
    FOLDERS(state, data) {
      state.mailfolders = data;
    },
    GET_ATTACHMENT(state, data) {
      state.oneAttachment = data;
    },
    GET_MAIL_CONTEXTE(state, data) {
      state.mailContexte = data;
    },
  },
};
