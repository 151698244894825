/* eslint-disable no-unused-vars */
import callservice from "../services/call.service.js";

export const call = {
    namespaced: true,
    state: {
        callsList: null,
        callsListFromBuilding: null,
        onecall: null,
        image: null,
        phone: null,
    },
    getters: {
        callsList: state => state.callsList,
        callsListFromBuilding: state => state.callsListFromBuilding,
        onecall: state => state.onecall,
        image: state => state.image,
        phone: state => state.phone,

    },
    actions: {
        async callsList({ commit }, params) {
            console.log("action")
            const data = await callservice.getListcalls(params);
            console.log(data);
            commit('calls_LIST', data);
        },
        async callsFromBuilding({ commit }, params) {
            console.log("action")
            const data = await callservice.getListcallsFromBuilding(params);
            console.log(data);
            commit('calls_LIST_FROM_BUILDING', data);
        },
        async phones({commit, dispatch}, params) {
            return await callservice.phones(params)
            .then(({list, meta}) => {
            commit('SET_PHONES', list);
            });
        },
        async getcall({ commit }, itemId) {
            const data = await callservice.getcall(itemId);
            commit('GET_call', data);
        },
        async createcall({ commit }, data) {
            const response = await callservice.createcall(data);
            commit('GET_call', response.data);
        },
    },
    mutations: {
        calls_LIST(state, data) {
            state.callsList = data;
        },
        GET_call(state, data) {
            state.onecall = data;
        },
        lots_LIST_FROM_BUILDING(state, data) {
            state.lotsFromBuilding = data;
        },
        UPLOAD_IMAGE_lot(state, data) {
            state.image = data;
        },
        SET_PHONES(state, data) {
            state.phone = data;
        },

  }
}