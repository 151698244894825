<template>
    <div class="card shadow m-2 flex-fill bg-light" @mouseover="show_more= true" @mouseleave="show_more=false">
        <div style="flex-direction: row;flex:1" class="flex-fill d-flex"> 
            
        <td class="mr-3 p-2 mt-1">
            <span v-if="modele.received_type =='N'" class="badge badge-info">{{ modele.type }} </span>
            <span v-else-if="modele.received_type =='I'" class="badge badge-warning">{{ modele.type }} </span>
            <span v-else-if="modele.received_type =='C'" class="badge badge-success">{{ modele.type }} </span>
        </td>
        <td class="flex-fill p-2 mt-1">{{ modele.title }}</td>
        <td >
            <button @click="editSubEvent(modele)" class="btn btn-xs m-2 btn-dark m-0" v-if="modele.draft"><i class="fa fa-pen"></i>Modifier</button>
            <button @click="editSubEvent(modele,1)" class="btn btn-xs m-2 btn-dark m-0" v-else><i class="fa fa-eye"></i>Voir</button>
        </td>
        </div>
        <div v-if="show_more">
              <table v-for="(event,i) in modele.descendants" :key="event.id" class="table-responsive w-100 flex-fill">
<EventMiniBlock v-model="modele.descendants[i]" :editSubEvent="editSubEvent"/>
              </table>
        </div>
    </div>
</template>
<script>
export default {
    name: "EventMiniBlock",
    props: {
        modelValue: {
            type: Object,
            default: () => {
            }
        },
        editSubEvent: {
            type:Function,
            default:()=>{}

        }
    },
    data() {
        return {
            modele: this.modelValue,
            show_more:false
        }
    },
    methods: {
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
}
</script>