<template>
    <div class="container-fluid card"  style="background: rgba(170, 120, 232, 0.1);height: calc(100vh - 80px); " >
        <div class="row">
            <div class="col-lg-6  pt-3 " :style="entreprise_adding ? 'display:none' : 'display:block'">

                <div v-if="existing_company">
                    <div class="text-lg text-bold">
                        Contrat à préciser
                    </div>
                    <div class="text-sm">
                        Nous n'avons pas le SIRET associé à cette entreprise.<br/> Veuillez rechercher l'entreprise dans la base INSEE
                    </div>
                    <div class="border-radius-xl bg-danger p-1 mb-2 mt-1" >
                        <h3 class="h6 col-12 mb-1 ml-1 pl-2 text-white text-sm">Entreprise existante</h3>

                    <div class="border-radius-xl bg-white p-2">
                                <h1 class="h5">{{ existing_company.nom }}</h1>
                                <div class="card p-2 m-0">
                                    <h4>Adresse</h4>
                                    {{ existing_company.adresse1 }} {{ existing_company.adresse2 }}<br />
                                    {{ existing_company.cp }} {{ existing_company.ville }}
                                </div>
                    </div>
                    </div>
                </div>

                <div class="border-radius-xl p-1 mb-2" style="background: rgba(170, 40, 232,1);">
                    <!--<h3 class="mb-0 text-white">Liste des fournisseurs</h3>-->

                    <h3 class=" col-12 text-white text-sm mb-2">Rechercher une entreprise dans la base nationale</h3>

                <!--<h2>Rechercher une entreprise dans la base nationale</h2>-->

                <div class=" border-radius-xl bg-white p-2">
                <div class="row p-2 text-left">
                    <div class="col-4">
                            <el-select filterable name="holding" placeholder="Département" v-model="search_insee_departement"
                                required>
                                <el-option v-for="single_category in departements"  :key="single_category.id"
                                    :value="single_category.id" :label="single_category.name">
                                </el-option>
                            </el-select>
                    </div>
                    <div class="col-8">
                        <input class="form-control" placeholder="Nom de l'entreprise" v-model="search_insee_nom" v-on:change="getInseeCompany"
                           />


                    </div>


                    <div class="col-12 ">

                        <button class="btn text-white border-radius-xl" v-on:click="getInseeCompany" style="background: rgba(170, 40, 232,1);">Rechercher dans la base INSEE</button>
                    </div>


                    <div class="col-6" v-if="entreprises.header">{{ entreprises.header.total }} entreprise(s) trouvée(s)</div>
                    <div class="col-6" v-if="entreprises.header" style="display:flex">
                        Afficher entreprises en cessation
                        <ArgonSwitch class="mr-1" label="" v-model="entreprises_cesses"></ArgonSwitch>

                    </div>
                </div>
                </div>


                </div>

                <div style="overflow-y:scroll;overflow-x: hidden;" :style="existing_company?'height: calc(250px );':'height: calc(100vh - 340px );'" class="border-radius-xl bg-white p-3 pt-2">
                    <div v-for="mentreprise in entreprises.etablissements" :key="mentreprise.siret" >
                        <div v-if="entreprises_cesses && mentreprise.uniteLegale.etatAdministratifUniteLegale == 'C'" class="d-flex align-items-start flex-column justify-content-center mt-2" style="border-bottom: 1px solid #EBEEF5;">
                            <div class="bg-danger text-white mb-1 text-sm" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 20px;cursor:not-allowed">
                                <b>{{ mentreprise.uniteLegale.denominationUniteLegale }} {{ mentreprise.uniteLegale.nomUniteLegale}} {{ mentreprise.uniteLegale.nomUsageUniteLegale }} {{ mentreprise.uniteLegale.sigleUniteLegale}}
                                </b>{{ mentreprise.adresseEtablissement.numeroVoieEtablissement }} {{ mentreprise.adresseEtablissement.indiceRepetitionEtablissement }} {{ mentreprise.adresseEtablissement.typeVoieEtablissement }}  {{ mentreprise.adresseEtablissement.libelleVoieEtablissement }} {{ mentreprise.adresseEtablissement.codePostalEtablissement }} {{ mentreprise.adresseEtablissement.libelleCommuneEtablissement }} — {{ mentreprise.siren }}
                            </div>
                        </div>
                        <div v-if="mentreprise.uniteLegale.etatAdministratifUniteLegale == 'A'" class="d-flex align-items-start flex-column justify-content-center mt-2" style="border-bottom: 1px solid #EBEEF5;">

                        <div  :id="mentreprise.siren" v-on:click="getCompany(mentreprise.siret)" class="mb-1 text-sm" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 20px;cursor:pointer">

                                <b>{{ mentreprise.uniteLegale.denominationUniteLegale }} {{ mentreprise.uniteLegale.nomUniteLegale }} {{ mentreprise.uniteLegale.nomUsageUniteLegale }} {{ mentreprise.uniteLegale.sigleUniteLegale}}
                                </b>{{ mentreprise.adresseEtablissement.numeroVoieEtablissement }} {{ mentreprise.adresseEtablissement.indiceRepetitionEtablissement }} {{ mentreprise.adresseEtablissement.typeVoieEtablissement }}  {{ mentreprise.adresseEtablissement.libelleVoieEtablissement }} {{ mentreprise.adresseEtablissement.codePostalEtablissement }} {{ mentreprise.adresseEtablissement.libelleCommuneEtablissement }} — {{ mentreprise.siren }}

                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="entreprise_loading || insee_etablissement" class="col-lg-6 py-3  text-center pt-3">

                <div class="border-radius-xl" style="background: rgba(170, 40, 232,1);padding: 0.9rem 0.9rem !important;">
                    <h3 class="h6 col-12 mb-0 text-white">Informations sur l'établissement</h3>
                </div>
                <div class="border-radius-xl bg-white p-2">
                    <div  v-if="entreprise_loading">
                        <img :src="loading_gif" style="height: 100px; width: 100px" />
                        <br />
                        <a>Chargement des informations de vérification de TVA et du Bodacc</a>
                    </div>
                    <div v-else-if="insee_etablissement" class="">

                        <i class="ni ni-info ni-3x"></i>
                        <h1 class="h3">{{ insee_etablissement.uniteLegale.denominationUniteLegale }} {{
                            insee_etablissement.uniteLegale.nomUniteLegale }} {{ insee_etablissement.uniteLegale.nomUsageUniteLegale}} {{ insee_etablissement.uniteLegale.sigleUniteLegale }}</h1>

                        <div class="card text-sm">
                            <h5> Localisation</h5>
                            {{ insee_etablissement.adresseEtablissement.complementAdresseEtablissement }}

                            {{ insee_etablissement.adresseEtablissement.numeroVoieEtablissement }}
                            {{ insee_etablissement.adresseEtablissement.typeVoieEtablissement }}
                            {{ insee_etablissement.adresseEtablissement.libelleVoieEtablissement }}<br />
                            {{ insee_etablissement.adresseEtablissement.codePostalEtablissement }}
                            {{ insee_etablissement.adresseEtablissement.libelleCommuneEtablissement }}



                        </div>
                        <br />

                        <div class="card p-1">
                            <h4> TVA</h4>
                            <span class="badge badge-success ml-2" v-if="entreprise.validvat">TVA Valide</span>
                            <span class="badge badge-danger ml-2" v-else>TVA non valide</span>


                        </div>
                        <br />
                        <div class="card p-1">
                            <h4> BODACC</h4>
                            <div  v-if="entreprise.bodacc">
                                <span class="badge badge-danger ml-2">À risque</span>
                                {{ entreprise.bodacc.date }}

                                {{ entreprise.bodacc.famille }}
                                <h6>{{ entreprise.bodacc.nature }}</h6>
                                <p>{{ entreprise.bodacc.complementJugement }}</p>


                            </div>
                            <span class="badge badge-success ml-2" v-else>Aucun évènement judiciaire inscrit au BODACC. </span>
                            <br />
                            <p class="text-xs">Le site du BODACC diffuse les annonces publiées depuis le 1er janvier 2008</p>

                        </div>
                        <br />



                        <div v-if="!entrepriseradiee && !entreprise_adding">
                            <div v-if="existing_company">
                                <div v-if="existing_company.perso.mails">


                                <div v-if="entreprise.fournisseur">

                                    <button class="btn btn-success" @click="setCompany(1)">Associer sur MyCopro</button>

                                </div>
                                <button v-else class="btn text-white" style="background: rgba(170, 40, 232,1);" @click="setCompany(1)">Ajouter et associer sur MyCopro</button>

                                </div>
                                <div v-else>
                                    <input class="form-control" type="email" placeholder="E-mail" v-model="email_to_use" />
                                    <button class="btn text-white" style="background: rgba(170, 40, 232,1);" @click="setCompany(1)">Ajouter et associer sur MyCopro</button>

                                </div>


                            </div>
                            <div v-else>

                                <div v-if="entreprise.fournisseur">
                                    <button class="btn btn-success" @click="setCompanyById(entreprise.fournisseur)">Cette entreprise est
                                        déjà sur MyCopro !</button>

                                </div>
                                <button v-else class="btn text-white" style="background: rgba(170, 40, 232,1);" @click="entreprise_adding = true">Ajouter sur MyCopro</button>

                            </div>


                        </div>

                        <div v-if="entreprise.validvat && entreprise_adding">
                            <button class="btn btn-danger" @click="entreprise_adding = false">Annuler l'ajout</button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-6  p-3 pt-2 " :style="entreprise_adding ? 'display:block' : 'display:none'"
                v-if="(insee_etablissement && !entreprise_loading) && entreprise_adding">
                <div class="border-radius-xl bg-white p-2 "  >
                    <div class="border-radius-xl text-center" style="background: rgba(170, 40, 232,1);padding: 0.9rem 0.9rem !important;">
                        <h3 class="h6 col-12 mb-0 text-white">Ajouter des informations de contact</h3>
                    </div>

                    <!--<h2>Ajouter des informations de contact</h2>-->


                    <h6>Utiliser l'adresse e-mail suivante pour inscription</h6>
                    <div class=" "
                        style="display:flex;margin: 5px;padding-left: 10px;padding-right: 10px;background: rgb(245, 245, 245);border-radius: 5px;align-items: center;">
                        <div class="" style="flex:1;"><input class="form-control" type="email" placeholder="E-mail"
                                v-model="email_to_use" v-on:change="getInseeCompany" /></div>
                        <div class=" text-right">
                            <button class="btn mt-2 mb-2 ml-2 text-white" style="background: rgba(170, 40, 232,1);" @click="setCompany">Valider</button>
                        </div>
                    </div>

                    <br />
                    <h6>Suggestions d'entreprises associées présentes dans votre base métier</h6>

                    <div style="overflow-y:scroll;height: calc(100vh - 380px );">
                        <div v-for="fentreprise in entreprise.efournisseur" :key="fentreprise.id">
                            <div class="card" body-style="">
                                <div class="col-lg-8">
                                    {{ fentreprise.nom }} <br />
                                    {{ fentreprise.adresse1 }} {{ fentreprise.adresse2 }} - {{ fentreprise.cp }} {{
                                        fentreprise.ville }}
                                </div>
                                <div class=" row"
                                    style="margin: 5px;background: rgb(245, 245, 245);border-radius: 5px;align-items: center;"
                                    v-for="m in fentreprise.perso.mails" :key="m.i">
                                    <div class="col-lg-8">{{ m.mail }}</div>
                                    <div class="col-lg-4 text-right">
                                        <button class="btn mt-2 mb-2 btn-xs text-white" style="background: rgba(170, 40, 232,1);"
                                            v-on:click="() => { email_to_use = m.mail }">Associer</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        </div>
</template>

<script>

import { ElSelect, ElOption } from "element-plus";
import ArgonSwitch from "../../../components/ArgonSwitch.vue";
import loading_gif from "../../../assets/img/loading.gif";

export default {
    components: {
        ElSelect, ElOption, ArgonSwitch
    },

    data: () => ({
        loading_gif: loading_gif,
        modals: {
            addfournisseur: true
        },
        departements: [
            { id:1,
            name: '01 - Ain'
            },
            { id:2,
            name: '02 - Aisne'
            },
            { id:3,
            name: '03 - Allier'
            },
            { id:4,
            name: '04 - Alpes-de-Haute-Provence'
            },
            { id:5,
            name: '05 - Hautes-Alpes'
            },
            { id:6,
            name: '06 - Alpes-Maritimes'
            },
            { id:7,
            name: '07 - Ardèche'
            },
            { id:8,
            name: '08 - Ardennes'
            },
            { id:9,
            name: '09 - Ariège'
            },
            { id:10,
            name: '10 - Aube'
            },
            { id:11,
            name: '11 - Aude'
            },
            { id:12,
            name: '12 - Aveyron'
            },
            { id:13,
            name: '13 - Bouches-du-Rhône'
            },
            { id:14,
            name: '14 - Calvados'
            },
            { id:15,
            name: '15 - Cantal'
            },
            { id:16,
            name: '16 - Charente'
            },
            { id:17,
            name: '17 - Charente-Maritime'
            },
            { id:18,
            name: '18 - Cher'
            },
            { id:19,
            name: '19 - Corrèze'
            },
            { id:20,
            name: '20 - Corse'
            },
            { id:21,
            name: '21 - Côte-d\'Or'
            },
            { id:22,
            name: '22 - Côtes d\'Armor'
            },
            { id:23,
            name: '23 - Creuse'
            },
            { id:24,
            name: '24 - Dordogne'
            },
            { id:25,
            name: '25 - Doubs'
            },
            { id:26,
            name: '26 - Drôme'
            },
            { id:27,
            name: '27 - Eure'
            },
            { id:28,
            name: '28 - Eure-et-Loir'
            },
            { id:29,
            name: '29 - Finistère'
            },
            { id:30,
            name: '30 - Gard'
            },
            { id:31,
            name: '31 - Haute-Garonne'
            },
            { id:32,
            name: '32 - Gers'
            },
            { id:33,
            name: '33 - Gironde'
            },
            { id:34,
            name: '34 - Hérault'
            },
            { id:35,
            name: '35 - Ille-et-Vilaine'
            },
            { id:36,
            name: '36 - Indre'
            },
            { id:37,
            name: '37 - Indre-et-Loire'
            },
            { id:38,
            name: '38 - Isère'
            },
            { id:39,
            name: '39 - Jura'
            },
            { id:40,
            name: '40 - Landes'
            },
            { id:41,
            name: '41 - Loir-et-Cher'
            },
            { id:42,
            name: '42 - Loire'
            },
            { id:43,
            name: '43 - Haute-Loire'
            },
            { id: 44,
            name: '44 - Loire-Atlantique'
            },
            { id:45,
            name: '45 - Loiret'
            },
            { id:46,
            name: '46 - Lot'
            },
            { id:47,
            name: '47 - Lot-et-Garonne'
            },
            { id:48,
            name: '48 - Lozère'
            },
            { id:49,
            name: '49 - Maine-et-Loire'
            },
            { id:50,
            name: '50 - Manche'
            },
            { id:51,
            name: '51 - Marne'
            },
            { id:52,
            name: '52 - Haute-Marne'
            },
            { id:53,
            name: '53 - Mayenne'
            },
            { id:54,
            name: '54 - Meurthe-et-Moselle'
            },
            { id:55,
            name: '55 - Meuse'
            },
            { id:56,
            name: '56 - Morbihan'
            },
            { id:57,

            name: '57 - Moselle'
            },
            { id:58,
            name: '58 - Nièvre'
            },
            { id:59,
            name: '59 - Nord'
            },
            { id:60,
            name: '60 - Oise'
            },
            { id:61,
            name: '61 - Orne'
            },
            { id:62,
            name: '62 - Pas-de-Calais'
            },
            { id:63,
            name: '63 - Puy-de-Dôme'
            },
            { id:64,
            name: '64 - Pyrénées-Atlantiques'
            },
            { id:65,
            name: '65 - Hautes-Pyrénées'
            },
            { id:66,
            name: '66 - Pyrénées-Orientales'
            },
            { id:67,
            name: '67 - Bas-Rhin'
            },
            { id:68,
            name: '68 - Haut-Rhin'
            },
            { id:69,
            name: '69 - Rhône'
            },
            { id:70,
            name: '70 - Haute-Saône'
            },
            { id:71,
            name: '71 - Saône-et-Loire'
            },
            { id:72,
            name: '72 - Sarthe'
            },
            { id:73,
            name: '73 - Savoie'
            },
            { id:74,
            name: '74 - Haute-Savoie'
            },
            { id:75,
            name: '75 - Paris'
            },
            { id:76,
            name: '76 - Seine-Maritime'
            },
            { id:77,
            name: '77 - Seine-et-Marne'
            },
            { id:78,
            name: '78 - Yvelines'
            },
            { id:79,
            name: '79 - Deux-Sèvres'
            },
            { id:80,
            name: '80 - Somme'
            },
            { id:81,
            name: '81 - Tarn'
            },
            { id:82,
            name: '82 - Tarn-et-Garonne'
            },
            { id:83,
            name: '83 - Var'
            },
            { id:84,
            name: '84 - Vaucluse'
            },
            { id:85,
            name: '85 - Vendée'
            },
            { id:86,
            name: '86 - Vienne'
            },
            { id:87,
            name: '87 - Haute-Vienne'
            },
            { id:88,
            name: '88 - Vosges'
            },
            { id:89,
            name: '89 - Yonne'
            },
            { id:90,
            name: '90 - Territoire de Belfort'
            },
            { id:91,
            name: '91 - Essonne'
            },
            { id:92,
            name: '92 - Hauts-de-Seine'
            },
            { id:93,
            name: '93 - Seine-Saint-Denis'
            },
            { id:94,
            name: '94 - Val-de-Marne'
            },
            { id:95,
            name: '95 - Val-d\'Oise'
            },
            { id:971,
            name: '971 - Guadeloupe'
            },
            { id:972,

            name: '972 - Martinique'
            },
            { id:973,
            name: '973 - Guyane'
            },
            { id:974,
            name: '974 - La Réunion'
            },
            { id:976,
            name: '976 - Mayotte'
            },
            { id:977,
            name: '977 - Saint-Barthélemy'
            },
            { id:978,
            name: '978 - Saint-Martin'
            },
            { id:987,
            name: '987 - Polynésie française'
            },
            { id:988,
            name: '988 - Nouvelle-Calédonie'
            },
            { id:989,
            name: '989 - Wallis-et-Futuna'
            },
            { id:99,
            name: '99 - Etranger'
            }
        ],
        search_insee_nom: "",
        search_insee_departement: "",
        email_to_use: "",
        insee_etablissement: false,
        entreprises: [],
        entreprise: {
            validvat: false,
            bodacc: false,
            efournisseur: []
        },
        entreprise_loading: false,
        entreprise_adding: false,
        entreprises_cesses: false,
        perso: null,
        immeuble: {},
        all_immeubles: [],
        all_persos: [],
        all_persosd: [],
        loading: {
            perso: false,
            immeuble: true
        },
        listOptions: [],

        dataMode: "",
        apiURL: "",
        existing_company: null,
    }),
    computed: {
        entrepriseradiee(){
            if(this.entreprise.bodacc?.famille == 'RADIATION'){
                return true;

            }
            if(this.entreprise.bodacc?.nature.includes('liquidation')){
                return true;
            }
            return false;
        }
    },
    mounted(){
        var agence;
        localStorage.getItem('agence') ? agence = JSON.parse(localStorage.getItem('agence')) : null;
        if(agence){
        this.search_insee_departement =  this.departements.find((elt) => elt.id == agence.cp.trim().substring(0, 2)).id;
        }
        if(this.defaultcompany){
        this.search_insee_nom = this.defaultcompany.nom;
        this.existing_company = this.defaultcompany;
        this.getInseeCompany();}
    },
    props:['defaultcompany'],
    created(){
    },
    methods: {

        async getInseeCompany() {
            this.loading.perso = true;
            let params = {
                search_insee_nom: this.search_insee_nom,
                search_insee_departement: this.search_insee_departement
            };
            console.log(params)
            await this.$store.dispatch("fournisseur/entreprisesList", params);
            this.entreprises = this.$store.getters["fournisseur/entreprisesList"];

        },
        async getCompany(id) {
            console.log(id)
            const myid = id;
            this.entreprise_loading = true;
            const params = {
                id: myid,
                searchname: this.search_insee_nom,
                searchdep: this.search_insee_departement
            }
            await this.$store.dispatch("fournisseur/getEntreprise", params);

            this.entreprise = this.$store.getters["fournisseur/oneEntreprise"];
            this.insee_etablissement = this.entreprises.etablissements.find((elt) => elt.siret == myid);
            this.entreprise_loading = false;
            console.log(myid, this.entreprise)

        },
        async setCompany(contrat=0) {
            var myitem;

            if(contrat){


                myitem = {
                siret: this.insee_etablissement.siret,
                mail: this.email_to_use,
                ext_fournisseur_id: this.existing_company.id,
                syndic_id: this.existing_company.syndic_id


            }
                if(this.existing_company.perso.mails){
                    myitem.mail = this.existing_company.perso.mails[0].mail;
                }
            } else {

            myitem = {
                siret: this.insee_etablissement.siret,
                mail: this.email_to_use

            }
            }
            console.log('I', myitem)
            try{
                    await this.$store.dispatch("fournisseur/addFournisseur", myitem);
                    var re = this.$store.getters["fournisseur/oneFournisseur"];
                    //this.router.go(-1)
                    console.log('I', re)

                    // pass to global listener
                    this.$emit('addFournisseur', re);


                    this.$notify({
                type: "success",
                message: "Le fournisseur a été correctement ajouté.",
                });
               // this.goBack();
            } catch (error) {

                this.$notify({
                type: "error",
                message: "Une erreur s'est produite.",
                });
            }

        },
        setCompanyById(id) {
            this.modals.addfournisseur = false
            this.perso = id;
        },





    },

}
</script>

<style >
.mod_top {
    max-width: calc(100vw - 15px);
    align-items: initial;
    background-color: #eefbff;
}
</style>
