/* eslint-disable no-unused-vars */
import lotservice from "../services/lot.service.js";

export const lot = {
    namespaced: true,
    state: {
        lotsList: null,
        lotsFromBuilding: null,
        oneLot: null,
        image: null,
    },
    getters: {
        lotsList: state => state.lotsList,
        lotsFromBuilding: state => state.lotsFromBuilding,
        oneLot: state => state.onelot,
        image: state => state.image,
    },
    actions: {
        async lotsList({ commit }, params) {
            console.log("action")
            const data = await lotservice.getListlots(params);
            console.log(data);
            commit('lots_LIST', data);
        },
        async lotsFromBuilding({ commit }, params) {
            console.log("action")
            const data = await lotservice.getListlotsFromBuilding(params);
            console.log(data);
            commit('lots_LIST_FROM_BUILDING', data);
        },
        async getLot({ commit }, itemId) {
            const data = await lotservice.getlot(itemId);
            commit('GET_lot', data);
        },
        async addlot({ commit }, item) {
            const data = await lotservice.createlot(item);
            commit('GET_ITEM', data);
        },
        async editlot({ commit }, item) {
            await lotservice.editlot(item);
        },
        async deletelot({ commit }, itemId) {
            await lotservice.deletelot(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await lotservice.uploadImagelot(item);
            commit('UPLOAD_IMAGE_lot', data);
        },
    },
    mutations: {
        lots_LIST(state, data) {
            state.lotsList = data;
        },
        GET_lot(state, data) {
            state.onelot = data;
        },
        lots_LIST_FROM_BUILDING(state, data) {
            state.lotsFromBuilding = data;
        },
        UPLOAD_IMAGE_lot(state, data) {
            state.image = data;
        },
  }
}