/* eslint-disable no-unused-vars */
import bienservice from "../services/bien.service.js";

export const bien = {
    namespaced: true,
    state: {
        biensListList: null,
        biensListFromBuilding: null,
        onebien: null,
        image: null,
    },
    getters: {
        biensList: state => state.biensListList,
        biensListFromBuilding: state => state.biensListFromBuilding,
        onebien: state => state.onebien,
        image: state => state.image,
    },
    actions: {
        async biensList({ commit }, params) {
            console.log("action")
            const data = await bienservice.getListbiens(params);
            console.log(data);
            commit('lots_LIST', data);
        },
        async biensFromBuilding({ commit }, params) {
            console.log("action")
            const data = await bienservice.getListbiensFromBuilding(params);
            console.log(data);
            commit('lots_LIST_FROM_BUILDING', data);
        },
        async getbien({ commit }, itemId) {
            const data = await bienservice.getbien(itemId);
            commit('GET_bien', data);
        },
        async createBien({ commit }, data) {
            const response = await bienservice.createbien(data);
            commit('GET_bien', response);
        },
    },
    mutations: {
        biens_LIST(state, data) {
            state.biensList = data;
        },
        GET_bien(state, data) {
            state.onebien = data;
        },
        lots_LIST_FROM_BUILDING(state, data) {
            state.lotsFromBuilding = data;
        },
        UPLOAD_IMAGE_lot(state, data) {
            state.image = data;
        },
  }
}