<template>
    <div class="row p-4">
        <div class="col-lg-3 ">
            <div class="row">
                <div class="col-md-9">
            <h1 class="h4">Modifier une vue</h1>

                </div>
                <div class="col-auto text-end">
                    <button class="btn btn-success" @click="save_model"><i class="fa fa-save"></i> Enregistrer</button>
                </div>
            </div>

            <div class="btn-group" role="group">
                <button class="btn " :class="mode == 'code'?'btn-info' :'btn-light'" @click="mode = 'code'" ><i class="fa-solid fa-code"></i>Code</button>
                <button class="btn " :class="mode == 'mail'?'btn-info' :'btn-light'" @click="mode = 'mail'" ><i class="fa-solid fa-paper-plane"></i>Mail</button>
                <button class="btn " :class="mode == 'show'?'btn-info' :'btn-light'" @click="mode = 'show'" ><i class="fa-solid fa-eye"></i>AffCode</button>
            
            </div>

            <input   type="text" v-model="content.title" class="mb-2 form-control" placeholder="Titre">

            <input type="text" v-model="content.syndic_id" class="mb-2 form-control" placeholder="ID syndic" >

            <input type="text" v-model="content.agence_id" class="mb-2 form-control" placeholder="ID agence" >


        </div>
        <div class="col-lg-9">
            <Vue3Jsoneditor v-if="mode == 'code'" ref="editor" v-model="content.value" height="90vh" />

            <EmailEditor v-if="mode == 'mail'"
            style="height: calc(100vh - 300px);"
        ref="emailEditor"
        v-on:load="editorLoaded"
        v-on:ready="editorReady"
      />
      <div v-if="mode == 'show'" class="mt-2">
        <div v-if="content.value.constructor === Array">
      <Block  v-for="v in content.value" :options="v" :key="v" v-model="modele_test"  />
</div>
      <Block  v-else :options="content.value" v-model="modele_test"  />

      </div>
        </div>
    </div>
</template>
<script>
import Vue3Jsoneditor from 'v3-jsoneditor/src/Vue3Jsoneditor.vue'
import axios from 'axios';
import showSwal from '../../../mixins/showSwal';
//import bg_capitole from '../../../assets/img/bg_capitole.png';
  import { EmailEditor } from 'vue-email-editor';
  import Block from '@/blocks/Block.vue';
export default {
    components: {
        Vue3Jsoneditor,
        EmailEditor,
        Block
},
    data() {
        return {
          modele_test: {},
            content: {
                value:{
                "hello": "vue"
            },
            syndic_id:1,
            agence_id:1,
        },
        mode: 'code',
        
            options: {
                prop: 'value'
            },
            options_pdf:{
                bg: null,
                prop: 'render',
                get_prop: 'value',
            },
            show_pdf: false,
        }
    },
    mixins: [showSwal],

    methods: {
        editorLoaded() {
        console.log('editorLoaded');
        // Pass the template JSON here
        if(this.content.value.design){
        let cv = JSON.parse(JSON.stringify(this.content.value.design))
         this.$refs.emailEditor.editor.loadDesign(cv);

        }
      },
      // called when the editor has finished loading
      editorReady() {
        console.log('editorReady');
      },
      saveDesign() {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          console.log('saveDesign', design);
        });
      },
      exportHtml() {
        this.$refs.emailEditor.editor.exportHtml((data) => {
          console.log('exportHtml', data);
        });
      },
        onError() {
            console.log('error')
        },
        arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    },
    async fetchFile(file) {
      try {
        const response = await axios.get(file, { responseType: 'arraybuffer' });
        const base64 = this.arrayBufferToBase64(response.data);
        this.options_pdf.bg =  base64;
        console.log(this.options_pdf)
        this.show_pdf = true;
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    },
        async save_model(){
            if (this.mode == 'mail'){
                this.$refs.emailEditor.editor.exportHtml( async (design) => {
                    this.content.value = design; 
                    console.log(design)
                    let view = {
                            title: this.content.title,
                            syndic_id: this.content.syndic_id,
                            data: this.content.value,
                            id: this.$route.params.id
                        }

                    await this.$store.dispatch("view/editView", view)
                        let view2 = this.$store.getters["view/oneView"]
                        console.log(view2)
                        
                        this.showSwal({message:"Vue modifiée",type: "success"});
                });
            } else {
                 let view = {
                title: this.content.title,
                syndic_id: this.content.syndic_id,
                data: this.content.value,
                id: this.$route.params.id
            }

          await this.$store.dispatch("view/editView", view)
            let view2 = this.$store.getters["view/oneView"]
            console.log(view2)
            
            this.showSwal({message:"Vue modifiée",type: "success"});
            }
           
            //
        }
    },
  async mounted() {
    await this.$store.dispatch("view/getView", this.$route.params.id);
    this.item = await this.$store.getters["view/oneView"];
    this.content.syndic_id = this.item.syndic_id;
    this.content.value = JSON.parse(this.item.view);
    this.content.title = this.item.title;
    if (this.content.value.design){
        this.mode = 'mail'
    }

    }
}

</script>