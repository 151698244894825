<template>
    <div class="mb-1">
    <div class="bg-light " >
        <div class="form-check form-switch ps-0">
    <input
      :id="options.name"
      class="form-check-input ms-0"
      :class="inputClass"
      type="checkbox"
      :value="options.name"
      v-model="modele[options.prop]"
      :disabled="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')"
    />
    <label class="form-check-label" :class="labelClass" :for="options.name">
        {{ options.label }}
    </label>
    
  </div>
        </div>
        
        </div>
</template>
<script>
//import Block from '../Block.vue'
//import {ArgonInput} from "@/components/ArgonInput.vue";
export default {
    name: "BasicInput",
    components: {
     //   Block
},
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: () =>[]
        },
    },
    data() {
        return {
            tmp_show:false,
            modele: this.modelValue,
            checked:this.options.default
        }
    },
    watch: {
        checked:function(val){
            this.modele[this.options.prop] = val
            this.$emit('update:modelValue', this.modele)
        },
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
    mounted(){
        if (this.options.default == true && this.modele[this.options.prop] === undefined){
            this.modele[this.options.prop] = true
        }
    }
}
</script>