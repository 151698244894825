<template>
    <div>
        <div ref="chartContainer"></div>
    </div>
  </template>

  <script>
import { ref, onMounted } from 'vue';
import Swal from 'sweetalert2'




function buildHierarchy(data) {
  const idMap = {};
  const root = [];

  // Créer un mapping des id aux objets
  data.forEach(item => {
    idMap[item.dolibarr_id] = { ...item, children: [],label:item.user.family_name+' '+item.user.given_name,type:'person', };
  });

    function generateKey(item, parent)  {
        // global idMap

        if (!parent) return `${item.dolibarr_id}`;
        const parentKey = idMap[parent.dolibarr_id].key;
        return `${parentKey}_${parent.children.length}`;
    }
  // Construire la hiérarchie
  data.forEach(item => {
    if (item.respo_hierarchique === null) {
        idMap[item.dolibarr_id].key =  generateKey(item);
        idMap[item.dolibarr_id].fontSize = '15pt';
      root.push(idMap[item.dolibarr_id]);
    } else {
        if (idMap[item.respo_hierarchique]) {
        idMap[item.dolibarr_id].key = generateKey(item, idMap[item.respo_hierarchique]);
        if(idMap[item.respo_hierarchique].children.findIndex(child => child.dolibarr_id === item.dolibarr_id) === -1){
            idMap[item.respo_hierarchique].children.push(idMap[item.dolibarr_id]);
        }
        // if a grandparent is the root, set the layout to hanging for the parent
        if (idMap[item.respo_hierarchique].respo_hierarchique === null) {
          idMap[item.dolibarr_id].layout = 'hanging';
          idMap[item.dolibarr_id].fontSize = '11pt';
          /*
            if(idMap[item.dolibarr_id].children.length <3){
                idMap[item.dolibarr_id].column = 10;
            } else {
            //  idMap[item.dolibarr_id].column = 2;
            }*/
        }
      } else {
        console.warn(`Parent avec id ${item.respo_hierarchique} non trouvé pour l'item ${item.dolibarr_id}. Ajout à la racine.`);
     //   root.push(idMap[item.id]);
      }

    }
  });

  return root;
}


  export default {
    setup() {
      const chartContainer = ref(null);


      onMounted(() => {
        const collaborateurs =   JSON.parse(localStorage.getItem('collabs'))


        const hierarchicalData = buildHierarchy(collaborateurs)[0];
        console.log(hierarchicalData)
        const chartdata = generateChartData(hierarchicalData);
        console.log(chartdata)
        const chartnodes = generateNodes(hierarchicalData);
        console.log(chartnodes)

        window.Highcharts.AST.allowedReferences = [...window.Highcharts.AST.allowedReferences, 'data:image/svg+xml;base64'];
        window.Highcharts.AST.allowedReferences = [...window.Highcharts.AST.allowedReferences, 'data:image/png;base64'];
        window.Highcharts.AST.allowedReferences = [...window.Highcharts.AST.allowedReferences, 'data:image/jpeg;base64'];
        window.Highcharts.AST.allowedReferences = [...window.Highcharts.AST.allowedReferences, 'data:image/jpg;base64'];
        window.Highcharts.AST.allowedReferences = [...window.Highcharts.AST.allowedReferences, 'data:image/gif;base64'];
        window.Highcharts.AST.allowedReferences = [...window.Highcharts.AST.allowedReferences, 'data:image/webp;base64'];

        window.Highcharts.setOptions({
        lang: {
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        decimalPoint: ",",
        downloadPNG: "Técharger en image PNG",
        downloadJPEG: "Télécharger en image JPEG",
        downloadPDF: "Télécharger en document PDF",
        downloadSVG: "Télécharger en document Vectoriel",
        exportButtonTitle: "Export du graphique",
        loading: "Chargement en cours...",
        printButtonTitle: "Imprimer le graphique",
        resetZoom: "Réinitialiser le zoom",
        resetZoomTitle: "Réinitialiser le zoom au niveau 1:1",
        // view in full screen
        viewFullscreen: "Voir en plein écran",
        printChart: "Imprimer le graphique",
        thousandsSep: " "
        }
        });

        window.Highcharts.chart(chartContainer.value, {
            hangingIndentTranslation: 'cumulative',
          chart: {
            height: "100%",
            inverted: true
          },
          title: {
            text: 'Organigramme de l\'entreprise'
          },
          series: [{
            type: 'organization',
            name: 'Collaborateur',
            keys: ['from', 'to'],
          nodeWidth: 30,
          point: {
      events: {
        click: function() {
          // open modal
          Swal.fire({
            imageUrl: this.image,
      title: this.info.user.family_name+' '+this.info.user.given_name,
      html: this.info.user.email+'<br>'+this.info.phone+'<br>'+this.info.mobile_phone_pro,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-phone"></i> Appeler',
      confirmButtonAriaLabel: "Appeler !",
      cancelButtonText: '<i class="fa fa-envelope"></i> Envoyer un mail',
      cancelButtonAriaLabel: "Envoyer un mail",
      customClass: {
        confirmButton: "btn btn-info",
        cancelButton: "btn btn-success ",
      },

      buttonsStyling: false,
    }
).then((result) => {
    if (result.isConfirmed) {

        if(this.info.mobile_phone_pro){
            window.location.href = 'tel:'+this.info.mobile_phone_pro;
        } else {
            window.location.href = 'tel:'+this.info.phone;
        }

    } else if (result.dismiss === Swal.DismissReason.cancel) {
        window.location.href = 'mailto:'+this.info.user.email;
    }
})


        },
  /*      mouseOut: function() {
          const popup = document.getElementById('popup');
          popup.style.display = 'none';
        }*/
      }
    },
            nodes: chartnodes,
            data: chartdata,
            fontSize: '10px',
            levels: [{
              level: 0,
              color: 'silver',
              dataLabels: {
                color: 'black',
                    fontSize:'5pt',
              },
              height: 20
            }, {
              level: 1,
              color: 'gold',
              dataLabels: {
                color: 'black',
                style:{
                textOutline: 'none',}
              },
              height: 25
            }, {
              level: 2,
              color: '#980104',
              dataLabels: {
                color: 'black',
                style:{
                    textOutline: 'none',
                    fontSize:'5pt',}
              },
                height: 10
            }, {
              level: 4,
              color: '#359154',
                dataLabels: {
                    color: 'black',
                    style:{
                        textOutline: 'none',
                        fontSize:'5pt',}
                },
                    style:{
                        textOutline: 'none',
                        fontSize:'5pt',},
                height: 10
            },
        {
            level: 5,
            color: '#359154',
            dataLabels: {
                color: 'black',
                style:{
                    textOutline: 'none',
                    fontSize:'5pt',}
            },
        }
        ],
            colorByPoint: false,
            color: '#007ad0',
            borderColor: 'black',
            textOutline: 'none',
            style: {
                fontSize: '5pt',
                textOutline: 'none',
            },
          }],
          tooltip: {
            outside: true
          },
          exporting: {
            allowHTML: true,
            sourceWidth: 1200,
            sourceHeight: 1400,
    buttons: {
        exportButton: {
            menuItems: [{
                text: 'my test',

                onclick:function() {
                    console.log('test')
                }
            }]
        }
    }
          }
        });
      });

      return {
        chartContainer,
        print
      };
    }
  }

  function generateChartData(node, parent = '') {
    let data = [];
    if (parent) {
      data.push([parent, node.label]);
    }

    // order data by metier and then by name
    if (node.children) {
      node.children.sort((a, b) => {
        if (a.metier < b.metier) {
          return -1;
        }
        if (a.metier > b.metier) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }


    if (node.children) {
      node.children.forEach(child => {
        data = data.concat(generateChartData(child, node.label));
      });
    }
    return data;
  }


  function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
  var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 130)
        ? darkColor
        : lightColor;
}


  function calcColor(node) {
    if(node.color){
        const treeColor = '#'+node.color;
        // black or white depending on the background
        return pickTextColorBasedOnBgColorSimple(treeColor, 'white', 'black');
    } else {
        return 'black'
    }
  }
  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


  function generateNodes(node) {
    var blob_image = null;
    var b64_image = null;

    if(sessionStorage.getItem('collab_image_'+node.dolibarr_id)){
        console.log('found image')
        b64_image = sessionStorage.getItem('collab_image_'+node.dolibarr_id)
       //blob_image = new Blob([b64_image], {type: 'image/'+b64_image.split(';')[0].split('/')[1]});

       try {
        let contentType = 'image/'+b64_image.split(';')[0].split('/')[1];
        let contentVal = b64_image.split(';')[1].split(',')[1];
        blob_image = b64toBlob(contentVal, contentType);
       // blob_image = URL.createObjectURL(blob_image);
         } catch (error) {
              console.log(error)}

    }
    console.log(blob_image)

    let nodes = [{
        dolibarr_id: node.dolibarr_id,
        id: node.label,
    //layout: 'hanging',
   //  title: '<p class="text-xs">'+node.qualite+'</p>',
      //name: node.label,
     // image: 'https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2020/03/17131210/Highsoft_04045_.jpg',
      //data:'<img src="https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2020/03/17131210/Highsoft_04045_.jpg" style="width:100px;height:100px"/>',
         image: b64_image,
         info:node,
    dataLabels: {
        useHTML: true,
        tooltip: {
            useHTML: true,
            borderWidth: 0,
            shadow: false,
            formatter: function() {
                return '<img src="'+b64_image+'" width="180" height="90" />'
            },
        },

        style: {
            fontSize: node.fontSize ? node.fontSize : '12px',
            textOverflow: 'ellipsis',
            textOutline: 'none',
            color: calcColor(node),
        },
        height: 10,


    },
    column:node.column,
    color: node.color?'#'+node.color:'white',
    }];
    if(node.layout){
        nodes[0].layout = node.layout
    }
    if (node.children) {
      node.children.forEach(child => {
        nodes = nodes.concat(generateNodes(child));
      });
    }
    return nodes;
  }


  </script>
<style>
h4{
    font-size: 12px!important;
}
</style>
