import axios from "axios";
import authHeader from "./auth-header";
import Jsona from "jsona";
import qs from "qs";

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListCollaborateurs(params) {
  const response = await axios.get(`${API_URL}/collaborateurs`, {
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader(),
  });

  return { data: response.data.data, meta: response.data.meta };
}

async function getCollaborateur(CollaborateurId) {
  const response = await axios.get(
    `${API_URL}/collaborateurs/${CollaborateurId}?include=category,tags`,
    { headers: authHeader() },
  );
  return response.data;
}

async function getZones(zonename) {
  const response = await axios.get(
    `${API_URL}/zones/${zonename}`,
    { headers: authHeader() },
  );
  return response.data;
}

async function editCollaborateur(controller) {
  const response = await axios.patch(
    `${API_URL}/collaborateurs/${controller.id}?include=category,tags`,
    controller,
    { headers: authHeader() },
  );
  return jsona.deserialize(response.data);
}

async function createCollaborateur(controller) {
  const response = await axios.post(`${API_URL}/collaborateurs?include=category,tags`, controller, {
    headers: authHeader(),
  });
  return response.data;
}

async function deleteCollaborateur(CollaborateurId) {
  await axios.delete(`${API_URL}/admin/collaborateurs/${CollaborateurId}`, {
    headers: authHeader(),
  });
}

async function uploadImageCollaborateur(controller) {
  const payload = new FormData();
  payload.append("attachment", controller.image);

  const response = await axios.post(
    `${API_URL}/uploads/collaborateurs/${controller.id}/image`,
    payload,
    { headers: authHeader() },
  );
  return response.data.url;
}

async function updateCollaborateurZones(item){

  const response = await axios.patch(
    `${API_URL}/collaborateurs/${item.collaborateurId}/zones`,
    {type_carte:item.type_carte,zones:item.zones},
    { headers: authHeader() },
  );
  return response.data;
}

/*
 * Cette fonction a update les valeurs des token de la ligne collaborateur dans la base de donnée
 *
 * Le controller doit avoir en paramètre les valeurs suivantes :
 * @param access_token : la valeur du token d'accès de webex
 * @param refresh_token : la valeur du refresh token de webex
 *
 * @param id : l'id de la ligne a update
 *
 */
async function editCollaborateurWebexToken(controller, id) {
  // On envoie la requète a l'api
  const response = await axios.patch(
    `${API_URL}/webex/callback`,
    {
      id: id,
      access_token: controller.access_token,
      refresh_token: controller.refresh_token,
    },
    {
      headers: authHeader(),
    },
  );
  console.log("réponse de la part du server : ", response);
  return jsona.deserialize(response.data);
}

export default {
  getListCollaborateurs,
  getCollaborateur,
  getZones,
  editCollaborateur,
  createCollaborateur,
  deleteCollaborateur,
  uploadImageCollaborateur,
  updateCollaborateurZones,
  editCollaborateurWebexToken,

};
