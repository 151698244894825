<template>
    <div class="py-1 container-fluid" v-if="!loading.activities">
          <div class="bg-dark p-2 border-radius-xl d-flex">
                <div class="dropdown">
  <a href="#" class="btn btn-dark btn-xs  mb-0 ml-2"  data-bs-toggle="dropdown" id="navbarDropdownMenuLink2">
    <i class="fa fa-list"></i>&nbsp;ACTIONS&nbsp;
  </a>
  <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2" style="width: 50vw;
height: 50vh;">
    <li class="p-2">
        <div>
            <ToDo/>
            <div v-for="(ac, num) in activities" v-bind:key="num" class="display:inline">
                {{ ac.titre  }}
            </div>

        </div>


    </li>
    </ul>
    </div>




            <div v-for="(ac,num) in reverseLastActivities" v-bind:key="num" class="display:inline">
                <div class="dropdown">
  <a href="#" class="btn  btn-xs dropdown-toggle mb-0 ml-2" :class="num == now_activity ? 'bg-gradient-primary':'bg-gradient-light'" data-bs-toggle="dropdown" id="navbarDropdownMenuLink2">
    <i :class="ac.icon"></i>&nbsp; {{ac.titre}}
  </a>
  <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
    <li class="p-1">
  <h6 class="dropdown-header">Immeuble</h6>
        <button class="btn btn-danger btn-xs" v-if="ac.immeuble" @click="goImm(ac.immeuble.id)">{{ ac.immeuble.EXTAPI_IMM_ID }} - {{ ac.immeuble.nom }}</button>
  <h6 class="dropdown-header">Mail</h6>
        <button class="btn btn-success btn-xs" v-if="ac.mail" @click="goMail(ac.mail.id)">{{ ac.mail.titre }} </button>


    </li>

      <li >
          <a class="dropdown-item" @click="startActivity(ac,num) ">
        <i class="fa fa-play text-success"></i> Démarrer l'action
          </a>
      </li>
      <li v-if="now_activity == num ">
          <a class="dropdown-item" @click="now_activity = null ">
            <i class="fa fa-pause text-warning"></i> Mettre en pause l'action
          </a>
      </li>
      <li v-if="now_activity == num ">
          <a class="dropdown-item"  @click="now_activity = null; ">
            <i class="fa fa-stop text-danger"></i> Terminer l'action
          </a>
      </li>
    <!--separator-->
    <div class="dropdown-divider"></div>

      <li>
          <a class="dropdown-item">
            <i class="fa fa-share text-primary"></i> Attribuer à un collaborateur
          </a>
      </li>
      <li>
          <a class="dropdown-item"  @click="activities.pop(num);saveActivities() ">
            <i class="fa fa-trash text-danger"></i> Supprimer l'action
          </a>
      </li>
  </ul>
</div>

            </div>
            <div style="flex:1"></div>
            <input type="text" class="form-control form-control-sm" v-model="search" placeholder="Rechercher" style="max-width:240px">
            <button style="max-width:240px" @click="ajouterAction" class="btn btn-white btn-sm btn-round p-1 mb-0">&nbsp;<i class="fa fa-plus"></i>&nbsp;Ajouter&nbsp;</button>
          </div>
    </div>
</template>
<script>

import ToDo from "./ToDo.vue"
export default {
  name: "ActivityBar",
  components: {ToDo},
  data() {
    return {
      loading: {
        activities: false
      },
        now_activity:null,
        activities: [
        ]
    }
},
mounted() {

    this.getActivities();
  window.addEventListener('activity_bar-localstorage-changed', (event) => {
   console.log(event)
   setTimeout(() => {
    let l = localStorage.getItem("activity_bar")
    console.log(l)
    this.getActivities();
   }, 100);
  });
},
watch: {
    /*activities: {
        handler: function (newVal) {
          this.loading.activities = true;
          // this.reverseActivities = newVal.reverse();
         //  this.reverseLastActivities = newVal.slice(-3).reverse();
            this.loading.activities = false;
        },
        deep: true
    }*/
},
computed: {
    reverseActivities() {

      //  return this.activities.reverse();
      return [...this.activities].reverse();
    },
    reverseLastActivities() {
        return [...this.activities].slice(-3).reverse();
    }
},
methods: {
    async startActivity(ac,num){
        console.log(ac)
        setTimeout(() => {
          console.log("now_activity",ac,num)
            this.now_activity = num;
        }, 100);
        var res;
        switch(ac.event_type){
            case 'ordre_intervention':
                await this.$store.dispatch("event/ia_task", {solution:ac.titre+''+ac.description, contexte:ac.mail.content} );
                 res = await this.$store.getters['event/ia']
               //  res ={texte_demande :"aaa"}
                    console.log(res)
                    console.log(res.texte_demande.replace(/(\r\n|\r|\n)/g, '<br>'))
                    this.$router.push({
                        name: "Liste des évènements",
                        params:{newEventProp: JSON.stringify({'immeuble_id':ac.immeuble.id,'immeuble':ac.immeuble, 'titre':res.objet,
                        'type':"Ordre de service",
                        'description':res.texte_demande.replace(/(\r\n|\r|\n)/g, '<br>')
                    })}
                    })
                break;
            case 'demande_devis':
                await this.$store.dispatch("event/ia_task", {solution:ac.titre+''+ac.description, contexte:ac.mail.content} );
                 res = await this.$store.getters['event/ia']

                    console.log(res)
                    console.log(res.texte_demande.replace(/(\r\n|\r|\n)/g, '<br>'))
                    this.$router.push({
                        name: "Liste des évènements",
                        params:{newEventProp: JSON.stringify({'immeuble_id':ac.immeuble.id,'immeuble':ac.immeuble, 'titre':res.objet,
                        'type':"Demande de devis",
                        'description':res.texte_demande.replace(/(\r\n|\r|\n)/g, '<br>')
                    })}
                    })
                break;
                case 'reponse_mail':
                    this.$router.push({
                        name: "Liste des mails",
                        query: { id: ac.mail.id }
                    })
                /*
                this.$router.push({
                    name: "Liste des évènements",
                    params:{newEventProp: JSON.stringify({'immeuble_id':ac.immeuble.id,'immeuble':ac.immeuble, 'description':ac.titre,

                })}
                })*/
        }
    },
    getActivities() {
        try{
          this.loading.activities = true;
        if(localStorage.getItem("activity_bar")){
        this.activities = JSON.parse(localStorage.getItem("activity_bar"));

        }
        this.loading.activities = false;
    }catch(e){
        console.log(e)}
    },
    saveActivities() {
        localStorage.setItem("activity_bar", JSON.stringify(this.activities));
        window.dispatchEvent(new CustomEvent('activity_bar-localstorage-changed', { detail: { storage: localStorage.getItem("activity_bar") } }));
    },
    goMail(id){
        this.$router.push({ name: 'Liste des mails', query: { id: id } })
    },
    goImm(Id){
        this.$router.push({ name: 'Immeuble', params: { id: Id } })
    }
},
}
</script>
