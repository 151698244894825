import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListSuperSyndics(params){
  const response =  await axios.get(`${API_URL}/supersyndics`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getSuperSyndic(SuperSyndicId){
  const response =  await axios.get(`${API_URL}/supersyndics/${SuperSyndicId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editSuperSyndic(controller){

  const payload = jsona.serialize({
    stuff: controller,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/supersyndics/${controller.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createSuperSyndic(controller){

  const payload = jsona.serialize({
    stuff: controller,
    includeNames: []
  });

  const response =  await axios.post(`${API_URL}/supersyndics?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function deleteSuperSyndic(SuperSyndicId){
  await axios.delete(`${API_URL}/supersyndics/${SuperSyndicId}`, {headers: authHeader()});
}

async function uploadImageSuperSyndic(controller) {
    const payload = new FormData();
    payload.append("attachment", controller.image);
  
    const response = await axios.post(`${API_URL}/uploads/supersyndics/${controller.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListSuperSyndics,
    getSuperSyndic,
    editSuperSyndic,
    createSuperSyndic,
    deleteSuperSyndic,
    uploadImageSuperSyndic
};