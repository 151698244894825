import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListbiens(params){
  const response =  await axios.get(`${API_URL}/baux`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getListbiensFromBuilding(params){
  let immeuble_id = params.immeuble_id;
  const response =  await axios.get(`${API_URL}/immeubles/${immeuble_id}/baux`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data, meta: response.data.meta};
}

async function getbien(bienId){
  const response =  await axios.get(`${API_URL}/baux/${bienId}`, {headers: authHeader()});
  return response.data;
}

async function editbien(bien){

  const payload = jsona.serialize({
    stuff: bien,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/biens/${bien.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createbien(bien){


  const response =  await axios.post(`${API_URL}/biens`, bien, {headers: authHeader()});
  return response.data;
}

async function deletebien(bienId){
  await axios.delete(`${API_URL}/biens/${bienId}`, {headers: authHeader()});
}

async function uploadImagebien(bien) {
    const payload = new FormData();
    payload.append("attachment", bien.image);
  
    const response = await axios.post(`${API_URL}/uploads/biens/${bien.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListbiens,getListbiensFromBuilding,
    getbien,
    editbien,
    createbien,
    deletebien,
    uploadImagebien
};