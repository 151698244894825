<template>
    <div class="mb-1">
        <!--op:{{ options }}-->
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
    <div  class="bg-red border-radius-lg p-2" style="display: flex;">
        <div class="bg-red border-radius-lg p-2 text-dark text-sm pr-3" >{{ options.name }}</div>
        <div class="bg-red border-radius-lg p-1 text-bold text-dark w-100" style="margin-top: 2px ;font-weight: 700;">{{ perso_name }}</div>
        <button @click="goPerso()" class="btn btn-xs btn-white mb-0"><i class="fa-solid fa-arrow-up-right-from-square"></i></button>
    </div>
    </div>
    <div v-else class="bg-red border-radius-lg p-2 text-end" >
        <Modal v-model="modals.edit_attributs.show" closeable header="Modifier les codes dans l'immeuble">
            <EditionAttributImmeuble  v-model="attributes" :closeModal="()=>{modals.edit_attributs.show = false}" :modalAttributes="modals.edit_attributs" :immeuble_id="$route.params.id"></EditionAttributImmeuble>
        </Modal>
        
        <div style="display: flex;">
        <div class="bg-red border-radius-lg p-2 text-white text-sm">{{ options.name }}</div>
        

        <el-select      v-model="modele[options.prop]"

                        filterable  reserve-keyword placeholder="Merci de choisir le code d'accès" >
                        <el-option v-for="item in modals.edit_attributs.codes" :key="item.localisation" :value="item.code">
                            {{ item.localisation }} : {{ item.code }}
                        </el-option>
                    </el-select>
        </div>
        <button class="btn btn-xs mb-0 btn-white" @click="modals.edit_attributs.show=true;"><i class="fa fa-key"></i> Modifier les codes d'accès </button>


    </div>    </div>
</template>
<script>
//import {ArgonInput} from "@/components/ArgonInput.vue";
/*
import ArgonInput from "../../../../components/ArgonInput.vue";
import ArgonRadio from "../../../../components/ArgonRadio.vue";
import ArgonSwitch from "../../../../components/ArgonSwitch.vue";*/
import Modal from "@/components/Modal.vue";
import {ElSelect, ElOption} from "element-plus";
import EditionAttributImmeuble from "@/views/collaborateur/immeubles/EditionAttributImmeuble.vue";
export default {
    name: "Immeuble",
    components: {
    // ArgonInput,
    ElSelect, ElOption, Modal,
    EditionAttributImmeuble
},
    props: {
        options:{
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        blocking: {
            type: Array,
            default: ()=>[]
        },
    },
    data() {
        return {
            addTiers:false,
            tiers:{
                civilite:{
                    madame:false,
                    monsieur:false
                },
                nom:'',
                prenom:'',
                mail:'',
                phone:'',
                adresse1:'',
                adresse2:'',
                cp:'',
                ville:'',
                idem_syndic:true,
                syndic:'',
                immeuble:'',
                etage:'',
                num_appart:'',
                gerant:'',
                comp_assurance:'',
                num_police_assurance:'',
                lot:''
            },
        modals:{
            addtiers:false, 
            reception_personne:false, 
            edit_attributs:{
                attr_type:'',
                codes:[],
                show:false,
            }
        },
        attributes:{},
            modele: this.modelValue,
            all_persos:[],
            perso_name:'',
            perso_id:'',
            loading:{
                perso:true,
                immeuble:true,
            },
            disabled: {
                perso:false,
                immeuble:false,
                lot:false
            }
        }
    },
    computed:{
        immeuble_id(){
            return this.modele.immeuble_id
        }
    },
    watch: {
        modele: async function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        },
        immeuble_id: function () {
            this.getFromImmeuble()
        }
    },
    mounted(){

        if (this.modele.perso){
            this.all_persos = [this.modele.perso];
            this.all_persosd = [this.modele.perso.id];
            this.bounce_perso = true;
            if (this.modele.perso.id){
                this.getPersoById(this.modele.perso.id)
            }
        } else if(this.modele.perso_id){

            this.getPersoById(this.modele.perso_id)
        } else if (this.modele.immeuble_id){
            this.getFromImmeuble()
        }
    },
    methods:{
        goPerso(){
        this.$router.push({
          name: "Perso",
          params: { id: this.perso_id },
        });
        },
        
        async getFromImmeuble(){
            let immeuble =  this.modele.immeuble_id
            await this.$store.dispatch("immeuble/getImmeuble", immeuble);
            let imm = this.$store.getters["immeuble/oneImmeuble"];
            this.attributes = imm.attributes;
            let d = imm.attributes.find(el => el.name === "Codes d'accès")
            if(d){
                this.modals.edit_attributs.attr_type =d.attr_type;
                this.modals.edit_attributs.value =d.value;
                this.modals.edit_attributs.immeuble_id = imm.id;
                this.modals.edit_attributs.id = d.id;
                this.modals.edit_attributs.name = d.name;


                this.modals.edit_attributs.codes =JSON.parse(d.value);
            }

            this.loading.perso =true;
            let params = {
                immeuble_id: immeuble,
                page: 1,
                size: 20,
                //sort: "events.last",
            };
            console.log(params)
            await this.$store.dispatch("perso/persosFromBuilding", params);
            this.all_persos = JSON.parse(JSON.stringify(this.$store.getters["perso/persosFromBuilding"].data));
            console.log("P")
            console.log(this.all_persos)
            this.all_persosd = [];
                this.all_persos.forEach(imm => {
                    this.all_persosd.push(imm.id);
                });
            this.loading.perso =false;
        },

        async getPerso(p){
                this.loading.perso =true;
                let params = {
                    ...(p ? { search: p } : {}),
                    page: 1,
                    size: 20,
                    //sort: "events.last",
                };
                console.log(params)
                await this.$store.dispatch("perso/persosList", params);
                this.all_persos = this.$store.getters["perso/persosList"].data;
                this.all_persosd = [];
                    this.all_persos.forEach(imm => {
                        this.all_persosd.push(imm.id);
                    });
                this.loading.persos =false;
                console.log(this.all_persos)
        },

        async getPersoById(pid){
            await this.$store.dispatch("perso/getPerso", pid);
            this.perso_tmp = this.$store.getters["perso/onePerso"];
            this.perso_id = this.perso_tmp.id;
            this.perso_name = this.perso_tmp.nom + ' ' + this.perso_tmp.prenom;

        }
    }
}
</script>
<style>
.el-input__wrapper{
    border-radius: 10px!important;
}
</style>