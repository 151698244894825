<template>

<div>
      <h4>Ma liste de tâches</h4>
      <input v-model="newTodo" @keyup.enter="addTodo" placeholder="Ajouter une nouvelle tâche">
      <button @click="addTodo">Ajouter</button>
      <ul>
        <li v-for="todo in todos" :key="todo.id">
          <input type="checkbox" :checked="todo.completed" @change="toggleTodo(todo.id)">
          <span :class="{ completed: todo.completed }">{{ todo.text }}</span>
          <button @click="removeTodo(todo.id)">Supprimer</button>
        </li>
      </ul>
    </div>
</template>
<script>
import { ref, onMounted, watch } from 'vue';

export default {
  name: 'TodoList',
  setup() {
    const todos = ref([]);
    const newTodo = ref('');

    const loadTodos = () => {
      const savedTodos = localStorage.getItem('todos');
      if (savedTodos) {
        todos.value = JSON.parse(savedTodos);
      }
    };

    const saveTodos = () => {
      localStorage.setItem('todos', JSON.stringify(todos.value));
    };

    const addTodo = () => {
      if (newTodo.value.trim()) {
        todos.value.push({
          id: Date.now(),
          text: newTodo.value,
          completed: false
        });
        newTodo.value = '';
      }
    };

    const removeTodo = (id) => {
      todos.value = todos.value.filter(todo => todo.id !== id);
    };

    const toggleTodo = (id) => {
      const todo = todos.value.find(todo => todo.id === id);
      if (todo) {
        todo.completed = !todo.completed;
      }
    };

    onMounted(loadTodos);

    watch(todos, saveTodos, { deep: true });

    return {
      todos,
      newTodo,
      addTodo,
      removeTodo,
      toggleTodo
    };
  }
};

</script>
<style>

.completed {
      text-decoration: line-through;
    }
</style>
