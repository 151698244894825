<template>
    <div class="mb-1">
    <div v-if="blocking.find(el=>el===options.prop)||blocking.find(el=>el==='all')">
        <div v-html="modele[options.prop]"></div>
    </div>
    <div v-else :class="options.label ? 'bg-light border-radius-lg p-2':''" >
        <div v-if="options.label" class="bg-light border-radius-lg p-2 text-black text-sm">{{ options.label }}</div>

    <Editor

    :init="{
      plugins: 'lists link image table code help wordcount',
      language: 'fr_FR',
      menubar: false,
  toolbar: 'modeles | code undo redo |fontsize |bold italic underline | alignleft aligncenter alignright alignjustify | backcolor forecolor  | blocks outdent indent | table',
        branding: false,
        promotion: false,
        height: 300,
        setup:setting
                    }"
                    v-model="modele[options.prop]"
        />

    </div>

        </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    name: "RichTextControl",
    components: {
        Editor,
    },
    mounted(){
        if (this.options.default && this.modele[this.options.prop] === undefined){
            this.modele[this.options.prop] = this.options.default
        }
    },
    props: {
        modelValue: {
            type: Object,
            default: ()=>{}
        },
        readOnly: {
            type: String,
            default: ''
        },
        blocking: {
            type: Array,
            default: () =>[]
        },
        options:{
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            modele: this.modelValue
        }
    },
    watch: {
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
    },
    methods:{
        async setting(editor){

            editor.ui.registry.addContextToolbar('textselection', {
      predicate: () => !editor.selection.isCollapsed(),
      items: 'bold italic underline',
      position: 'selection',
      scope: 'node'
    });

try{
            await this.$store.dispatch('modele/modelesListGest')
            let modeles = this.$store.getters['modele/modelesList'].data
            console.log('modeles')
            console.log(modeles)
           // let toggleState = false;

    /* example, adding a toolbar menu button */
    editor.ui.registry.addMenuButton('modeles', {
      text: 'Modèles',
      /*search: {
        placeholder: 'Rechercher un modèle...'
      },*/
      fetch: (callback, fetchContext) => {

        if (fetchContext.pattern.length > 0) {
            console.log(fetchContext.pattern)



          callback([
            {
              type: 'menuitem',
              text: `You searched for: "${fetchContext.pattern}"`,
              onAction: () => editor.insertContent(`<strong>Inserted selected search result</strong>`)
            }
          ]);
        } else {//fetchContext.pattern
            var items0 = {}
            modeles.forEach((item)=>{
                if (!items0[item.element]){
                    items0[item.element] = []
                }
                items0[item.element].push({
                    type: 'menuitem',
                    text: item.title,
                    onAction: () => editor.insertContent(JSON.parse(item.data))
                })
            })
            console.log(items0)

            var items = []
            for (const [key, value] of Object.entries(items0)) {
                items.push({
              type: 'menuitem',
                    text: key,
                    getSubmenuItems: () => value
                })
                console.log(key, value);
            }

/*
          const items = [
            {
              type: 'menuitem',
              text: 'Menu item 1',
            getSubmenuItems: () => [
              {
                type: 'menuitem',
                text: 'Sub menu item 1',
                icon: 'unlock',
                onAction: () => editor.insertContent('&nbsp;<em>You clicked Sub menu item 1!</em>')
              },
              {
                type: 'menuitem',
                text: 'Sub menu item 2',
                icon: 'lock',
                onAction: () => editor.insertContent('&nbsp;<em>You clicked Sub menu item 2!</em>')
              }
            ],
              onAction: () => editor.insertContent('&nbsp;<em>You clicked menu item 1!</em>')
            },
            {
              type: 'togglemenuitem',
              text: 'Toggle menu item',
              onAction: () => {
                toggleState = !toggleState;
                editor.insertContent('&nbsp;<em>You toggled a menuitem ' + (toggleState ? 'on' : 'off') + '</em>');
              },
              onSetup: (api) => {
                api.setActive(toggleState);
                return () => {};
              }
            }
          ];*/
          callback(items);
        }
      }
    });
} catch {
    console.log('error')
}

        }
    }
}
</script>
