<template>
    <div class="container-fluid pt-3" style="background: rgba(232, 178, 120, 0.1);height: calc(100vh); ">

        <div class="  border-radius-xl row" style="background: rgb(232, 165, 40);padding: 0.9rem 0.9rem !important;">
            <!--<h3 class="mb-0 text-white">Liste des fournisseurs</h3>-->
            <div class="col-sm-3"></div>
            <div class="col-sm-6 text-center">
                <h3 class="h6 col-12 mb-0 text-white">Ajouter un bien</h3>

            </div>
            <div class="col-sm-3 text-end">
                                    <ElTooltip placement="bottom"
                                        content="Merci d'entrer tous les champs requis pour enregistrer">
                <button class="btn btn-sm btn-white mb-0" @click="addBien()">Enregistrer
                                </button> 
                                    </ElTooltip>

            </div>


        </div>
        <div class="row  ">
            <div class="col-sm-12 text-center">
                <div class="btn-group text-center bg_light_orange mt-1 border-radius-lg mb-2" style="" role="group">
                    <button type="button" class="btn btn-sm mb-0"
                        :class="activeTab == 'princ' ? 'btn-white' : 'btn-outline-white bg_light_orange'"
                        @click="changeTab('princ')">Informations principales</button>
                    <button type="button" class="btn btn-sm  mb-0"
                        :class="activeTab == 'desc' ? 'btn-white' : 'btn-outline-white bg_light_orange'"
                        @click="changeTab('desc')">Description</button>
                    <button type="button" class="btn btn-sm  mb-0"
                        :class="activeTab == 'pieces' ? 'btn-white' : 'btn-outline-white bg_light_orange'"
                        @click="changeTab('pieces')">Pièces</button>
                    <button type="button" class="btn btn-sm  mb-0"
                        :class="activeTab == 'bailleur' ? 'btn-white' : 'btn-outline-white bg_light_orange'"
                        @click="changeTab('bailleur')">Bailleur</button>

                </div>
            </div>
        </div>
        <div id="princ-tab" style="overflow-y:scroll;height: calc(100vh - 128px);display:block">
            <div class="card card-body">
                <div class="row">
                    <div class="col-md-6">
                        <label class="form-label">Statut</label>
                        <select class="form-control form-control-sm " v-model="data['121']">
                            <!--<option value="" title=""></option>
                            <option value="Estimation" title="2 - Estimation">2 - Estimation</option>
                            <option value="EnCours" selected="selected" title="3 - Mandat en cours">3 - Mandat en
                                cours
                            </option>-->
                            <option value="Suspendu" title="4 - Suspendu">4 - Suspendu</option>
                            <!--<option value="SousOffre" title="5 - Sous offre">5 - Sous offre</option>
                            <option value="Compromis" title="7 - Compromis">7 - Compromis</option>
                            <option value="Vendu" title="8 - Vendu / Loué">8 - Vendu / Loué</option>
                            <option value="Archive" title="10 - Archivé">10 - Archivé</option>-->
                        </select>
                    </div>
                    <div class="col-md-3">

                        <label class="form-label">Prochain contact</label>
                        <flat-pickr v-model="data.products_next_contact" class="form-control form-control-sm datetimepicker"
                            placeholder="Prochain contact le" :config="config"></flat-pickr>
                    </div>
                    <div class="col-md-3">

                        <label class="form-label">Disponible</label>
                        <flat-pickr v-model="data.date_disponibilite" class="form-control form-control-sm datetimepicker"
                            placeholder="Disponible le" :config="config"></flat-pickr>


                    </div>


                </div>
                <div class="row">

                    <div class="col-md-6 col-lg-4">
                        <table class="product-table">

                            <tbody>
                                <tr>
                                    <td>
                                        <label class="form-label">Transaction</label>
                                        <select class="form-control" v-model="data['28']">
                                            <!--<option value="vente">Vente</option>-->
                                            <option selected value="Location">Location</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label class="form-label">Type</label>
                                        <select name="C_27" id="C_27" class="form-control" required v-model="data['27']">
                                            <option value="1" selected="selected" title="Appartement">Appartement
                                            </option>
                                            <option value="Chambre" title="Chambre">Chambre</option>
                                            <option value="demeure_contemporaine" title="Demeure Contemporaine">
                                                Demeure Contemporaine</option>
                                            <option value="Ecurie" title="Ecurie">Ecurie</option>
                                            <option value="15" title="Entreprise">Entreprise</option>
                                            <option value="Ferme" title="Ferme">Ferme</option>
                                            <option value="Fermette" title="Fermette">Fermette</option>
                                            <option value="4" title="Fonds de commerce">Fonds de commerce</option>
                                            <option value="Haras" title="Haras">Haras</option>
                                            <option value="Hotel_Part" title="Hotel particulier">Hotel particulier
                                            </option>
                                            <option value="6" title="Immeuble">Immeuble</option>
                                            <option value="ImmeubleRapport" title="Immeuble de rapport">Immeuble de
                                                rapport</option>
                                            <option value="23" title="Local commercial">Local commercial</option>
                                            <option value="16" title="Longère">Longère</option>
                                            <option value="2" title="Maison">Maison</option>
                                            <option value="MaisonBourgeoise" title="Maison bourgeoise">Maison
                                                bourgeoise</option>
                                            <option value="MaisonArchitecte" title="Maison d'architecte">Maison
                                                d'architecte</option>
                                            <option value="MaisonMaitre" title="Maison de maître">Maison de maître
                                            </option>
                                            <option value="MaisonPays" title="Maison de pays">Maison de pays
                                            </option>
                                            <option value="MaisonVille" title="Maison de ville">Maison de ville
                                            </option>
                                            <option value="21" title="Manoir">Manoir</option>
                                            <option value="MobilHome" title="Mobil Home">Mobil Home</option>
                                            <option value="5" title="Parking / box">Parking / box</option>
                                            <option value="Peniche" title="Péniche">Péniche</option>
                                            <option value="PN" title="Programme Neuf">Programme Neuf</option>
                                            <option value="ProprieteEquestre" title="Propriété Equestre">Propriété
                                                Equestre</option>
                                            <option value="10" title="Terrain">Terrain</option>
                                            <option value="17" title="Villa">Villa</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr style="border:none;">
                                    <td colspan="3" style="padding:0;">
                                        <table width="100%" >
                                            <tbody>
                                                <tr>
                                                    <td>
                                                    <label class="form-label">Pièce(s)</label>
                                                        <div class="input_container">
                                                            <div class="input_container_int">
                                                                <input type="text" name="CR_33" v-model="data['33']"
                                                                    placeholder="Non renseigné"
                                                                    class="form-control form-control-sm">
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>
                                        <label class="form-label">Chambres</label>
                                                        <div class="input_container">
                                                            <div class="input_container_int">
                                                                <input type="text" name="CR_38" v-model="data['38']"
                                                                    placeholder="Non renseigné"
                                                                    class="form-control form-control-sm">
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>
                                        <label class="form-label">Surface</label>
                                                        <div class="input_container">
                                                            <div class="input_container_int">
                                                                <input type="text" name="CR_34" placeholder="Non renseigné"
                                                                    v-model="data['34']"
                                                                    class="form-control form-control-sm">
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <table>
                                                <tbody>
                                                <tr>

                                                    <td>
                                        <label class="form-label">Terrain
                                    <ElTooltip placement="top"
                                        content="Surface de la parcelle cadastrale">
                                        <i class="fa fa-info-circle"></i>
                                    </ElTooltip></label>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <input type="text" name="CR_36" 
                                                                    placeholder="Non renseigné"
                                                                    v-model="data['36']"
                                                                    class="form-control ">
                                                            </div>
                                                        </div>

                                                    </td>
                                                    <td>
                                        <label class="form-label">Étage</label>
                                        <div class="form-group">
        <div class="input-group">
                                                        <input type="text" name="CR_39" v-model="data['39']"
                                                                    class="form-control form-control-sm">
                                                                    <span class="input-group-text text-sm" >
            /
          </span>
                                                        <input type="text" name="CR_100" placeholder="" v-model="data['100']"
                                                                    class="form-control form-control-sm">
                                                                    </div>
                                                                </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                    <div class="col-md-6 col-lg-4">
                        <tr class="type-bail" v-if="data.transaction == 'location'">
                            <td>
                                        <label class="form-label">Type de bail</label>
                                
                                <select name="C_1801" required id="C_1801" class="form-select  form-control-sm"
                                    v-model="data['1801']">
                                    <option value="Bail_construire" title="Bail à construire (hors loi 89)">Bail à
                                        construire (hors loi 89)</option>
                                    <option value="BailAdministratif" title="Bail administratif (hors loi 89)">Bail
                                        administratif (hors loi 89)</option>
                                    <option value="Bail_civil" title="Bail civil (hors loi 89)">Bail civil (hors loi
                                        89)
                                    </option>
                                    <option value="BailCommercial" title="Bail commercial">Bail commercial</option>
                                    <option value="Commercial10" title="Bail commercial 10 ans">Bail commercial 10
                                        ans
                                    </option>
                                    <option value="Commercial12" title="Bail commercial 12 ans">Bail commercial 12
                                        ans
                                    </option>
                                    <option value="Commercial369" title="Bail commercial 3/6/9">Bail commercial
                                        3/6/9
                                    </option>
                                    <option value="Commercial69" title="Bail commercial 6/9">Bail commercial 6/9
                                    </option>
                                    <option value="Commercial9" title="Bail commercial 9 ans ferme">Bail commercial
                                        9
                                        ans ferme</option>
                                    <option value="BailSousLocation" title="Bail de sous-location (Loi 89)">Bail de
                                        sous-location (Loi 89)</option>
                                    <option value="BailStationnement" title="Bail de stationnement / parking">Bail
                                        de
                                        stationnement / parking</option>
                                    <option value="BailDerogatoire" title="Bail dérogatoire (Loi 89)">Bail
                                        dérogatoire
                                        (Loi 89)</option>
                                    <option value="DerogatoireCD" title="Bail dérogatoire de courte durée (Loi 89)">
                                        Bail
                                        dérogatoire de courte durée (Loi 89)</option>
                                    <option value="Bail_emphy" title="Bail emphytéotique (hors loi 89)">Bail
                                        emphytéotique (hors loi 89)</option>
                                    <option value="BailExtinction" title="Bail en extinction">Bail en extinction
                                    </option>
                                    <option value="ResidencePrincipale" title="Bail habitation (Loi 89)">Bail
                                        habitation
                                        (Loi 89)</option>
                                    <option value="BailHabitation" title="Bail loi 48 (hors loi 89)">Bail loi 48
                                        (hors
                                        loi 89)</option>
                                    <option value="MeubleResidencePrincipale"
                                        title="Bail meublé résidence principale (Loi 89)">Bail meublé résidence
                                        principale (Loi 89)</option>
                                    <option value="Mixte" title="Bail mixte (Loi 89)">Bail mixte (Loi 89)</option>
                                    <option value="BailProfessionnel" title="Bail professionnel">Bail professionnel
                                    </option>
                                    <option value="BailVerbal" title="Bail verbal (hors loi 89)">Bail verbal (hors
                                        loi
                                        89)</option>
                                    <option value="ConventionOccupationPrécaire"
                                        title="Convention d'occupation précaire (hors loi 89)">Convention
                                        d'occupation
                                        précaire (hors loi 89)</option>
                                    <option value="BailMeubleHorsResidencePrincipale"
                                        title="Meublé hors résidence principale (hors loi 89)">Meublé hors résidence
                                        principale (hors loi 89)</option>
                                    <option value="Usufruit" title="Usufruit">Usufruit</option>
                                </select>
                            </td>
                        </tr>
                        <div class="mt-2 btn-group text-center w-100" role="group">
                            <button type="button" class="btn btn-xs"
                                :class="activeLHTab == 'l' ? 'btn-secondary' : 'btn-outline-secondary'"
                                @click="activeLHTab = 'l'">Loyer</button>
                            <button type="button" class="btn btn-xs "
                                :class="activeLHTab == 'h' ? 'btn-secondary' : 'btn-outline-secondary'"
                                @click="activeLHTab = 'h'">Honoraires</button>

                        </div>
                        <table class="table text-xs" id="tarif_avendre_alouer">

                            <tr style="display: table-row;" v-if="activeLHTab == 'l'">
                                <td>Loyer&nbsp;HC</td>
                                <td>
                                    <div class="input_container">
                                        <div class="input_container_int">
                                    <input type="text" name="products_loyer" placeholder="Non renseigné" size="8"
                                        v-model="data['31']" class="form-control form-control-sm"
                                        @keypress="isNumber($event)">
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            <tr v-if="activeLHTab == 'l'" style="display: table-row;">
                                <td>Provision</td>
                                <td>
                                    <div class="input_container">
                                        <div class="input_container_int">
                                    <input type="text" name="products_provision" placeholder="Non renseigné"
                                        v-model="data['247']" @keypress="isNumber($event)" size="7"
                                        class="form-control form-control-sm">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="activeLHTab == 'l'" style="display: table-row;">
                                <td>Honoraires


                                    <ElTooltip placement="top"
                                        content="Montant total des honoraires à la charge du locataire">
                                        <i class="fa fa-info-circle"></i>
                                    </ElTooltip>
                                </td>


                                <td>
                                    <div class="input_container">
                                        <div class="input_container_int">
                                            <input type="text" name="products_honoraire" placeholder="Non renseigné"
                                                id="products_honoraire" readonly=""
                                                class="form-control form-control-sm">
                                        </div>
                                    </div>

                                    <span
                                        style="position: absolute; left: 185px; line-height: 2.25em; font-weight: normal; font-style: italic;"
                                        id="span_products_honoraire_percent"></span>
                                </td>
                            </tr>
                            <tr v-if="activeLHTab == 'l'" style="display: table-row;">
                                <td>Dépôt</td>
                                <td>
                                    <div class="input_container">
                                        <div class="input_container_int">
                                            <input type="text" name="products_depot" placeholder="Non renseigné" size="8"
                                                class="form-control form-control-sm">
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr v-if="activeLHTab == 'h'">
                                <td>
                                    <table class="table text-xs">
                                        <thead>
                                            <th></th>
                                            <th>Locataire</th>
                                            <th>/Bailleur</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Négoc. et entremise</td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        v-if="data['1801'] != 'BailSousLocation' && data['1801'] != 'BailDerogatoire' && data['1801'] != 'BailHabitation'
                                                            && data['1801'] != 'BailMeubleHorsResidencePrincipale' && data['1801'] != 'Mixte'"
                                                        @keypress="isNumber($event)" v-model="data['2343']" /></td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        @keypress="isNumber($event)" v-model="data['2344']" /></td>
                                            </tr>
                                            <tr v-if="data['1801'] != 'BailCommercial'">
                                                <td>Visite/Dossier/Acte</td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        @keypress="isNumber($event)" v-model="data['2338']" /></td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        @keypress="isNumber($event)" v-model="data['2339']" /></td>
                                            </tr>
                                            <tr v-if="data['1801'] != 'BailCommercial'">
                                                <td>Etat des lieux</td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        @keypress="isNumber($event)" v-model="data['2324']" /></td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        @keypress="isNumber($event)" v-model="data['2325']" /></td>
                                            </tr>
                                            <tr v-if="data['1801'] != 'BailCommercial'">
                                                <td>Frais divers</td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        @keypress="isNumber($event)" v-model="data['2326']" /></td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        @keypress="isNumber($event)" v-model="data['2327']" /></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>Total TTC</td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        :value="parseFloat(data['2343']) + parseFloat(data['2338']) + parseFloat(data['2324']) + parseFloat(data['2326'])"
                                                        disabled /></td>
                                                <td><input type="text" class="form-control form-control-sm"
                                                        :value="parseFloat(data['2344']) + parseFloat(data['2339']) + parseFloat(data['2325']) + parseFloat(data['2327'])"
                                                        disabled /></td>

                                            </tr>
                                        </tfoot>
                                    </table>
                                </td>
                            </tr>

                        </table>
                        <!--<table class="product-table" v-if="data.transaction == 'vente'" id="tarif_avendre_alouer">

                            <tbody>
                                <tr>
                                    <td><strong class="text-sm">Prix du bien</strong> <span class="fa fa-info-circle"
                                            data-container="body" data-toggle="tooltip" data-title="Valeur du bien (TTC)"
                                            data-original-title="" title=""></span></td>
                                    <td>
                                        <div class="input_container">
                                            <div class="input_container_int">
                                                <input type="text" name="products_prix_de_vente" value="0"
                                                    placeholder="Non renseigné" class="form-control form-control-sm">
                                            </div>
                                        </div>
                                    </td>
                                    <td style="margin:0; padding:0; "></td>
                                </tr>
                                <tr>
                                    <td><strong class="text-sm">Hono. vendeur </strong></td>
                                    <td>
                                        <div class="input_container">
                                            <div class="input_container_int">
                                                <input type="text" name="products_ComVen" placeholder="Non renseigné"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>

                                        <span
                                            style="position: absolute; left: 185px; line-height: 2.25em; font-weight: normal; font-style: italic;"
                                            class="pc_hono_laforet" id="pc_hono_vendeur_alur"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong class="text-sm">Hono. acquéreur</strong></td>
                                    <td>
                                        <div class="input_container">
                                            <div class="input_container_int">
                                                <input type="text" name="products_ComAcq" placeholder="Non renseigné"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>

                                        <span
                                            style="position: absolute; left: 185px; line-height: 2.25em; font-weight: normal; font-style: italic;"
                                            class="pc_hono_laforet" id="pc_hono_acquereur_alur"></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong class="text-sm">Prix de vente</strong> <span class="fa fa-info-circle"
                                            data-container="body" data-toggle="tooltip" data-html="true"
                                            data-title="Prix de présentation du bien : addition du prix TTC du bien et des honoraires à la <br>charge de l'acquéreur"
                                            data-original-title="" title=""></span></td>
                                    <td>
                                        <div class="input_container">
                                            <div class="input_container_int">
                                                <input type="text" name="products_price" placeholder="Non renseigné"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong class="text-sm">Reste au vendeur </strong> <span class="fa fa-info-circle"
                                            data-container="body" data-toggle="tooltip"
                                            data-title="Résultat en TTC de la soustraction des honoraires à la charge du vendeur du prix du bien"
                                            data-original-title="" title=""></span></td>
                                    <td>
                                        <div class="input_container">
                                            <div class="input_container_int">
                                                <input type="text" name="products_price_net_vendeur"
                                                    placeholder="Non renseigné" class="form-control form-control-sm">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong class="text-sm">Notaire</strong></td>
                                    <td><i>
                                            <div class="input_container">
                                                <div class="input_container_int">
                                                    <input type="text" name="products_Frais" placeholder="Non renseigné"
                                                        class="form-control form-control-sm">
                                                </div>
                                            </div>
                                        </i></td>
                                </tr>
                                <tr></tr>
                                <tr>
                                    <td><strong class="text-sm">TFC </strong></td>
                                    <td>
                                        <div class="input_container">
                                            <div class="input_container_int">
                                                <input type="text" name="products_price_tfc" placeholder="Non renseigné"
                                                    size="11" class="form-control form-control-sm">
                                            </div>
                                        </div>
                                    </td>
                                </tr><input type="hidden" name="initial_products_price_net_vendeur"><input type="hidden"
                                    name="initial_products_price"><input type="hidden" name="products_pourcentage_hono">
                            </tbody>
                        </table>-->
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <table class="table text-xs">

                            <tbody>
                                <tr>
                                    <td>Référence</td>
                                    <td>
                                        <div class="input_container">
                                            <div class="input_container_int">
                                                <input style="min-width:130px;" type="text" name="products_model"
                                                    class="form-control form-control-sm" placeholder="Non renseigné"
                                                    value="4168" id="products_model">
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Page d'accueil (site)</td>
                                    <td>
                                        <div class="form-check form-switch ms-1">
                                            <input id="products_selection" class="form-check-input" type="checkbox"
                                                name="products_selection" />
                                            <label class="form-check-label" for="products_selection"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Prix visible
                                    <ElTooltip placement="top"
                                        content="Cette case permet de choisir d'afficher ou non le prix du bien sur les fiches vitrines et passerelles de diffusion.">
                                        <i class="fa fa-question-circle"></i>
                                    </ElTooltip>
                                    </td>
                                    <td>

                                        <div class="form-check form-switch ms-1">
                                            <input id="products_status_price" class="form-check-input" type="checkbox"
                                                name="products_status_price" />
                                            <label class="form-check-label" for="products_status_price"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2"><br>
                                        <div id="message_prix" style="display:none;">
                                            <a color="red">Cette fonctionnalité est prévue pour vous permettre de
                                                masquer le prix d'un bien vendu.
                                                L'affichage du prix d'un bien en cours de commercialisation est une
                                                obligation légale, masquer le prix relève de votre responsabilité.
                                            </a>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>



                </div>

            </div>

        </div>
        <div id="desc-tab" style="overflow-y:scroll;height: calc(100vh - 128px);display:none">
            <div class="row w-100">

                <div class="col-lg-3">
                    <side-nav />
                </div>
                <div class="col-lg-9 mt-lg-0 mt-4">
                    <side-nav-item v-model="data" />
                </div>
            </div>
        </div>
        <div id="pieces-tab" style="overflow-y:scroll;height: calc(100vh - 128px);display:none">
            <table class="table">
                <thead>
                    <th>Niveau</th>
                    <th>Nom</th>
                    <th>Surface</th>
                    <th>Exposition</th>
                    <th>Sol</th>
                    <th>Commentaires</th>
                </thead>
                <tbody>
                    <tr v-for="room in data.rooms" :key="room.id">
                        <td>
                            <input class="form-control" type="text" v-model="room.niveau">
                        </td>
                        <td>
                            <select class="form-select" v-model="room.type">
                                <option v-for="n in rooms_names" :key="n.value" :value="n.value">{{ n.label }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <input class="form-control" type="text" v-model="room.surface" @keypress="isNumber($event)">
                        </td>
                        <td>
                            <select class="form-select" v-model="room.exposition">
                                <option value="42" title="SO">SO</option>
                                <option value="37" title="E">E</option>
                                <option value="33" title="EO">EO</option>
                                <option value="35" title="N">N</option>
                                <option value="39" title="NE">NE</option>
                                <option value="40" title="NO">NO</option>
                                <option value="34" title="NS">NS</option>
                                <option value="38" title="O">O</option>
                                <option value="36" title="S">S</option>
                                <option value="41" title="SE">SE</option>
                            </select>
                        </td>
                        <td>
                            <select class="form-select" v-model="room.sol">
                                <option value="6" title="Beton">Beton</option>
                                <option value="3" title="Carrelage">Carrelage</option>
                                <option value="10" title="Lino">Lino</option>
                                <option value="11" title="Marbre">Marbre</option>
                                <option value="5" title="Moquette">Moquette</option>
                                <option value="4" title="Parquet">Parquet</option>
                                <option value="1864" title="Parquet flottant stratifié">Parquet flottant stratifié
                                </option>
                                <option value="14" title="Parquet Massif">Parquet Massif</option>
                                <option value="7" title="Plancher">Plancher</option>
                            </select>
                        </td>
                        <td>
                            <input class="form-control" type="text" v-model="room.commentaire">
                        </td>
                    </tr>
                </tbody>
            </table>

            <button class="btn btn-sm btn-primary" @click="data.rooms.push({})">Ajouter une pièce</button>

        </div>
        <div id="bailleur-tab" style="overflow-y:scroll;display:none">
            <div class="card card-body">
                Créer un nouveau contact
                <div class="row">
                    <div class="col-sm-4">
                        <label class="form-label">Genre</label>
                        <select v-model="data.bailleur.gender" class="form-select" placeholder="Non renseigné">
                            <option value="a" title="Association">Association</option>
                            <option value="con" title="Consorts">Consorts</option>
                            <option value="i" title="Indivision">Indivision</option>
                            <option value="f" title="Madame">Madame</option>
                            <option value="fm" selected="selected" title="Madame, Monsieur">Madame, Monsieur</option>
                            <option value="fl" title="Mademoiselle">Mademoiselle</option>
                            <option value="mmm" title="Mademoiselle, Monsieur">Mademoiselle, Monsieur</option>
                            <option value="mai" title="Maître">Maître</option>
                            <option value="ff" title="Mesdames">Mesdames</option>
                            <option value="mm" title="Messieurs">Messieurs</option>
                            <option value="m" title="Monsieur">Monsieur</option>
                            <option value="s" title="Société">Société</option>
                            <option value="su" title="Succession">Succession</option>
                        </select>

                    </div>
                    <div class="col-sm-4" v-if="data.bailleur.gender == 's'">
                        <label class="form-label">Raison sociale</label>
                        <input class="form-control" type="text" v-model="data.bailleur.raison_sociale"
                            placeholder="Non renseigné" />

                    </div>
                    <div class="col-sm-4" v-if="data.bailleur.gender == 's'">
                        <label class="form-label">Forme juridique</label>
                        <select v-model="data.bailleur.forme_juridique" class="form-select">
                            <option value="0" title=""></option>
                            <option value="6" title="Association">Association</option>
                            <option value="7" title="Coopérative">Coopérative</option>
                            <option value="23" title="EI">EI</option>
                            <option value="12" title="EP">EP</option>
                            <option value="13" title="EPIC">EPIC</option>
                            <option value="2" title="EURL">EURL</option>
                            <option value="17" title="GFA">GFA</option>
                            <option value="9" title="GIE">GIE</option>
                            <option value="3" title="SA">SA</option>
                            <option value="11" title="SAPO">SAPO</option>
                            <option value="5" title="SARL">SARL</option>
                            <option value="4" title="SAS">SAS</option>
                            <option value="22" title="SASU">SASU</option>
                            <option value="19" title="SC">SC</option>
                            <option value="20" title="SCCV">SCCV</option>
                            <option value="18" title="SCEA">SCEA</option>
                            <option value="8" title="SCI">SCI</option>
                            <option value="10" title="SCIC">SCIC</option>
                            <option value="15" title="SCP">SCP</option>
                            <option value="14" title="SEM">SEM</option>
                            <option value="1" title="SNC">SNC</option>
                            <option value="16" title="SPRL">SPRL</option>
                        </select>

                    </div>
                    <div class="col-sm-4" v-if="data.bailleur.gender == 's'">
                        <label class="form-label">Responsable</label>
                        <select v-model="data.bailleur.responsable" class="form-select">
                            <option value="0" title=""></option>
                            <option value="a" title="Association">Association</option>
                            <option value="con" title="Consorts">Consorts</option>
                            <option value="i" title="Indivision">Indivision</option>
                            <option value="f" title="Madame">Madame</option>
                            <option value="fm" title="Madame, Monsieur">Madame, Monsieur</option>
                            <option value="fl" title="Mademoiselle">Mademoiselle</option>
                            <option value="mmm" title="Mademoiselle, Monsieur">Mademoiselle, Monsieur</option>
                            <option value="mai" title="Maître">Maître</option>
                            <option value="ff" title="Mesdames">Mesdames</option>
                            <option value="mm" title="Messieurs">Messieurs</option>
                            <option value="m" title="Monsieur">Monsieur</option>
                            <option value="s" title="Société">Société</option>
                            <option value="su" title="Succession">Succession</option>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <label class="form-label">Nom</label>
                        <input class="form-control" type="text" v-model="data.bailleur.lastname" placeholder="Non renseigné" />

                    </div>
                    <div class="col-sm-4">
                        <label class="form-label">Prénom</label>
                        <input class="form-control" type="text" v-model="data.bailleur.firstname"
                            placeholder="Non renseigné" />

                    </div>


                    <div class="col-sm-6">
                        <label class="form-label">Mail</label>
                        <input class="form-control" type="email" v-model="data.bailleur.email" placeholder="Non renseigné" />

                    </div>
                    <div class="col-sm-6">
                        <label class="form-label">Notaire</label>
                        <input class="form-control" type="text" v-model="data.bailleur.notaire"
                            placeholder="Non renseigné" />

                    </div>


                    <div class="col-sm-12">
                        <label class="form-label">Adresse</label>
                        <textarea class="form-control" placeholder="Non renseigné"
                            v-model="data.bailleur.adresse"></textarea>

                    </div>
                    <div class="col-sm-2">
                        <label class="form-label">Code postal</label>
                        <input class="form-control" type="text" v-model="data.bailleur.cp" placeholder="Non renseigné"
                            @keypress="isNumber($event)" />

                    </div>
                    <div class="col-sm-4">
                        <label class="form-label">Ville</label>
                        <input class="form-control" type="text" v-model="data.bailleur.ville" placeholder="Non renseigné" />

                    </div>
                    <div class="col-sm-4">
                        <label class="form-label">Pays</label>
                        <select v-model="data.bailleur.country_id" class="form-select">
                            <option value="75" title="Afghanistan">Afghanistan</option>
                            <option value="76" title="Albania">Albania</option>
                            <option value="77" title="Algeria">Algeria</option>
                            <option value="3" title="Allemagne">Allemagne</option>
                            <option value="78" title="American Samoa">American Samoa</option>
                            <option value="79" title="Andorra">Andorra</option>
                            <option value="80" title="Angola">Angola</option>
                            <option value="81" title="Anguilla">Anguilla</option>
                            <option value="82" title="Antarctica">Antarctica</option>
                            <option value="83" title="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="84" title="Argentina">Argentina</option>
                            <option value="85" title="Armenia">Armenia</option>
                            <option value="86" title="Aruba">Aruba</option>
                            <option value="12" title="Australie">Australie</option>
                            <option value="5" title="Autres">Autres</option>
                            <option value="13" title="Autriche">Autriche</option>
                            <option value="87" title="Azerbaijan">Azerbaijan</option>
                            <option value="88" title="Bahamas">Bahamas</option>
                            <option value="89" title="Bahrain">Bahrain</option>
                            <option value="90" title="Bangladesh">Bangladesh</option>
                            <option value="91" title="Barbados">Barbados</option>
                            <option value="92" title="Belarus">Belarus</option>
                            <option value="8" title="Belgique">Belgique</option>
                            <option value="93" title="Belize">Belize</option>
                            <option value="94" title="Benin">Benin</option>
                            <option value="95" title="Bermuda">Bermuda</option>
                            <option value="96" title="Bhutan">Bhutan</option>
                            <option value="97" title="Bolivia">Bolivia</option>
                            <option value="98" title="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="99" title="Botswana">Botswana</option>
                            <option value="100" title="Bouvet Island">Bouvet Island</option>
                            <option value="52" title="Brésil">Brésil</option>
                            <option value="101" title="British Indian Ocean Territory">British Indian Ocean Territory
                            </option>
                            <option value="102" title="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="54" title="Bulgarie">Bulgarie</option>
                            <option value="22" title="Burkina Faso">Burkina Faso</option>
                            <option value="103" title="Burundi">Burundi</option>
                            <option value="104" title="Cambodia">Cambodia</option>
                            <option value="105" title="Cameroon">Cameroon</option>
                            <option value="18" title="Canada">Canada</option>
                            <option value="266" title="Canary Islands">Canary Islands</option>
                            <option value="106" title="Cape Verde">Cape Verde</option>
                            <option value="107" title="Cayman Islands">Cayman Islands</option>
                            <option value="108" title="Central African Republic">Central African Republic</option>
                            <option value="109" title="Chad">Chad</option>
                            <option value="110" title="Chile">Chile</option>
                            <option value="24" title="Chine">Chine</option>
                            <option value="112" title="Christmas Island">Christmas Island</option>
                            <option value="55" title="Chrypre">Chrypre</option>
                            <option value="113" title="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="114" title="Colombia">Colombia</option>
                            <option value="115" title="Comoros">Comoros</option>
                            <option value="116" title="Congo">Congo</option>
                            <option value="269" title="Congo, Democratic Republic of the">Congo, Democratic Republic of the
                            </option>
                            <option value="117" title="Cook Islands">Cook Islands</option>
                            <option value="118" title="Costa Rica">Costa Rica</option>
                            <option value="119" title="Côte d'Ivoire">Côte d'Ivoire</option>
                            <option value="120" title="Croatia">Croatia</option>
                            <option value="121" title="Cuba">Cuba</option>
                            <option value="122" title="Czech Republic">Czech Republic</option>
                            <option value="27" title="Danemark">Danemark</option>
                            <option value="124" title="Djibouti">Djibouti</option>
                            <option value="125" title="Dominica">Dominica</option>
                            <option value="126" title="Dominican Republic">Dominican Republic</option>
                            <option value="128" title="Egypt">Egypt</option>
                            <option value="129" title="El Salvador">El Salvador</option>
                            <option value="273" title="Equateur">Equateur</option>
                            <option value="130" title="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="131" title="Eritrea">Eritrea</option>
                            <option value="16" title="Espagne">Espagne</option>
                            <option value="57" title="Estonie">Estonie</option>
                            <option value="132" title="Ethiopia">Ethiopia</option>
                            <option value="4" title="Europe Autres Pays">Europe Autres Pays</option>
                            <option value="133" title="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="134" title="Faroe Islands">Faroe Islands</option>
                            <option value="135" title="Fiji">Fiji</option>
                            <option value="50" title="Finlande">Finlande</option>
                            <option value="1" selected="selected" title="France">France</option>
                            <option value="137" title="France Guiana">France Guiana</option>
                            <option value="138" title="French Southern Territories">French Southern Territories</option>
                            <option value="139" title="Gabon">Gabon</option>
                            <option value="140" title="Gambia">Gambia</option>
                            <option value="141" title="Georgia">Georgia</option>
                            <option value="142" title="Ghana">Ghana</option>
                            <option value="143" title="Gibraltar">Gibraltar</option>
                            <option value="20" title="Grece">Grece</option>
                            <option value="144" title="Greenland">Greenland</option>
                            <option value="145" title="Grenada">Grenada</option>
                            <option value="45" title="Guadeloupe">Guadeloupe</option>
                            <option value="146" title="Guatemala">Guatemala</option>
                            <option value="265" title="Guernsey">Guernsey</option>
                            <option value="147" title="Guinea">Guinea</option>
                            <option value="148" title="Guinea-Bissau">Guinea-Bissau</option>
                            <option value="19" title="Guyane">Guyane</option>
                            <option value="150" title="Haiti">Haiti</option>
                            <option value="151" title="Heard and McDonald Islands">Heard and McDonald Islands</option>
                            <option value="23" title="Hollande">Hollande</option>
                            <option value="152" title="Honduras">Honduras</option>
                            <option value="7" title="Hong Kong">Hong Kong</option>
                            <option value="58" title="Hongrie">Hongrie</option>
                            <option value="272" title="Ile Maurice">Ile Maurice</option>
                            <option value="154" title="India">India</option>
                            <option value="155" title="Indonesia">Indonesia</option>
                            <option value="274" title="Iran">Iran</option>
                            <option value="156" title="Iraq">Iraq</option>
                            <option value="32" title="Irlande">Irlande</option>
                            <option value="60" title="Islande">Islande</option>
                            <option value="270" title="Isle of Man">Isle of Man</option>
                            <option value="15" title="Israel">Israel</option>
                            <option value="14" title="Italie">Italie</option>
                            <option value="159" title="Jamaica">Jamaica</option>
                            <option value="11" title="Japon">Japon</option>
                            <option value="263" title="Jersey">Jersey</option>
                            <option value="160" title="Jordan">Jordan</option>
                            <option value="161" title="Kazakhstan">Kazakhstan</option>
                            <option value="28" title="Kenya">Kenya</option>
                            <option value="162" title="Kiribati">Kiribati</option>
                            <option value="163" title="Korea, Democratic People's Republic of">Korea, Democratic People's
                                Republic of</option>
                            <option value="164" title="Korea, Republic of">Korea, Republic of</option>
                            <option value="275" title="Kosovo">Kosovo</option>
                            <option value="165" title="Kuwait">Kuwait</option>
                            <option value="166" title="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="36" title="La Réunion">La Réunion</option>
                            <option value="167" title="Lao People's Democratic Republic">Lao People's Democratic Republic
                            </option>
                            <option value="169" title="Lesotho">Lesotho</option>
                            <option value="61" title="Lettonie">Lettonie</option>
                            <option value="168" title="Liban">Liban</option>
                            <option value="170" title="Liberia">Liberia</option>
                            <option value="171" title="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="62" title="Liechtenstein">Liechtenstein</option>
                            <option value="64" title="Lituanie">Lituanie</option>
                            <option value="34" title="Luxembourg">Luxembourg</option>
                            <option value="172" title="Macao">Macao</option>
                            <option value="173" title="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former
                                Yugoslav Republic of</option>
                            <option value="46" title="Madagascar">Madagascar</option>
                            <option value="175" title="Malawi">Malawi</option>
                            <option value="176" title="Malaysia">Malaysia</option>
                            <option value="177" title="Maldives">Maldives</option>
                            <option value="178" title="Mali">Mali</option>
                            <option value="31" title="Malte">Malte</option>
                            <option value="47" title="Maroc">Maroc</option>
                            <option value="180" title="Marshall Islands">Marshall Islands</option>
                            <option value="38" title="Martinique">Martinique</option>
                            <option value="181" title="Mauritania">Mauritania</option>
                            <option value="182" title="Mauritius">Mauritius</option>
                            <option value="37" title="Mayotte">Mayotte</option>
                            <option value="183" title="Mexico">Mexico</option>
                            <option value="184" title="Micronesia, Federated States of">Micronesia, Federated States of
                            </option>
                            <option value="185" title="Monaco">Monaco</option>
                            <option value="186" title="Mongolia">Mongolia</option>
                            <option value="267" title="Montenegro">Montenegro</option>
                            <option value="187" title="Montserrat">Montserrat</option>
                            <option value="188" title="Mozambique">Mozambique</option>
                            <option value="189" title="Myanmar">Myanmar</option>
                            <option value="190" title="Namibia">Namibia</option>
                            <option value="191" title="Nauru">Nauru</option>
                            <option value="192" title="Nepal">Nepal</option>
                            <option value="193" title="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="194" title="New Zealand">New Zealand</option>
                            <option value="195" title="Nicaragua">Nicaragua</option>
                            <option value="196" title="Niger">Niger</option>
                            <option value="197" title="Nigeria">Nigeria</option>
                            <option value="198" title="Niue">Niue</option>
                            <option value="199" title="Norfolk Island">Norfolk Island</option>
                            <option value="200" title="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="25" title="Norvege">Norvege</option>
                            <option value="44" title="Nouvelle Calédonie">Nouvelle Calédonie</option>
                            <option value="201" title="Oman">Oman</option>
                            <option value="202" title="Pakistan">Pakistan</option>
                            <option value="203" title="Palau">Palau</option>
                            <option value="271" title="Palestinian Territory, Occupied">Palestinian Territory, Occupied
                            </option>
                            <option value="204" title="Panama">Panama</option>
                            <option value="205" title="Papua New Guinea">Papua New Guinea</option>
                            <option value="206" title="Paraguay">Paraguay</option>
                            <option value="9" title="Pays Bas">Pays Bas</option>
                            <option value="207" title="Peru">Peru</option>
                            <option value="208" title="Philippines">Philippines</option>
                            <option value="209" title="Pitcairn">Pitcairn</option>
                            <option value="68" title="Pologne">Pologne</option>
                            <option value="33" title="Polynésie Française">Polynésie Française</option>
                            <option value="49" title="Portugal">Portugal</option>
                            <option value="210" title="Puerto Rico">Puerto Rico</option>
                            <option value="211" title="Qatar">Qatar</option>
                            <option value="26" title="Republique Tcheque">Republique Tcheque</option>
                            <option value="71" title="Roumanie">Roumanie</option>
                            <option value="2" title="Royaume-Uni">Royaume-Uni</option>
                            <option value="48" title="Russie">Russie</option>
                            <option value="212" title="Rwanda">Rwanda</option>
                            <option value="227" title="Saint Helena">Saint Helena</option>
                            <option value="213" title="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="214" title="Saint Lucia">Saint Lucia</option>
                            <option value="215" title="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines
                            </option>
                            <option value="40" title="Saint-Barthélémy">Saint-Barthélémy</option>
                            <option value="41" title="Saint-Martin">Saint-Martin</option>
                            <option value="42" title="Saint-Pierre-et-Miquelon">Saint-Pierre-et-Miquelon</option>
                            <option value="216" title="Samoa">Samoa</option>
                            <option value="217" title="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="218" title="Saudi Arabia">Saudi Arabia</option>
                            <option value="51" title="Sénégal">Sénégal</option>
                            <option value="268" title="Serbia">Serbia</option>
                            <option value="219" title="Seychelles">Seychelles</option>
                            <option value="220" title="Sierra Leone">Sierra Leone</option>
                            <option value="221" title="Singapore">Singapore</option>
                            <option value="72" title="Slovaquie">Slovaquie</option>
                            <option value="73" title="Slovénie">Slovénie</option>
                            <option value="222" title="Solomon Islands">Solomon Islands</option>
                            <option value="223" title="Somalia">Somalia</option>
                            <option value="224" title="South Africa">South Africa</option>
                            <option value="225" title="South Georgia and the South Sandwich Islands">South Georgia and the
                                South Sandwich Islands</option>
                            <option value="226" title="Sri Lanka">Sri Lanka</option>
                            <option value="228" title="Sudan">Sudan</option>
                            <option value="29" title="Suède">Suède</option>
                            <option value="10" title="Suisse">Suisse</option>
                            <option value="229" title="Suriname">Suriname</option>
                            <option value="230" title="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="231" title="Swaziland">Swaziland</option>
                            <option value="232" title="Sweden">Sweden</option>
                            <option value="233" title="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="234" title="Taiwan, Province of China">Taiwan, Province of China</option>
                            <option value="235" title="Tajikistan">Tajikistan</option>
                            <option value="236" title="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="237" title="Thailand">Thailand</option>
                            <option value="127" title="Timor-Leste">Timor-Leste</option>
                            <option value="238" title="Togo">Togo</option>
                            <option value="239" title="Tokelau">Tokelau</option>
                            <option value="240" title="Tonga">Tonga</option>
                            <option value="241" title="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="21" title="Tunisie">Tunisie</option>
                            <option value="243" title="Turkey">Turkey</option>
                            <option value="244" title="Turkmenistan">Turkmenistan</option>
                            <option value="245" title="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="246" title="Tuvalu">Tuvalu</option>
                            <option value="247" title="Uganda">Uganda</option>
                            <option value="248" title="Ukraine">Ukraine</option>
                            <option value="249" title="United Arab Emirates">United Arab Emirates</option>
                            <option value="250" title="United States Minor Outlying Islands">United States Minor Outlying
                                Islands</option>
                            <option value="251" title="Uruguay">Uruguay</option>
                            <option value="6" title="USA">USA</option>
                            <option value="252" title="Uzbekistan">Uzbekistan</option>
                            <option value="253" title="Vanuatu">Vanuatu</option>
                            <option value="254" title="Vatican City State (Holy See)">Vatican City State (Holy See)</option>
                            <option value="255" title="Venezuela">Venezuela</option>
                            <option value="256" title="Viet Nam">Viet Nam</option>
                            <option value="257" title="Virgin Islands (British)">Virgin Islands (British)</option>
                            <option value="258" title="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                            <option value="43" title="Wallis-et-Futuna">Wallis-et-Futuna</option>
                            <option value="259" title="Western Sahara">Western Sahara</option>
                            <option value="260" title="Yemen">Yemen</option>
                            <option value="261" title="Zambia">Zambia</option>
                            <option value="262" title="Zimbabwe">Zimbabwe</option>
                            <option value="264" title="Åland Islands">Åland Islands</option>
                        </select>

                    </div>

                    <div class="col-sm-4">
                        <label class="form-label">Téléphone</label>
                        <input class="form-control" type="phone" v-model="data.bailleur.phone"
                            placeholder="Non renseigné" />

                    </div>
                    <div class="col-sm-4">
                        <label class="form-label">Téléphone portable</label>
                        <input class="form-control" type="phone" v-model="data.bailleur.mobile_phone"
                            placeholder="Non renseigné" />

                    </div>
                    <div class="col-sm-4">
                        <label class="form-label">Fax</label>
                        <input class="form-control" type="phone" v-model="data.bailleur.fax"
                            placeholder="Non renseigné" />

                    </div>
                    <div class="col-sm-12">
                        <label class="form-label">Source du contact</label>
                        <select v-model="data.bailleur.origine" class="form-select" required>
<option value="58889" title="Agence">Agence</option>
<option value="58888" title="AMEPI">AMEPI</option>
<option value="58890" title="Apporteur d'affaire">Apporteur d'affaire</option>
<option value="64506" title="Autre">Autre</option>
<option value="58893" title="AvendreAlouer">AvendreAlouer</option>
<option value="51461" title="AvendreAlouer">AvendreAlouer</option>
<option value="51931" title="Bien Ici">Bien Ici</option>
<option value="58894" title="Boîtage">Boîtage</option>
<option value="64509" title="Client">Client</option>
<option value="64510" title="Client Acquéreur">Client Acquéreur</option>
<option value="64515" title="Client Location">Client Location</option>
<option value="64516" title="Client Vendeur">Client Vendeur</option>
<option value="64514" title="Copropriétaire">Copropriétaire</option>
<option value="71660" title="Courrier copro">Courrier copro</option>
<option value="64511" title="Délégation">Délégation</option>
<option value="64504" title="Démarchage">Démarchage</option>
<option value="64590" title="Direct Annonces">Direct Annonces</option>
<option value="51460" title="Explorimmo">Explorimmo</option>
<option value="8" title="Facebook">Facebook</option>
<option value="77629" title="Immo Agenda">Immo Agenda</option>
<option value="58896" title="Immorégion">Immorégion</option>
<option value="2" title="Indication Service Gestion">Indication Service Gestion</option>
<option value="3" title="Intercabinet">Intercabinet</option>
<option value="58897" title="Interne">Interne</option>
<option value="64502" title="Internet">Internet</option>
<option value="58898" title="Interview">Interview</option>
<option value="69428" title="Jurisur (Keymex)">Jurisur (Keymex)</option>
<option value="1886" title="Le Bon Coin">Le Bon Coin</option>
<option value="64507" title="Le Figaro">Le Figaro</option>
<option value="64518" title="Locataire">Locataire</option>
<option value="64521" title="Location saisonnière">Location saisonnière</option>
<option value="127" title="Logic Immo">Logic Immo</option>
<option value="67005" title="Logiciel (logiciel)">Logiciel (logiciel)</option>
<option value="64591" title="Mail">Mail</option>
<option value="64520" title="Mailing">Mailing</option>
<option value="51467" title="maisons et appartements">maisons et appartements</option>
<option value="52724" title="Meilleurs agents">Meilleurs agents</option>
<option value="64562" title="Notaire">Notaire</option>
<option value="108" title="Ouest France (Internet)">Ouest France (Internet)</option>
<option value="22" title="Panneau">Panneau</option>
<option value="38636" title="Pige">Pige</option>
<option value="70861" title="Poliris (Poliris)">Poliris (Poliris)</option>
<option value="64563" title="Promoteur">Promoteur</option>
<option value="64517" title="Propriétaire Bailleur">Propriétaire Bailleur</option>
<option value="365" title="Prosp > Pige">Prosp &gt; Pige</option>
<option value="64519" title="Prospectus">Prospectus</option>
<option value="64503" title="Publicité">Publicité</option>
<option value="58901" title="Recommandation interne">Recommandation interne</option>
<option value="38654" title="Relation Négociateur">Relation Négociateur</option>
<option value="67646" title="Scorimmo (Scorimmo)">Scorimmo (Scorimmo)</option>
<option value="26" title="Se Loger">Se Loger</option>
<option value="109" title="Site Internet Agence">Site Internet Agence</option>
<option value="64513" title="Syndic">Syndic</option>
<option value="64508" title="Téléphone">Téléphone</option>
</select>

                    </div>


                    <div class="col-sm-6 mt-2">
                <argon-switch
                  id="notify-login-sms"
                  class="mb-0"
                  v-model="data.bailleur.newsletter"
                >Communication par mail
            </argon-switch>

                <argon-switch
                  id="notify-login-sms"
                  class="mb-0 "
                  v-model="data.bailleur.proposition_bien"
                >Accepte les propositions de biens
            </argon-switch>
            </div>
                    <div class="col-sm-6 mt-2">
                <argon-switch
                  id="notify-login-sms"
                  class="mb-0 "
                  v-model="data.bailleur.newsletter"
                >Accepte la newsletter
            </argon-switch>
                <argon-switch
                  id="notify-login-sms"
                  class="mb-0"
                  v-model="data.bailleur.newsletter"
                >Accepte de recevoir les emails de partenaires
            </argon-switch>



                    </div>

                    







                </div>


            </div>

            bailleur TAB
        </div>

    </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import Dropzone from "dropzone";
import Choices from "choices.js";
import Quill from "quill";
import { ElTooltip } from "element-plus";

import SideNav from "./components/SideNav.vue";
import SideNavItem from "./components/SideNavItem.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import Swal from "sweetalert2";
import copy from 'copy-to-clipboard';
export default {
    name: "NewProject",
    components: {
        flatPickr,
        SideNav,
        SideNavItem,
        ElTooltip,
        ArgonSwitch
    },
    data() {
        return {
            reduceup: false,
            date: "",
            endDate: "",
            activeTab: "princ",
            config: {
                allowInput: true,
                // fr
                locale: French,
            },
            activeLHTab: "l",
            data: {
                transaction: "location",
                type: "1",
                products_next_contact: "",
                date_disponibilite: "",
                rooms: [],
                bailleur: {
                    genre: "",
                    nom: "",
                    prenom: "",
                    email: "",
                    telephone: "",
                    adresse: "",
                    code_postal: "",
                    ville: "",
                    country_id: 1,
                    commentaire: "",
                },
                "2343": 0,
                "2338": 0,
                "2324": 0,
                "2326": 0,
                "2344": 0,
                "2339": 0,
                "2325": 0,
                "2327": 0
            },

            rooms_names: [

                { value: "19954", label: "1er ETAGE" },
                { value: "19955", label: "2ème ETAGE" },
                { value: "32000", label: "Abri" },
                { value: "20535", label: "Abri de jardin" },
                { value: "20536", label: "Abri vélo" },
                { value: "20952", label: "Abri voiture" },
                { value: "20537", label: "Accueil" },
                { value: "32001", label: "Alcôve" },
                { value: "20538", label: "Annexe" },
                { value: "20539", label: "Appartement" },
                { value: "987", label: "Appenti" },
                { value: "20617", label: "Arcades" },
                { value: "36", label: "Arrière cuisine" },
                { value: "57", label: "Atelier" },
                { value: "32002", label: "Auditorium" },
                { value: "20540", label: "Autre" },
                { value: "26", label: "Autre" },
                { value: "20915", label: "Auvent" },
                { value: "18", label: "Balcon" },
                { value: "20541", label: "Balcon Terrasse" },
                { value: "20618", label: "Balnéo" },
                { value: "20619", label: "Bar" },
                { value: "20542", label: "Barbecue" },
                { value: "20916", label: "Bergerie" },
                { value: "58", label: "Bibliothèque" },
                { value: "20543", label: "Bibliothèque" },
                { value: "20544", label: "Boudoir" },
                { value: "20545", label: "Boutique" },
                { value: "20546", label: "Box" },
                { value: "20957", label: "Box double" },
                { value: "8", label: "Buanderie" },
                { value: "20547", label: "Bûcher" },
                { value: "20940", label: "Bungalow" },
                { value: "6", label: "Bureau" },
                { value: "20548", label: "Cabanon" },
                { value: "20549", label: "Cabine" },
                { value: "20550", label: "Cage d'escalier" },
                { value: "20917", label: "Cagibi" },
                { value: "20551", label: "Casier" },
                { value: "25", label: "Cave" },
                { value: "20552", label: "Cave à vin" },
                { value: "32003", label: "Cave voûtée" },
                { value: "14", label: "Cellier" },
                { value: "20553", label: "Chai" },
                { value: "32004", label: "Châlet" },
                { value: "9", label: "Chambre" },
                { value: "988", label: "Chambre 1" },
                { value: "989", label: "Chambre 2" },
                { value: "990", label: "Chambre 3" },
                { value: "991", label: "Chambre 4" },
                { value: "20555", label: "Chambre 5" },
                { value: "19483", label: "Chambre 6" },
                { value: "19484", label: "Chambre 7" },
                { value: "20558", label: "Chambre 8" },
                { value: "19485", label: "Chambre 8" },
                { value: "20559", label: "Chambre 9" },
                { value: "20554", label: "Chambre 10" },
                { value: "32005", label: "Chambre d'amis" },
                { value: "32006", label: "Chambre d'hôtes" },
                { value: "20620", label: "Chambre de Service" },
                { value: "20918", label: "Chambre en enfilade" },
                { value: "62", label: "Chambre froide" },
                { value: "32007", label: "Chambre Salle d'Eau" },
                { value: "32008", label: "Chambre Salle de Bains" },
                { value: "981", label: "Chaufferie" },
                { value: "20621", label: "Chaumière" },
                { value: "20560", label: "Chenil" },
                { value: "32009", label: "Coin Bureau" },
                { value: "19486", label: "Coin cabine" },
                { value: "20561", label: "Coin cuisine" },
                { value: "20562", label: "Coin nuit" },
                { value: "20563", label: "Coin repas" },
                { value: "20953", label: "Coin TV" },
                { value: "20564", label: "Combles" },
                { value: "984", label: "Couloir" },
                { value: "27", label: "Couloir placard" },
                { value: "44", label: "Cour" },
                { value: "32010", label: "Coursive" },
                { value: "7", label: "Cuisine" },
                { value: "20941", label: "Cuisine - Salle à manger" },
                { value: "20622", label: "Cuisine d'été" },
                { value: "43", label: "Débarras" },
                { value: "28", label: "Dégagement" },
                { value: "20919", label: "Dégagement placards" },
                { value: "32011", label: "Demi-niveau" },
                { value: "33", label: "Dépendance" },
                { value: "20920", label: "Dortoir" },
                { value: "13", label: "Dressing" },
                { value: "32012", label: "Duplex" },
                { value: "20565", label: "Ecurie" },
                { value: "1", label: "Entrée" },
                { value: "20623", label: "Entrée - Cuisine" },
                { value: "20624", label: "Entrée - Dégagement" },
                { value: "20625", label: "Entrée - Séjour" },
                { value: "20626", label: "Entrée - Séjour - Cuisine" },
                { value: "32013", label: "Entrée - WC" },
                { value: "20627", label: "Entrée Placards" },
                { value: "32014", label: "Entrepôt" },
                { value: "20921", label: "Entresol" },
                { value: "20566", label: "Escalier" },

                { value: "20567", label: "Etable" },
                { value: "20568", label: "Etage" },
                { value: "20958", label: "Etage 1" },
                { value: "20959", label: "Etage 2" },
                { value: "20960", label: "Etage 3" },
                { value: "20961", label: "Etage 4" },
                { value: "20962", label: "Etage 5" },
                { value: "20569", label: "Extension" },

                { value: "20628", label: "Extérieur" },
                { value: "20570", label: "Family room" },
                { value: "32015", label: "Fumoir" },
                { value: "20571", label: "Galerie" },
                { value: "17", label: "Garage" },
                { value: "1429", label: "GITE 1" },
                { value: "982", label: "Grange" },
                { value: "19956", label: "Grenier" },
                { value: "19", label: "Grenier" },
                { value: "2", label: "Hall" },
                { value: "20572", label: "Hall d'entrée" },
                { value: "20573", label: "Hammam" },
                { value: "40", label: "Hangar" },
                { value: "20574", label: "Jacuzzi" },
                { value: "21", label: "Jardin" },
                { value: "22", label: "Jardin d'hiver" },
                { value: "49", label: "Kitchenette" },
                { value: "20575", label: "Laboratoire" },
                { value: "20951", label: "Lavabo - Lave-mains" },
                { value: "32016", label: "Laverie" },
                { value: "20949", label: "Lavoir" },
                { value: "30", label: "Lingerie" },
                { value: "20576", label: "Local" },
                { value: "20577", label: "Local commercial" },
                { value: "32017", label: "Local professionnel" },
                { value: "20578", label: "Local technique" },
                { value: "32018", label: "Local vélos" },
                { value: "20636", label: "Loft" },
                { value: "32019", label: "Logement" },
                { value: "29", label: "Loggia" },
                { value: "20579", label: "Magasin" },
                { value: "20922", label: "Maison" },
                { value: "20942", label: "Maison d'hôtes" },
                { value: "20923", label: "Maison de ville" },
                { value: "20580", label: "Maisonnette" },
                { value: "15", label: "Mezzanine" },
                { value: "20924", label: "Moulin" },
                { value: "20629", label: "Niveau" },
                { value: "20963", label: "Niveau 1" },
                { value: "20964", label: "Niveau 2" },
                { value: "20965", label: "Niveau 3" },
                { value: "20966", label: "Niveau 4" },
                { value: "20967", label: "Niveau 5" },
                { value: "20581", label: "Office" },
                { value: "32020", label: "Open Space" },
                { value: "20635", label: "Paddock" },
                { value: "35", label: "Palier" },
                { value: "46", label: "Parcelle" },
                { value: "16", label: "Parking" },
                { value: "20582", label: "Parking couvert" },
                { value: "32021", label: "Parking en sous-sol" },
                { value: "20956", label: "Parking extérieur" },
                { value: "20955", label: "Parking intérieur" },
                { value: "20583", label: "Patio" },
                { value: "32022", label: "Penderie" },
                { value: "20584", label: "Penderie" },
                { value: "32023", label: "Pergola" },
                { value: "19958", label: "Pièce" },
                { value: "20586", label: "Pièce à aménager" },
                { value: "20587", label: "Pièce aménagée" },
                { value: "986", label: "Pièce de vie" },
                { value: "20925", label: "Pièce en enfilade" },
                { value: "20630", label: "Pièce noire" },
                { value: "20926", label: "Pièce palière" },
                { value: "20631", label: "Pièce principale" },
                { value: "20927", label: "Pièce traversante" },
                { value: "32024", label: "Pigeonnier" },
                { value: "37", label: "Piscine" },
                { value: "48", label: "Placard" },
                { value: "32", label: "place" },
                { value: "20588", label: "Place de stationnement" },
                { value: "32025", label: "Plateau" },
                { value: "20928", label: "Point d'eau- Lavabo" },
                { value: "20589", label: "Pool House" },
                { value: "20590", label: "Porche" },
                { value: "20591", label: "Préau" },
                { value: "41", label: "Puits" },
                { value: "985", label: "Rangement" },
                { value: "19496", label: "RDC" },
                { value: "20592", label: "Réception" },
                { value: "20637", label: "Réfectoire" },

                { value: "20593", label: "Remise" },
                { value: "64", label: "Réserve" },
                { value: "20929", label: "Résidence" },
                { value: "20594", label: "Rez-de-chaussée" },
                { value: "20595", label: "Rez-de-jardin" },
                { value: "20930", label: "Salle" },
                { value: "5", label: "Salle à manger" },
                { value: "32026", label: "Salle d'archives" },
                { value: "20632", label: "Salle d'attente" },
                { value: "11", label: "Salle d'eau" },
                { value: "20931", label: "Salle d'exposition" },
                { value: "10", label: "Salle de bains" },
                { value: "20933", label: "Salle de bien-être" },
                { value: "20596", label: "Salle de billard" },
                { value: "20597", label: "Salle de cinéma" },
                { value: "20932", label: "Salle de consultation" },
                { value: "20934", label: "Salle de détente" },
                { value: "983", label: "Salle de jeux" },
                { value: "20946", label: "Salle de musique" },

                { value: "20598", label: "Salle de réception" },
                { value: "20935", label: "Salle de réunion" },
                { value: "56", label: "Salle de sport" },
                { value: "60", label: "Salle Restaurant" },
                { value: "20945", label: "Salle TV Vidéo" },
                { value: "4", label: "Salon" },
                { value: "20944", label: "Salon - Cuisine" },
                { value: "47", label: "Salon - Séjour" },
                { value: "19487", label: "Salon 1" },
                { value: "19488", label: "Salon 2" },
                { value: "19489", label: "Salon 3" },
                { value: "20936", label: "Salon de Thé" },
                { value: "20599", label: "Salon-Salle à manger" },
                { value: "32027", label: "Sanitaires" },
                { value: "20601", label: "SAS" },
                { value: "20602", label: "Sauna" },
                { value: "31", label: "SDB + WC" },
                { value: "19490", label: "SDB 1" },
                { value: "19491", label: "SDB 2" },
                { value: "19492", label: "SDB 3" },
                { value: "50", label: "SDE + WC" },
                { value: "20603", label: "Séchoir" },
                { value: "20633", label: "Secrétariat" },
                { value: "3", label: "Séjour" },
                { value: "20606", label: "Séjour - Cuisine" },
                { value: "20943", label: "Séjour - Salle à manger" },
                { value: "32028", label: "Séjour - Salon - Cuisine" },
                { value: "20634", label: "Séjour Balcon" },
                { value: "20605", label: "Séjour cathédrale" },
                { value: "20604", label: "Séjour double" },
                { value: "20937", label: "Séjour triple" },
                { value: "20607", label: "Serre" },
                { value: "32029", label: "Show Room" },
                { value: "20608", label: "Solarium" },
                { value: "32030", label: "Souplex" },
                { value: "24", label: "Sous-sol" },
                { value: "20938", label: "Sous-pente" },
                { value: "20609", label: "SPA" },
                { value: "20948", label: "Studette" },
                { value: "20610", label: "Studio" },
                { value: "20947", label: "Studio d'enregistrement" },
                { value: "20611", label: "Suite" },
                { value: "20612", label: "Suite parentale" },
                { value: "32032", label: "Surface commerciale" },
                { value: "32033", label: "Surface de stockage" },
                { value: "32034", label: "Surface de vente" },
                { value: "42", label: "Terrain" },
                { value: "20", label: "Terrasse" },
                { value: "19499", label: "TERRASSE 1" },
                { value: "19500", label: "TERRASSE 2" },
                { value: "20613", label: "Toilettes" },
                { value: "20950", label: "Toît Terrasse" },
                { value: "32035", label: "Tour" },
                { value: "32036", label: "Triplex" },
                { value: "20954", label: "Varangue" },
                { value: "23", label: "Véranda" },
                { value: "20939", label: "Verrière" },
                { value: "20614", label: "Vestiaire" },
                { value: "20615", label: "Vestibule" },
                { value: "20616", label: "Vide sanitaire" },
                { value: "32037", label: "Villa" },
                { value: "12", label: "W.C." },
                { value: "19493", label: "WC 1" },
                { value: "19494", label: "WC 2" },
                { value: "19495", label: "WC 3" },




            ]
        };
    },
    mounted() {

        if (document.getElementById("editor")) {
            new Quill("#editor", {
                theme: "snow", // Specify theme in configuration
            });
        }

        if (document.getElementById("choices-multiple-remove-button")) {
            var element = document.getElementById("choices-multiple-remove-button");
            const example = new Choices(element, {
                removeItemButton: true,
                allowHTML: true,
            });

            example.setChoices(
                [
                    {
                        value: "One",
                        label: "Label One",
                        disabled: true,
                    },
                    {
                        value: "Two",
                        label: "Label Two",
                        selected: true,
                    },
                    {
                        value: "Three",
                        label: "Label Three",
                    },
                ],
                "value",
                "label",
                false
            );
        }

        Dropzone.autoDiscover = false;
        /* var drop = document.getElementById("dropzone");
         new Dropzone(drop, {
             url: "/file/post",
             addRemoveLinks: true,
         });*/

        //get route params newLocationProp

        var prop = this.$route.params.newLocationProp
        if(prop == undefined){
            this.data = JSON.parse(localStorage.getItem('newLocationProp'))
        } else {
        prop = JSON.parse(prop)
        console.log(prop)
        this.data.lot_id = prop.lot.id
        this.data['33'] = prop.lot.nbpiece
        this.data['34'] = prop.lot.surface
        this.data['39'] = prop.lot.etage
        this.data['31'] = prop.lot.locataires[0].bail_actif.loyer_ppal_HT
        this.data['247'] = prop.lot.locataires[0].bail_actif.prov_charges
        this.data['262'] = prop.lot.locataires[0].bail_actif.depot_garantie
        if(prop.lot.immeuble.adresse1 == ""){
        this.data['122'] = prop.lot.immeuble.nom

        } else {
        this.data['122'] = prop.lot.immeuble.adresse1 +' '+prop.lot.immeuble.adresse2

        }
        this.data['52'] = prop.lot.immeuble.cp
        this.data['54'] = prop.lot.immeuble.ville

        this.data['1801'] = 'ResidencePrincipale'
        this.data['28'] = 'Location'
        this.data['121'] = 'Suspendu'
        this.data['1970'] = prop.lot.immeuble.nom

        this.data.bailleur.lastname = prop.lot.perso.nom
        this.data.bailleur.firstname = prop.lot.perso.prenom
        this.data.bailleur.email = prop.lot.perso.email
        

        }


    },
    watch:{
        data:{
            handler:function(val){
                // save to local storage
                localStorage.setItem('newLocationProp', JSON.stringify(val))
            },
            deep:true
        }
    },
    methods: {
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        changeTab(tab) {
            console.log(tab)
            document.getElementById('princ-tab').style.display = 'none'
            document.getElementById('desc-tab').style.display = 'none'
            document.getElementById('pieces-tab').style.display = 'none'
            document.getElementById('bailleur-tab').style.display = 'none'
            document.getElementById(tab + '-tab').style.display = 'block'
            this.activeTab = tab
        },
        async addBien(){
            // use createBien
            console.log("add bien")
            console.log(this.data)
            let d = this.data
            d['65'] = d['52']+ " "+d['54']
            Swal.fire({
                title: 'Ajout du bien',
                text: "Merci de patienter",
                icon: 'info',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.showLoading()
                },
                timer: 2000,
            })
            await this.$store.dispatch('bien/createBien', d)
            // redirect to bien
            let b=  this.$store.getters['bien/onebien']
            console.log(b)
                // Swal
                Swal.fire({
                    title: 'Bien ajouté\nVoulez-vous accéder à Immo-Facile ?',
                    text: "Merci de coller votre presse-papier dans le champ de recherche sur ImmoFacile",
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',

                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non'
                }).then((result) => {
                    if (result.isConfirmed) {
                        copy(b.original_id)
                        console.log(b)
                        window.open('https://v2.immo-facile.com/catalog/admin-v2/home.php?product_id='+b.original_id, '_blank')
                        //this.$router.push({ name: 'newBailleur', params: { newLocationProp: JSON.stringify(res) } })
                    } else {
                        // go to dashboard
                        this.$router.push({ name: 'dashboard' })
                    }
                })


        }

    }
};

</script>
<style>
.bg_light_orange {
    background: rgb(232, 165, 40);
}</style>