<template>
    <div>
    <div v-if="loading" class="text-center">
        <i class="fa fa-spinner fa-spin fa-3x"></i>
    </div>
    <button class="btn btn-primary" @click="loadPDF"><i class="fa fa-refresh"></i>  Recharger</button>
    <iframe :src="pdf" width="100%" style="height: calc(100vh - 300px);"></iframe>

    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import pdfmake from "pdfmake/build/pdfmake";
//import htmlToPdfmake from "html-to-pdfmake";




export default {
    name: "PDFView",
    components: {

    },
    props: {
        options: {
            type: Object,
            default: () => { }
        },
        modelValue: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            modele: this.modelValue,
            pdf: null,
            loading: true,
        }
    },
    computed: {
        ...mapGetters({
            getEvent: 'getEvent',
        }),
    },
    methods: {
        async loadPDF() {
            this.loading = true;
            this.loading = false;

            var pdfMake = require("pdfmake/build/pdfmake");
            var pdfFonts = require("pdfmake/build/vfs_fonts");
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            var htmlToPdfmake = require("html-to-pdfmake");
            var html =htmlToPdfmake(this.modele[this.options.get_prop],{tableAutoSize:true,
                customTag:function(params) {
    var ret = params.ret;
    var element = params.element;
    var parents = params.parents;

    switch(ret.nodeName) {
      case "DIV": {
        ret = this.applyStyle({ret:ret, parents:parents.concat([element])});
        ret.qr = ret.text[0].text;
        console.log("DIVQR",ret)
        switch(element.getAttribute("class")){
          case 'QR':
            console.log("QR",ret)
            delete ret.text;

            // convert to table with one cell and add QR code
            ret.table = {
              widths: ['auto'],
              body: [[{
                qr: ret.qr,
                fit: 80,
                margin: [0, 0, 0, 0]
              }
            ]],
            // noBorder: true
            noBorders: true
            };

           // ret.nodeName='QR';


            /*
            if(!ret.style || !Array.isArray(ret.style)){
              ret.style = [];
            }
            ret.style.push('html-qr');*/
            break;
        }

        break;
      }
    }
    console.log("RET",ret)
    return ret;

            }});
            console.log("HTML",html)
            pdfMake.fonts = {
                Roboto: {
                    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                },
            }

            let mywatermark = {
              text: 'Projet',
              color: 'blue',
              opacity: 0.2,
              bold: true,
              italics: false,
              fontSize: 150
            }
            console.log(this.options)
            var bg_options = null
            if (this.options.bg != null){
                bg_options = {
              image: 'data:image/png;base64,' + this.options.bg,
              width: 595
            }
            } else {
                let file_name = 'agence_file_32'
                if(localStorage.getItem(file_name)){
                    bg_options = {
                image: 'data:image/png;base64,' + localStorage.getItem(file_name),
                width: 595
                }
                }

            }

            /*
            let pageMarginsOptions_1= {
              left: 40,
              top: 60,
              right: 40,
              bottom: 60,
            }
            let pageMarginsOptions= {
              top: 60,
              bottom: 60,
              left: 40,
              right: 40
            }*/
            pdfmake.createPdf({
            watermark: mywatermark,

        pageMargins :[40, 140, 40, 60]
        ,
            background: function(currentPage, pageCount, pageSize) {
              // you can apply any logic and return any valid pdfmake element
              console.log(currentPage)
                console.log(pageCount)
                console.log(pageSize)

              if (currentPage == 1) {
                return bg_options

              }} ,
                content:html
            }).getDataUrl((dataUrl) => {
                this.pdf = dataUrl;
            })
        }
    },
    mounted() {
        this.loadPDF();
    },
}
</script>
