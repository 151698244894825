import axios from 'axios';
import authHeader from './auth-header';
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;


async function getListModeles(params){
  const response =  await axios.get(`${API_URL}/admin/modeles`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}
async function getListModelesGest(params){
  const response =  await axios.get(`${API_URL}/modeles`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}


async function getModele(ModeleId){
  const response =  await axios.get(`${API_URL}/admin/modeles/${ModeleId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}
async function getDetailedModele(ModeleId){
  const response =  await axios.get(`${API_URL}/modeles/${ModeleId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editModele(Modele){

  const response =  await axios.patch(`${API_URL}/admin/modeles/${Modele.id}?include=category,tags`, Modele, {headers: authHeader()});
  return response.data;
}
async function dupplicateModele(Modele){

  const response =  await axios.post(`${API_URL}/admin/modeles/${Modele.id}/duppl`, Modele, {headers: authHeader()});
  return response.data;
}


async function createModele(item){


  const response =  await axios.post(`${API_URL}/admin/modeles?include=category,tags`, item, {headers: authHeader()});
  return response.data;
}

async function deleteModele(ModeleId){
  await axios.delete(`${API_URL}/admin/modeles/${ModeleId}`, {headers: authHeader()});
}

async function uploadImageModele(Modele) {
    const payload = new FormData();
    payload.append("attachment", Modele.image);
  
    const response = await axios.post(`${API_URL}/uploads/modeles/${Modele.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListModeles,getListModelesGest,
    getModele,getDetailedModele,
    editModele,
    createModele,
    deleteModele,
    uploadImageModele,
    dupplicateModele
};