<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-4">

      <div class="container text-center mb-2  ">
        <div class="bg-gradient-pink p-3 border-radius-xl">
                <h5 class="mb-0 text-white">Liste des courriers</h5>
        </div>
        <div class="mt-2">
        <ArgonInput icon="fa fa-search" iconDir="left" placeholder="Rechercher" v-model="search"/>

        </div>
        <!--
            <div class="dataTable-search ">
                <input   class="form-control rounded-lg dataTable-input search-input-table" placeholder="Recherche..." type="text">
            </div>-->
      </div>
              <!--<div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <router-link :to="{ name: 'Add Role' }"
                    class="mb-0 btn bg-gradient-success btn-sm">
                      <i class="fa fa-plus me-1"/> Add Role
                  </router-link>
                </div>
              </div>-->


        <div class="card">
          <!-- Card header -->
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive" id="infinite-list" style="height:calc(100vh - 200px)">
              <table   class="table table-responsive">
                <tbody class="text-sm" >
                  <tr v-for="row in roleList" v-bind:key="row.id" >
                    <div   @click="editItem(row)" class=" d-flex align-items-start flex-column justify-content-center" style=" padding: 5px; cursor: pointer;margin: 0px;" >

                 <h6 class="mb-0 text-sm" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 20px; ">{{ row.from }}</h6>
                 <h6 class="mb-0 text-sm" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 20px; ">{{ row.message }}</h6>
                 <p class="mb-0  text-xs" style="color: grey; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; line-height: 16px; ">

           <button v-if="row.building" class="btn btn-danger btn-sm" style="margin: 0;padding: 0;"><i
           class="fa fa-building"></i>{{ row.building.EXTAPI_IMM_ID }}</button>

          <b style="color:rgb( 0,209, 0);font-weight: 600;">{{ new Date(row.received).toLocaleDateString("fr", {minute: 'numeric',  hour: 'numeric', year: 'numeric', month: 'numeric',day: 'numeric' } ) }}</b>  {{ row.bodyPreview }} </p>



             </div>

                  </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card"
           v-if="mailid">

            <CourrierView v-bind:id="mailid" />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
//import { DataTable } from "simple-datatables";
import store from "../../../store";
//import BasePagination from "../../../components/BasePagination.vue";
import eventTable from "../../../mixins/eventTable.js";
import _ from "lodash";
import ArgonInput from "../../../components/ArgonInput.vue";
import CourrierView from "./CourrierView.vue";

var sortDirection = "EXTAPI_IMM_ID";
var getCurrentPage = 1;
var searchQuery = '';

const getRoleList = _.debounce(async function (params) {
  await store.dispatch("courrier/courriersList", {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.query ? { search: params.query } : {}),

    page: params.nr,
    size: params.per_page,
    /*page: {
      number: params.to,
    },*/
  });
}, 300);

export default {
  name: "ListRolePage",
  components: {
    //BasePagination,
    ArgonInput,
    CourrierView
},
  mixins: [eventTable],
  data() {
    return {
      mailid: null,
      updatedList:[],
      dataRoles: [],
      pagination: {},
      tableRoles: null,
      keys: null,
      page_t: 1,
      search: ''
    }
  },
  computed: {
    roleList() {
      return this.getList();
    },
    metaPage() {
      return this.$store.getters["courrier/courriersList"]?.meta;
    }
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    roleList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch"
    }
  },
  async mounted() {

    const masonry = document.querySelector('#infinite-list');


    masonry.addEventListener('scroll', e => {
    console.log(e)
      if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
        if (this.metaPage.current_page == 1){
          this.page_t = 1
        }
        if (this.page_t <= this.metaPage.current_page && this.page_t <= this.metaPage.last_page) {
        this.page_t+=1;
        this.getDataFromPage(this.page_t);
        }
      }
    })

      await getRoleList({
        sort: sortDirection,
        query: '',
        nr: getCurrentPage,
        perpage: 15//this.tableRoles.options.perPage
      });
  },
  beforeUnmount() {
    sortDirection = "EXTAPI_IMM_ID";
    searchQuery = '';
    getCurrentPage = 1;
  },
  methods: {
      editItem(row) {
        this.mailid = row.id;
        this.$router.replace({
           query: { id: row.id }
              })
        this.showlist =false;
/*
            this.$router.push({

                path: "/s/mails",
                params: { id: row.id }
            });
       */

      },
    getList(){
      if (this.$store.getters["mail/mailsList"]) {
        if (this.$store.getters["mail/mailsList"].meta.current_page == 1){
          console.log(this.$store.getters["mail/mailsList"].data)
          this.updatedList = this.$store.getters["mail/mailsList"].data

        }
        else {
          this.updatedList = [...this.updatedList,...this.$store.getters["mail/mailsList"].data]

        }
      }

      else{
        this.updatedList = [...this.updatedList]
      }
      return this.updatedList
    },
    openImmeuble(row){
            this.$router.push({
                name: "Mail",
                params: { id: row.id }
            });
          },
    async getDataFromPage(page) {
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: 15//this.tableRoles.options.perPage
      });
    },

    async reactiveSearch() {
      searchQuery = this.search;
      console.log(searchQuery)
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        nr: 1,
        perpage: 15//this.tableRoles.options.perPage
      });
    },

    async reactivePagination() {

      this.pagination = await this.metaPage;


      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total

      }
      getCurrentPage = this.pagination.current_page;
      return this.pagination;
    },

    async reactiveTable() {/*
      this.dataRoles = [];
      if (this.roleList.length > 0) {
        this.roleList.forEach(row => {
          this.dataRoles.push(
            [

            `${row.EXTAPI_IMM_ID}`,
              `<h6 class="my-auto">${row.nom}</h6>`,
              row.created_at,
              this.actionEditButton(row.id, "Immeuble") + this.actionDeleteButton(row.id, "Delete Role")
            ]
          )
        });
        this.tableRoles.data = [];
        this.tableRoles.refresh();
        this.tableRoles.insert({ data: this.dataRoles });
        this.removeEvent();
        this.eventToCall({
          table: this.tableRoles,
          redirectPath: "Edit Role",
          deletePath: "role/deleteRole",
          getPath: "role/rolesList",
          textDelete: "Role deleted successfully!",
          textDefaultData: 'roles'
        });
      }
      else {
        this.tableRoles.setMessage('Aucun résultat trouvé');
      }*/
    }
  },
};
</script>
<style>
.dataTable-top {
  display: none;
}
</style>
