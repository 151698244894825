<script setup>
import { useStore } from 'vuex'
import { ScheduleXCalendar } from '@schedule-x/vue'
import { createCurrentTimePlugin } from '@schedule-x/current-time'
import { createResizePlugin } from '@schedule-x/resize'
import { createEventModalPlugin } from '@schedule-x/event-modal'
import { createScrollControllerPlugin } from '@schedule-x/scroll-controller'
import { createEventsServicePlugin } from '@schedule-x/events-service'
import {
  createCalendar,
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek,
} from '@schedule-x/calendar'
import '@schedule-x/theme-default/dist/index.css'
import { onMounted } from 'vue'

// Do not use a ref here, as the calendar instance is not reactive, and doing so might cause issues
// For updating events, use the events service plugin

const store = useStore();

const scrollController = createScrollControllerPlugin({
  initialScroll: // current hour - 3 hours
  (new Date().getHours() - 3).toString().padStart(2, '0')+':00',
})

const eventsServicePlugin = createEventsServicePlugin();

function convertDate(dateStr) {
    // Create a new Date object using the input string
    const date = new Date(dateStr);

    // Format the date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Construct the new date string
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}


const updateEvents = async (range)=> {

    await  store.dispatch("calendar/eventsList", range);
      const val = store.getters["calendar/eventsList"];
        console.log('events', val)

        val.events.forEach((event) => {
            if(event.start.dateTime == null || event.end.dateTime == null) return;
            // if event is not in the calendar, add it
            if (!eventsServicePlugin.get(event.id)) {
                eventsServicePlugin.add({
                    id: event.id,
                    title: event.subject,
                    start: convertDate(event.start.dateTime),
                    end: convertDate(event.end.dateTime),
                })
            } else {
                // if event is in the calendar, update it
                eventsServicePlugin.update(event.id, {
                    title: event.subject,
                    start: convertDate(event.start.dateTime),
                    end: convertDate(event.end.dateTime),
                })
            }

        })





      console.log('new calendar range start date', range.start)
      console.log('new calendar range end date', range.end)
}


const calendarApp = createCalendar({

  locale: 'fr-FR',
//  selectedDate: new Date().toJSON(),
  views: [
    createViewDay(),
    createViewWeek(),
    createViewMonthGrid(),
    createViewMonthAgenda()

  ],
  monthGrid: {
    weekStartsOn: 1,
    nEventsPerDay: 3
  },

  plugins: [createCurrentTimePlugin(),createResizePlugin(),createEventModalPlugin(),scrollController,eventsServicePlugin],
  callbacks:{
    async onRangeUpdate(range) {
        updateEvents(range)
    },
  },
})

onMounted(() => {
  //updateEvents({})
})

//

</script>

<template>
  <div>
    <ScheduleXCalendar :calendar-app="calendarApp" style="height:90vh" />
  </div>
</template>
