/* eslint-disable no-unused-vars */
import ControleurService from "../services/controleur.service.js";

export const controleur = {
    namespaced: true,
    state: {
        controleursList: null,
        oneControleur: null,
        image: null,
    },
    getters: {
        controleursList: state => state.controleursList,
        oneControleur: state => state.oneControleur,
        image: state => state.image,
    },
    actions: {
        async controleursList({ commit }, params) {
            console.log("action")
            const data = await ControleurService.getListControleurs(params);
            console.log(data);
            commit('ControleurS_LIST', data);
        },
        async getControleur({ commit }, itemId) {
            const data = await ControleurService.getControleur(itemId);
            commit('GET_Controleur', data);
        },
        async addControleur({ commit }, item) {
            const data = await ControleurService.createControleur(item);
            commit('GET_Controleur', data);
        },
        async editControleur({ commit }, item) {
            await ControleurService.editControleur(item);
        },
        async deleteControleur({ commit }, itemId) {
            await ControleurService.deleteControleur(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await ControleurService.uploadImageControleur(item);
            commit('UPLOAD_IMAGE_Controleur', data);
        },
    },
    mutations: {
        ControleurS_LIST(state, data) {
            state.controleursList = data;
        },
        GET_Controleur(state, data) {
            state.oneControleur = data;
        },
        UPLOAD_IMAGE_Controleur(state, data) {
            state.image = data;
        },
  }
}