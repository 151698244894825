
<template>
    <div class="w-100 d-flex mb-1">
                    <div class="el-input-group__prepend">
            {{ label }}</div>
        <el-select class="w-100"
           v-model="modele[options.prop]" filterable remote reserve-keyword :placeholder="label"
                    remote-show-suffix :remote-method="search" :loading="loading.perso" :disabled="disabled.perso" multiple allow-create>

                    <el-option v-for="item in people" :key="item.id" :label="item.nom +' '+item.prenom" :value="item.mails[0]?.mail" >
                    {{ item.nom +' '+item.prenom  +' <'+item.mails[0]?.mail+'>'}}
                    </el-option>
                </el-select>


    </div>
</template>
<script>
//import AutoComplete from 'primevue/autocomplete';
import { ElSelect, ElOption } from 'element-plus';

export default {
    name: 'AutoCompleteGroupDemo',
    components: {
    ElSelect,
    ElOption
},
    data() {
        return {
            cities: null,
            modele: this.modelValue,
            loading: {
                perso: false
            },
            disabled: {
                perso: false
            },

            item: {
                description: null,

            },
            selectedCity: '',
            filteredCities: null,
            people: []
        };
    },
    props:{
        label:{
            type:String,
            default:''
        },
        options: {
            type: Object,
            default: () => {
                return {
                    prop: 'to'
                };
            },
        },
        modelValue: {
            type: Object,
            default: ()=>{}
        },
    },
    watch: {
        modele: function (val) {
            let val2 = [];
            val[this.options.prop].forEach((item) => {
                if(item.includes('@')){
                    val2.push(item)
                }
            })
            val[this.options.prop] = val2;
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.selectedCity = val
        },
    },
    methods: {
        async search(event) {
            this.loading.perso = true;
                try{
            let params = {
                    ...(event ? { search: event } : {}),
                    page: 1,
                    size: 20,
                    //sort: "events.last",
                };
                    await this.$store.dispatch("perso/persosList", params);
             let c = this.$store.getters["perso/persosList"].data;

            this.people = []
            c.forEach((item) => {
                if(item.mails.length > 0){
                    this.people.push(item)
                }

            })
                } catch(e){
                    console.log(e)
                }


            this.loading.perso = false;
        }
    }
};
</script>
