import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListSyndics(params){
  const response =  await axios.get(`${API_URL}/syndics`, { 
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getSyndic(SyndicId){
  const response =  await axios.get(`${API_URL}/syndics/${SyndicId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function editSyndic(controller){

  const payload = jsona.serialize({
    stuff: controller,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/syndics/${controller.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createSyndic(controller){

  const payload = jsona.serialize({
    stuff: controller,
    includeNames: []
  });

  const response =  await axios.post(`${API_URL}/syndics?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function deleteSyndic(SyndicId){
  await axios.delete(`${API_URL}/syndics/${SyndicId}`, {headers: authHeader()});
}

async function uploadImageSyndic(controller) {
    const payload = new FormData();
    payload.append("attachment", controller.image);
  
    const response = await axios.post(`${API_URL}/uploads/syndics/${controller.id}/image`, payload, { headers: authHeader() });
    return response.data.url;
  
  }

export default {
  getListSyndics,
    getSyndic,
    editSyndic,
    createSyndic,
    deleteSyndic,
    uploadImageSyndic
};