<template>
    <div>

        <ModalNew v-model="modal_new" closeable header="Nouvel évènement" :saveDraft="actionDraft" container="fluid" maxwidth="95vw">
    <Block v-if="options != {}" :options="options" v-model="modele" :action="action" :blocking="blocking"></Block>
{{ options }}
        </ModalNew>


    </div>

</template>
<script>
//import BaseButton from "@/components/BaseButton.vue";
import Block from "@/blocks/Block.vue";
import ModalNew from "../../../components/ModalNew.vue";
import showSwal from "../../../mixins/showSwal";
export default {
    name: "NewEvent",
    components: {
        Block,
        ModalNew
    },
    props: {
        newEventModal: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
            default: () => {
                return {
                html:'<p>test</p>'
                };
            },
        },
        blocking: {
            type: Array,
            default: () => [],
        },
    },
    mixins: [showSwal],
    watch:{
        modal_new: function (val) {
            this.$emit('update:newEventModal', val)
        },
        newEventModal: function (val) {
            console.log('newEventModal',val)
            this.modal_new = val
        },
        modele: function (val) {
            this.$emit('update:modelValue', val)
        },
        modelValue: function (val) {
            this.modele = val
        }
        
    },
    data() {
        return {
            modal_new:this.newEventModal,
            modals: {
                modalnewevent: {
                    show: false,
                },
            },
            options:{},
            modele:this.modelValue
        };
    },
    methods: {
      actionDraft(){
        this.modele.draft = true
        this.action()
      },
    async action() {
      try{
        //delete this.modele.tab0
        //delete this.modele.tab1
        switch(this.modele.tab0.name){
          case 'Consigner':
            this.modele.received_type = 'N'
            break;
          case 'Intervenir':
            this.modele.received_type = 'I'
            break;
          case 'Communiquer':
            this.modele.received_type = 'C'
            break;
        }
        this.modele.type = this.modele.tab1.name_after
           await this.$store.dispatch("event/addEvent", this.modele);
          let v = this.$store.getters['event/oneEvent']
        console.log(v)
        this.modal_new = false
        this.$router.push({name:'Évènement',params:{id:v.id}})
            this.showSwal({message:"Évènement créé",type: "success"});
      } catch(e){
            this.showSwal({message:"problème",type: "danger"});
          }



        },
        async loadView(){
          console.log("LOADING VIEW")

          await this.$store.dispatch("view/getDetailedView", 1)
          let v = this.$store.getters['view/oneView']
          this.options = v.view

          console.log(v)

        }

    },
    mounted(){

      this.loadView()

    }
};
</script>