<template>
    <div class="p-2">
        <!--
        <base-header class="pb-1 bg-white" style="width: 100%;" v-if="items">
          <div class="row align-items-center pb-3" v-if="items[0]">
            <div class="col-lg-12 ">
              <h6 class="h5  d-inline-block mb-0">{{ items[0].message }}</h6>
            </div>
          </div>

        </base-header>
        -->

        <base-header class="col-xl-12  pr-2 pt-3 pb-2 pl-2 bg-gradient-green border-radius-xl" >
        <div style="display:flex">

          <div class="h6 text-white pt-1 mr-2" >Contexte</div>

          <div v-for="elt in contexte" :key="elt.id" style="display:flex; width:100%;margin-left: 10px;">

            <button class="btn btn-danger btn-xs" v-if="elt[1]" @click="goImm(elt[1].id)"><i class="fa fa-building"></i>{{
              elt[1].nom }}
            </button>
            <button class="btn btn-primary btn-xs" v-if="elt[2]" @click="goEvent(elt[2])"><i class="fa fa-eye"></i>Voir
              l'évènement associé
            </button>
            <div style="flex:1"></div>



            <button class="btn btn-xs btn-warning text-white" @click="modals.corrigerContexte.show = true"><i
                class="fa fa-pen"></i>Corriger le contexte</button>


          </div>
        </div>

        </base-header>
        <div class="col-xl-12 pt-4">
          <div v-for="item in items" v-bind:key="item.id">
            <div v-if="item.main">
              <div  class="mb-0 row">
                <div class="col-lg-8">
                  <h4>{{ item.message }}</h4>
                  <h6 style="display: flex;">De
                    <a
                      style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;line-height: 20px;overflow-x: scroll;">
                      <el-tooltip placement="bottom">
                        <template #content>
                          <h4 class="text-white">{{ item.from_email }}</h4>

                          <div v-if="item.from_perso">
                            <h3 class="text-white">{{ item.from_perso[0] }} {{ item.from_perso[1] }}</h3>
                            <i v-if="item.from_perso[3]" class="fas fa-check text-success">Dans mon syndic</i>
                            <button class="btn btn-sm btn-warning" @click="goPerso(item.from_perso[2])">Aller sur la
                              fiche</button>

                          </div>

                        </template>
                        <base-button size="xs" type="neutral">
                          <span class="btn-inner--icon" v-if="item.from_perso">
                            <i v-if="item.from_perso[3]" class="fas fa-check text-success"></i>
                            <i v-else class="fas fa-check"></i>
                          </span>
                          <span class="btn-inner--text">{{ item.from }}</span>

                        </base-button>

                      </el-tooltip>
                    </a>
                  </h6>
                  <h6>À <a v-for="t in item.to" v-bind:key="t.emailAddress.name">

                      <el-tooltip placement="bottom" effect="light">
                        <template #content> {{ t.emailAddress }}</template>
                        <base-button size="xs" type="neutral">{{ t.emailAddress.name }}</base-button>

                      </el-tooltip>
                    </a>
                  </h6>
                </div>
                <div class="col-lg-4 text-right">
                  <h5>Le {{ new Date(item.received).toLocaleDateString("fr", {
                    minute: 'numeric', hour: 'numeric', year:
                      'numeric', month: 'numeric', day: 'numeric'
                  }) }} </h5>

                </div>

              </div>
              <div>
                <img :src="srcimg" v-if="srcimg" />
                <iframe :srcdoc="item.body" width="100%"
                  onload="javascript:(function(o){o.style.height=(o.contentWindow.document.body.scrollHeight+35)+'px';}(this));"
                  style="height:calc(100vh - 400px);width:100%;border:none;overflow:hidden;" />
                <a v-for="t in item.attachments" v-bind:key="t.id">
                  <base-button size="xs" type="neutral" @click="get_att(item.id, t.id)">{{ t.name }}</base-button>

                </a>
              </div>


            </div>
          </div>
        </div>
    </div>
</template>
<script>
import BaseHeader from '../../../components/BaseHeader.vue';
import BaseButton from '../../../components/BaseButton.vue';
export default {
    components: {
        BaseHeader, BaseButton
    },
  props: ['id'],
  watch: {
    id: {
      async handler() {
        this.get();
      }
    }
  },
    data() {
        return {
            items: [],
            contexte: [],
            modals: {
                corrigerContexte: {
                    show: false
                }
            }
        }
    },
    methods: {
        goImm(id) {
            this.$router.push({
                name: 'Immeuble',
                params: {
                    id: id
                }
            })
        },
        goEvent(id) {
            this.$router.push({
                name: 'Immeuble',
                params: {
                    id: id
                }
            })
        },
    async get() {
      try {
        console.log('QUERY', this.id)

        const id = this.id;
        console.log('QUERY', id, this.$route.query)
        this.mainid = id;
        await this.$store.dispatch("mail/getMail", id);
        var b = this.$store.getters["mail/oneMail"]
        this.items = b.mails;
        var maxelt = 0;
        var va = Object.values(b.contexte);

        var numax = 1;
        va.forEach(elt => {
          if (elt[0] >= maxelt) {
            if (elt[0] == maxelt) {
              numax += 1;
            } else {
              numax = 1;
            }
            maxelt = elt[0];
          }
        })
        console.log(va, maxelt)
        var contexte2 = new Array();
        if (numax < 4) {

          va.forEach(elt => {
            if (elt[0] >= maxelt) {
              contexte2.push(elt);
            }
          })
        }

        this.contexte = contexte2;
        console.log('itis', contexte2);

      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Problème de récupération de l'e-mail!`,
        });
      }
    },
    },
    mounted() {
        this.items = this.$route.params.items;
        this.contexte = this.$route.params.contexte;
        this.get()
    }
}

</script>