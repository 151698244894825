<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Connexion Outlook</h1>
              <p class="text-lead text-white">Avec compte Microsoft</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card card-profile bg-secondary mt-5">
            <div class="card-body pt-7 px-5">
              <div class="text-center mb-4">
                <h3>Une erreur s'est produite</h3>
              </div>
              <div class="text-center">
                <base-button class="mt-2" type="primary" @click="goHome"
                  >Revenir à l'accueil</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      item: {
        nom: "T",
        id: "",
        lots: {
          numlot: 0,
          perso: {
            nom: "TEST",
          },
        },
        mainevents: {
          title: "",
          created_at: "",
          text: "",
        },
      },
      loading: true,
    };
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
  },
  watch: {
    code: {
      async handler() {
        this.get();
      },
    },
  },
  created() {
    this.get();
  },
  mounted() {
  },
  methods: {
    goHome() {
      window.location = "../../";
    },

    async get() {
      try {
        console.log("CONNECTstore", this.$store);

        //
        await this.$store.dispatch("mail/validateconnect", {
          code: this.$route.query.code,
          state: this.$route.query.state,
        });
        this.connect = this.$store.getters["mail/connect"];
        console.log(this.connect);
            sessionStorage.deleteItem("fullme");
        if (this.connect.valid == "enregistre") {
            localStorage.deleteItem("microsoft_auth_url");
            window.location = "../../";
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Le profil n'a pas été trouvé !`,
        });
      }
    },

  },
};
</script>
<style></style>
