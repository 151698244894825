<template>
    <div class="border-radius-xl"
      style="background: #E9EAF9;" >
        <div class="bg-gradient-primary border-radius-xl p-2 " >
            <div class="row">
                <div class="col-9" @click="goMails" style="cursor: pointer;">
                    <h4 class="h6 mb-0 text-white">Liste des évènements</h4>
                </div>
                <div class="col-3 text-end">
                    <button class="btn btn-xs btn-light mb-0"  @click="$emit('update:modals', {show:true, text:'Intro'})">
                    <span class="btn-inner--icon"
                        ><i class="fas fa-edit"></i
                    ></span>
                    </button>
                </div>
            </div>
         
        </div>
    <div
      class="px-0 pb-1 pt-2"
      
      body-classes=""
      td-classes="pd-5"
      footer-classes=" py-0"
    >
    <div class="table-responsive" id="infinite-list-events" style="height:calc((100vh - 400px) / 2)">
              <table   class="table table-responsive">
                <tbody class="text-sm" > 
                  <tr v-for="evenement in roleList" v-bind:key="evenement.id" @click="openImmeuble(evenement)">
                    <td class="text-danger" v-if="evenement.immeuble">
                      {{ evenement.immeuble.EXTAPI_IMM_ID }}
                    </td>
                    <td style="flex:1">{{ evenement.title }}</td>
                    <td>{{ evenement.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
    </div>
</template>

<script>
//import { DataTable } from "simple-datatables";
import store from "../../../store";
//import BasePagination from "../../../components/BasePagination.vue";
import eventTable from "../../../mixins/eventTable.js";
import _ from "lodash";

var sortDirection = "id";
var getCurrentPage = 1;
var searchQuery = '';

const getRoleList = _.debounce(async function (params) { 
  await store.dispatch("event/eventsListF", {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.query ? { search: params.query } : {}),
  
    page: params.nr,
    size: params.per_page,
    /*page: {
      number: params.to,
    },*/
  });
}, 300);


export default {
  name: "ListRolePage",
  components: {
    //BasePagination,
},
  mixins: [eventTable],
  data() {
    return {
      eventid: null,
      updatedList:[],
      dataRoles: [],
      pagination: {},
      tableRoles: null,
      keys: null,
      page_t: 1,
      search: ''
    }
  },
  computed: {
    roleList() {
      return this.getList();
    },
    metaPage() {
      return this.$store.getters["event/eventsList"]?.meta;
    }
  },
  watch: {
    metaPage: {
      handler: "reactivePagination",
      immediate: false,
      deep: true,
    },
    roleList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
    search: {
      handler: "reactiveSearch"
    }
  },
  async mounted() {

    const masonry = document.querySelector('#infinite-list-events');


    masonry.addEventListener('scroll', e => {
    console.log(e)
      if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
        if (this.metaPage.current_page == 1){
          this.page_t = 1
        }
        if (this.page_t <= this.metaPage.current_page && this.page_t <= this.metaPage.last_page) {
        this.page_t+=1;
        this.getDataFromPage(this.page_t);
        }
      }
    })

      await getRoleList({
        sort: sortDirection,
        query: '',
        nr: getCurrentPage,
        perpage: 15//this.tableRoles.options.perPage
      });
  },
  beforeUnmount() {
    sortDirection = "EXTAPI_IMM_ID";
    searchQuery = '';
    getCurrentPage = 1;
  },
  methods: {
      editItem(row) {
        this.eventid = row.id;
              this.$router.push({
                  name: "Évènement",
           params: { id: row.id }
              })
        this.showlist =false;
/*
            this.$router.push({
              
                path: "/s/mails",
                params: { id: row.id }
            });
       */
        
      },
    getList(){
      if (this.$store.getters["event/eventsList"]) {
        if (this.$store.getters["event/eventsList"].meta.current_page == 1){
          console.log(this.$store.getters["event/eventsList"].data)
          this.updatedList = this.$store.getters["event/eventsList"].data

        }
        else {
          this.updatedList = [...this.updatedList,...this.$store.getters["event/eventsList"].data]

        }
      }
      
      else{
        this.updatedList = [...this.updatedList]
      }
      return this.updatedList
    },
    openImmeuble(row){
            this.$router.push({
                name: "ÉvènementF",
                params: { id: row.id }
            });
          },
    async getDataFromPage(page) {
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: 15//this.tableRoles.options.perPage
      });
    },

    async reactiveSearch() {
      searchQuery = this.search;
      console.log(searchQuery)
      await getRoleList({
        sort: sortDirection,
        query: this.search,
        nr: 1,
        perpage: 15//this.tableRoles.options.perPage
      });
    },

    async reactivePagination() {
      
      this.pagination = await this.metaPage;

      
      this.pagination = {
        currentPage: this.pagination.current_page,
        perPage: this.pagination.per_page,
        total: this.pagination.total
      
      }
      getCurrentPage = this.pagination.current_page;
      return this.pagination;
    },

    async reactiveTable() {/*
      this.dataRoles = [];
      if (this.roleList.length > 0) {
        this.roleList.forEach(row => {
          this.dataRoles.push(
            [

            `${row.EXTAPI_IMM_ID}`,
              `<h6 class="my-auto">${row.nom}</h6>`,
              row.created_at,
              this.actionEditButton(row.id, "Immeuble") + this.actionDeleteButton(row.id, "Delete Role")
            ]
          )
        });
        this.tableRoles.data = [];
        this.tableRoles.refresh();
        this.tableRoles.insert({ data: this.dataRoles });
        this.removeEvent();
        this.eventToCall({
          table: this.tableRoles,
          redirectPath: "Edit Role",
          deletePath: "role/deleteRole",
          getPath: "role/rolesList",
          textDelete: "Role deleted successfully!",
          textDefaultData: 'roles'
        });
      }
      else {
        this.tableRoles.setMessage('Aucun résultat trouvé');
      }*/
    }
  },
};
</script>
<style>
.dataTable-top {
  display: none;
}
</style>