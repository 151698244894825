<template>
  <div class="card">
    <div class="p-3 card-body">
      <div class="row">
        <div class="my-auto col-8">
          <div class="numbers">
            <p
              class="mb-0 text-sm text-secondary text-capitalize font-weight-bold opacity-7"
            >
              Weather today
            </p>
            <h5 class="mb-0 text-dark font-weight-bolder">
              San Francisco - 29°C
            </h5>
          </div>
        </div>
        <div class="col-4 text-end">
          <img
            class="w-50"
            src="@/assets/img/small-logos/icon-sun-cloud.png"
            alt="image sun"
          />
          <h5 class="mb-0 text-dark text-end me-1">Cloudy</h5>
        </div>
      </div>
    </div>
  </div>
</template>
