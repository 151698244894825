<template>
    <div class="row p-4">
        <div class="col-lg-6 ">
            <div class="row">
                <div class="col-md-9">
            <h1 class="h4">Modifier un modèle</h1>

                </div>
                <div class="col-auto text-end">
                    <button class="btn btn-info" @click="save_model"><i class="fa fa-save"></i> Enregistrer</button>
                </div>
            </div>
            <input type="text" v-model="content.theme" class="mb-2 form-control" placeholder="Thème" >
            <input   type="text" v-model="content.title" class="mb-2 form-control" placeholder="Titre">
            <div class="card p-2 mb-2">
    <el-select class="mt-1" style="width:100%" round v-model="content.categorie" filterable multiple placeholder="Catégories (facultatif) pour création d'évènement"
                    >
                    <el-option key="rdv" value="Organiser un RDV">Organiser un RDV</el-option>
                    <el-option key="note" value="Ajouter une note">Ajouter une note</el-option>
                    <el-option key="visite" value="Compte-rendu de visite"  >Compte-rendu de visite</el-option>
                    <el-option key="sinistre" value="Sinistre" >Sinistre</el-option>
                    <el-option key="devis"  value="Devis">Devis</el-option>
                    <el-option key="os" value="OS" >OS</el-option>
                    <el-option key="courrier" value="Courrier">Courrier</el-option>
                    <el-option key="mail" value="Mail" >Mail</el-option>
                    <el-option key="sms" value="SMS" >SMS</el-option>
                </el-select>
OU
    <el-select class="mt-1" style="width:100%" round v-model="content.flow" filterable remote multiple reserve-keyword placeholder="Modèles précédents (facultatif) pour poursuite d'évènement"
                    remote-show-suffix :remote-method="getModeles" :loading="loading.modele" >
                    <el-option v-for="item in all_modeles" :key="item.id" :label="item.title" :value="item.id" />
                </el-select>
</div>
            <input type="text" v-model="content.syndic_id" class="mb-2 form-control" placeholder="ID syndic" >

            <input type="text" v-model="content.agence_id" class="mb-2 form-control" placeholder="ID agence" >


            <RichText v-model="content" :options="options"/>

        </div>
        <div class="col-lg-6">
            <PDFView v-if="show_pdf" v-model="content" :options="options_pdf"/>

        </div>
    </div>
</template>
<script>
import axios from 'axios';
import PDFView from '@/blocks/Controls/PDFView.vue';
import RichText from '@/blocks/Controls/RichText.vue';
import showSwal from '../../../mixins/showSwal';
import {ElSelect, ElOption} from "element-plus";
//import bg_capitole from '../../../assets/img/bg_capitole.png';
export default {
    components: {
    RichText,
    PDFView,
    ElSelect,ElOption,
},
    data() {
        return {
            content: {value:'<p>Some <strong>rich</strong> <em>text</em></p>',
            syndic_id:1,
            agence_id:1,
            categorie:'Devis',
            flow:'Entrant',
        },
        loading:{
            modele:false,
        },
        all_modeles:[],
            options: {
                prop: 'value'
            },
            options_pdf:{
                bg: null,
                prop: 'render',
                get_prop: 'value',
            },
            show_pdf: false,
        }
    },
    mixins: [showSwal],
    methods: {
        arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    },
    async fetchFile(file) {
      try {
        const response = await axios.get(file, { responseType: 'arraybuffer' });
        const base64 = this.arrayBufferToBase64(response.data);
        this.options_pdf.bg =  base64;
        console.log(this.options_pdf)
        this.show_pdf = true;
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    },
        async set_bg(agence_id) {
          await this.$store.dispatch("agence/getAgence", agence_id)
            let agence = this.$store.getters["agence/oneAgence"]
            let pdfop = this.options_pdf
            //let test = {}
            agence.attributes.forEach(element => {
                if (element.name !== undefined && element.attr_type !== undefined  && element.value !== undefined ){
                console.log("A")
                console.log(element.name)
                console.log(element.attr_type)
                console.log(element.value)
                //test[element.attr_type]
                //[element.name] = element.value
                if (element.attr_typ in pdfop) {
                    pdfop[element.attr_type] = element.value
                } else {
                    pdfop[element.attr_type] = {}
                    pdfop[element.attr_type][element.name] = element.value
                }
                }
            });
            let attr = agence.attributes.find(attr=>attr.name=="bg_1")
            let file_id = agence.attributes.find(attr=>attr.name=="bg_1").fichiers[0].id
            let attr_id = attr.id
            //let fic_id = fichier1.id
            let sent = {agence_id,attr_id,file_id}
            console.log(sent)
            
            await this.$store.dispatch("agence/getFile", sent)
            let file1 = this.$store.getters["agence/file"]
            console.log(file1)
            this.fetchFile(file1)


/*
            let file_id = "test"
            
          await this.$store.dispatch("agence/getFile", agence_id, file_id)
            let file = this.$store.getters["agence/file"]
            this.options_pdf.bg = file*/
        },
        async getModeles(el){
            this.loading.modele = true
            let params ={'query' :el}
  await this.$store.dispatch("modele/modelesList", {
    ...(params.query ? { search: params.query } : {}),
    sort:"id",
    page: params.nr,
    size: 25,
    /*page: {
      number: params.to,
    },*/
  });
      if (this.$store.getters["modele/modelesList"]) {
        if (this.$store.getters["modele/modelesList"].meta.current_page == 1){
          console.log(this.$store.getters["modele/modelesList"].data)
          this.updatedList = this.$store.getters["modele/modelesList"].data

        }
        else {
          this.updatedList = [...this.updatedList,...this.$store.getters["modele/modelesList"].data]

        }
      }
      
      this.all_modeles = this.updatedList
        this.loading.modele = false
    },
        
        async save_model(){
            let modele = {
                title: this.content.title,
                syndic_id: this.content.syndic_id,
                element:  this.content.theme,
                data: this.content.value,
                id: this.$route.params.id,
                categories: this.content.categorie,
                flow: this.content.flow,

            }

          await this.$store.dispatch("modele/editModele", modele)
            let modele2 = this.$store.getters["modele/oneModele"]
            console.log(modele2)
            
            this.showSwal({message:"Modèle modifié",type: "success"});
            //
        }
    },
  async mounted() {
    await this.$store.dispatch("modele/getModele", this.$route.params.id);
    this.item = await this.$store.getters["modele/oneModele"];
    this.content.title = this.item.title;
    this.content.syndic_id = this.item.syndic_id;
    this.content.value = JSON.parse(this.item.data);
    this.content.theme = this.item.element;
    /*
    this.content.categorie = this.item.categories;
    this.content.flow = this.item.flow;*/

        this.set_bg(1)
    }
}

</script>